import React, { useState, useEffect } from "react";
import { sortableContainer, arrayMove } from "react-sortable-hoc";

/**
 * Component that wraps elements in a sortable container.
 *
 * @param {object} props - The component's props.
 * @param {React.ReactNode} props.children - Child elements to render.
 * @returns {JSX.Element}
 */
const SortableContainerWrapper = sortableContainer(({ children }) => (
    <ul style={{ cursor: "grab" }} className="list-none  p-0 m-0">
        {children}
    </ul>
));

/**
 * SortableList Component
 *
 * Renders a sortable list where items can be dragged with movement locked to the specified direction.
 *
 * @param {object} props - The component's props.
 * @param {Array} props.items - Array of objects in the form { id, value, actions, ... }.
 * @param {Function} props.SortableItemComponent - Component wrapped with sortableElement for each item.
 * @param {Function} [props.onSortEnd] - Callback executed when reordering is finished.
 * @param {"x"|"y"|"xy"} [props.lock="y"] - The axis along which dragging is locked (lockAxis).
 * @param {boolean} [props.lockToContainerEdges=true] - Whether movement should be constrained to the container edges.
 * @returns {JSX.Element}
 */

const SortableList = ({
    items: propItems,
    SortableItemComponent,
    onSortEnd: parentOnSortEnd,
    lock = "xy",
    lockToContainerEdges = false,
}) => {
    const [items, setItems] = useState(propItems || []);

    useEffect(() => {
        if (propItems) {
            setItems(propItems);
        }
    }, [propItems]);

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        const newItems = arrayMove(items, oldIndex, newIndex);
        setItems(newItems);

        if (typeof parentOnSortEnd === "function") {
            parentOnSortEnd({
                updatedItems: newItems,
                movedItem: items[oldIndex],
                oldIndex,
                newIndex,
            });
        }
    };

    return (
        <SortableContainerWrapper
            onSortEnd={handleSortEnd}
            lockAxis={lock}
            distance={30}
            lockToContainerEdges={lockToContainerEdges}
        >
            {items.map((item, index) => (
                <SortableItemComponent key={item.id || index} index={index} {...item} />
            ))}
        </SortableContainerWrapper>
    );
};

export default SortableList;
