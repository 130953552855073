import React, { useEffect, useState } from "react";
import {
    parseVendureTranslation,
    getProjectLangs,
    formatPriceValueVendure,
    getSymbolCurrency,
} from "../../../../../../../../hooks/Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../../../../../hooks/Utils/Utils";
import currenciesVendure from "../../../../../../../../hooks/Utils/Integrations/vendure-currencies.json";
import classNames from "classnames";
import ReactTooltip from "components/ReactTooltip";
import uuid from "react-uuid";
import { useAuth } from "hooks/Session/auth";

const ProductSettingsExtrasCardItem = ({ itemData, currencyCode, itemIndex }) => {
    const { languages: projectLangs } = useAuth();
    const { t } = useTranslation();
    const [langDefault, setLangDefault] = useState("");
    const [nameExtra, setNameExtra] = useState("");
    const [symbolCurrency, setSymbolCurrency] = useState("");
    const SINGULAR = "singular";
    const GROUP = "group";
    const typeItem = itemData.type === "numeric" || itemData.type === "normal" ? SINGULAR : GROUP;
    const itemGroupAvailable =
        (itemData === GROUP && !itemData?.options?.choices) ||
        itemData?.options?.choices?.length === 0 ||
        itemData?.options?.choices?.every((opt) => opt.available === false);
    const itemAvailable = itemData?.available && !itemGroupAvailable ? true : false;
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        if (projectLangs) {
            setLangDefault(getProjectLangs(projectLangs, t).langDefault);
        }
        //eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        if (itemData && langDefault) {
            setNameExtra(parseVendureTranslation(itemData.translations, langDefault));
        }
    }, [itemData, langDefault]);

    useEffect(() => {
        if (currencyCode) {
            setSymbolCurrency(getSymbolCurrency({ currency_code: currenciesVendure.currencies[currencyCode].code }));
        }
    }, [currencyCode]);

    return (
        <div>
            <div className={`bg-gray-100 flex justify-between rounded text-left px-2 py-1 mb-3 `}>
                <span
                    id={`item-extra-name-${itemIndex}`}
                    className={classNames("font-bold truncate ", {
                        " w-full": itemAvailable,
                        " w-3/5": !itemAvailable,
                    })}
                    data-tip={showTooltip ? nameExtra : ""}
                    onMouseOver={(e) => {
                        if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
                            setShowTooltip(true);
                        } else {
                            setShowTooltip(false);
                        }
                    }}
                >
                    {`${capitalizeFirst(t(typeItem === SINGULAR ? "singular-item" : "group"))} - ${nameExtra}  `}
                </span>
                {!itemAvailable ? (
                    <span id={`unavailable-extra-${itemIndex}`} className=" text-gray-700">
                        {t("unavailable")}
                    </span>
                ) : null}
            </div>
            {itemAvailable ? (
                <div>
                    {typeItem === SINGULAR ? (
                        <div className=" mb-4 w-full flex pl-4  ">
                            <span className="  w-1/2 font-bold flex justify-items-start">{`${capitalizeFirst(
                                t("price")
                            )}`}</span>
                            <span id={`item-extra-${itemIndex}-price`} className="  w-1/2 flex justify-end pr-28">
                                {`${formatPriceValueVendure(
                                    itemData.options.priceWithoutTax / 100,
                                    currenciesVendure.currencies?.[currencyCode]
                                )}`}
                            </span>
                        </div>
                    ) : (
                        <div className=" mb-4 w-full pl-4  flex flex-col  ">
                            {typeItem === GROUP && itemData?.options?.choices?.length > 0 ? (
                                <span className=" w-full font-bold flex mb-2 justify-end pr-28">
                                    {capitalizeFirst(t("price"))}
                                </span>
                            ) : (
                                <span className=" w-full flex mb-2 justify-start -ml-1 text-gray-800">
                                    {capitalizeFirst(t("no-items-yet"))}
                                </span>
                            )}
                            <div>
                                {typeItem === GROUP && itemData?.options?.choices?.length > 0
                                    ? itemData.options.choices.map((item, index) => {
                                          const price = formatPriceValueVendure(
                                              item.priceWithoutTax / 100,
                                              currenciesVendure.currencies?.[currencyCode]
                                          );
                                          const name = parseVendureTranslation(item.translations, langDefault);
                                          const itemKey = uuid();
                                          return (
                                              <div className=" flex" key={itemKey}>
                                                  <span
                                                      id={`item-extra-${itemIndex}-${index}-name`}
                                                      className="  w-1/2 font-bold flex justify-items-start"
                                                  >
                                                      {name}
                                                  </span>
                                                  <span
                                                      id={`item-extra-${itemIndex}-${index}-price`}
                                                      className="  w-1/2 flex justify-end pr-28"
                                                  >
                                                      {`${price}`}
                                                  </span>
                                              </div>
                                          );
                                      })
                                    : null}
                            </div>
                        </div>
                    )}
                </div>
            ) : null}
            {showTooltip === true ? (
                <ReactTooltip delayShow={0} type={"light"} borderColor={"#D3DAE1"} border={true} multiline={true} />
            ) : null}
        </div>
    );
};

export default ProductSettingsExtrasCardItem;
