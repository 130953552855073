import React from "react";
import { Link, useNavigate as useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIdSubRow } from "../../../actions/tableActions";
import { rgbaPattern } from "../../../hooks/Utils/Utils";

const UseImageCell = ({ cellData, colIndex, rowIndex, depth, subIndex }) => {
    const {
        hidden,
        path,
        name,
        type,
        adjust,
        onclickCallback,
        link,
        isFromUrl,
        id,
        urlPath,
        linkAdjust,
        linkText,
        noImgText,
        adjustNotImage,
        adjustNotImageIcon,
        buttonOpenSubrow,
        redirectTo,
        adjustText,
        adjustColorBg,
        iconButtonText,
        rowId,
        subrowAdjust,
        subrowOpenIcon,
        subrowCloseIcon,
        disabled,
        iconError,
    } = cellData || {};
    const history = useHistory();
    const { idsSubrows } = useSelector((state) => state.table);
    const dispatch = useDispatch();
    const prefixId = "image-cell-";
    //const content = GetCellContent(cellData);
    let src = null;
    //const onErrorPath = "./../../assets/images/icons/image.svg";
    if (isFromUrl && urlPath) {
        src = urlPath;
    } else {
        src = require(`../../../assets/images/${path}${name}.${type}`);
    }
    const onImageError = (e, conteinerErrorIconId) => {
        if (iconError) {
            e.target.classList.add("hidden");
            if (conteinerErrorIconId) {
                const conteinerErrorIcon = document.querySelector(`#${conteinerErrorIconId}`);
                if (conteinerErrorIcon) {
                    conteinerErrorIcon.classList.remove("hidden");
                    conteinerErrorIcon.innerHTML = `<span class="icon ${iconError}"></span>`;
                }
            }
        } else {
            e.target.src = require(`../../../assets/images/icons/image.svg`);
        }
    };

    return (
        <>
            {!hidden ? (
                <>
                    {link ? (
                        <Link to={link} className={`${linkAdjust}`}>
                            <img
                                id={`${prefixId}img-c${colIndex}-r${rowIndex}${depth >= 0 ? `-d${depth}` : ""}`}
                                src={src}
                                alt=""
                                className={`${adjust} ${onclickCallback ? "cursor-pointer" : ""}`}
                                onClick={onclickCallback}
                                onError={(e) => {
                                    onImageError(
                                        e,
                                        `${prefixId}img-c${colIndex}-r${rowIndex}${depth >= 0 ? `-d${depth}` : ""}`
                                    );
                                }}
                            ></img>
                            <div
                                className={`hidden ${adjustNotImage}`}
                                id={`${prefixId}img-c${colIndex}-r${rowIndex}${depth >= 0 ? `-d${depth}` : ""}`}
                            ></div>
                        </Link>
                    ) : (
                        <div className=" flex items-center">
                            {(noImgText && urlPath && !rgbaPattern.test(urlPath)) || name === "folder" ? (
                                <div className=" relative" style={{ minWidth: "5.5rem" }}>
                                    <img
                                        id={`${prefixId}img-c${colIndex}-r${rowIndex}${depth >= 0 ? `-d${depth}` : ""}`}
                                        src={src}
                                        alt=""
                                        className={`${adjust} ${onclickCallback ? "cursor-pointer" : ""}`}
                                        onClick={onclickCallback}
                                        onError={(e) => {
                                            onImageError(
                                                e,
                                                `${prefixId}img-c${colIndex}-r${rowIndex}${
                                                    depth >= 0 ? `-d${depth}` : ""
                                                }`
                                            );
                                        }}
                                    ></img>
                                    <div className={`hidden ${adjustNotImage}`}></div>
                                    {disabled ? <div className={` absolute top-0 bg-gray-400 ${adjust} `}></div> : null}
                                    {subrowAdjust ? (
                                        <div
                                            className={`${subrowAdjust} ${
                                                idsSubrows.includes(Number(rowId)) ? subrowOpenIcon : subrowCloseIcon
                                            }`}
                                        ></div>
                                    ) : null}
                                </div>
                            ) : urlPath && rgbaPattern.test(urlPath) ? (
                                <div className=" relative">
                                    <div className={`${adjustColorBg}`} style={{ backgroundColor: urlPath }}></div>
                                    {disabled ? (
                                        <div className={` absolute top-0 bg-gray-400 ${adjustColorBg} `}></div>
                                    ) : null}
                                    {subrowAdjust ? (
                                        <div
                                            className={`${subrowAdjust} ${
                                                idsSubrows.includes(Number(rowId)) ? subrowOpenIcon : subrowCloseIcon
                                            }`}
                                        ></div>
                                    ) : null}
                                </div>
                            ) : (
                                <div className=" relative">
                                    <div className={`${adjustNotImage}`}>
                                        <span className={`${adjustNotImageIcon}`}></span>
                                    </div>
                                    {disabled ? (
                                        <div className={` absolute top-0  bg-gray-400 ${adjustNotImage} `}></div>
                                    ) : null}
                                    {subrowAdjust ? (
                                        <div
                                            className={`${subrowAdjust} ${
                                                idsSubrows.includes(Number(rowId)) ? subrowOpenIcon : subrowCloseIcon
                                            }`}
                                        ></div>
                                    ) : null}
                                </div>
                            )}
                            {linkText ? (
                                <span
                                    id={`${prefixId}text-c${colIndex}-r${rowIndex}${depth >= 0 ? `-d${depth}` : ""}`}
                                    onClick={() => {
                                        if (redirectTo) {
                                            history(redirectTo);
                                        }
                                    }}
                                    className={` two-lines-ellipsis ${adjustText}`}
                                >
                                    {linkText}
                                </span>
                            ) : buttonOpenSubrow ? (
                                <div
                                    id={`${prefixId}button-subrow-c${colIndex}-r${rowIndex}${
                                        depth >= 0 ? `-d${depth}` : ""
                                    }`}
                                    onClick={() => {
                                        dispatch(setIdSubRow(rowId));
                                    }}
                                    className={`${adjustText}`}
                                >
                                    {buttonOpenSubrow}
                                    <span
                                        className={` icon pl-2 icon-chevron animated  ${iconButtonText}  ${
                                            idsSubrows.includes(Number(rowId)) ? "ml-2 -rotate-180" : " ml-0"
                                        }`}
                                    ></span>
                                </div>
                            ) : null}
                        </div>
                    )}
                </>
            ) : null}
        </>
    );
};

export default UseImageCell;
