import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";

const ModalDelete = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { delete: deleteAction } = props.useDelete({
        onCompleted: () => {
            setLoading(false);
            props.fetch();
            props.close();
        },
    });

    const handleDeleteClick = () => {
        setLoading(true);
        deleteAction({ variables: { id: props.id, mapId: props.mapId } });
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${props.name} - ${t("delete")}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="red" id="modal-button-delete" disabled={loading} onClick={handleDeleteClick}>
                        {t("delete")}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div>
                    <p>{t(`are-you-sure-you-want-to-delete-this-${props.type}`)}</p>
                </div>
            )}
        </Modal>
    );
};

export const useModalDelete = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalDelete {...newProps} />);
        },
        close,
    };
};
