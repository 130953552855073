import Dropdown from "components/Dropdown";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isToday, isSameDay, endOfMonth, isAfter, isBefore, isSameMonth, format, startOfMonth } from "date-fns";
import DayPicker, { DateUtils } from "react-day-picker";
import { CalendarNumberInput } from "./CalendarNumberInput";
import { GlobalContext } from "contexts/Global";
import { enUS, es } from "date-fns/locale";
import TableContext from "components/ZafiroTable/Context";
import { CustomCalendarHeader } from "./CustomCalendarHeader";

export const RangeDate = ({ id, placeHolder, selectFromDate, hideFutureDates = true }) => {
    const { setDateRange, setActiveFilters } = useContext(TableContext);
    const currentMonth = new Date();
    const fromMonth = startOfMonth(currentMonth);
    const userLocale = navigator.language || navigator.userLanguage;
    const currentDate = new Date();
    const startDate = selectFromDate || new Date(currentDate);
    startDate.setDate(startDate.getDate() - 29);

    const [month, setMonth] = useState(fromMonth);
    const [range, setRange] = useState({ from: null, to: null });
    const { t } = useTranslation();

    const isSelected = !!(range?.from || range?.to);

    const removeToButtonAction = () =>
        setRange((prevRange) => ({
            from: prevRange?.from ? prevRange.from : null,
            to: null,
        }));

    const removeFromButtonAction = () =>
        setRange((prevRange) => ({
            from: null,
            to: prevRange?.to ? prevRange.to : null,
        }));

    const onDateChange = (newDates, type) => {
        setRange((prevRange) => {
            if (type === "end" && newDates.to && newDates.to < prevRange.from) {
                newDates.to.setHours(0, 0, 0, 0);
                if (isToday(prevRange.from)) {
                    const now = new Date();
                    prevRange.from.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
                } else {
                    prevRange.from.setHours(23, 59, 59, 999);
                }
                return {
                    from: newDates.to,
                    to: prevRange.from,
                };
            }

            if (type === "start" && newDates.from && prevRange.to && newDates.from > prevRange.to) {
                prevRange.to.setHours(0, 0, 0, 0);
                if (isToday(newDates.from)) {
                    const now = new Date();
                    newDates.from.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
                } else {
                    newDates.from.setHours(23, 59, 59, 999);
                }
                return {
                    from: prevRange.to,
                    to: newDates.from,
                };
            }

            if (newDates.from && !isToday(newDates.from)) {
                newDates.from.setHours(0, 0, 0, 0);
            }
            if (prevRange.from && !isToday(prevRange.from)) {
                prevRange.from.setHours(0, 0, 0, 0);
            }

            if (newDates.to && !isToday(newDates.to)) {
                newDates.to.setHours(23, 59, 59, 999);
            }

            if (prevRange.to && !isToday(prevRange.to)) {
                prevRange.to.setHours(23, 59, 59, 999);
            }

            if (newDates.to && isToday(newDates.to)) {
                const now = new Date();
                newDates.to.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
            }

            if (prevRange.to && isToday(prevRange.to)) {
                const now = new Date();
                prevRange.to.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
            }

            return {
                from: newDates.from !== undefined ? newDates.from : prevRange.from,
                to: newDates.to !== undefined ? newDates.to : prevRange.to,
            };
        });
    };

    useEffect(() => {
        if (setDateRange && setActiveFilters) {
            setDateRange(range);
            setActiveFilters((prevFilters) => ({
                ...prevFilters,
                [id]: range,
            }));
        }
    }, [range]);

    const modifiers = {
        startDay: (day) => DateUtils.isSameDay(day, range.from),
        endDay: (day) => DateUtils.isSameDay(day, range.to),
        outsideAndBeforeCurrent: (day) => {
            const currentMonthEnd = endOfMonth(month);
            return isBefore(month, currentMonthEnd) && isAfter(month, startDate) && !isSameMonth(month, new Date());
        },
        afterCurrent: (day) => (isAfter(day, new Date()) && !isSameDay(day, new Date())) || isBefore(day, startDate),
    };

    const handleDayClick = (day) => {
        if (!range.to && !range.from) {
            const now = new Date();
            if (isToday(day)) {
                const startOfDay = new Date(day);
                startOfDay.setHours(0, 0, 0, 0);

                setRange({ from: startOfDay, to: now });
            } else {
                const startOfDay = new Date(day);
                startOfDay.setHours(0, 0, 0, 0);

                const endOfDay = new Date(day);
                endOfDay.setHours(23, 59, 59, 999);

                setRange({ from: startOfDay, to: endOfDay });
            }
        } else if (range.from && !range.to) {
            setRange({ from: range.from, to: day });
        } else {
            const newRange = DateUtils.addDayToRange(day, range);

            if (newRange.from) {
                newRange.from.setHours(0, 0, 0, 0);
            }

            if (newRange.to) {
                if (isToday(newRange.to)) {
                    newRange.to = new Date();
                } else if (isSameDay(newRange.from, newRange.to)) {
                    newRange.from = new Date(newRange.from);
                    newRange.to = new Date(newRange.to);

                    newRange.from.setHours(0, 0, 0, 0);
                    newRange.to.setHours(23, 59, 59, 999);
                } else {
                    newRange.to.setHours(23, 59, 59, 999);
                }
            }

            setRange(newRange);
        }
    };

    return (
        <Dropdown
            id={id}
            showArrow={true}
            float={true}
            minWidth="20vw"
            designClass={{ selectPages: "bg-gray-200 rounded px-2 gap-4" }}
            handler={
                <div className="w-full whitespace-nowrap p-2 flex items-center justify-between  rounded relative">
                    <span className={`${range.to && range.from ? "text-gray-900" : "text-gray-600"}`}>
                        {isSelected ? t("date-selected") : placeHolder}
                    </span>
                </div>
            }
        >
            <div className="p-4 rounded border border-gray-300 w-full bg-white mt-1">
                <div className="flex items-center gap-6">
                    <p className="font-bold">{t("start")}</p>
                    <CalendarNumberInput
                        date={range?.from}
                        range={range}
                        onDateChange={onDateChange}
                        startDate={startDate}
                        removeButtonAction={removeFromButtonAction}
                    />
                </div>
                <div className="flex items-center gap-8 my-4">
                    <p className="font-bold">{t("end")}</p>
                    <CalendarNumberInput
                        date={range?.to}
                        range={range}
                        onDateChange={onDateChange}
                        startDate={startDate}
                        removeButtonAction={removeToButtonAction}
                    />
                </div>
                <DayPicker
                    className="filter-range-date w-full border border-gray-100"
                    firstDayOfWeek={1}
                    month={month}
                    navbarElement={
                        <CustomCalendarHeader
                            locale={userLocale}
                            setMonth={setMonth}
                            startDate={startDate}
                            hideFutureDates={hideFutureDates}
                        />
                    }
                    fromMonth={month}
                    toMonth={hideFutureDates ? month : null}
                    modifiers={hideFutureDates ? modifiers : null}
                    renderDay={renderDay}
                    locale={userLocale}
                    selectedDays={[range.from, { from: range.from, to: range.to }]}
                    onDayClick={handleDayClick}
                    onMonthChange={(month) => setMonth(month)}
                    weekdayElement={<Weekday locale={userLocale} />}
                />
            </div>
        </Dropdown>
    );
};

function renderDay(day) {
    return <div>{format(day, "d")}</div>;
}

function Weekday({ weekday, className, locale }) {
    const { lang } = useContext(GlobalContext);
    const spanishAbbreviations = ["D", "L", "M", "X", "J", "V", "S"];
    const weekdayName = format(new Date(1970, 1, weekday + 1), "iiii", {
        locale: locale === "es-MX" ? es : enUS,
    });

    let displayedWeekday;
    if (lang === "es") {
        displayedWeekday = spanishAbbreviations[weekday];
    } else {
        displayedWeekday = weekdayName.slice(0, 1).toUpperCase() + weekdayName.slice(1, 3);
    }

    return <div className={className}>{displayedWeekday}</div>;
}
