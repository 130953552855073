import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setRefreshContentData } from "actions/sectionActions";
import { useVendureAPI, executeVendureQuery } from "hooks/Utils/Integrations/useVendure";
import Select from "components/Select";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import { nl2br } from "i18n.js";

const UPDATE_EDIT_CURRENCY_AND_TAXES = `mutation UpdateChannel($input: UpdateChannelInput!) {
  updateChannel(input: $input) {
     __typename
      ... on Channel {
        customFields {
          is_active
        }
      }
      ... on LanguageNotAvailableError {
        message
      }
  }
}`;

const useMutationEditCurrencyAndTaxes = ({ closeModal }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { adminApi } = useVendureAPI();
    const [loading, setLoading] = useState(false);

    const mutate = async (variables) => {
        setLoading(true);
        try {
            const response = await executeVendureQuery(adminApi, {
                queryBody: UPDATE_EDIT_CURRENCY_AND_TAXES,
                variables: { input: variables },
            });

            if (response.errors && response.errors.length > 0) {
                const errorMessage = response.errors[0]?.message || "Unknown error";
                toast.error(errorMessage);
                closeModal();
            } else if (response?.data) {
                toast.success(t("operation-successful"));
                dispatch(setRefreshContentData(true));
                closeModal();
            } else {
                toast.error(t("error-mutation"));
                closeModal();
            }
        } catch (error) {
            const fallbackError = error.message || t("network-error");
            toast.error(fallbackError);
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    return { mutate, loading };
};

const useArrangeData = (props) => {
    const selectData = {
        currenciesSelect: [],
        taxesZoneSelect: [],
        allTaxes: [],
        allTaxZones: [],
        values: {
            currencyCode: "",
            defaultTaxZoneId: "",
            defaultTaxId: "",
            defaultTaxCategoryId: "",
            id: props?.id,
        },
    };

    if (props?.allCurrencies) {
        selectData.currenciesSelect = props?.allCurrencies
            ? props?.allCurrencies.map((currency) => ({
                  id: currency.code,
                  value: currency.code,
                  label: `${currency.name} (${currency.symbol})`,
              }))
            : [];
    }

    if (props?.values) {
        selectData.values = props?.values;
    }

    if (props?.allTaxZones) {
        selectData.taxesZoneSelect = props.allTaxZones
            ? props.allTaxZones.map((tax) =>
                  tax?.id
                      ? {
                            id: tax.id,
                            value: tax.id,
                            label: tax.name,
                        }
                      : null
              )
            : [];
        selectData.allTaxZones = props.allTaxZones ? props.allTaxZones : [];
    }

    if (props?.allTaxes) {
        selectData.allTaxes = props.allTaxes ? props.allTaxes : [];
    }

    return { selectData };
};

const ModalContent = (props) => {
    const { t } = useTranslation();
    const { selectData } = useArrangeData(props);
    const { mutate, loading } = useMutationEditCurrencyAndTaxes({ closeModal: props?.close });
    const { currenciesSelect, taxesZoneSelect, values, allTaxes, allTaxZones } = selectData || {};

    const [currentValues, setCurrentValues] = useState(selectData.values);

    useEffect(() => {
        if (values) {
            setCurrentValues(values);
        }
    }, [values]);

    const taxRates =
        currentValues.defaultTaxZoneId && allTaxes
            ? allTaxes.filter((tax) => tax?.zone?.id === currentValues.defaultTaxZoneId)
            : null;

    const input = {
        id: currentValues?.id,
        currencyCode: currentValues?.currencyCode,
        defaultTaxZoneId: currentValues?.defaultTaxZoneId,
        defaultShippingZoneId: currentValues?.defaultTaxZoneId,
        customFields: {
            default_taxId: currentValues?.defaultTaxId,
            default_tax_categoryId: currentValues?.defaultTaxCategoryId,
        },
    };

    return (
        <Modal
            footer={
                !loading ? (
                    <>
                        <Button design="blue-outline" onClick={() => props?.close()} id="modal-button-cancel">
                            {t("cancel")}
                        </Button>
                        <Button
                            design="blue"
                            onClick={() => {
                                mutate(input);
                            }}
                            id="modal-button-save"
                        >
                            {t("save")}
                        </Button>
                    </>
                ) : null
            }
            minWidth="40vw"
            title={`${t("currency-and-taxes")} - ${t("edit")}`}
        >
            {loading === false ? (
                <>
                    <div className="pb-5 ">
                        <div className="font-bold first-capital">{`${t("currency")} *`}</div>
                    </div>
                    <div className="pb-5 ">
                        <div className="first-capital -mt-3">{nl2br(t("select-shop-currency"))}</div>
                    </div>
                    <div className=" flex items-center mt-2 space-x-2">
                        <Select
                            key={currenciesSelect}
                            id={"select-currency"}
                            allowUnselect={false}
                            options={currenciesSelect}
                            value={currentValues.currencyCode}
                            onChange={(value) => setCurrentValues({ ...currentValues, currencyCode: value })}
                            search={true}
                        />
                    </div>
                    <div className=" mb-2 mt-3 ">
                        <div>
                            <div className="font-bold first-capital">{`${t("taxes-zone")} *`}</div>
                        </div>
                    </div>
                    <div className="first-capital mb-2">{nl2br(t("select-shop-taxes-zone"))}</div>
                    <Select
                        key={taxesZoneSelect}
                        id={"select-tazes-zone"}
                        allowUnselect={false}
                        options={taxesZoneSelect}
                        value={currentValues.defaultTaxZoneId}
                        onChange={(value) => {
                            if (allTaxZones && currentValues.defaultTaxZoneId !== value) {
                                const defaultTax = allTaxes.filter((t) => t?.zone?.id == value)?.[0];

                                setCurrentValues({
                                    ...currentValues,
                                    defaultTaxId: defaultTax?.id,
                                    defaultTaxCategoryId: defaultTax?.category?.id,
                                    defaultTaxZoneId: value,
                                });
                            }
                        }}
                        search={true}
                    />
                    {taxRates && taxRates.length > 0 ? (
                        <div>
                            <div className=" mt-3">{t("select-shop-default-tax")}</div>
                            <div className="w-full pt-3 max-h-18 overflow-auto">
                                {taxRates.map((taxRate, index) => (
                                    <div className="w-full flex items-center mb-2" key={taxRate.id}>
                                        <Radiobutton
                                            id={`select-tax-rate-${index}`}
                                            checked={currentValues.defaultTaxId === taxRate.id}
                                            onChange={({ checked, value }) => {
                                                if (checked) {
                                                    setCurrentValues({
                                                        ...currentValues,
                                                        defaultTaxId: value?.id,
                                                        defaultTaxCategoryId: value?.category?.id,
                                                    });
                                                }
                                            }}
                                            label={`${taxRate.name}: ${taxRate.value}%`}
                                            value={taxRate}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </>
            ) : (
                <Loading />
            )}
        </Modal>
    );
};

export const useEditCurrencyAndTaxesModal = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};

export default useEditCurrencyAndTaxesModal;
