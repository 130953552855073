import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Icon from "components/Icon";
import { useAuth } from "hooks/Session/auth";
import { getProjectLangs } from "hooks/Utils/SalesUtils";
import { getParseMapName } from "../utils";
import { useNavigate } from "react-router-dom";
import { useMap } from "./MapContext"; // Importa el hook del contexto
import { useModalEditName } from "../modals/useModalEditName";
import { useUpdateMap } from "../graphql/useMaps";

/**
 * HeaderMapEditor component for editing the map name.
 *
 * @param {Object} props - Component props.
 * @param {boolean} [props.edit=true] - Determines whether the edit button should be displayed.
 * @param {string} [props.backToText="back-to-list"] - Translation key for the back button text.
 *
 * @returns {JSX.Element} The rendered HeaderMapEditor component.
 */
const HeaderMapEditor = ({ edit = true, backToText = "back-to-list" }) => {
    const { t } = useTranslation();
    const { dataMap, loading, refetch } = useMap();
    const { languages: projectLangs } = useAuth();
    const { langDefault } = getProjectLangs(projectLangs, t);
    const { open: openModalEditNameMap } = useModalEditName();
    const navigate = useNavigate();

    const mapName = getParseMapName(dataMap?.name, langDefault);

    const handleEditNameMap = () => {
        openModalEditNameMap({
            id: dataMap?.id,
            names: dataMap?.name,
            name: mapName,
            fetch: refetch,
            useUpdate: useUpdateMap,
        });
    };

    const headerClasses = classNames("w-full absolute border-b border-gray-200 top-0 left-0 h-20 bg-white z-10 flex");
    const backContainer = classNames("h-full flex pl-4 pr-18 text-gray-800 items-center border-r border-gray-200");
    const nameContainer = classNames("px-4 h-full flex items-center text-gray-900");
    const editIconClasses = classNames("ml-6 text-gray-800");

    return (
        <div className={headerClasses}>
            <div className={backContainer}>
                <Button onClick={() => navigate(-1)} id="back-to-action">
                    <Icon type="chevron-left" size={1.5} />
                    <span>{t(backToText)}</span>
                </Button>
            </div>
            <div className={nameContainer}>
                <span className="font-bold pr-4">{mapName}</span>
                {edit && (
                    <Button
                        id="edit-name-map"
                        disabled={loading || !dataMap}
                        onClick={handleEditNameMap}
                        className="ml-2"
                    >
                        <Icon type="edit" size={1.5} className={editIconClasses} />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default HeaderMapEditor;
