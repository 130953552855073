import React, { useState, useRef, useEffect } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Button from "components/Button";
import TextInput from "components/TextInput";
import Loading from "components/Loading";
import SelectorImageIcon, { TYPE_IMAGES } from "components/SelectorImageIcon";
import useModalSelectLibraryContent from "../../Advertising/modals/signages/useModalSelectLibraryContent";
import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";
import { useLazyQuery } from "react-apollo";
import { GET_LIBRARY_ASSET_NAME } from "../../Advertising/modals/modalDestination/Destination/Hooks/useDestinationGraphCalls";
import { useCreateMapInterestPoint } from "../graphql/useMaps";
import uuid from "react-uuid";
import { useSelectLibraryContent } from "../../Advertising/modals/signages/selectLibraryContent";

export const useModalAddInterestPoint = () => {
    const { open, close } = useModal();
    return {
        open: (props) => {
            const updatedProps = { ...props, close, open };
            open(<ModalAddInterestPoint {...updatedProps} />);
        },
        close,
    };
};

const ModalAddInterestPoint = (props) => {
    const { t } = useTranslation();
    const { lang, languages: projectLanguages } = useAuth();
    const { close: closeModal, data: modalInputData } = props;
    const { open: openInterestPointModal } = useModalAddInterestPoint();
    const imageIconRef = useRef(null);
    const containerRef = useRef(null);
    const parentElement = containerRef?.current?.parentElement;

    const [formData, setFormData] = useState({
        name: "",
        language: "",
        codeLang: "",
        pins: modalInputData?.pins || [{ x: 0, y: 0, id: uuid() }],
        id: modalInputData?.id || null,
    });
    const [formValidations, setFormValidations] = useState({ name: true, iconOrLibrary: true });
    const [isSaving, setIsSaving] = useState(false);

    const [fetchLibraryAssetName, { data: libraryAssetData }] = useLazyQuery(GET_LIBRARY_ASSET_NAME, {
        fetchPolicy: "network-only",
    });

    const { create: createInterestPoint } = useCreateMapInterestPoint({
        onCompleted: () => {
            setIsSaving(false);
            toast.success(t("operation-successful"));
            if (props.close) props.close();
            if (props.refetch) props.refetch();
        },
        onError: (error) => {
            setIsSaving(false);
            toast.error(error.message || t("unknown-error"));
            console.error(error);
            if (props.close) props.close();
            if (props.refetch) props.refetch();
        },
    });

    useEffect(() => {
        if (parentElement) {
            parentElement.classList.remove("overflow-auto");
        }
    }, [parentElement]);

    useEffect(() => {
        if (projectLanguages?.length > 0) {
            const defaultLanguage = projectLanguages.find((lang) => lang.isDefault);
            if (defaultLanguage) {
                setFormData((prev) => ({
                    ...prev,
                    language: t("language:" + defaultLanguage.languageRef),
                    codeLang: defaultLanguage.languageRef,
                }));
            }
        }
    }, [projectLanguages, t]);

    useEffect(() => {
        if (modalInputData && imageIconRef.current) {
            imageIconRef.current.updateImageData(modalInputData, lang, fetchLibraryAssetName);
        }
        updateFormData(modalInputData);
    }, [modalInputData, lang]);

    const updateFormData = (data) => {
        setFormData((prev) => ({
            ...prev,
            ...(data?.name ? { name: data.name } : {}),
            ...(data?.pins ? { pins: data.pins } : {}),
        }));
    };

    const validateForm = (data, activeType) => ({
        name: !data.name || data.name.trim() === "",
        iconOrLibrary: !data[activeType]?.value,
    });

    const handleSave = async () => {
        const imageIconCurrent = imageIconRef.current;
        if (!imageIconCurrent) return;

        const errorMessages = [];
        let isValid = true;

        if (!formData.name || formData.name.trim() === "") {
            errorMessages.push(t("errors-in-red"));
            setFormValidations((prev) => ({ ...prev, name: false }));
            isValid = false;
        }

        const imageData = imageIconCurrent.getData() || {};
        const activeType = imageIconCurrent.getActiveType();
        const activeSelection = imageIconCurrent.getActiveSelectionValue();

        if (activeType === TYPE_IMAGES.LIBRARY && (!activeSelection || !activeSelection.value)) {
            errorMessages.push("you-must-select-an-image");
            isValid = false;
        }
        if (activeType === TYPE_IMAGES.ICON && (!activeSelection || !activeSelection.value)) {
            errorMessages.push("you-must-select-an-icon");
            isValid = false;
        }

        const additionalErrors = validateForm({ name: formData.name, ...imageData }, activeType);

        setFormValidations({
            name: !additionalErrors.name,
            iconOrLibrary: !additionalErrors.iconOrLibrary,
        });

        if (!isValid) {
            errorMessages.forEach((err) => toast.error(err));
            return;
        }

        let pinIcon = null;
        if (activeSelection) {
            if (activeSelection.type === TYPE_IMAGES.LIBRARY) {
                pinIcon = { libraryRef: activeSelection.value };
            } else if (activeSelection.type === TYPE_IMAGES.ICON) {
                pinIcon = { ...activeSelection.value };
            }
        }

        setIsSaving(true);
        const nameInput = [{ text: formData.name, lang: formData.codeLang }];

        createInterestPoint({
            variables: {
                mapID: formData.id,
                nameInput,
                pinIcon,
                pins: formData.pins,
            },
        });
    };

    const handleClose = () => {
        if (typeof closeModal === "function") {
            closeModal();
        }
    };

    const openSelectLibrary = useSelectLibraryContent({
        afterSaveAction: (selectedRef) => {
            openInterestPointModal({
                ...props,
                data: {
                    name: formData.name,
                    pins: formData.pins,
                    id: formData.id,
                    type: TYPE_IMAGES.LIBRARY,
                    value: selectedRef,
                },
            });
        },
    });

    return (
        <Modal
            id="modal-add-interest-point"
            title={t("add-point-of-interest")}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" disabled={isSaving} onClick={handleClose}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-add" disabled={isSaving} onClick={handleSave}>
                        {t("add")}
                    </Button>
                </>
            }
            className="w-4/12 p-10"
        >
            {isSaving ? (
                <Loading />
            ) : (
                <div ref={containerRef} className="flex w-full flex-col ">
                    <p className="font-bold">{t("name")}*</p>
                    <div className="flex w-full items-center">
                        <p className="whitespace-no-wrap">{`${formData.language} (${t("default-language")})`}</p>
                        <TextInput
                            inputContainerClass="w-full"
                            id="map-input-name"
                            className="w-full ml-2"
                            placeholder={t("name")}
                            error={!formValidations.name}
                            value={formData.name}
                            onChange={(val) => setFormData((prev) => ({ ...prev, name: val }))}
                        />
                    </div>
                    <div>
                        <p className="font-bold">{t("pin")}*</p>
                        <p className="block my-4">{t("select-icon")}:</p>
                        <SelectorImageIcon
                            ref={imageIconRef}
                            allowedImageTypes={[TYPE_IMAGES.LIBRARY, TYPE_IMAGES.ICON]}
                            id="add-interest-point-selector-image-icon"
                            nameAsset={libraryAssetData?.libraryAsset?.response?.name}
                            onError={!formValidations.iconOrLibrary}
                            onImageSelect={() => {
                                handleClose();
                                openSelectLibrary();
                            }}
                        />
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ModalAddInterestPoint;
