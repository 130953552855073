import React, { useState } from "react";
import UseSectionHeader from "components/useSectionHeader";
import ExportMap from "hooks/GraphqlCalls/Hotel/Settings/ExportMap";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { TopologyProvider } from "contexts/Topology";
import { TopologyMapView } from "./TopologyMapView";

/*
import React, { useContext, useEffect, useState } from "react";
import UseSectionHeader from "components/useSectionHeader";
import UseButton from "components/useButton";
import UpdateTopology from "hooks/GraphqlCalls/Hotel/Settings/UpdateTopology";
import ExportMap from "hooks/GraphqlCalls/Hotel/Settings/ExportMap";
import { toast } from "react-toastify";
import { useListTopologyRooms } from "hooks/GraphqlCalls/Hotel/Settings/useListTopologyRooms";
import Table from "components/ZafiroTable";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { TopologyContext, TopologyProvider } from "contexts/Topology";
import Icon from "components/Icon";
import Loading from "components/Loading";
import { Location } from "./TopologyComponents/Location";
import { Wings } from "./TopologyComponents/Wings";
import { Floors } from "./TopologyComponents/Floors";
import { TopologyTextInputs } from "./TopologyComponents/TopologyTextInputs";
import { TopologyNumberInputs } from "./TopologyComponents/TopologyNumberInputs";
import { Save } from "./TopologyComponents/Save";
import { TopologyMapView } from "./TopologyMapView";
*/

const TopologyWithoutContext = () => {
    const sectionName = "topology";
    const { t } = useTranslation();
    //State
    const viewMode = "map";
    // const [viewMode, setViewMode] = useState("map");
    // const [roomsData, setRoomsData] = useState(null);
    const [startMapExport, setStartMapExport] = useState(false);

    // Context
    // Comentada la parte de vista tabla, volvera en el futuro
    // const { roomsInformation, locationFilter, floorFilter, refetch } = useListTopologyRooms();
    // const {
    //     setSelectedWing,
    //     setSelectedFloor,
    //     setRoomNumber,
    //     setPmsNumber,
    //     setName,
    //     setTvs,
    //     setLocation,
    //     editing,
    //     setEditing,
    //     setCreating,
    //     setTvsLengthWhileEditing,
    //     loadingRefresh,
    //     setLoadingRefresh,
    // } = useContext(TopologyContext);

    //Functions
    // Comentada la parte de vista tabla, volvera en el futuro
    // const cancelEdit = () => {
    //     setRoomsData(roomsInformation);
    //     setSelectedWing(null);
    //     setSelectedFloor(null);
    //     setRoomNumber(null);
    //     setPmsNumber(null);
    //     setName(null);
    //     setTvs(null);
    //     setLocation([]);
    //     setEditing((prev) => ({ ...prev, editing: false }));
    //     setCreating(false);
    // };

    // const addRoom = () => {
    //     if (editing?.editing) {
    //         return toast.error(t("save-before-continue"));
    //     }
    //     let newRoom = {
    //         wing: <Wings floorFilter={floorFilter} />,
    //         floor: <Floors floorFilter={floorFilter} />,
    //         roomNumber: <TopologyNumberInputs type="room-number" />,
    //         pmsNumber: <TopologyTextInputs type="pms-number" section="topology" />,
    //         name: <TopologyTextInputs type="name" section="topology" />,
    //         tvs: <TopologyNumberInputs type="tvs" />,
    //         location: <Location locationFilter={locationFilter} />,
    //         edit: (
    //             <Button id="cancel" className="bg-red-100 p-1 rounded" onClick={cancelEdit}>
    //                 <Icon type="remove" size="xl" className="text-white" />
    //             </Button>
    //         ),
    //         delete: <Save floorFilter={floorFilter} type="addRoom" />,
    //     };
    //     setRoomsData([newRoom, ...roomsInformation]);
    // };

    // Comentada la parte de vista tabla, volvera en el futuro
    // useEffect(() => {
    //     if (loadingRefresh) {
    //         toast.success(t("operation-successful"));
    //         refetch();
    //         setCreating(false);
    //         setEditing((prev) => ({ ...prev, editing: false }));
    //         setTimeout(() => {
    //             setLoadingRefresh(false);
    //         }, 1200);
    //     }
    // }, [loadingRefresh]);

    // useEffect(() => {
    //     if (editing?.editing) {
    //         setRoomsData((prevData) => {
    //             const updatedData = [...prevData];
    //             const selectedObjectIndex = updatedData.findIndex((room) => room.id === editing?.id);
    //             if (selectedObjectIndex !== -1) {
    //                 const selectedObject = updatedData[selectedObjectIndex];
    //                 setTvsLengthWhileEditing(selectedObject?.tvs);
    //                 setTvs(selectedObject?.tvs);
    //                 const updatedRoom = {
    //                     ...selectedObject,
    //                     roomNumber: (
    //                         <TopologyNumberInputs value={selectedObject?.floorNumberForEdit} type="room-number" />
    //                     ),
    //                     pmsNumber: (
    //                         <TopologyTextInputs
    //                             value={selectedObject?.pmsNumberForEdit}
    //                             type="pms-number"
    //                             section="topology"
    //                         />
    //                     ),
    //                     name: (
    //                         <TopologyTextInputs
    //                             value={selectedObject?.nameForEdit}
    //                             type="name"
    //                             alt={selectedObject?.nameForEdit}
    //                             section="topology"
    //                         />
    //                     ),
    //                     tvs: <TopologyNumberInputs value={selectedObject?.tvs} type="tvs" />,
    //                     location: (
    //                         <Location
    //                             locationFilter={locationFilter}
    //                             locations={selectedObject?.locationsForEdit}
    //                             type="edit"
    //                         />
    //                     ),
    //                     edit: (
    //                         <Button id="cancel" className="bg-red-100 p-1 rounded" onClick={cancelEdit}>
    //                             <Icon type="remove" size="xl" className="text-white" />
    //                         </Button>
    //                     ),
    //                     delete: (
    //                         <Save
    //                             floorFilter={floorFilter}
    //                             id={selectedObject?.id}
    //                             roomTv={selectedObject?.locationsForEdit}
    //                         />
    //                     ),
    //                 };
    //                 updatedData[selectedObjectIndex] = updatedRoom;
    //             }
    //             return updatedData;
    //         });
    //     }
    // }, [editing?.editing]);

    // useEffect(() => {
    //     if (roomsInformation) {
    //         setRoomsData(roomsInformation);
    //     }
    // }, [roomsInformation]);

    return (
        <>
            {/* <UpdateTopology viewMode={viewMode} /> */}
            <ExportMap startMapExport={startMapExport} setStartMapExport={setStartMapExport} />
            <UseSectionHeader title={["topology"]} />
            {/* Comentada la parte de vista tabla, volvera en el futuro */}
            {/* <div className=" z-20 absolute -mt-12" style={{ right: "3.5rem" }}>
                <div className="float-left pr-2">{t("view")}:</div>
                <div className="inline-block -mt-2 ra">
                    <UseButton
                        id={`${sectionName}-view-map`}
                        action={(e) => setViewMode("map")}
                        buttonName={"map"}
                        buttonColor={`${viewMode === "map" ? "btn-dark-blue" : "btn-white"}`}
                        adjust="w-16 float-left min-w-24 rounded-l-sm rounded-r-none"
                    />
                    <UseButton
                        id={`${sectionName}-view-table`}
                        action={(e) => setViewMode("table")}
                        buttonName={"table"}
                        buttonColor={`${viewMode === "table" ? "btn-dark-blue" : "btn-white"}`}
                        adjust="w-16 float-left min-w-24 rounded-l-none rounded-r-sm"
                    />
                </div>
            </div> */}

            {viewMode === "map" && (
                <>
                    <div className="inline-flex export-btn z-20 ">
                        <div id={`${sectionName}-export-map-container`}>
                            <Button
                                onClick={() => setStartMapExport(true)}
                                id={`${sectionName}-export-map-button`}
                                design="blue-outline"
                                className="font-bold"
                            >
                                {t("export-map")}
                            </Button>
                        </div>
                    </div>
                    <main className="main-container">
                        <TopologyMapView />
                    </main>
                </>
                // {/* Comentada la parte de vista tabla, volvera en el futuro */}
                // <div>
                //     <div className="main-container">
                //         {!loadingRefresh ? (
                //             <Table
                //                 id="topology-table"
                //                 search={true}
                //                 paginate={"lazy"}
                //                 perPage={20}
                //                 maxHeight="60vh"
                //                 topRightCorner={
                //                     <div className="flex gap-2 items-center">
                //                         <Button id="export-map" className="btn-blue-outline" onClick={exportMap}>
                //                             {t("export-map")}
                //                         </Button>
                //                         <Button
                //                             id="add-room"
                //                             className="btn btn-blue"
                //                             onClick={() => {
                //                                 setCreating(true);
                //                                 addRoom();
                //                             }}
                //                         >
                //                             {t("add-room")}
                //                         </Button>
                //                     </div>
                //                 }
                //                 header={{
                //                     wing: { title: t("wing"), sortable: true, width: "15%" },
                //                     floor: { title: t("floor"), sortable: true, width: "10%" },
                //                     roomNumber: { title: t("room-number-short"), sortable: true, width: "10%" },
                //                     pmsNumber: { title: t("pms-number"), sortable: true, width: "10%" },
                //                     name: { title: t("name"), sortable: true, width: "20%" },
                //                     tvs: { title: t("tvs"), width: "10%" },
                //                     location: { title: t("location"), width: "35%" },
                //                     edit: { title: "", width: "5%" },
                //                     delete: { title: "", width: "5%" },
                //                 }}
                //                 cols={[
                //                     "wing",
                //                     "floor",
                //                     "roomNumber",
                //                     "pmsNumber",
                //                     "name",
                //                     "tvs",
                //                     "location",
                //                     "edit",
                //                     "delete",
                //                 ]}
                //                 rows={roomsData}
                //                 filters={[
                //                     {
                //                         id: "filter-by-floor",
                //                         title: t("floor"),
                //                         multiple: true,
                //                         options: floorFilter,
                //                         onFilter: (values, row) => (values?.includes(row?.floorFilter) ? row : null),
                //                     },
                //                     {
                //                         id: "location",
                //                         title: t("location"),
                //                         options: locationFilter,
                //                         onFilter: (values, row) => {
                //                             return row?.location?.props?.locations?.some((selectedValue) =>
                //                                 [values].some((value) => value === selectedValue?.name)
                //                             );
                //                         },
                //                     },
                //                 ]}
                //             />
                //         ) : (
                //             <Loading adjust="section-loading" style={{ height: "50vh" }} />
                //         )}
                //     </div>
                // </div>
            )}
        </>
    );
};

const Topology = () => {
    return (
        <TopologyProvider>
            <TopologyWithoutContext />
        </TopologyProvider>
    );
};

export default Topology;
