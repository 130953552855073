import React, { useState, useEffect, useRef, useLayoutEffect, Fragment } from "react";
import { convertFromRaw, convertToRaw, EditorState, genKey, AtomicBlockUtils, Modifier } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createFocusPlugin from "@draft-js-plugins/focus";
import { useDispatch, useSelector } from "react-redux";
import UseSearch from "../useSearch";
import { useTranslation } from "react-i18next";
import { GetCustomTags } from "../../hooks/GraphqlCalls/Design/ListTags";
import UseCreateBlockTagPlugin from "./UseCreateBlockTagPlugin";
import { predefinedSalesTags, capitalizeFirst, removeDiacritics, predefinedTags } from "../../hooks/Utils/Utils";
import { changeActionValues } from "../../actions/globalActions";
import Immutable from "immutable";
import Icon from "components/Icon";

const focusPlugin = createFocusPlugin();

const blockTagPlugin = UseCreateBlockTagPlugin({ decorator: focusPlugin.decorator });
const plugins = [focusPlugin, blockTagPlugin];

const UseInputDraftEditableTagsField = ({
    defaultTextState,
    setTextState,
    idParentContainer = "",
    textPlaceholder = "",
    parentSectionName = "nonParentSectionName",
    listTagsFixed = false,
    topPositionTags = null,
    rightPositionTags = null,
    height,
    id,
    disabled = false,
    typeTags = "",
    adjustTagsButton = "",
    adjustTagsButtonComponentStyles = null,
    forceScrollContent = false,
    disabledColor = "bg-white border border-gray-200",
    noChangeValueOnKeyUp,
    changeValueOnBlur,
}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [placeholderState, setPlaceholderState] = useState(EditorState.createEmpty());
    const [showTagsSelector, setShowTagsSelector] = useState(false);
    const isPastingText = useRef(false);
    const [onFocus, setOnFocus] = useState(false);
    const inputs = [`${id}-container-editor`, `${id}-button-tags-selector`];
    const editorRef = useRef(null);
    const mainContainerRef = useRef(null);
    const buttonRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (editorRef && !editorRef.current.editor.editor.id) {
            editorRef.current.editor.editor.id = `${inputs[0]}-child`;
        }
    }, [editorRef]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (mainContainerRef.current && !mainContainerRef.current.contains(event.target)) {
                setShowTagsSelector(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        if (idParentContainer) {
            const containerElement = document.getElementById(idParentContainer);
            const handleScroll = () => {
                setShowTagsSelector(false);
            };

            containerElement.addEventListener("scroll", handleScroll);

            return () => {
                containerElement.removeEventListener("scroll", handleScroll);
            };
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (defaultTextState) {
            const contentState = convertFromRaw(buildInitialState(defaultTextState));
            const newEditorState = EditorState.push(editorState, contentState);
            setEditorState(newEditorState);
        }
        // eslint-disable-next-line
    }, [defaultTextState]);

    useEffect(() => {
        if (textPlaceholder) {
            const contentState = convertFromRaw(buildInitialState(textPlaceholder));
            const newEditorState = EditorState.push(placeholderState, contentState);
            setPlaceholderState(newEditorState);
        }
        // eslint-disable-next-line
    }, [textPlaceholder]);

    useEffect(() => {
        if (editorState) {
            let newText = getEditorValue({ editorState });
            if (id && !noChangeValueOnKeyUp) {
                dispatch(
                    changeActionValues({
                        [id]: newText.trim(),
                    })
                );
            }
            if (setTextState) {
                setTextState(newText);
            }
        }
        // eslint-disable-next-line
    }, [editorState]);

    useLayoutEffect(() => {
        if (buttonRef.current && listTagsFixed) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            const { bottom, left, width } = buttonRect;
            const top = bottom + window.scrollY;
            const right = window.innerWidth - left - width + window.scrollX;

            const selectorDraftTag = document.getElementById("tag-selector-container");
            if (selectorDraftTag) {
                selectorDraftTag.style.top = `${top}px`;
                selectorDraftTag.style.right = `${right}px`;
            }
        }
        if (forceScrollContent) {
            if (showTagsSelector) {
                document.getElementById("sectionContent").scrollTop =
                    document.getElementById("sectionContent").scrollHeight;
            }
        }
    }, [showTagsSelector]);

    const handlePastedText = (text) => {
        const currentContent = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();

        const newContentState = convertFromRaw(buildInitialState(text));
        const newBlockArray = newContentState.getBlockMap().toArray();

        const concatenatedContent = Modifier.replaceWithFragment(
            currentContent,
            selectionState,
            Immutable.OrderedMap(newBlockArray.map((block) => [block.getKey(), block]))
        );

        isPastingText.current = true;

        const newEditorState = EditorState.push(editorState, concatenatedContent, "insert-fragment");
        setEditorState(newEditorState);

        return true;
    };

    const onChange = (newEditorState) => {
        if (!isPastingText.current) {
            setEditorState(newEditorState);
        }

        if (isPastingText.current) {
            isPastingText.current = false;
        }
    };

    const focus = (e) => {
        if (e.target.id === inputs[0] || e.target.id === inputs[1]) {
            setEditorState(EditorState.moveFocusToEnd(editorState));
        }
        if (e.target.id !== inputs[1]) {
            setOnFocus(true);
            setTimeout(() => {
                editorRef.current.focus();
            }, 0);
        }
    };

    const onBlur = () => {
        if (editorState) {
            let newText = getEditorValue({ editorState });
            if (id && changeValueOnBlur) {
                dispatch(
                    changeActionValues({
                        [id]: newText.trim(),
                    })
                );
            }
        }
    };

    GetCustomTags();

    const adddBlockTag = (tag = "") => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("BlockTag", "IMMUTABLE", { tag: tag });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, " ");

        setEditorState(newEditorState);
    };

    const addInlineTag = (tag = "") => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const newText = ` ${tag} `;
        const newContentState = Modifier.replaceText(contentState, selectionState, newText);
        const newEditorState = EditorState.push(editorState, newContentState, "insert-characters");
        setEditorState(newEditorState);
    };

    return (
        <div className=" relative " ref={mainContainerRef}>
            <div
                id={inputs[0]}
                onClick={(e) => {
                    if (!disabled) {
                        focus(e);
                    }
                }}
                className={`relative pt-2 px-4 pb-4 rounded w-full text-editor-container ${
                    disabled ? `${disabledColor}` : " bg-gray-200"
                } editor${id ? `-${id}` : ""}-translate-text text-lost-editor${id ? `-${id}` : ""}`}
                style={{
                    minHeight: height || "8.2rem",
                    maxHeight: height || "8.2rem",
                    overflowY: "scroll",
                    boxShadow: onFocus || showTagsSelector ? "0 0 0 3px rgba(66, 153, 225, 0.5)" : null,
                }}
            >
                <Editor
                    onFocus={() => {
                        if (!disabled) {
                            setOnFocus(true);
                        }
                    }}
                    onBlur={() => {
                        if (!disabled) {
                            onBlur();
                            setOnFocus(false);
                        }
                    }}
                    placeholder={""}
                    editorState={editorState}
                    handlePastedText={handlePastedText}
                    onChange={(editorState) => {
                        onChange(editorState);
                    }}
                    plugins={plugins}
                    ref={editorRef}
                    readOnly={disabled ? true : false}
                />
            </div>
            {editorState.getCurrentContent().getPlainText().trim().length === 0 ? (
                <div
                    className=" absolute top-0  text-gray-600 overflow-hidden pt-2 px-4 pb-4 rounded break-words w-full text-editor-container"
                    style={{
                        minHeight: height || "8.2rem",
                        maxHeight: height || "8.2rem",
                        overflowY: "none",
                        pointerEvents: "none",
                    }}
                >
                    <Editor editorState={placeholderState} readOnly={true} plugins={plugins} />
                </div>
            ) : null}
            {!disabled ? (
                <button
                    ref={buttonRef}
                    id={inputs[1]}
                    className={`bg-white focus:outline-none absolute cursor-pointer rounded ${
                        adjustTagsButton ? adjustTagsButton : "z-100 py-2 px-2"
                    }`}
                    onClick={(e) => {
                        setShowTagsSelector((val) => !val);
                        focus(e);
                        setEditorState(EditorState.moveFocusToEnd(editorState));
                    }}
                    style={
                        adjustTagsButtonComponentStyles
                            ? adjustTagsButtonComponentStyles
                            : { bottom: "0.5rem", right: "0.5rem", paddingTop: "0.05rem", paddingBottom: "0.05rem" }
                    }
                >
                    <i className=" icon-tags pr-1 "></i>{" "}
                    <i className={`icon-chevron${showTagsSelector ? "-up" : ""}`}></i>
                </button>
            ) : null}
            {showTagsSelector ? (
                <SeletectorDraftTag
                    callBackBlockTag={(tag) => adddBlockTag(tag)}
                    callBackInlineTag={(tag) => addInlineTag(tag)}
                    listTagsFixed={listTagsFixed}
                    topPosition={topPositionTags}
                    rightPosition={rightPositionTags}
                    typeTags={typeTags}
                />
            ) : null}
        </div>
    );
};

const SeletectorDraftTag = ({
    topPosition = "7.9rem",
    rightPosition = "0.5rem",
    callBackInlineTag,
    callBackBlockTag,
    listTagsFixed,
    typeTags = "",
}) => {
    const { t } = useTranslation();
    const { customTags } = useSelector((state) => state.sectionContent);
    const [allTags, setAllTags] = useState([]);
    let lastCategory = null;

    useEffect(() => {
        let _allTags = [];
        if (typeTags === "sales") {
            predefinedSalesTags(t).forEach((staticTag) => {
                _allTags.push({ ...staticTag, visible: true });
            });
        } else {
            predefinedTags(t).forEach((staticTag) => {
                _allTags.push({ ...staticTag, visible: true });
            });
        }
        if (customTags && customTags.length > 0) {
            customTags.forEach((tag) => {
                _allTags.push({
                    key: tag.id,
                    value: `{{.${tag.tag}}}`,
                    text: tag.name,
                    category: "custom-tags",
                    visible: true,
                    blockTag: false,
                });
            });
        }

        if (typeTags !== "sales") {
            _allTags.reverse();
        }

        setAllTags(_allTags);
        // eslint-disable-next-line
    }, [customTags]);

    const filterTags = (value) => {
        const temCopy = JSON.parse(JSON.stringify(allTags));
        if (value && value.length > 0) {
            temCopy.forEach((item) => {
                const isVisible =
                    removeDiacritics(item.text.toLowerCase()).indexOf(removeDiacritics(value.toLowerCase())) > -1;
                item.visible = isVisible;
            });
        } else {
            temCopy.map((item) => (item.visible = true));
        }
        setAllTags(temCopy);
    };

    return (
        <div
            id="tag-selector-container"
            className={` w-56 h-auto z-205 rounded bg-white border border-gray-300 p-2 ${
                listTagsFixed ? "fixed" : "absolute"
            } `}
            style={{
                top: topPosition,
                right: rightPosition,
                minHeight: "24.3rem",
                maxHeight: "24.3rem",
                overflowY: "scroll",
                overflowX: "hidden",
            }}
        >
            <UseSearch id="tag-selector-searcher" placeholder={"search-for"} onChange={(val) => filterTags(val)} />

            {allTags.length > 0 &&
                allTags.map((item, index) => {
                    if (!item.visible) {
                        return null;
                    }
                    let showCategoryName = false;
                    if (item.category !== lastCategory) {
                        showCategoryName = true;
                        lastCategory = item.category;
                    }
                    return (
                        <Fragment key={item.key}>
                            {showCategoryName && (
                                <div className={`font-bold mt-2 mb-1 pt-1 ${index > 0 ? "border-t" : ""}`}>
                                    {capitalizeFirst(t(item.category))}
                                </div>
                            )}
                            <li
                                id={item.value}
                                onMouseDown={(e) => {
                                    if (item.blockTag) {
                                        callBackBlockTag(item.value);
                                    } else {
                                        callBackInlineTag(item.value);
                                    }
                                }}
                                className="rdw-dropdownoption-default placeholder-li text-gray-900 clickable"
                            >
                                {item.text}
                                {item.info && (
                                    <Icon className={"ml-2"} type={"info"} size={1.5} tooltip={item.info}></Icon>
                                )}
                            </li>
                        </Fragment>
                    );
                })}
        </div>
    );
};

export default UseInputDraftEditableTagsField;

//UTILS

const buildInitialState = (text = "") => {
    const textBlock = parseTextToDraftStateToBlocks(text);
    const state = {
        entityMap: {},
        blocks: [],
    };
    textBlock.forEach((block) => {
        let entityMapKey = 0;
        if (block.text === "{{.OrderSummary}}") {
            state.entityMap[entityMapKey] = {
                type: "BlockTag",
                mutability: "IMMUTABLE",
                data: { tag: "{{.OrderSummary}}" },
            };
            entityMapKey++;
        }

        state.blocks.push({
            key: genKey(),
            text: block.text === "{{.OrderSummary}}" ? " " : block.text,
            type: block.type,
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [{ ...block.entityRanges }],
            data: {},
        });
    });

    return state;
};

const parseTextToDraftStateToBlocks = (text) => {
    const textArray = text.split("\n");
    const blocks = textArray.map((t) => {
        return {
            text: t,
            type: t === "{{.OrderSummary}}" ? "atomic" : "unstyled",
            entityRanges:
                t === "{{.OrderSummary}}"
                    ? {
                          offset: 0,
                          length: 1,
                          key: 0,
                      }
                    : {},
        };
    });

    return blocks;
};

const getEditorValue = (props = {}) => {
    const { editorState } = props;
    let response = "";
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const mappedBlocks = rawContentState.blocks.map((block) => {
        if (block.type === "atomic") {
            const entity = rawContentState.entityMap[block.entityRanges[0].key];
            const entityData = entity.data;
            if (entity.type === "BlockTag" && entityData.tag === "{{.OrderSummary}}") {
                return "{{.OrderSummary}}";
            }
        }
        return (!block.text.trim() && "\n") || block.text;
    });
    for (let i = 0; i < mappedBlocks.length; i++) {
        const block = mappedBlocks[i];
        if (i === mappedBlocks.length - 1) {
            response += block;
        } else {
            if (block === "\n") {
                response += block;
            } else {
                response += block + "\n";
            }
        }
    }
    return response;
};
