import { gql } from "apollo-boost";

export const GET_POINTS_OF_INTEREST = gql`
    query getPointsOfInterest($mapId: Int64!, $poiId: Int64) {
        mapInterestPoints(mapID: $mapId, filter: { id: $poiId }, lang: "all") {
            results {
                id
                name
                description
                pins
                pinIcon
                navigationAction
                image
            }
        }
    }
`;
