import React from "react";

import { Component, ListComponents } from "sections/playground";

import Button from "components/Button";
import { useLocationMapModal } from "components/LocationMap";
import { useModal, useAlert, useConfirm, ModalSize } from "components/Modal";

const Modals = () => {
    const { open, close } = useModal();
    const { open: showMap } = useLocationMapModal();
    const { open: alert } = useAlert();
    const { open: confirm } = useConfirm();

    const launchSample = (size, title, props) => () =>
        open({
            title: title || "Modal title",
            children: (
                <>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <p>Cras non metus sed odio tristique fermentum.</p>
                </>
            ),
            size,
            ...props,
            footer: (
                <>
                    <Button
                        id="btn-custom"
                        design="white"
                        className="px-10 bg-pink-200 text-pink-500 hover:text-pink-600"
                        onClick={() => {
                            alert("Custom button clicked");
                        }}
                    >
                        Custom button
                    </Button>
                    <Button id="btn-exit" design="blue" onClick={close}>
                        Exit
                    </Button>
                </>
            ),
        });

    return (
        <ListComponents>
            <Component title="Modals">
                <Button id="basic-modal" design="blue" onClick={launchSample(ModalSize.SMALL, "Small modal")}>
                    Small
                </Button>
                <Button id="basic-modal" design="blue" onClick={launchSample(ModalSize.MEDIUM, "Medium modal")}>
                    Medium
                </Button>
                <Button id="basic-modal" design="blue" onClick={launchSample(ModalSize.LARGE, "Large modal")}>
                    Large
                </Button>
                <Button
                    id="basic-modal"
                    design="blue"
                    onClick={launchSample(ModalSize.MEDIUM, "Medium (Full height) modal", { fullHeight: true })}
                >
                    Medium (Full height)
                </Button>

                <Button
                    id="basic-modal"
                    design="blue"
                    onClick={() => {
                        launchSample(ModalSize.MEDIUM, "Medium (Full height) modal", { fullHeight: true })();
                        launchSample(ModalSize.LARGE, "Large modal")();
                        launchSample(ModalSize.MEDIUM, "Medium modal")();
                        launchSample(ModalSize.SMALL, "Small modal")();
                    }}
                >
                    Open all sizes
                </Button>

                <Button
                    id="location-map"
                    design="blue"
                    onClick={() => showMap({ latitude: "41.6488", longitude: "-0.8891" })}
                >
                    useLocationMapModal
                </Button>
            </Component>
            <Component title="Alerts">
                <Button
                    design="blue"
                    id="alert-1"
                    onClick={() => alert("Lorem ipsum dolor sit amet, consectetur adipiscing elit.")}
                >
                    useAlert
                </Button>
                <Button
                    design="blue"
                    id="alert-2"
                    onClick={() =>
                        confirm({
                            id: "confirm-destroy-planet",
                            message: "Are you sure you want to do this?",
                            title: "Destroy planet?",
                            onConfirm: () => {
                                alert("Planet destroyed!");
                            },
                        })
                    }
                >
                    useConfirm
                </Button>
            </Component>
        </ListComponents>
    );
};

export default Modals;
