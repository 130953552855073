import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Logo from "./Logo";
import ReactTooltip from "components/ReactTooltip";

import {
    PROPERTY_LOGOS,
    CORPORATE_LOGOS,
    CORPORATE_REF,
    CORPORATE_APPEARANCE_TYPE,
    PROPERTY_APPEARANCE_TYPE,
    permissionNullLogo,
    modalsOff,
    PROPERTY_LOGOS_TYPE,
    THEME_TYPE_MOBILE,
    validateNumberLogosMobileTheme,
    CORPORATE_LOGOS_TYPE,
} from "../../../../../hooks/Utils/AppearancesUtils";
import { setActionName } from "../../../../../actions/globalActions";
import ModalLogoData from "../../../../../hooks/GraphqlCalls/Appearances/Modals/ModalLogoData";
import { setSectionContent } from "../../../../../actions/sectionActions";
import { THEME_TYPE_DESIGN } from "hooks/Utils/AppearancesUtils";

import { useAuth } from "hooks/Session/auth";

export default function LogosSidebar({ themeType }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { project, isCorporate, hasChainModule } = useAuth();

    const { appearanceSelected } = useSelector((state) => state.sectionContent);

    const showGlobalLogos = isCorporate || hasChainModule;
    const defaultGroupType = showGlobalLogos ? CORPORATE_LOGOS : PROPERTY_LOGOS;

    const [groupType, setGroupType] = useState(defaultGroupType);

    const isGlobalTheme = appearanceSelected?.type === CORPORATE_APPEARANCE_TYPE;
    const isPropertyTheme = appearanceSelected?.type === PROPERTY_APPEARANCE_TYPE;
    const isPropertyLogo = groupType === PROPERTY_LOGOS;

    const addAction = isCorporate ? isGlobalTheme : isPropertyTheme;

    //states
    const projectRef = project?.ref;

    const [locks, setLocks] = useState({ generalLogos: null, propertyLogos: null, propertyInfoTooltip: null });

    const displayLogos = appearanceSelected?.logos?.length
        ? appearanceSelected.logos.filter(
              (logo) => logo.type === (groupType === CORPORATE_LOGOS ? CORPORATE_LOGOS_TYPE : PROPERTY_LOGOS_TYPE)
          )
        : [];

    useEffect(() => {
        if (themeType === THEME_TYPE_MOBILE && appearanceSelected) {
            setGroupType(PROPERTY_LOGOS);
        }
    }, [themeType, appearanceSelected]);

    useEffect(() => {
        if (
            appearanceSelected &&
            projectRef === CORPORATE_REF &&
            appearanceSelected.type === CORPORATE_APPEARANCE_TYPE
        ) {
            setLocks({
                generalLogos: null,
                propertyLogos: true,
                propertyInfoTooltip: t("tooltip-property-logos-replace-2"),
            });
        } else if (
            appearanceSelected &&
            projectRef === CORPORATE_REF &&
            appearanceSelected.type === PROPERTY_APPEARANCE_TYPE
        ) {
            setLocks({ generalLogos: null, propertyLogos: null, propertyInfoTooltip: null });
        } else if (appearanceSelected && appearanceSelected.type === CORPORATE_APPEARANCE_TYPE) {
            setLocks({
                generalLogos: true,
                propertyLogos: true,
                propertyInfoTooltip: t("tooltip-property-logos-replace"),
            });
        } else if (appearanceSelected && appearanceSelected.type === PROPERTY_APPEARANCE_TYPE) {
            setLocks({ generalLogos: null, propertyLogos: null, propertyInfoTooltip: null });
        }
        // eslint-disable-next-line
    }, []);

    //KEEP DATA THROUGH MODALS SEQUENCES
    ModalLogoData();

    const divAddLogo = () => {
        if (
            (appearanceSelected && appearanceSelected.locks && appearanceSelected.locks.LOGOS_TOOLBAR) ||
            (appearanceSelected &&
                appearanceSelected.type === CORPORATE_APPEARANCE_TYPE &&
                projectRef !== CORPORATE_REF) ||
            !validateNumberLogosMobileTheme({ appearanceSelected, themeType }) ||
            (appearanceSelected?.type === PROPERTY_APPEARANCE_TYPE && projectRef === CORPORATE_REF)
        ) {
            return null;
        }
        return (
            <div
                className={`relative rounded-lg border w-48 h-28 top-0 mx-auto items-center align-middle text-center border-dashed text-4xl cursor-pointer border-zafiro-600`}
            >
                <div
                    className="flex my-auto h-full w-full m-auto items-center text-center align-middle justify-center text-zafiro-600"
                    id="add-logos-sidebar-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setActionName("appearance-add-logo"));
                        dispatch(
                            setSectionContent({
                                modalAddLogo: true,
                                addLogo: { type: groupType },
                                allowNullLogo: permissionNullLogo(groupType, appearanceSelected.type, projectRef),
                                modals: { ...modalsOff, add: true },
                                themeType: themeType,
                            })
                        );
                    }}
                >
                    +
                </div>
            </div>
        );
    };

    return (
        <>
            {showGlobalLogos && themeType === THEME_TYPE_DESIGN ? (
                <div className="min-w-full grid grid-flow-col grid-cols-2 border-b text-base border-gray-200 ">
                    <div
                        className={`flex w-full text-center align-middle items-center py-5 cursor-pointer ${
                            groupType === CORPORATE_LOGOS ? "bg-blue-300 text-white" : ""
                        }`}
                        id={"logos-sidebar-generic-logos-button"}
                        onClick={() => setGroupType(CORPORATE_LOGOS)}
                    >
                        {locks.generalLogos && (
                            <span className="inline icon-lock-close ml-auto mr-2 my-auto text-3xl"></span>
                        )}
                        <div className={`inline mr-auto my-auto ${!locks.generalLogos ? "ml-auto" : ""}`}>
                            {t("generic-logos")}
                        </div>
                    </div>
                    <div
                        id={"logos-sidebar-property-logos-button"}
                        className={`flex w-full text-center justify-center items-center py-5 cursor-pointer ${
                            groupType === PROPERTY_LOGOS ? "bg-blue-300 text-white" : ""
                        }`}
                        onClick={() => setGroupType(PROPERTY_LOGOS)}
                    >
                        {t("property-logos")}
                        {appearanceSelected?.type === "CORPORATE" ? (
                            <div>
                                <span
                                    onMouseOver={() => {
                                        ReactTooltip.rebuild();
                                    }}
                                    className="block mt-0.5 icon-info mr-auto ml-2 my-auto text-1xl cursor-default"
                                    data-tip={locks.propertyInfoTooltip}
                                ></span>
                                <ReactTooltip
                                    place="bottom"
                                    type="light"
                                    // offset={{ top: -8, left: -8 }}
                                    html={true}
                                    border={true}
                                    borderColor="#D3DAE1"
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className="min-w-full border-b text-base border-gray-200 text-center p-5 text-gray-800 font-bold">
                    {t("property-logos")}
                </div>
            )}
            {appearanceSelected?.type === "CORPORATE" && groupType === PROPERTY_LOGOS ? (
                <div className="text-gray-900 px-8 my-4">{t("tooltip-property-logos-replace-2")}</div>
            ) : null}
            <ul
                id="logos_List"
                className="grid grid-flow-row grid-cols-2 flex-col max-h-80 overflow-y-scroll  overflow-x-hidden p-4 pb-32 collapseAnimation min-w-full"
            >
                {displayLogos?.length
                    ? displayLogos.map((logo, idx) => {
                          return (
                              <Logo index={idx} logo={logo} key={logo.id} groupType={groupType} themeType={themeType} />
                          );
                      })
                    : null}
                {addAction ? divAddLogo() : null}
            </ul>
        </>
    );
}
