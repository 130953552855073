import { useState } from "react";
import useGQLQuery from "hooks/useQuery";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useEffect } from "react";

export const useRooms = (type, options, free = false) => {
    const { onSuccess, onError } = options || {};
    const query = gql`
        query rooms($type: RoomType!, $free: Boolean) {
            rooms(filter: { type: $type, free: $free }) {
                results {
                    id
                    name
                }
            }
        }
    `;

    const q = useGQLQuery(query, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response?.rooms);
            }
        },
        onError,
    });
    return {
        ...q,
        load: () => q.load({ type, free }),
    };
};

export const useRoomGroups = (type, options) => {
    const { onSuccess, onError } = options || {};
    const query = gql`
        query roomGroups($type: RoomType!) {
            roomGroups(filter: { type: $type }) {
                results {
                    id
                    name
                    type
                    rooms {
                        id
                        name
                    }
                }
            }
        }
    `;

    const q = useGQLQuery(query, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response?.roomGroups);
            }
        },
        onError,
    });
    return {
        ...q,
        load: () => q.load({ type }),
    };
};

export const useRoomGroupRooms = (options) => {
    const { onSuccess, onError } = options || {};
    const query = gql`
        query hotelRooms($id: Int64, $roomType: RoomType!) {
            hotelRooms(filter: { roomGroupID: [$id], roomType: $roomType }) {
                results {
                    id
                }
            }
        }
    `;

    const [customCallbacks, setCustomCallbacks] = useState({});

    const q = useGQLQuery(query, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response?.hotelRooms);
            }
            if (customCallbacks?.onSuccess) {
                customCallbacks.onSuccess(response?.hotelRooms);
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
            if (customCallbacks?.onError) {
                customCallbacks.onError(error);
            }
        },
    });

    return {
        ...q,
        load: (id, roomType, options) => {
            const { onSuccess, onError } = options || {};
            setCustomCallbacks({ onSuccess, onError });
            q.load({ id, roomType });
        },
    };
};

export const useDeleteRoomGroup = (options) => {
    const { onSuccess, onError } = options || {};
    const query = gql`
        mutation deleteRoomGroup($id: Int64!) {
            deleteRoomGroup(id: $id) {
                error
                id
                ok
            }
        }
    `;
    const [post, { data, error: err, called, loading }] = useMutation(query);

    const [customCallbacks, setCustomCallbacks] = useState({});

    const success = called && !loading && data?.deleteRoomGroup?.id;
    const error = called && !loading && (err || data?.deleteRoomGroup?.error || (!success ? "Unknown error" : null));

    useEffect(() => {
        if (success) {
            if (onSuccess) {
                onSuccess();
            }
            if (customCallbacks?.onSuccess) {
                customCallbacks.onSuccess();
            }
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            if (onError) {
                onError(error);
            }
            if (customCallbacks?.onError) {
                customCallbacks.onError(error);
            }
        }
    }, [error]);

    return (id, options) => {
        const { onSuccess, onError } = options || {};
        setCustomCallbacks({ onSuccess, onError });
        return post({ variables: { id } });
    };
};

export const useCreateRoomGroup = (options) => {
    const { onSuccess, onError } = options || {};
    const query = gql`
        mutation createRoomGroup($type: RoomType!, $name: String!, $rooms: [Int64]) {
            createRoomGroup(type: $type, name: $name, rooms: $rooms) {
                error
                id
                ok
            }
        }
    `;
    const [post, { data, error: err, called, loading }] = useMutation(query);

    const [customCallbacks, setCustomCallbacks] = useState({});

    const success = called && !loading && data?.createRoomGroup?.id;
    const error = called && !loading && (err || data?.createRoomGroup?.error || (!success ? "Unknown error" : null));

    useEffect(() => {
        if (success) {
            if (onSuccess) {
                onSuccess();
            }
            if (customCallbacks?.onSuccess) {
                customCallbacks.onSuccess();
            }
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            if (onError) {
                onError(error);
            }
            if (customCallbacks?.onError) {
                customCallbacks.onError(error);
            }
        }
    }, [error]);

    return (input, options) => {
        const { onSuccess, onError } = options || {};
        setCustomCallbacks({ onSuccess, onError });
        return post({
            variables: {
                type: input?.type,
                name: input?.name,
                rooms: input?.rooms,
            },
        });
    };
};

export const useUpdateRoomGroup = (options) => {
    const { onSuccess, onError } = options || {};
    const query = gql`
        mutation updateRoomGroup($id: Int64!, $name: String!, $rooms: [Int64]) {
            updateRoomGroup(id: $id, name: $name, rooms: $rooms, replace: true) {
                error
                id
                ok
            }
        }
    `;
    const [post, { data, error: err, called, loading }] = useMutation(query);

    const [customCallbacks, setCustomCallbacks] = useState({});
    const success = called && !loading && data?.updateRoomGroup?.ok;
    const error = called && !loading && (err || data?.updateRoomGroup?.error || (!success ? "Unknown error" : null));

    useEffect(() => {
        if (success) {
            if (onSuccess) {
                onSuccess();
            }
            if (customCallbacks?.onSuccess) {
                customCallbacks.onSuccess();
            }
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            if (onError) {
                onError(error);
            }
            if (customCallbacks?.onError) {
                customCallbacks.onError(error);
            }
        }
    }, [error]);

    return (input, options) => {
        const { onSuccess, onError } = options || {};
        setCustomCallbacks({ onSuccess, onError });
        return post({
            variables: {
                id: input?.id,
                name: input?.name,
                rooms: input?.rooms,
            },
        });
    };
};
