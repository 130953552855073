import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PinLocation = ({ imageSrc, icon, isHighlighted = false }) => {
    const clipIdRef = useRef(`clip-shape-${Math.random().toString(36).substr(2, 9)}`);

    return (
        <svg
            width="30"
            height="35"
            viewBox="0 0 30 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full h-full relative"
        >
            <defs>
                <clipPath id={clipIdRef.current}>
                    <path d="M29.5 14.433C29.5 16.2941 28.6731 18.4835 27.3554 20.7588C26.0443 23.0229 24.2794 25.3177 22.4755 27.3761C20.6729 29.4331 18.8412 31.2427 17.4047 32.5357C16.686 33.1827 16.0709 33.6961 15.6114 34.0449C15.3806 34.2201 15.1966 34.348 15.0627 34.4297C15.0392 34.444 15.0183 34.4562 15 34.4666C14.9817 34.4562 14.9608 34.444 14.9373 34.4297C14.8034 34.348 14.6194 34.2201 14.3886 34.0449C13.9291 33.6961 13.314 33.1827 12.5953 32.5357C11.1588 31.2427 9.32708 29.4331 7.52447 27.3761C5.72058 25.3177 3.95574 23.0229 2.6446 20.7588C1.32691 18.4835 0.5 16.2941 0.5 14.433C0.5 6.756 6.97354 0.5 15 0.5C23.0265 0.5 29.5 6.756 29.5 14.433Z" />
                </clipPath>
            </defs>
            <rect x="0" y="0" width="30" height="35" fill="white" clipPath={`url(#${clipIdRef.current})`} />
            {imageSrc && (
                <image
                    href={imageSrc}
                    x="0"
                    y="0"
                    width="30"
                    height="35"
                    clipPath={`url(#${clipIdRef.current})`}
                    preserveAspectRatio="xMidYMid slice"
                />
            )}
            {!imageSrc && icon && (
                <foreignObject x="0" y="0" width="30" height="35" clipPath={`url(#${clipIdRef.current})`}>
                    <div
                        className="w-full h-full flex items-center justify-center"
                        xmlns="http://www.w3.org/1999/xhtml"
                    >
                        <FontAwesomeIcon
                            icon={[icon.lib, icon.icon]}
                            style={{ width: "55%", height: "55%", marginTop: "-15%", marginLeft: "0%" }}
                        />
                    </div>
                </foreignObject>
            )}
            <path
                d="M29.5 14.433C29.5 16.2941 28.6731 18.4835 27.3554 20.7588C26.0443 23.0229 24.2794 25.3177 22.4755 27.3761C20.6729 29.4331 18.8412 31.2427 17.4047 32.5357C16.686 33.1827 16.0709 33.6961 15.6114 34.0449C15.3806 34.2201 15.1966 34.348 15.0627 34.4297C15.0392 34.444 15.0183 34.4562 15 34.4666C14.9817 34.4562 14.9608 34.444 14.9373 34.4297C14.8034 34.348 14.6194 34.2201 14.3886 34.0449C13.9291 33.6961 13.314 33.1827 12.5953 32.5357C11.1588 31.2427 9.32708 29.4331 7.52447 27.3761C5.72058 25.3177 3.95574 23.0229 2.6446 20.7588C1.32691 18.4835 0.5 16.2941 0.5 14.433C0.5 6.756 6.97354 0.5 15 0.5C23.0265 0.5 29.5 6.756 29.5 14.433Z"
                fill="none"
                stroke={isHighlighted ? "#FF991F" : "#BDC8D3"}
                strokeWidth={isHighlighted ? "3px" : "1px"}
            />
        </svg>
    );
};

export default PinLocation;
