import React, { useRef, useState, useEffect } from "react";
import GridLayout from "./MapsGridLayout";
import useDevice from "components/Editor/devices";
import { useMap } from "./MapContext";
import { useEditorMapResizeObserver } from "./hooks";

const MapEditor = () => {
    const [area, setArea] = useState(null);
    const areaRef = useRef(null);
    const editorRef = useRef(null);

    const { device: contextDevice, setDevice, dataInterestPoints, manageInterestPoints } = useMap();
    const {
        interestPoints,
        showingHighlitedPoints,
        handleLayoutChange,
        handleRemovePinsInterestPoint,
        updateMapInterestPoints,
        selectedItemId,
        setSelectedItemId,
    } = manageInterestPoints;

    const device = useDevice({
        editorRef,
        deviceType: null,
        customDevice: {
            cols: 50,
            rows: 29,
            scrollX: false,
            scrollY: false,
        },
        area,
        readOnly: false,
    });

    useEditorMapResizeObserver(areaRef, setArea, device);

    useEffect(() => {
        if (JSON.stringify(device) !== JSON.stringify(contextDevice)) {
            setDevice(device);
        }
    }, [device, contextDevice, setDevice]);

    useEffect(() => {
        if (dataInterestPoints) {
            updateMapInterestPoints(dataInterestPoints);
        }
    }, [dataInterestPoints]);

    return (
        <div className="flex w-full">
            <div style={{ width: "100%" }}>
                <div ref={areaRef} className="w-auto h-full flex justify-center">
                    <div
                        style={{
                            width: `${device?.width}px`,
                            height: `${device?.height - device?.border * 2}px`,
                            transition: "0.3s ease-in-out",
                            position: "relative",
                        }}
                    >
                        <GridLayout
                            ref={editorRef}
                            items={interestPoints}
                            showingHighlitedPoints={showingHighlitedPoints}
                            cols={device?.cols}
                            rows={device?.rows}
                            rowHeight={device?.rowHeight}
                            margin={device?.margin}
                            gap={device?.gap}
                            handleRemoveItem={handleRemovePinsInterestPoint}
                            onLayoutChange={handleLayoutChange}
                            style={{
                                height: `${device?.height - device?.border * 2}px`,
                            }}
                            selectedItemId={selectedItemId}
                            setSelectedItemId={setSelectedItemId}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapEditor;
