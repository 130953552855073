import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Session } from "../../../hooks/Utils/Session";

import { showGlobalLoading } from "../../../actions/uiActions";
import { setExecuteQuery } from "../../../actions/sectionActions";

//Utils
import ReactHlsPlayer from "react-hls-player";
import { capitalizeFirst, formatBytes } from "../../../hooks/Utils/Utils";
import { convertMsToTime } from "../../../hooks/Utils/DesignUtils";
import { useTranslation } from "react-i18next";

import UseFile from "../../useFile";
import UseButton from "../../useButton";
import UseIcon from "../../useIcon";
import UseEditableField from "../../../components/useEditableField";

const STATUS_SUBMITTED = "SUBMITTED";
const STATUS_PROGRESSING = "PROGRESSING";
const STATUS_COMPLETE = "COMPLETE";
const STATUS_CANCELED = "CANCELED";
const STATUS_ERROR = "ERROR";

const VideoLocale = ({
    isDefault,
    defaultLanguage,
    resetInputs,
    languagesOptions,
    assetLang,
    lang,
    fileRef,
    assetName,
    lastUpdateInterfaz,
    deleteAsset,
    downloadAsset,
    makeAsDefaultAsset,
    replaceAsset,
    transcodeAsset,
    createAsset,
    isLangEditable,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [updateName, setUpdateName] = useState({ status: 0, value: "" });

    const playerRef = React.useRef();
    const [playing, setPlaying] = useState(false);

    const languageRef = lang.id;
    const isEditable = isLangEditable(languageRef);
    let extension = "";
    let splitExtension = null;

    const status = assetLang ? assetLang.streamStatus : "";
    const transcodePending = status === "";
    const transcodeInProgress = status === STATUS_SUBMITTED || status === STATUS_PROGRESSING;
    const transcodeCompleted = status === STATUS_COMPLETE;
    const transcodeStatus = getTranscodeStatusMessage(status, t);

    const handleChangeUpdateNameStatus = (new_status) => {
        setUpdateName((updateName) => ({ ...updateName, status: new_status }));
    };

    const handleChangeName = (e) => {
        const { value } = e.target;
        setUpdateName((updateName) => ({ ...updateName, value: value }));
    };

    function playVideo() {
        if (!playing) {
            if (playerRef.current) {
                playerRef.current.controls = true;
                playerRef.current.play();
                setPlaying(true);
            }
        }
    }

    useEffect(() => {
        if (playing && playerRef.current) {
            playerRef.current.pause();
        }
        setPlaying(false);
    }, [fileRef, languageRef, transcodeCompleted]);

    useEffect(() => {
        if (updateName.status === 0) {
            setUpdateName((updateName) => ({ ...updateName, value: assetName }));
        } else if (updateName.status === 2) {
            dispatch(showGlobalLoading(true));
            dispatch(
                setExecuteQuery({
                    action: "media-library-update-video",
                    params: {
                        id: fileRef,
                        name: updateName.value,
                        defaultLanguage: "notC",
                    },
                })
            );
            handleChangeUpdateNameStatus(0);
        }
        // eslint-disable-next-line
    }, [updateName.status]);

    if (assetLang && assetLang.contentType) {
        splitExtension = assetLang.contentType.split("/");
        if (splitExtension && splitExtension.length >= 2) extension = splitExtension[1];
    }

    if (assetLang) {
        const defaultLangInfo = assetLang.id ? languagesOptions.filter((l) => l.id === assetLang.id)[0] : null;

        const posterSrc = Session.getDasUrl(
            `thumb/${fileRef}-${assetLang.language}?w=633&h=336&lastUpdateInterfaz=${lastUpdateInterfaz}`
        );
        const videoSrc = Session.getDasUrl(
            `stream/${fileRef}-${assetLang.language}.m3u8?lastUpdateInterfaz=${lastUpdateInterfaz}`
        );

        return (
            <>
                <div className="w-full flex">
                    <div className="w-6/12 h-96 flex flex-col">
                        <div
                            className={
                                `relative w-full h-full bg-gray-200 text-center ` +
                                (transcodeCompleted ? `cursor-pointer` : `p-1`)
                            }
                            onClick={() => {
                                if (transcodeCompleted) {
                                    playVideo();
                                }
                            }}
                        >
                            {transcodeCompleted ? (
                                <ReactHlsPlayer
                                    playerRef={playerRef}
                                    key={`video${assetLang.language}`}
                                    poster={posterSrc}
                                    src={videoSrc}
                                    controls={false}
                                    autoPlay={false}
                                    muted={false}
                                    loop={false}
                                    className={`w-full h-full bg-gray-200`}
                                ></ReactHlsPlayer>
                            ) : (
                                <img
                                    src={posterSrc}
                                    alt={posterSrc}
                                    style={{
                                        display: "inline-block",
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                    }}
                                />
                            )}
                            {!playing ? (
                                <div
                                    className={
                                        `absolute top-0 left-0 w-full bg-gray-300 bg-opacity-25 h-full table text-center ` +
                                        (transcodeCompleted ? `hover:bg-opacity-0` : `opacity-50`)
                                    }
                                >
                                    <div className="table-cell align-middle">
                                        <div className={`icon icon-play2 text-white text-6xl`}></div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="w-full p-1 mt-2 text-left">
                            {isDefault ? null : (
                                <>
                                    <div
                                        id={"asset-detail-make-default-button"}
                                        onClick={() => {
                                            if (isEditable) makeAsDefaultAsset(assetLang.language, assetLang.name);
                                        }}
                                        className={
                                            "inline-block " + (isEditable ? " t-link cursor-pointer" : " text-gray-500")
                                        }
                                    >
                                        <div className="first-capital">{capitalizeFirst(t("make-default"))}</div>
                                    </div>
                                    &nbsp;
                                    <span className="text-gray-200">|</span>
                                </>
                            )}
                            &nbsp;
                            <div
                                onClick={() => {
                                    if (isEditable) {
                                        if (isDefault) {
                                            replaceAsset(lang.id, assetName ? assetName : null);
                                        } else {
                                            replaceAsset(assetLang.language, assetLang.name);
                                        }
                                    }
                                }}
                                id={"asset-detail-replace-button"}
                                className={"inline-block " + (isEditable ? " t-link cursor-pointer" : " text-gray-500")}
                            >
                                <div className="first-capital">{capitalizeFirst(t("replace"))}</div>
                            </div>
                            &nbsp;
                            <span className="text-gray-200">|</span>
                            &nbsp;
                            <div
                                onClick={() => {
                                    if (isDefault) {
                                        downloadAsset(lang.id);
                                    } else {
                                        downloadAsset(assetLang.language);
                                    }
                                }}
                                id={"asset-detail-download-button"}
                                className="inline-block cursor-pointer"
                            >
                                <div className="t-link first-capital">{capitalizeFirst(t("download"))}</div>
                            </div>
                            &nbsp;
                            <span className="text-gray-200">|</span>
                            &nbsp;
                            <div
                                onClick={() => {
                                    if (isEditable) {
                                        if (isDefault) {
                                            deleteAsset(null, assetLang.name, true);
                                        } else {
                                            deleteAsset(assetLang.language, assetLang.name);
                                        }
                                    }
                                }}
                                id={"asset-detail-delete-permanently-button"}
                                className={
                                    "inline-block " +
                                    (isEditable ? " t-link cursor-pointer text-red-100" : " text-gray-500")
                                }
                            >
                                <div className="first-capital">{capitalizeFirst(t("delete-permanently"))}</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-6/12 pl-6 pr-4">
                        <div className="w-12/12">
                            <h1 className={`section-title first-capital mb-5`}>
                                {capitalizeFirst(t("file-information"))}
                            </h1>
                            {isDefault && updateName.status === 0 ? (
                                <>
                                    <p className={`mb-5 truncate max-w-80 float-left`}>
                                        <span className="float-left font-bold text-gray-900 first-capital overflow-hidden cursor-pointer pr-1">
                                            {capitalizeFirst(t("name"))}:
                                        </span>{" "}
                                        {assetName ? assetName : assetLang ? assetLang.name : null}
                                    </p>
                                    {assetName || assetLang ? (
                                        <div
                                            className={`float-left pt-1 mr-1`}
                                            onClick={() => {
                                                handleChangeUpdateNameStatus(1);
                                            }}
                                        >
                                            <UseIcon icon={{ name: "edit" }} adjust="t-icon-headers text-gray-800" />
                                        </div>
                                    ) : null}
                                </>
                            ) : isDefault ? (
                                <div className={`mb-5`}>
                                    <span className="float-left font-bold text-gray-900 first-capital overflow-hidden cursor-pointer pr-1 mb-3">
                                        {capitalizeFirst(t("name"))}:
                                    </span>{" "}
                                    <input
                                        placeholder={capitalizeFirst(t("name"))}
                                        type="text"
                                        className={`float-left w-4/12 bg-gray-200 rounded hover:text-zafiro-400  text-gray-900  p-2 pl-4 phNoTransform -mt-2 mb-3`}
                                        name="UpdateName"
                                        id="UpdateName_input"
                                        onChange={handleChangeName}
                                        value={updateName.value}
                                    ></input>
                                    <div
                                        className="float-left mx-2 -mt-2 mb-3"
                                        onClick={() => {
                                            handleChangeUpdateNameStatus(0);
                                        }}
                                    >
                                        <UseButton buttonName="cancel" buttonColor="btn-white" />
                                    </div>
                                    <div
                                        className="float-left -mt-2 mb-3"
                                        onClick={() => {
                                            handleChangeUpdateNameStatus(2);
                                        }}
                                    >
                                        <UseButton buttonName="save" buttonColor="btn-blue" />
                                    </div>
                                </div>
                            ) : (
                                <p className={`mb-5`}>
                                    <span className="font-bold text-gray-900 first-capital overflow-hidden cursor-pointer">
                                        {capitalizeFirst(t("name"))}:
                                    </span>{" "}
                                    {assetLang ? assetLang.name : null}
                                </p>
                            )}
                            <p className={`mb-5 clear-left`}>
                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                    {capitalizeFirst(t("file-type"))}:
                                </span>{" "}
                                {(extension + "").toUpperCase()}
                            </p>
                            <p className={`mb-5`}>
                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                    {capitalizeFirst(t("last-modified"))}:
                                </span>{" "}
                                {assetLang
                                    ? `${new window.ZDate(
                                          assetLang.lastModified
                                      ).toLocaleDateString()} ${new window.ZDate(assetLang.lastModified).toLocaleString(
                                          [],
                                          {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                          }
                                      )}`
                                    : null}
                            </p>
                            <p className={`mb-5`}>
                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                    {capitalizeFirst(t("file-size"))}:
                                </span>{" "}
                                {assetLang ? formatBytes(assetLang.size) : null}
                            </p>
                            <p className={`mb-5 clear-left`}>
                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                    {capitalizeFirst(t("duration"))}:
                                </span>{" "}
                                {assetLang ? convertMsToTime(assetLang.duration) : null}
                            </p>
                            <p className={`mb-5 clear-left`}>
                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                    {capitalizeFirst(t("quality"))}:
                                </span>{" "}
                                {assetLang ? t(assetLang.resolution).split("-")[0] : null}
                            </p>
                            {isDefault ? (
                                <>
                                    <h1 className={`section-title first-capital mb-5`}>{capitalizeFirst(t("lang"))}</h1>
                                    <div className={`mb-5`}>
                                        <span className="font-bold text-gray-900 first-capital overflow-hidden float-left pt-2">
                                            {capitalizeFirst(t("file-language"))}
                                        </span>
                                        {!resetInputs && isLangEditable(assetLang.id) ? (
                                            <UseEditableField
                                                data={{
                                                    value: assetLang.id,
                                                    inEditionMode: true,
                                                    type: "select-with-search",
                                                    oneSelected: true,
                                                    optionData: languagesOptions.filter((item) => {
                                                        if (
                                                            item.id &&
                                                            !isLangEditable(item.id) &&
                                                            (isLangEditable(defaultLanguage) ||
                                                                defaultLanguage !== item.id)
                                                        ) {
                                                            return false;
                                                        }
                                                        return item;
                                                    }),
                                                    styleAdjust: "float-left w-3/12 mx-2",
                                                    selectedIds: [lang.id],
                                                    name: "assetDefaultLanguage",
                                                    label: "assetDefaultLanguage",
                                                    noLabel: true,
                                                }}
                                            ></UseEditableField>
                                        ) : (
                                            <strong className="float-left p-2 mr-2">
                                                {defaultLangInfo ? defaultLangInfo.name : "?"}
                                            </strong>
                                        )}
                                        <p className="text-gray-800 pt-2 capitalize">{t("default-lang")}</p>
                                    </div>
                                </>
                            ) : null}
                            <h1 className={`section-title first-capital mb-5`}>{capitalizeFirst(t("status"))}</h1>
                            <div className={`mb-5`}>
                                {!transcodeCompleted ? (
                                    <>
                                        <div className="flex justify-between w-full">
                                            <div
                                                className={`flex align-middle pt-2 font-bold ${
                                                    transcodeInProgress || transcodePending
                                                        ? "text-orange-100"
                                                        : "text-red-100"
                                                }`}
                                            >
                                                <div
                                                    className={
                                                        `icon ` +
                                                        (transcodeInProgress
                                                            ? ""
                                                            : transcodePending
                                                            ? "icon-info"
                                                            : "icon-warning") +
                                                        ` mr-2 pt-1 text-xl`
                                                    }
                                                >
                                                    {transcodeInProgress ? (
                                                        <div
                                                            className="lds-roller"
                                                            style={{
                                                                transform: "scale(0.3)",
                                                                transformOrigin: "top left",
                                                                width: "24px",
                                                                height: "24px",
                                                            }}
                                                        >
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <span>{transcodeStatus}</span>
                                            </div>
                                            {!transcodeInProgress ? (
                                                <div className="flex align-middle">
                                                    <span
                                                        className="btn-blue m-auto px-2"
                                                        onClick={() => {
                                                            if (isEditable) {
                                                                transcodeAsset(
                                                                    assetLang.language,
                                                                    assetName ? assetName : null,
                                                                    t("language:" + assetLang.language)
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {t("transcode-file")}
                                                    </span>
                                                </div>
                                            ) : null}
                                        </div>
                                        <span className="text-gray-800">{t("file-cannot-be-played")}</span>
                                    </>
                                ) : (
                                    <div className="font-bold text-green-500 text-lg">
                                        <img
                                            alt="active btn"
                                            className="inline-block w-5 h-5 align-middle"
                                            src={require("../../../assets/images/icons/save-circle-green.svg")}
                                        />
                                        <span className="ml-2">{transcodeStatus}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="w-full flex">
                    <div className="w-6/12">
                        {isEditable ? (
                            <>
                                <UseFile
                                    data={{
                                        name: `files${languageRef}`,
                                        value: "",
                                        type: "file",
                                        contentTypes: [
                                            {
                                                type: "video",
                                                maxSize: 2048,
                                                multiple: false,
                                            },
                                        ],
                                        ref: `media-library-update-input-media-${languageRef}`,
                                        triggerAction: `media-library-update-media-${languageRef}`,
                                    }}
                                />
                                <div
                                    onClick={() => {
                                        createAsset(languageRef);
                                    }}
                                    data-action={`media-library-update-media-${languageRef}`}
                                ></div>
                            </>
                        ) : null}
                    </div>
                </div>
            </>
        );
    }
};

function getTranscodeStatusMessage(status, t) {
    let text = "";
    switch (status) {
        case STATUS_SUBMITTED:
        case STATUS_PROGRESSING:
            text = t("transcoding");
            break;
        case STATUS_COMPLETE:
            text = t("completed");
            break;
        case STATUS_CANCELED:
            text = t("canceled-transcoding");
            break;
        case STATUS_ERROR:
            text = t("error-transcoding");
            break;
        default:
            text = t("pending-transcoding");
    }
    return text;
}

export default VideoLocale;
