import React, { useState } from "react";

import { Component, ListComponents } from "sections/playground";

import LocationMap from "components/LocationMap";
import { useAlert } from "components/Modal";
import SettingsCard from "components/SettingsCard";
import { DropdownSection } from "components/DropdownSection";
import InteractiveImageDisplay from "components/InteractiveImageDisplay";
import DragAndDropMediaCard from "components/DragAndDropMediaCard";
import ProjectsList from "components/ProjectsList";
import ProjectName from "components/ProjectName";

const Others = () => {
    const { open: alert } = useAlert();

    const [image, setImage] = useState(testImages[0]);

    console.log(mediaCardScenarios);

    return (
        <ListComponents cols={2}>
            <InteractiveImageDisplay
                id="test-library-image-selector"
                title={"Test full component"}
                src={image?.src}
                info={image?.alt}
                className="w-full h-20"
                onAdd={() => {
                    const images = testImages?.filter((i) => i?.src != image?.src);
                    setImage(images[Math.floor(Math.random() * images.length)]);
                }}
                onDelete={() => setImage(null)}
            />

            <InteractiveImageDisplay
                id="test-library-image-selector"
                title={"Another test"}
                resolution={null}
                src={testImages[1]?.src}
                info={testImages[1]?.alt}
                className="w-full h-64"
                onAdd={() => alert("No action, test only")}
            />

            <ProjectName name="Test project" hasChainModule={true} />

            <ProjectsList
                projects={[
                    {
                        id: 1,
                        name: "Test project",
                        hasChainModule: true,
                    },
                    {
                        id: 1,
                        name: "Test chain",
                        type: "CHAIN",
                        projects: [
                            {
                                id: 4,
                                name: "Hotel DMC",
                                hasChainModule: true,
                            },
                            {
                                id: 5,
                                name: "Hotel Troia",
                                hasChainModule: false,
                            },
                            {
                                id: 6,
                                name: "Hotel Porto",
                                hasChainModule: true,
                            },
                            {
                                id: 7,
                                name: "Hotel Lisbon",
                                hasChainModule: true,
                            },
                        ],
                    },
                    {
                        id: 1,
                        ref: "brand-1",
                        name: "Test group",
                        type: "BRAND",
                    },
                    {
                        id: 8,
                        name: "Hotel Madrid",
                        hasChainModule: true,
                    },
                    {
                        id: 9,
                        name: "Hotel Barcelona",
                        hasChainModule: true,
                    },
                ]}
                groups={[
                    {
                        ref: "brand-1",
                        projects: [
                            {
                                id: 2,
                                name: "Hotel Anon",
                                hasChainModule: true,
                            },
                            {
                                id: 3,
                                name: "Hotel Oslo",
                                hasChainModule: true,
                            },
                        ],
                    },
                ]}
            />

            <SettingsCard
                title="The title"
                description="This is a description of the SettingsCard component"
                info="This is information"
                warning="Warning example"
                edit={{ onClick: () => alert("Edit") }}
                toggle={{ checked: true }}
                right={<div className="icon icon-room text-4xl text-purple-500"></div>}
            >
                <p className="py-5">Example of a SettingsCard component</p>
            </SettingsCard>

            <DropdownSection dropdownSectionHeader="Dropdown Section" id="dropdownsection-playground">
                <div className="w-full bg-white flex items-center justify-center py-8">
                    <img
                        src="https://plus.unsplash.com/premium_photo-1669879825881-6d4e4bde67d5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHJhbmRvbSUyMHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D"
                        alt="placeholder"
                        width={"150px"}
                        height={"150px"}
                    />
                </div>
            </DropdownSection>
            <DropdownSection
                dropdownSectionHeader={{
                    title: {
                        content: "Title example",
                        className: "text-4xl",
                    },
                    titleIcon: {
                        type: "room",
                        size: "xl",
                        className: "text-purple-500",
                        tooltip: "You can add a tooltip",
                    },
                }}
                id="dropdownsection-playground"
                canDisplay={false}
            >
                <div className="w-full bg-white flex items-center justify-center py-8">
                    <p className="font bold text-2xl">Always displayed</p>
                </div>
            </DropdownSection>
            <DropdownSection
                dropdownSectionHeader={{
                    title: {
                        content: "Opened",
                        className: "text-xl font-bold ",
                    },
                    finalIcon: {
                        type: "info",
                        size: "xl",
                    },
                }}
                id="dropdownsection-playground"
                open={true}
            >
                <div className="w-full bg-white flex items-center justify-center py-8">
                    <p className="p-4">Opened by default</p>
                </div>
            </DropdownSection>
            <DropdownSection
                dropdownSectionHeader={{
                    title: {
                        content: "With icons",
                        className: "text-xl font-bold text-red-100",
                    },
                    titleIcon: {
                        type: "info",
                        size: "xl",
                    },
                    finalIcon: {
                        type: "delete",
                        size: "xl",
                        className: "text-green-500",
                    },
                }}
                id="dropdownsection-playground"
            >
                <div className="w-full bg-white p-4">
                    <LocationMap latitude={41.6488} longitude={-0.8891} />
                </div>
            </DropdownSection>
            <DropdownSection dropdownSectionHeader={"Inside another one"} id="dropdownsection-playground">
                <DropdownSection
                    dropdownSectionHeader={{
                        title: {
                            content: "Opened",
                            className: "text-xl font-bold ",
                        },
                        finalIcon: {
                            type: "info",
                            size: "xl",
                        },
                    }}
                    id="dropdownsection-playground"
                    open={true}
                >
                    <div className="w-full bg-red-800 flex items-center justify-center py-8">
                        <p className="p-4 font-bold text-white">Opened by default</p>
                    </div>
                </DropdownSection>
            </DropdownSection>

            <LocationMap latitude={41.6488} longitude={-0.8891} />
            <Component title="DragAndDropMediaCard" validated={false}>
                {mediaCardScenarios.map((scenario, index) => (
                    <DragAndDropMediaCard
                        key={scenario.item.id || index}
                        item={scenario.item}
                        actions={scenario.actions}
                        indexItem={scenario.indexItem}
                        className={" w-52 h-64"}
                    />
                ))}
            </Component>
        </ListComponents>
    );
};

const testImages = [
    {
        src: "https://cdn.pixabay.com/photo/2023/06/14/23/12/sunset-8064078_1280.jpg",
        alt: "Sunset",
    },
    {
        src: "https://cdn.pixabay.com/photo/2023/04/10/15/56/bowl-7914112_1280.jpg",
        alt: "Bowl with fruits",
    },
    {
        src: "https://cdn.pixabay.com/photo/2023/04/24/16/50/cake-7948556_1280.jpg",
        alt: "Cake strawberries",
    },
    {
        src: "https://cdn.pixabay.com/photo/2016/09/07/16/19/pile-1651945_1280.jpg",
        alt: "Animals",
    },
];

const mediaCardScenarios = [
    {
        item: {
            id: "1",
            label: "Media Item 1",
            imgSrc: testImages[Math.floor(Math.random() * testImages.length)]?.src,
            showWarning: true,
            tooltipWarning: "This is a warning tooltip for item 1",
            name: "Example Media 1",
        },
        actions: [
            {
                id: "action1",
                label: "Edit",
                onClick: () => alert("Edit action clicked for item 1"),
                disabled: false,
                tooltip: "Edit this item",
            },
            {
                id: "action2",
                label: "Delete",
                onClick: () => alert("Delete action clicked for item 1"),
                disabled: false,
                tooltip: "Delete this item",
            },
        ],
        indexItem: 0,
    },
    {
        item: {
            id: "2",
            label: "Media Item 2",
            imgSrc: testImages[Math.floor(Math.random() * testImages.length)]?.src,
            showWarning: false,
            name: "Example Media 2",
        },
        actions: [
            {
                id: "action3",
                label: "Preview",
                onClick: () => alert("Preview action clicked for item 2"),
                disabled: false,
                tooltip: "Preview this item",
            },
            {
                id: "action4",
                label: "Share",
                onClick: () => alert("Share action clicked for item 2"),
                disabled: true,
                tooltip: "Share this item (disabled)",
            },
        ],
        indexItem: 1,
    },
    {
        item: {
            id: "3",
            label: "Media Item 3",
            imgSrc: "",
            showWarning: true,
            tooltipWarning: "No image available for item 3",
            name: "Example Media 3",
        },
        actions: [
            {
                id: "action5",
                label: "Download",
                onClick: () => alert("Download action clicked for item 3"),
                disabled: false,
                tooltip: "Download this item",
            },
        ],
        indexItem: 2,
    },
    {
        item: {
            id: "4",
            label: "Media Item 4",
            imgSrc: testImages[Math.floor(Math.random() * testImages.length)]?.src,
            showWarning: false,
            name: "Example Media 4",
        },
        actions: [
            {
                id: "action6",
                label: "Open",
                onClick: () => alert("Open action clicked for item 4"),
                disabled: false,
                tooltip: "Open this item",
            },
            {
                id: "action7",
                label: "Archive",
                onClick: () => alert("Archive action clicked for item 4"),
                disabled: true,
                tooltip: "Archive this item (disabled)",
            },
        ],
    },
];

export default Others;
