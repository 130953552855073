import React, { useState, useEffect } from "react";
import { gql } from "apollo-boost";
import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import { useAuth } from "hooks/Session/auth";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import { getProjectLangs, parseTranslation } from "hooks/Utils/SalesUtils";
import { GET_CONTENT_TREE } from "components/Section/Design/Advertising/graphql/queries/signage";

import {
    getScreensOptions,
    extractPermissions,
    updateLoadingAndErrors,
    arrangeOptionsPredefinedSection,
    DestinationType,
    GUEST_STAYS_OPTIONS,
    MOVIES_OPTIONS,
    TV_OPTIONS,
    APPS_OPTIONS,
} from "../utils/utils";
// import { getScreensOptions } from "../utils/utils";
import { parseMapsOptions } from "components/Section/Design/Maps/utils";

export const GET_TV_CHANNELS = (hasChannels) => {
    return !hasChannels
        ? gql`
              {
                  oldTVChannels {
                      channels
                  }
              }
          `
        : gql`
              {
                  tunes {
                      results {
                          id
                          name
                      }
                  }
              }
          `;
};

export const GET_LIBRARY_ASSET_NAME = gql`
    query getLibraryAssetName($ref: String!) {
        libraryAsset(ref: $ref) {
            response {
                name
            }
        }
    }
`;

export const GET_ROOMSHOPS = () => gql`
    {
        roomShops {
            results {
                id
                name
                available
            }
        }
    }
`;

export const GET_VENDURE_SHOPS = () => gql`
    {
        shops {
            results {
                id
                name: nameTranslations {
                    lang
                    text
                }
                token
                users
                customFields {
                    isActive
                }
            }
        }
    }
`;

export const GET_COMMON_ZONES = () => gql`
    {
        rooms(filter: { type: COMMONZONE }) {
            results {
                name
                roomTVs {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_MAPS = gql`
    query listMaps {
        maps(lang: "all") {
            results {
                id
                name
            }
        }
    }
`;

export const useGetOptionsPredefinedSection = () => {
    const { permissions } = useSelector((state) => state.ui);
    const { isCorporate, languages, defaultLanguage } = useAuth();

    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [optionsPredefinedSection, setOptionsPredefinedSection] = useState([]);

    const {
        hasChannels,
        hasStay,
        hasCast,
        hasMyOrders,
        hasApps,
        hasSignage,
        hasMovies,
        hasAdultMovies,
        hasActionInputs,
        hasActionChannelList,
        hasActionChannel,
        hasActionVendureSales,
        hasActionLegacySales,
        hasParentalCode,
        hasActionBill,
        hasMaps,
        hasActionFeedback,
        hasActionCheckout,
    } = extractPermissions(permissions, isCorporate);

    const [getTvChannels, { data: tvChannelsData, loading: loadingTvChannels, error: errorTvChannels }] = useMSQuery(
        GET_TV_CHANNELS(hasChannels)
    );
    const [getRoomShops, { data: roomShopsData, loading: loadingRoomShops, error: errorRoomShops }] = useMSQuery(
        GET_ROOMSHOPS()
    );
    const [getVendureShops, { data: vendureShopsData, loading: loadingVendureShops, error: errorVendureShops }] =
        useMSQuery(GET_VENDURE_SHOPS());
    const [getCommonZones, { data: commonZonesData, loading: loadingCommonZones, error: errorCommonZones }] =
        useMSQuery(GET_COMMON_ZONES());
    const [getMaps, { data: mapsData, loading: loadingMaps, error: errorMaps }] = useMSQuery(GET_MAPS);

    useEffect(() => {
        triggerConditionalQueries({
            hasActionChannel,
            getTvChannels,
            hasActionVendureSales,
            getVendureShops,
            hasActionLegacySales,
            getRoomShops,
            hasSignage,
            getCommonZones,
            hasMaps,
            getMaps,
            setReady,
        });
    }, [
        hasActionChannel,
        getTvChannels,
        hasActionVendureSales,
        getVendureShops,
        hasActionLegacySales,
        getRoomShops,
        hasSignage,
        getCommonZones,
        hasMaps,
        getMaps,
        setReady,
    ]);

    useEffect(() => {
        updateLoadingAndErrors({
            ready,
            setLoading,
            loadingVendureShops,
            loadingRoomShops,
            errorTvChannels,
            loadingTvChannels,
            loadingCommonZones,
            hasActionChannel,
            tvChannelsData,
            roomShopsData,
            hasActionLegacySales,
            errorRoomShops,
            vendureShopsData,
            errorVendureShops,
            hasActionVendureSales,
            errorCommonZones,
            loadingMaps,
            errorMaps,
            hasMaps,
        });
    }, [
        ready,
        loadingRoomShops,
        tvChannelsData,
        errorTvChannels,
        loadingVendureShops,
        roomShopsData,
        vendureShopsData,
        errorRoomShops,
        loadingTvChannels,
        hasActionVendureSales,
        hasActionLegacySales,
        loadingCommonZones,
        errorVendureShops,
        hasActionChannel,
        errorCommonZones,
        loadingMaps,
        errorMaps,
        hasMaps,
    ]);

    useEffect(() => {
        const arranged = arrangeOptionsPredefinedSection({
            vendureShopsData,
            languages,
            hasMyOrders,
            hasApps,
            hasActionChannelList,
            hasStay,
            hasCast,
            hasActionInputs,
            hasMovies,
            hasAdultMovies,
            hasActionVendureSales,
            hasActionLegacySales,
            roomShopsData,
            hasParentalCode,
            hasActionBill,
            hasActionFeedback,
            hasActionCheckout,
            tvChannelsData,
            commonZonesData,
        });
        setOptionsPredefinedSection(arranged);
    }, [
        vendureShopsData,
        languages,
        hasMyOrders,
        hasApps,
        hasActionChannelList,
        hasStay,
        hasCast,
        hasActionInputs,
        hasMovies,
        hasAdultMovies,
        hasActionVendureSales,
        hasActionLegacySales,
        roomShopsData,
        tvChannelsData,
        commonZonesData,
    ]);

    return {
        optionsPredefinedSection,
        loadingOptionsPredefinedSection: loading,
        mapsOptions: parseMapsOptions(mapsData, defaultLanguage) || [],
    };
};

export const useGetNameDestination = (destination, idDesignApplied) => {
    const { t } = useTranslation();
    const { languages } = useAuth();
    const [name, setName] = useState("");
    const [alert, setAlert] = useState(false);

    const langDefault = getProjectLangs(languages, t)?.langDefault || "en";

    const PREDEFINED_SECTION_OPTIONS = [
        ...Object.values(GUEST_STAYS_OPTIONS),
        ...Object.values(MOVIES_OPTIONS),
        ...Object.values(TV_OPTIONS),
        ...Object.values(APPS_OPTIONS),
    ];

    const staticTranslations = {
        [DestinationType.PREDEFINED_SECTION]: t("predefined-section"),
        [DestinationType.LINK]: t("link"),
        [DestinationType.MEDIA_FILE]: t("media-file"),
        [DestinationType.SCREEN]: t("screen"),
        [DestinationType.DISABLED]: t("disabled"),
    };

    // Definimos qué query usar
    const GET_QUERY = (() => {
        if (destination?.type === DestinationType.MEDIA_FILE) return GET_LIBRARY_ASSET_NAME;
        if (destination?.type === DestinationType.SCREEN) return GET_CONTENT_TREE;
        if (destination?.type === DestinationType.PREDEFINED_SECTION && destination?.value?.includes("roomshop:")) {
            return GET_VENDURE_SHOPS();
        }
        return FAKE_QUERY;
    })();

    const { data, loading } = useQuery(GET_QUERY, {
        fetchPolicy: "network-only",
        variables: { ref: destination?.value, designID: idDesignApplied },
        skip: GET_QUERY === FAKE_QUERY,
        onCompleted: (data) => handleQuerySuccess(data),
        onError: (error) => {
            console.error("GraphQL Query Error:", error);
            setAlert(destination?.type === DestinationType.SCREEN);
            setName(t("unknown"));
        },
    });

    const generateName = () => {
        let prefix = staticTranslations[destination?.type] || t("unknown");
        let generatedName = destination?.value ? `${prefix} - ${destination.value}` : t("disabled");

        if (destination?.type === DestinationType.PREDEFINED_SECTION) {
            const predefinedSection = PREDEFINED_SECTION_OPTIONS.find((option) => option.value === destination.value);
            generatedName = predefinedSection ? `${prefix} - ${t(predefinedSection?.label)}` : t("disabled");
        }

        if (destination?.value === "myOrders") {
            generatedName = `${prefix} - ${t("my-orders")}`;
        }
        if (destination?.value === "roomshops") {
            generatedName = `${prefix} - ${t("shop-list")}`;
        }

        return generatedName;
    };

    const handleQuerySuccess = (data) => {
        if (!data) {
            setAlert(destination?.type === DestinationType.SCREEN);
            return;
        }

        let newName = t("disabled");

        switch (destination?.type) {
            case DestinationType.MEDIA_FILE:
                newName = data?.libraryAsset?.response?.name
                    ? `${staticTranslations[destination.type]} - ${data.libraryAsset.response.name}`
                    : t("disabled");
                break;
            case DestinationType.SCREEN:
                const screenOptions = getScreensOptions(data?.contentTree);
                const screen = screenOptions?.find((s) => s.value === destination.value);
                newName = screen ? `${staticTranslations[destination.type]} - ${screen.label}` : t("disabled");
                setAlert(!screen);
                break;
            case DestinationType.PREDEFINED_SECTION:
                if (destination?.value?.includes("roomshop:")) {
                    const shop = data?.shops?.results?.find((shop) => shop.token === destination.value.split(":")[1]);
                    const shopName = parseTranslation(shop?.name, langDefault);
                    newName = shopName ? `${staticTranslations[destination.type]} - ${shopName}` : t("disabled");
                }
                break;
            default:
                break;
        }

        setName(newName);
    };

    useEffect(() => {
        setAlert(false);
        setName("");

        if (!destination?.type || !destination?.value) {
            setName(t("disabled"));
            return;
        }

        if (GET_QUERY === FAKE_QUERY) {
            const generatedName = generateName();
            setName(generatedName);
            setAlert(destination?.type === DestinationType.SCREEN && !generatedName);
        }
    }, [destination, idDesignApplied]);

    return { name: loading ? "" : name, loading, alert };
};

const FAKE_QUERY = gql`
    query FakeQuery {
        fakeField
    }
`;

export const triggerConditionalQueries = ({
    hasActionChannel,
    getTvChannels,
    hasActionVendureSales,
    getVendureShops,
    hasActionLegacySales,
    getRoomShops,
    hasSignage,
    getCommonZones,
    hasMaps,
    getMaps,
    setReady,
}) => {
    if (hasActionChannel) {
        getTvChannels();
    }
    if (hasMaps) {
        getMaps();
    }
    if (hasActionVendureSales) {
        getVendureShops();
    }
    if (hasActionLegacySales) {
        getRoomShops();
    }
    if (hasSignage) {
        getCommonZones();
    }
    setReady(true);
};
