import { useState } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import { GET_CATEGORIES } from "./queries/categories";
import { ADD_CATEGORY, DELETE_CATEGORY, UPDATE_CATEGORIES, SET_CATEGORY_ORDER_IN_MAP } from "./mutations/categories";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const useListCategories = (props) => {
    const [categories, setCategories] = useState([]);

    const [getCategories, { loading, error }] = useLazyQuery(GET_CATEGORIES, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setCategories(data.mapCategories.results);
            if (props.onCompleted && typeof props.onCompleted == "function") {
                props.onCompleted();
            }
        },
    });

    return {
        getCategories,
        loading,
        error,
        categories,
    };
};

export const useUpdateCategories = (props) => {
    const { t } = useTranslation();
    const [executeMutation, { loading }] = useMutation(UPDATE_CATEGORIES, {
        onCompleted() {
            toast.success(t("operation-successful"));
            if (props.onCompleted && typeof props.onCompleted == "function") {
                props.onCompleted();
            }
        },
    });
    return {
        update: executeMutation,
        loading,
    };
};

export const useAddCategory = (props) => {
    const { t } = useTranslation();
    const [executeMutation, { loading }] = useMutation(ADD_CATEGORY, {
        onCompleted(data) {
            toast.success(t("operation-successful"));
            if (props.onCompleted && typeof props.onCompleted == "function") {
                props.onCompleted(data);
            }
        },
    });
    return {
        add: executeMutation,
        loading,
    };
};

export const useDeleteCategory = (props) => {
    const { t } = useTranslation();
    const [executeMutation, { loading }] = useMutation(DELETE_CATEGORY, {
        onCompleted() {
            toast.success(t("operation-successful"));
            if (props.onCompleted && typeof props.onCompleted == "function") {
                props.onCompleted();
            }
        },
    });
    return {
        delete: executeMutation,
        loading,
    };
};

export const useSetCategoryOrderInMap = (props) => {
    const [executeMutation, { loading, error, data }] = useMutation(SET_CATEGORY_ORDER_IN_MAP, {
        onCompleted: (response) => {
            if (props.onCompleted && typeof props.onCompleted === "function") {
                props.onCompleted(response);
            }
        },
        onError: (err) => {
            if (props.onError && typeof props.onError === "function") {
                props.onError(err);
            }
        },
    });

    return {
        setCategoryOrder: executeMutation,
        loading,
        error,
        data,
    };
};
