import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Parser from "hooks/Utils/Parser";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setRefreshContentData } from "actions/sectionActions";
import Loading from "components/Loading";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";

const UPDATE_CHANNEL_MUTATION = `
  mutation UpdateChannel($input: UpdateChannelInput!) {
    updateChannel(input: $input) {
      __typename
      ... on Channel {
        customFields {
          is_active
        }
      }
      ... on LanguageNotAvailableError {
        message
      }
    }
  }
`;

const useMutationUpdateShop = ({ closeModal }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { adminApi } = useVendureAPI();
    const [loading, setLoading] = useState(false);

    const mutate = async (variables) => {
        setLoading(true);
        try {
            const response = await executeVendureQuery(adminApi, {
                queryBody: UPDATE_CHANNEL_MUTATION,
                variables: { input: variables },
            });

            if (response.errors) {
                const message = response.errors[0].message;
                toast.error(message);
                closeModal();
            } else if (response.data) {
                toast.success(t("operation-successful"));
                dispatch(setRefreshContentData(true));
                closeModal();
            } else {
                toast.error(t("error-mutation"));
                closeModal();
            }
        } catch (error) {
            toast.error(error.message || "Network or syntax error.");
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    return { mutate, loading };
};

const ModalContent = (props) => {
    const { t } = useTranslation();
    const textToRender = `${props?.available ? t("set-as-unavailable") : t("set-as-available")}`;
    const { mutate, loading } = useMutationUpdateShop({
        closeModal: props?.close,
    });

    return (
        <Modal
            className={"w-3/12 p-10"}
            title={` ${props?.nameShop} - ${textToRender}`}
            footer={
                !loading ? (
                    <>
                        <Button
                            design="blue-outline"
                            onClick={() => {
                                if (props?.close) {
                                    props.close();
                                }
                            }}
                            id="modal-button-cancel"
                        >
                            {t("cancel")}
                        </Button>
                        <Button
                            design="blue"
                            id="modal-button-save"
                            onClick={() => {
                                mutate({
                                    id: props?.VARIABLES?.id,
                                    customFields: {
                                        is_active: !props.available,
                                    },
                                });
                            }}
                        >
                            {textToRender}
                        </Button>
                    </>
                ) : null
            }
        >
            {loading ? (
                <Loading />
            ) : (
                Parser(
                    t(props?.available ? "set-as-unavailable-shop-html" : "set-as-available-shop-html", {
                        shop: "<strong>" + props?.nameShop + "</strong>",
                    })
                )
            )}
        </Modal>
    );
};

const useModifyStateShop = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};

export default useModifyStateShop;
