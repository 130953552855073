import { Session } from "./Session";

import {
    ACCESSE,
    PERMISSION,
    PRODUCT,
    validatePermission,
    validateProduct,
    validateAccesse,
} from "constants/permissions";

const ComposePermissionsTree = ({
    globalPerms, // available permissions in global mode (only filled when is Global Management)
    chainPerms, // available permissions in any chain project (only filled when is Global Management)
    projectPermissions, // Active project permissions (only filled when is not Global Management)
    userAccesses, // current user roles accesses (only filled when !isSuperUser)
    userManagerRole, // chain manager
    isSuperUser, // if is super user
    isCorporate, // if is Global Management
    isESChain, // if is ES chain
    hasChainModule, // if current project has chain module
}) => {
    // Check if has the permission in the active project
    const hasProjectPermission = (id) => {
        if (!validatePermission(id)) {
            return false;
        }
        if (isCorporate) {
            // If global management, project permissions are not available
            return false;
        }
        return projectPermissions ? projectPermissions.includes(id) : false;
    };

    const hasProjectProduct = (id) => {
        if (!validateProduct(id)) {
            return false;
        }
        if (isCorporate) {
            // If global management, project permissions are not available
            return false;
        }
        return hasProjectPermission(id);
    };

    // Check if has the permission in the global mode
    const hasGlobalPermission = (id) => {
        if (!validatePermission(id)) {
            return false;
        }
        return globalPerms ? globalPerms.includes(id) : false;
    };

    // Check if has the product in the active project
    const hasGlobalProduct = (id) => {
        if (!validateProduct(id)) {
            return false;
        }
        return hasGlobalPermission(id);
    };

    // Check if has the permission in any project from the chain (only available in Global Management)
    const hasChainPermission = (id) => {
        if (!validatePermission(id)) {
            return false;
        }
        if (!isCorporate) {
            // If not global management, chain permissions are not available
            return false;
        }
        return chainPerms?.includes(id);
    };

    // Check if has the product in any project from the chain (only available in Global Management)
    const hasChainProduct = (id) => {
        if (!validateProduct(id)) {
            return false;
        }
        if (!isCorporate) {
            // If not global management, chain products are not available
            return false;
        }
        return hasChainPermission(id);
    };

    // Check if has the role access and the permission in the active project or global mode
    const hasCurrentAccesse = (id) => {
        return hasAccesse(id) && (hasCurrentPermission(id) || !validatePermission(id, false));
    };

    // Check if has the role access
    const hasAccesse = (id) => {
        if (!validateAccesse(id)) {
            return false;
        }
        // Super user and roles manager have access to all roles
        return isSuperUser || userManagerRole || userAccesses?.includes(id);
    };

    // Check if has the permission in the active project or global mode (including chain permissions)
    const hasPermission = (id) => {
        if (isCorporate) {
            return hasGlobalPermission(id) || hasChainPermission(id);
        }
        return hasProjectPermission(id);
    };

    // Check if has the role access && has the permission in the active project or in any chain project when is Global Management
    // Is excluding specific corporate permissions
    const hasPrivilege = (id) => {
        if (!hasAccesse(id)) {
            return false;
        }
        // We need to check only if permissions exists
        if (validatePermission(id, false)) {
            if (isCorporate) {
                return hasChainPermission(id);
            }
            return hasProjectPermission(id);
        }
        return true;
    };

    // Check if has the product in the active project or global mode (including chain products)
    const hasProduct = (id) => {
        if (isCorporate) {
            return hasCurrentProduct(id) || hasChainProduct(id);
        }
        return hasCurrentProduct(id);
    };

    // Check if has the permission in the active project or global mode
    const hasCurrentPermission = (id) => {
        if (isCorporate) {
            return hasGlobalPermission(id);
        }
        return hasProjectPermission(id);
    };

    // Check if has the product in the active project or global mode
    const hasCurrentProduct = (id) => {
        if (isCorporate) {
            return hasGlobalProduct(id);
        }
        return hasProjectProduct(id);
    };

    ////////////////////////////////////////

    const hotelCastConfig = hasCurrentAccesse(ACCESSE.castConfig);
    const hotelTopology =
        !isCorporate &&
        (isSuperUser || (hasCurrentAccesse(ACCESSE.topology) && !hasCurrentPermission(PERMISSION.legacyTV)));
    const hotelTvSettings = hasCurrentAccesse(ACCESSE.tvConfig);
    const hotelMobileSettings = hasCurrentAccesse(ACCESSE.mobileConfig);
    const hotelTvInputs = hasCurrentAccesse(ACCESSE.tvInputs);
    const hasSales = hasCurrentProduct(PRODUCT.SALES);

    const adminShops = hasSales && !isCorporate && hasCurrentAccesse(ACCESSE.shopsAdministrator);

    const manageShops = hasSales && (adminShops || hasAccesse(ACCESSE.shopsManager)) && !isCorporate;

    const manageOrders =
        hasSales &&
        !isCorporate &&
        (manageShops || hasAccesse(ACCESSE.shopsOrders)) &&
        hasCurrentPermission(PERMISSION.shopsOrders);

    return {
        product: {
            advancedDesign: hasProduct(PRODUCT.ADVANCEDDESIGN),
            cast: hasProduct(PRODUCT.CAST),
            chain: hasProduct(PRODUCT.CHAIN),
            channels: hasProduct(PRODUCT.CHANNELS),
            corporate: hasProduct(PRODUCT.CORPORATE),
            guest: hasProduct(PRODUCT.GUEST),
            house: hasProduct(PRODUCT.HOUSE),
            mobile: hasProduct(PRODUCT.MOBILE),
            sales: hasProduct(PRODUCT.SALES),
            tv: hasProduct(PRODUCT.TV),
            movies: hasProduct(PRODUCT.ONDEMAND),
            wifi: hasProduct(PRODUCT.WIFI),
            signage: hasProduct(PRODUCT.SIGNAGE),
            salesPremium: hasProduct(PRODUCT.SALES) && hasPermission(PERMISSION.myOrders), // SO_SALE+
            tvPremium: hasProduct(PRODUCT.TV) && hasPermission(PERMISSION.optTVPremium), // SO_TVDE+
            legacy:
                hasPermission(PERMISSION.legacyWIFI) ||
                hasPermission(PERMISSION.legacyTV) ||
                hasPermission(PERMISSION.legacyMOBILE) ||
                hasPermission(PERMISSION.legacyGUEST) ||
                hasPermission(PERMISSION.legacySALES),
        },
        integrations: {
            pos: hasCurrentPermission(PERMISSION.posIntegration),
        },
        hotel: {
            map: hasCurrentAccesse(ACCESSE.map),
            monitoring: hasCurrentAccesse(ACCESSE.monitoring),
            networkAlerts:
                hasProduct(PRODUCT.CAST) && !isCorporate && (hasCurrentAccesse(ACCESSE.networkAlerts) || isSuperUser),
            networkMonitor: hasCurrentAccesse(ACCESSE.networkMonitor) && !isCorporate && hasProduct(PRODUCT.NMS),
            statusMail: hasCurrentAccesse(ACCESSE.statusMail),
            roomList: hasCurrentAccesse(ACCESSE.roomList),
            roomGroups: hasCurrentAccesse(ACCESSE.roomGroups),
            castConfig: hotelCastConfig,
            topology: hotelTopology,
            tvInputs: hotelTvInputs,
            TVSettings: hotelTvSettings,
            wifiSettings: hotelCastConfig || hotelTopology || hotelTvSettings || hotelMobileSettings || hotelTvInputs,
            wifiConfig: hasCurrentAccesse(ACCESSE.wifiConfig),
            mobileConfig: hotelMobileSettings,
            nativeApps: hasCurrentPermission(PERMISSION.nativeApps) && !isCorporate && hasAccesse(ACCESSE.mobileConfig),
        },
        allProperties: {
            mobileConfig: hasPermission(PERMISSION.mobileConfig) && hasAccesse(ACCESSE.mobileConfig) && isCorporate,
            manageProperties: hasAccesse(ACCESSE.manageProperties) && isCorporate,
            manageBrands: hasAccesse(ACCESSE.manageBrands) && isCorporate,
            nativeApps:
                isCorporate &&
                hasChainPermission(PERMISSION.mobileConfig) &&
                hasChainPermission(PERMISSION.nativeApps) &&
                hasAccesse(ACCESSE.mobileConfig),
        },
        design: {
            interfacePreview: hasCurrentPermission(PERMISSION.interfacePreview),
            interfaceEditor: hasCurrentAccesse(ACCESSE.interfaceEditor),
            multipleInterface:
                hasAccesse(ACCESSE.interfaceEditor) && hasCurrentPermission(PERMISSION.multipleInterface),
            corporateIdentity: hasCurrentPermission(PERMISSION.corporateIdentity),
            library: hasCurrentAccesse(ACCESSE.library),
            libraryVideos: hasPermission(PERMISSION.libraryVideos),
            templates: isSuperUser && isESChain,
            appearances: hasCurrentAccesse(ACCESSE.appearancesEditor),
            tags: isCorporate && hasCurrentAccesse(ACCESSE.interfaceEditor),
            apps: hasPermission(PERMISSION.apps),
            mobileAppearance: hasAccesse(ACCESSE.mobileConfig) && hasPermission(PERMISSION.mobileConfig),
            sequences: hasCurrentAccesse(PERMISSION.sequences) && !isCorporate,
            banners: hasCurrentAccesse(PERMISSION.banners) && !isCorporate,
            tickerTapes: hasCurrentAccesse(PERMISSION.tickerTapes) && !isCorporate,
            maps: hasCurrentAccesse(PERMISSION.geoMaps) && !isCorporate && Session.develMode(),
        },
        guests: {
            guestList: hasCurrentAccesse(ACCESSE.guestList),
            guestGroups: hasCurrentAccesse(ACCESSE.guestGroups),
            guestConfig: hasCurrentAccesse(ACCESSE.guestSettings),
            loyaltyCustomers:
                hasAccesse(ACCESSE.loyaltyCustomers) &&
                ((!isCorporate && hasCurrentPermission(PERMISSION.loyaltyCustomers)) ||
                    (isCorporate && hasPermission(PERMISSION.loyaltyCustomers))),
            digitalKey: hasCurrentPermission(PERMISSION.digitalKey),
            messages: hasCurrentAccesse(ACCESSE.messaging) && (hasProduct(PRODUCT.TV) || hasProduct(PRODUCT.MOBILE)),
            alarms: hasCurrentAccesse(ACCESSE.alarms) && hasProduct(PRODUCT.TV),
            welcomeScreens: hasPermission(PERMISSION.welcomeScreens),
            wifi: hasCurrentProduct(PRODUCT.WIFI),
            wifiPasscodes: hasCurrentAccesse(ACCESSE.wifiPasscodes),
            guestSatisfaction: hasCurrentAccesse(ACCESSE.guestSatisfaction),
            preCheckin: hasCurrentAccesse(ACCESSE.guestList) && hasCurrentPermission(PERMISSION.preCheckin),
            messageSendLater: hasCurrentPermission(PERMISSION.messageSendLater),
        },
        stats: {
            cast: hasPrivilege(ACCESSE.statsCast),
            wifi: hasPrivilege(ACCESSE.statsWifi),
            mobile: hasPrivilege(ACCESSE.statsMobile),
            sales: hasPrivilege(ACCESSE.statsSales),
            internal: isSuperUser && hasPrivilege(ACCESSE.statsCast),
        },
        goOutSafe: {
            bookings: hasCurrentAccesse(ACCESSE.bookings),
            capacity: hasCurrentAccesse(ACCESSE.capacity),
            cleaning: hasCurrentAccesse(ACCESSE.cleaning),
            staff: hasCurrentAccesse(ACCESSE.staff),
            areas: hasCurrentAccesse(ACCESSE.zonesConfig),
            categories: hasCurrentAccesse(ACCESSE.zonesConfig),
        },
        managerSetup: {
            manageUsers: hasAccesse(ACCESSE.manageUsers),
            manageLanguages: hasAccesse(ACCESSE.manageLanguages),
            manageRoles: hasAccesse(ACCESSE.manageRoles),
            staff: hasCurrentAccesse(ACCESSE.staff) && !isCorporate,
            networkManagement: hasAccesse(ACCESSE.networkManagement),
            networkAdmin: hasAccesse(ACCESSE.networkAdmin),
            wifiOrMobile: isCorporate && (hasChainProduct(PRODUCT.WIFI) || hasChainProduct(PRODUCT.MOBILE)),
            taxManagement: hasAccesse(ACCESSE.taxManagement) && hasPermission(PERMISSION.taxManagement),
        },
        legacy: {
            wifi:
                (hasAccesse(ACCESSE.networkManagement) || hasAccesse(ACCESSE.networkAdmin)) &&
                hasPermission(PERMISSION.legacyWIFI),
            tv: hasAccesse(ACCESSE.legacyAdmin) && hasPermission(PERMISSION.legacyTV),
            // Legacy sales can't coexist with cloud sales, so in development mode (where perms are always true) we force it to be false
            sales: hasAccesse(ACCESSE.legacyAdmin) && hasPermission(PERMISSION.legacySALES) && !Session.develMode(),
            mobile: hasAccesse(ACCESSE.legacyAdmin) && hasPermission(PERMISSION.legacyMOBILE),
            guest: hasAccesse(ACCESSE.legacyAdmin) && hasPermission(PERMISSION.legacyGUEST),
        },
        sales: {
            wdgParental: hasCurrentPermission(PERMISSION.wdgParental),
        },
        movies: {
            freeToguest: hasCurrentPermission(PERMISSION.freeToguest),
            hollywood: hasCurrentPermission(PERMISSION.hollywoodContent),
            adult: hasCurrentPermission(PERMISSION.adultContent),
        },
        services: {
            productSALES: !isCorporate && hasSales,
            productONDEMAND: !isCorporate && hasCurrentProduct(PRODUCT.ONDEMAND),
            shopsAdministrator: adminShops,
            shopsManager: manageShops,
            shopsOrders: manageOrders,
            shopsGeneralconfig: hasAccesse(ACCESSE.shopsGeneralconfig) && hasPermission(PERMISSION.shopsGeneralconfig),
            myOrders: hasPermission(PERMISSION.myOrders),
            channelList: !isCorporate && hasPermission(PERMISSION.channelList) && hasAccesse(ACCESSE.channelList),
            channelGroups: !isCorporate && hasPermission(PERMISSION.channelGroups) && hasAccesse(ACCESSE.channelGroups),
            channelGrids: !isCorporate && hasPermission(PERMISSION.channelGrids) && hasAccesse(ACCESSE.channelGrids),
            multipleGrids: !isCorporate && hasPermission(PERMISSION.multipleGrids),
            corporateChannelsInGlobalManagement:
                isCorporate &&
                hasAccesse(ACCESSE.corporateChannels) &&
                hasChainPermission(PERMISSION.corporateChannels),
            corporateChannelsWithoutGlobalManagement: hasAccesse(ACCESSE.corporateChannels) && !hasChainModule,
            predefinedChannels: isSuperUser && hasProduct(PRODUCT.CHANNELS),
            checkoutInterface: hasPermission(PERMISSION.checkoutInterface),
            channelSettings:
                !isCorporate && hasPermission(PERMISSION.channelSettings) && hasAccesse(ACCESSE.channelSettings),
            corporateChannels: hasAccesse(ACCESSE.corporateChannels) && hasPermission(PERMISSION.corporateChannels),
            highAvailability: hasCurrentPermission(PERMISSION.highAvailability),
            movieList: hasAccesse(ACCESSE.movieList) && hasPermission(PERMISSION.movieList),
            movieSettings: hasAccesse(ACCESSE.movieSettings) && hasPermission(PERMISSION.movieSettings),
        },
        widgets: [
            hasPermission(PERMISSION.wdgText) ? "TEXT" : null,
            hasPermission(PERMISSION.wdgButton) ? "BUTTON" : null,
            hasPermission(PERMISSION.wdgLogo) ? "LOGO" : null,
            hasPermission(PERMISSION.wdgImage) ? "IMAGE" : null,
            hasPermission(PERMISSION.wdgVideo) ? "VIDEO" : null,
            hasPermission(PERMISSION.wdgCarrousel) ? "CAROUSEL" : null,
            hasPermission(PERMISSION.wdgIcon) ? "ICON" : null,
            hasPermission(PERMISSION.wdgWeather) ? "WEATHER" : null,
            hasPermission(PERMISSION.wdgClock) ? "CLOCK" : null,
            hasPermission(PERMISSION.wdgCalendar) ? "CALENDAR" : null,
            hasPermission(PERMISSION.wdgMessages) ? "MESSAGES" : null,
            hasPermission(PERMISSION.wdgQrcode) ? "QR" : null,
            hasPermission(PERMISSION.wdgIframe) ? "IFRAME" : null,
            hasPermission(PERMISSION.wdgHtml) ? "HTML" : null,
            hasPermission(PERMISSION.wdgContainer) ? "CONTAINER" : null,
            hasPermission(PERMISSION.wdgChat) ? "CHATBUTTON" : null,
            hasPermission(PERMISSION.wdgAlarm) ? "ALARM" : null,
            hasPermission(PERMISSION.wdgLogin) ? "WIFILOGIN" : null,
            hasPermission(PERMISSION.interfaceMenu) ? "TVMENU" : null,
            hasPermission(PERMISSION.wdgNavigElements) ? "TVMENUNAV" : null,
            hasPermission(PERMISSION.wdgSlider) ? "SLIDER" : null,
        ],
        actions: {
            tvInputs: hasPermission(PERMISSION.actTvinputs),
            bill: hasPermission(PERMISSION.actBill),
            areas: hasPermission(PERMISSION.actAreas),
            bookings: hasPermission(PERMISSION.actMybookings),
            pos: hasPermission(PERMISSION.actPos),
            surverys: hasPermission(PERMISSION.actSurveys),
            checkout: hasPermission(PERMISSION.actCheckout),
            channel: hasPermission(PERMISSION.actChannel),
            listChannels: hasPermission(PERMISSION.actListChannels),
            sales: hasPermission(PERMISSION.actSales),
            actParental:
                hasPermission(PERMISSION.actParental) && (hasProduct(PRODUCT.TV) || hasProduct(PRODUCT.MOBILE)),
            customQr: hasPermission(PERMISSION.customQr),
            actFeedback: hasPermission(PERMISSION.actFeedback),
        },
        tags: [
            hasPermission(PERMISSION.tagRoomnumber) ? "roomNumber" : null,
            hasPermission(PERMISSION.tagRoomname) ? "roomName" : null,
            hasPermission(PERMISSION.tagGuesttitle) ? "guestTitle" : null,
            hasPermission(PERMISSION.tagGuestname) ? "guestName" : null,
            hasPermission(PERMISSION.tagGuestsurname) ? "guestSurname" : null,
            hasPermission(PERMISSION.tagGuestfullname) ? "guestFullname" : null,
            hasPermission(PERMISSION.tagGuestgroup) ? "guestGroup" : null,
            hasPermission(PERMISSION.tagChekcoutdate) ? "checkoutDate" : null,
            hasPermission(PERMISSION.tagPairingurl) ? "pairingURL" : null,
            hasPermission(PERMISSION.tagRoomcode) ? "pairingCode" : null,
            hasPermission(PERMISSION.tagWifissid) ? "wifiSSID" : null,
            hasPermission(PERMISSION.tagWifipassword) ? "wifiPassword" : null,
            hasPermission(PERMISSION.tagChromecastname) ? "CCName" : null,
            hasPermission(PERMISSION.tagGuestSharerfullname) ? "tagGuestSharerfullname" : null,
        ],
    };
};
export default ComposePermissionsTree;
