import React, { useEffect, useRef, useState } from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
//Utils
import UpdateBooking from "hooks/GraphqlCalls/Booking/UpdateBooking";
import UseButton from "components/useButton";
import { useDispatch, useSelector } from "react-redux";
import { openModal, setModalContent } from "actions/uiActions";
import { ExportGuestRegistrationForm } from "hooks/GraphqlCalls/Guest/modals/ExportGuestRegistrationForm";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Table from "components/ZafiroTable";
import Modal, { useModal } from "components/Modal";
import { ROOM_TYPE } from "constants/rooms";
import { useGuests, useUpcomingCheckIns } from "hooks/Data/useGuests";
import { AddBooking, AssignAction, CheckoutAction, DeleteBatchAction, GuestActions } from "./GuestListActions";
import { UPCOMING_STATE } from "./constants";

const Guests = () => {
    const upcomingRef = useRef(null);
    const [newOptions, setNewOptions] = useState([
        {
            id: "currents",
            label: "currents",
            value: "currents",
            active: true,
        },
        {
            id: "upcoming-check-ins",
            label: "upcoming-check-ins",
            value: "upcoming-check-ins",
            active: false,
        },
    ]);
    const states = [
        UPCOMING_STATE.validated,
        UPCOMING_STATE.pendingCompletion,
        UPCOMING_STATE.pendingValidation,
        UPCOMING_STATE.pendingModification,
    ];
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState({ field: "ID", criteria: "desc" });
    const [upcomingSort, setUpcomingSort] = useState({ field: "ID", criteria: "desc" });
    const [room, setRoom] = useState();
    const [status, setStatus] = useState(states);
    const [guestGroup, setGuestGroup] = useState();

    const dispatch = useDispatch();
    const { open, close } = useModal();
    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);
    const hasPreCheckin = permissions?.guests?.preCheckin;
    const { getGuests, rows, filters, refetch, count: currentCount, currentLoading } = useGuests();
    const {
        getUpcomingCheckIns,
        rows: upcomingCheckInsRows,
        count: upcomingCount,
        refetch: upcomingRefetch,
        upcomingLoading,
    } = useUpcomingCheckIns();

    const setDateRange = (range) => {
        const dateFrom = range.from && new Date(range.from);
        const dateTo = range.to && new Date(range.to);
        setDateFrom(
            dateFrom &&
                `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString().padStart(2, "0")}-${(
                    dateFrom.getDate() + 1
                )
                    .toString()
                    .padStart(2, "0")}`
        );
        setDateTo(
            dateTo &&
                `${dateTo.getFullYear()}-${(dateTo.getMonth() + 1).toString().padStart(2, "0")}-${(dateTo.getDate() + 1)
                    .toString()
                    .padStart(2, "0")}`
        );
    };

    const handleClicked = (id) => {
        const updatedOptions = newOptions.map((option) => {
            if (option.id === id) {
                return { ...option, active: true };
            } else {
                return { ...option, active: false };
            }
        });
        setNewOptions(updatedOptions);
    };

    const cols = {
        currents: ["name", "email", "guestGroups", "rooms", "staydate", "feedback", "actions"],
        upcomingCheckIns: ["code", "guests.email", "guests.name", "number_of_guests", "arrival_date", "state"],
    };

    const headers = {
        currents: {
            name: { title: t("name"), sortable: true, width: "25%" },
            email: { title: t("email"), sortable: true, width: "15%" },
            guestGroups: { title: t("group"), sortable: true, width: "20%" },
            rooms: { title: t("room-number-short"), sortable: true, width: "15%" },
            staydate: { title: t("stay-date"), sortable: true, width: "25%" },
            feedback: { title: t("latest-feedback"), sortable: false, width: "10%", align: "center" },
            actions: { title: t(""), sortable: false, width: "10%" },
        },
        upcomingCheckIns: {
            code: { title: t("booking-code"), sortable: true, width: "15%" },
            "guests.email": { title: t("email"), sortable: true, width: "20%" },
            "guests.name": { title: t("name"), sortable: true, width: "20%" },
            number_of_guests: { title: t("booking"), sortable: true, width: "15%" },
            arrival_date: { title: t("stay-date"), sortable: true, width: "15%" },
            state: { title: t("status"), sortable: true, width: "25%", align: "center" },
            actions: { title: t(""), sortable: false, width: "10%" },
        },
    };

    const activeOption = newOptions.find((option) => option.active).id;
    const isCurrentRows = activeOption === "currents";
    const tableRows = isCurrentRows ? rows : upcomingCheckInsRows;
    const tableCols = isCurrentRows ? cols?.currents : cols?.upcomingCheckIns;
    const tableHeaders = isCurrentRows ? headers?.currents : headers?.upcomingCheckIns;

    useEffect(() => {
        if (isCurrentRows) {
            getGuests({
                variables: { page, search, room, guestGroup, stayFrom: dateFrom, stayTo: dateTo, orderBy: sort },
            });
        } else {
            getUpcomingCheckIns({
                variables: { page, orderBy: upcomingSort, stayFrom: dateFrom, stayTo: dateTo, state: status },
            });
        }
    }, [page, search, room, guestGroup, dateFrom, dateTo, sort, activeOption, status, upcomingSort]);

    useEffect(() => {
        if (isCurrentRows) {
            setUpcomingSort({ field: "ID", criteria: "desc" });
        } else {
            setSort({ field: "ID", criteria: "desc" });
        }
    }, [isCurrentRows]);

    const currentFilters = [
        {
            id: "room",
            title: t("room"),
            multiple: true,
            options: filters?.rooms || [],
            onChange: (value) => {
                setPage(1);
                setRoom(value);
            },
        },
        {
            id: "guest-group",
            title: t("group"),
            multiple: true,
            options: filters?.groups || [],
            onChange: (value) => {
                setPage(1);
                setGuestGroup(value);
            },
        },
    ];

    const upcomingFilters = [
        {
            id: "status",
            title: t("status"),
            multiple: false,
            allowUnselect: false,
            options: [
                { id: "all", label: `-- ${t("all")} --`, value: null },
                { id: "validated", label: t("validated"), value: "VALIDATED" },
                { id: "pendingCompletion", label: t("pending_completion"), value: "PENDING_COMPLETION" },
                { id: "pendingValidation", label: t("pending_validation"), value: "PENDING_VALIDATION" },
                { id: "pendingModification", label: t("pending_modification"), value: "PENDING_MODIFICATION" },
            ],
            onChange: (value) => {
                if (!value) {
                    value = states;
                }
                setPage(1);
                setStatus(value);
            },
        },
    ];

    const currentBatchActions = {
        options: [
            {
                id: "assign-to-group",
                label: t("assign-to-group"),
                value: "assign-to-group",
            },
            {
                id: "check-out",
                label: t("checkout"),
                value: "check-out",
            },
        ],
        onChange: (action, rows) => {
            switch (action) {
                case "assign-to-group":
                    open(
                        <Modal
                            id="assign-to-group"
                            title={t("assign-to-group")}
                            minWidth="30rem"
                            className="w-1/2 p-10"
                            onKeyPress={{ Enter: close }}
                        >
                            <AssignAction rows={rows} refetch={refetch} type={ROOM_TYPE.ROOM} />
                        </Modal>
                    );
                    break;
                case "check-out":
                    open(<CheckoutAction rows={rows} refetch={refetch} />);
                    break;
            }
        },
    };

    const upcomingBatchActions = {
        options: [
            {
                id: "delete",
                label: t("delete"),
                value: "delete",
            },
        ],
        onChange: (action, rows) => {
            switch (action) {
                case "delete":
                    open(<DeleteBatchAction rows={rows} refetch={upcomingRefetch} ref={upcomingRef} />);
                    break;
            }
        },
    };

    return (
        <>
            <UpdateBooking></UpdateBooking>
            <div className="flex justify-between">
                <UseSectionHeader title={"hotel-guests"} />
                {hasPreCheckin && (
                    <div className="flex items-end pb-5">
                        <Button
                            id="export-guest-registration-form"
                            design="link"
                            onClick={() => {
                                dispatch(setModalContent(ExportGuestRegistrationForm(t)));
                                dispatch(openModal());
                            }}
                        >
                            {t("export-guest-registration-form")}
                        </Button>
                    </div>
                )}
            </div>
            <div className="main-container">
                <Table
                    id="guests-list"
                    ref={!isCurrentRows ? upcomingRef : null}
                    minHeight="30rem"
                    paginate={true}
                    topRightCorner={isCurrentRows ? <GuestActions /> : <AddBooking refetch={upcomingRefetch} />}
                    cols={tableCols}
                    sort={isCurrentRows ? sort : upcomingSort}
                    loading={isCurrentRows ? !currentLoading : !upcomingLoading}
                    customSort={
                        isCurrentRows
                            ? { value: sort, onChange: setSort }
                            : { value: upcomingSort, onChange: setUpcomingSort }
                    }
                    customPagination={{ value: page, onChange: setPage }}
                    showCount={true}
                    customCount={(isCurrentRows ? currentCount : upcomingCount) || 0}
                    search={true}
                    folders={
                        <div className="flex items-center">
                            {newOptions?.map((folder, index) => (
                                <Button
                                    id={`${folder.id}-button`}
                                    key={index}
                                    onClick={() => handleClicked(folder.id)}
                                    className={`border-b-4 ${
                                        folder?.active
                                            ? "text-blue-800 border-blue-700 font-black"
                                            : "border-gray-400 text-gray-800"
                                    } px-6 py-2 text-md justify-center font-bold hover:text-zafiro-600`}
                                >
                                    {t(folder?.label)}
                                </Button>
                            ))}
                        </div>
                    }
                    header={tableHeaders}
                    rows={tableRows}
                    filters={isCurrentRows ? currentFilters : upcomingFilters}
                    customSearch={{ value: search, onChange: setSearch }}
                    customRangeSelector={{
                        value: {},
                        hideTimePicker: true,
                        onChange: setDateRange,
                        placeholder: t("stay-date"),
                    }}
                    batchActions={isCurrentRows ? currentBatchActions : upcomingBatchActions}
                />
                {!isCurrentRows && <p>{t("bookings-remain-30-days")}</p>}
            </div>
        </>
    );
};

export default Guests;
