import React, { forwardRef, createRef } from "react";
import Icon from "./Icon";
import Dropdown from "./Dropdown";
import classNames from "classnames";
import Button from "./Button";

export const ItemActions = forwardRef(({ actions, id, icon = "row-actions" }, ref) => {
    const actionRef = ref || createRef();
    const disabledActions = actions?.every((a) => a?.disabled);

    return (
        <div>
            <Dropdown
                id={`row-actions-${id}`}
                ref={actionRef}
                float={true}
                showArrow={false}
                minWidth={"fit-content"}
                handler={
                    <Icon
                        type={icon}
                        size="xl"
                        className={classNames({
                            "p-2": true,
                            "hover:text-zafiro-300": !disabledActions,
                            "opacity-50": disabledActions,
                        })}
                    />
                }
            >
                <div className="select-none rounded shadow-lg text-left border bg-white border-gray-100 text-gray-900 flex-row">
                    {actions?.map((action, index) => {
                        const id = `opt-row-actions-${action?.id?.toLowerCase() || index + 1}`;
                        return (
                            <Button
                                id={id}
                                key={id}
                                to={action?.to}
                                disabled={action?.disabled}
                                onClick={(e) => {
                                    if (e) {
                                        e.stopPropagation();
                                    }
                                    if (action?.onClick) {
                                        action.onClick(e);
                                    }
                                    if (actionRef.current) {
                                        actionRef.current.close();
                                    }
                                }}
                                tooltip={action?.tooltip}
                                className={`px-4 py-2 w-full ${
                                    action.disabled ? "text-gray-500 hover:text-gray-500" : ""
                                }`}
                            >
                                {action?.label}
                            </Button>
                        );
                    })}
                </div>
            </Dropdown>
        </div>
    );
});
