import React, { useState, useEffect } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { allergensDefaultLang } from "hooks/Utils/SalesUtils";
import TextInput from "components/TextInput";
import Button from "components/Button";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";
import Loading from "components/Loading";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setRefreshContentData } from "actions/sectionActions";
import { CORPORATE_REF } from "constants/permissions";

const buildFacetValuesMutation = (items, facetId) => {
    let facetValues = [];
    let mutation = "mutation {";

    if (Array.isArray(items) && items.length > 0) {
        const allergens = items[0].allergens || [];

        allergens.forEach((allergen) => {
            const facet = {
                id: allergen.facetId,
                code: allergen.facetCode,
                translations: [],
            };

            items.forEach((language) => {
                const allergenInLang = language.allergens?.find((a) => a.facetCode === allergen.facetCode);

                if (allergenInLang.translationValue !== undefined) {
                    const translation = {
                        languageCode: language.ref,
                        name: allergenInLang.translationValue,
                    };
                    if (allergenInLang.translationId) {
                        translation.id = allergenInLang.translationId;
                    }
                    facet.translations.push(translation);
                }
            });

            if (facet.translations.length > 0) {
                facetValues.push(facet);
            }
        });
    }

    if (Array.isArray(facetValues) && facetValues.length > 0) {
        facetValues.forEach((facetValue, index) => {
            const translationsString = facetValue.translations
                .map((translation) => {
                    const languageCode = `${translation.languageCode}`;
                    const name = translation.name.replace(/"/g, '\\"');
                    const idPart = translation.id ? `, id: ${translation.id}` : "";
                    return `{ languageCode: ${languageCode}, name: "${name}"${idPart} }`;
                })
                .join(", ");

            if (facetValue.id && facetValue.id > 0) {
                mutation += `
            updateFacetValues${index}: updateFacetValues(input: {
              id: ${facetValue.id},
              translations: [${translationsString}]
            }) {
              id
            }
          `;
            } else {
                const code = facetValue.code.replace(/"/g, '\\"');
                mutation += `
            createFacetValues${index}: createFacetValues(input: {
              facetId: ${facetId},
              code: "${code}",
              translations: [${translationsString}]
            }) {
              id
            }
          `;
            }
        });
        mutation += "\n}";
    }

    return mutation;
};

const useSendMutation = ({ items, facetId, closeModal }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { adminApi } = useVendureAPI();

    const mutate = async () => {
        setLoading(true);
        // setError(null);
        try {
            const response = await executeVendureQuery(adminApi, {
                queryBody: buildFacetValuesMutation(items, facetId),
            });

            if (response?.error) {
                toast.error(t("error-mutation"));
                dispatch(setRefreshContentData(true));
                closeModal();
            } else {
                toast.success(t("operation-successful"));
                dispatch(setRefreshContentData(true));
                closeModal();
            }
        } catch (error) {
            toast.error(t("error-mutation"));
            dispatch(setRefreshContentData(true));
            closeModal();
        } finally {
            setLoading(false);
        }
    };

    return { mutate, loading };
};
const ModalContent = (props) => {
    const { t } = useTranslation();
    const { name, data, close: closeModal } = props;
    const { readOnly, projectLangs, allergens, facetValues, projectRef, facetId } = data;
    const [items, setItems] = useState(null);
    const [openTab, setOpenTab] = useState(0);
    const { mutate, loading } = useSendMutation({ items, facetId, closeModal });

    useEffect(() => {
        if (data) {
            setItems(arrangeItems(projectLangs, allergens, facetValues));
        }
        // eslint-disable-next-line
    }, []);

    const arrangeItems = (projectLangs = [], allergens = [], facetValues = []) => {
        const response = [];

        if (projectLangs?.length > 0) {
            const sortedProjectLangs = projectLangs.sort((a, b) => a.name.localeCompare(b.name));

            sortedProjectLangs.forEach((projectLang) => {
                const item = {
                    ref: projectLang.id,
                    name: projectLang.name,
                    allergens: [],
                    isDefault: projectLang.isDefault,
                    translationsCompleted: true,
                };

                if (allergens?.length > 0) {
                    allergens.forEach((allergen) => {
                        const facetValue = facetValues?.length
                            ? facetValues.find((fv) => fv.code === allergen.code)
                            : null;
                        let translation = null;

                        if (facetValue && facetValue.translations && facetValue.translations.length > 0) {
                            translation =
                                facetValue.translations.find((t) => t.languageCode === projectLang.id) || null;

                            if (translation && !translation.name) {
                                item.translationsCompleted = false;
                            } else if (!translation) {
                                const defaultTranslation = facetValue.translations.find(
                                    (t) => t.languageCode === allergensDefaultLang
                                );

                                if (defaultTranslation && defaultTranslation.name) {
                                    translation = {
                                        name: defaultTranslation.name,
                                        languageCode: allergensDefaultLang,
                                    };
                                }

                                item.translationsCompleted = false;
                            }
                        } else {
                            item.translationsCompleted = false;
                        }

                        const allergenData = {
                            facetId: facetValue ? facetValue.id : null,
                            facetName: t(allergen.label),
                            facetCode: allergen.code,
                            translationId: translation ? translation.id : null,
                            translationValue: translation && translation.name ? translation.name : "",
                            translationLang: translation ? translation.languageCode : projectLang.id,
                        };

                        item.allergens.push(allergenData);
                    });
                }

                response.push(item);
            });
        }

        return response;
    };

    const handleChange = (index, indexAllergen, value) => {
        let itemsClone = [...items];
        itemsClone[index].allergens[indexAllergen].translationValue = value;

        const allFilled = itemsClone[index].allergens.every(
            (allergen) => allergen.translationValue && allergen.translationValue.trim() !== ""
        );
        itemsClone[index].translationsCompleted = allFilled;

        setItems(itemsClone);
    };

    return (
        <Modal
            title={t("allergens")}
            footer={
                !loading ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={() => closeModal()}>
                            {readOnly ? t("close") : t("cancel")}
                        </Button>
                        {readOnly ? null : (
                            <Button
                                design="blue"
                                id="modal-button-save"
                                onClick={() => {
                                    mutate();
                                }}
                            >
                                {t("save")}
                            </Button>
                        )}
                    </>
                ) : null
            }
            className={"w-11/12 p-10"}
        >
            {loading ? (
                <Loading />
            ) : (
                <div className="w-full">
                    <div className="my-5">{t("languages")}</div>
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <ul className="flex mb-0 list-none flex-wrap flex-row" role="tablist">
                                {items && items.length > 0
                                    ? items.map((item, index) => (
                                          <li key={index} className={`text-center`}>
                                              <Button
                                                  className={`first-capital px-10 py-3 block border-b-4 font-bold ${
                                                      openTab === index
                                                          ? `border-blue-600 t-link`
                                                          : `text-gray-800 border-b-0`
                                                  }`}
                                                  onClick={(e) => {
                                                      e.preventDefault();
                                                      setOpenTab(index);
                                                  }}
                                                  id={`tab-${item.ref}`}
                                                  tooltip={
                                                      item.isDefault && projectRef !== CORPORATE_REF
                                                          ? t("default-lang")
                                                          : ""
                                                  }
                                              >
                                                  {item.name}
                                                  {item.translationsCompleted ? (
                                                      <img
                                                          alt="active btn"
                                                          className="float-right w-5 h-5 ml-1"
                                                          src={require(`../../../../../assets/images/icons/save-circle-green.svg`)}
                                                      ></img>
                                                  ) : null}
                                              </Button>
                                          </li>
                                      ))
                                    : null}
                            </ul>
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 ">
                                <div className={`py-5 flex-auto`}>
                                    <div className="tab-content tab-space">
                                        {items && items.length > 0
                                            ? items.map((item, index) => (
                                                  <div
                                                      key={`contentlink${index}`}
                                                      className={openTab === index ? "block" : "hidden"}
                                                      id={`link${index}`}
                                                  >
                                                      <div className="w-full text-left">
                                                          {item.allergens.map((allergen, indexAllergen) => (
                                                              <div
                                                                  key={indexAllergen}
                                                                  className="w-4/12 inline-block align-top pr-20 mb-5"
                                                              >
                                                                  <span className="block mb-1 text-gray-800 font-bold">
                                                                      {allergen.facetName}
                                                                  </span>
                                                                  <TextInput
                                                                      id={`allergen-input-${indexAllergen}-${item.ref}`}
                                                                      className={
                                                                          readOnly ? "" : "hover:text-zafiro-400"
                                                                      }
                                                                      value={allergen.translationValue}
                                                                      readOnly={readOnly}
                                                                      onChange={(value) =>
                                                                          handleChange(index, indexAllergen, value)
                                                                      }
                                                                  />
                                                              </div>
                                                          ))}
                                                      </div>
                                                  </div>
                                              ))
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

const useModalsAllergensTranslate = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalContent {...newProps} />);
        },
    };
};

export default useModalsAllergensTranslate;
