import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "react-apollo";

import {
    useUpdateMarketing,
    useUpdateUnregisteredMarketing,
    useLogoutDevices,
    useAccessListModal,
    GET_COUNTRIES,
    GET_CUSTOMERS_DATA,
} from "hooks/Data/useCustomer";
import Parser from "hooks/Utils/Parser";

import UseSectionHeader from "components/useSectionHeader";
import ZafiroTable from "components/ZafiroTable";
import Button from "components/Button";
import Icon from "components/Icon";
import BooleanText from "components/BooleanText";
import { TAG_CHECK_IN, FILTER_CHECK_IN, FILTER_SELF_CHECK_IN, FILTER_APP, FILTER_WIFI } from "components/Tag";
import ErrorInfo from "components/ErrorInfo";
import { useAuth } from "hooks/Session/auth";
import { TagApp } from "./TagApp";
import { getOrigin } from "hooks/Utils/Customers/Customers";
import { Projects } from "./Projects";
import { CustomerDetails } from "./CustomerDetails";
import { Accesses } from "./Accesses";
import { ActivateCommercialCommunications, LogOutDevices } from "./BatchActionComponents";
import { ButtonExportCustomers } from "./ButtonExportCustomers";
import { useConfirm, useModal } from "components/Modal";

const Loyalty = () => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();
    const [orderBy, setOrderBy] = useState({ field: "signupDate", criteria: "desc" });
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [marketingAccepted, setMarketingAccepted] = useState(null);
    const [emailVerified, setEmailVerified] = useState(null);
    const [registered, setRegistered] = useState();
    const [filterCountries, setFilterCountries] = useState(null);
    const [accessFilter, setAccessFilter] = useState();
    const [filterProperties, setFilterProperties] = useState();
    const { t } = useTranslation();
    const { isCorporate, project, projects } = useAuth();
    const { open: confirm } = useConfirm();
    const { open } = useModal();

    const { update: updateMarketing } = useUpdateMarketing({
        onError: (error) => {
            console.error("Error updating marketing", error);
            toast.error(t("mutation-error"));
        },
        onSuccess: () => toast.success(t("operation-successful")),
    });

    const { update: updateUnregisteredMarketing } = useUpdateUnregisteredMarketing({
        onError: (error) => {
            console.error("Error updating marketing", error);
            toast.error(t("mutation-error"));
        },
        onSuccess: () => toast.success(t("operation-successful")),
    });

    const { logout: logoutDevices } = useLogoutDevices({
        onError: (error) => {
            console.error("Error logging out devices", error);
            toast.error(t("mutation-error"));
        },
        onSuccess: () => toast.success(t("operation-successful")),
    });

    const updateCustomerMarketing = (id, value) => {
        setCustomers(
            customers
                ? customers.map((c) => (c.id === id ? { ...c, marketingAccepted: value, lastUpdate: new Date() } : c))
                : []
        );
        const isNumericID = !isNaN(parseFloat(id?.[0]));
        if (isNumericID) {
            updateMarketing({ id, value });
        } else {
            updateUnregisteredMarketing({ email: id, value });
        }
    };

    const [customers, setCustomers] = useState([]);

    const [getCustomersData, { loading, error, data, refetch }] = useLazyQuery(GET_CUSTOMERS_DATA, {
        fetchPolicy: "network-only",
    });

    const [getCountries, { data: dataCountries }] = useLazyQuery(GET_COUNTRIES, {
        fetchPolicy: "network-only",
    });
    const customersData = data?.customers?.results;
    const count = data?.customers?.info?.count;
    const countries = dataCountries?.countries?.results;
    const mobileApps = data?.mobileApps?.results;
    // Propiedades que solo tengan chain
    const projectsWithChain = projects?.filter((p) => p?.hasChainModule);

    // Filtra por proyecto (hotel)
    const isProject = projectsWithChain?.find((p) => p?.ref === project?.ref);
    // Si es CORPORATE muestra todos los proyectos y sino en el que te encuentras
    const filterProjects = isCorporate ? projectsWithChain : isProject;

    const { open: showAccessListModal } = useAccessListModal({ customersData, mobileApps, projects: filterProjects });

    const tableData = customers
        ? customers.map((customer) => {
              const isRegistered = customer.registered;
              const marketingAccepted = !!customer.marketingAccepted;
              const emailVerified = !!customer.emailVerified;
              const signupOrigin = getOrigin(
                  customer.project,
                  customer.signupApp,
                  customer.signupWifi,
                  t,
                  mobileApps,
                  projects
              );

              const signinOrigins = [
                  signupOrigin?.type === TAG_CHECK_IN ? signupOrigin : null,
                  ...(customer.logins
                      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                      ?.reduce((acc, login) => {
                          const { type, name, host, icon, tooltip, prefix } = getOrigin(
                              customer.project,
                              login.app,
                              login.wifi,
                              t,
                              mobileApps,
                              projects
                          );
                          if (acc.filter((app) => app?.type === type).length < 1) {
                              acc.push({ type, name, host, icon, tooltip, prefix });
                          }
                          return acc;
                      }, []) ?? []),
              ].filter(Boolean);

              // Saca las propiedades del custoemr si hizo login por wifi o checkin
              const propertiesByCustomer =
                  projects?.filter((project) => project.ref === (customer?.project || customer?.signupWifi)) || [];

              // Saca las propiedades del customer si hizo login por mobile app
              const loginByMobileApp = customer?.logins
                  ?.filter((login) => login.app)
                  ?.reduce((acc, login) => {
                      if (!acc.some((app) => app.app === login.app)) {
                          acc.push(login);
                      }
                      return acc;
                  }, []);

              // Encuentra dentro de las apps de la cadena los proyectos que coinciden con las propiedades del customer
              const mobileAppProperties =
                  mobileApps?.find((app) => loginByMobileApp?.some((login) => login.app === app.url))?.projects || [];

              // Concatena las propiedades de los logins por wifi y checkin con las propiedades de los logins por mobile app
              const concatProperties = [...propertiesByCustomer, ...mobileAppProperties]
                  .filter(Boolean)
                  .filter((value, index, self) => self.findIndex((p) => p.ref === value.ref) === index);

              const properties = concatProperties?.slice(0, 1)?.map((project) => (
                  <div className="bg-teal-500 rounded px-1 py-2 text-white" key={project.ref}>
                      <Button
                          id="more-projects"
                          key="remaining-projects"
                          onClick={() => open(<Projects customer={customer} propertiesByCustomer={concatProperties} />)}
                      >
                          {project.name}
                      </Button>
                  </div>
              ));
              const remainingProjectsCount = concatProperties?.length - 1;
              if (remainingProjectsCount > 0) {
                  properties.push(<span className="t-link">+{remainingProjectsCount}</span>);
              }

              return customer
                  ? {
                        rowConfig: {
                            expanded: (
                                <CustomerDetails customer={customer} mobileApps={mobileApps} projects={projects} />
                            ),
                            actions: [
                                ...(isRegistered
                                    ? [
                                          {
                                              label: t("logout-devices"),
                                              onClick: () => {
                                                  confirm({
                                                      id: "logout-devices",
                                                      message: Parser(
                                                          t("logout-devices-confirm").replace(
                                                              "%0",
                                                              customer?.email || "-"
                                                          )
                                                      ),
                                                      title: `${customer?.email} - ${t("logout-devices")}`,
                                                      onConfirm: () => {
                                                          logoutDevices({ refs: [customer.ref] });
                                                      },
                                                      confirmText: t("logout-all"),
                                                      sensitive: true,
                                                  });
                                              },
                                          },
                                      ]
                                    : []),
                                {
                                    label: t(
                                        marketingAccepted
                                            ? "deactivate-commercial-communications"
                                            : "activate-commercial-communications"
                                    ),
                                    onClick: () => {
                                        confirm({
                                            id: "update-marketing",
                                            message: t(
                                                marketingAccepted
                                                    ? "deactivate-commercial-communications-confirm"
                                                    : "activate-commercial-communications-confirm",
                                                {
                                                    account: customer?.email || "-",
                                                }
                                            ),
                                            title: `${customer?.email} - ${t(
                                                marketingAccepted
                                                    ? "deactivate-commercial-communications"
                                                    : "activate-commercial-communications"
                                            )}`,
                                            onConfirm: () => {
                                                updateCustomerMarketing([customer.id], !marketingAccepted);
                                                setTimeout(() => refetch(), 500);
                                            },
                                        });
                                    },
                                },

                                {
                                    label: t("customer-activity"),
                                    onClick: () => {
                                        showAccessListModal({
                                            properties: concatProperties,
                                            id: customer?.id,
                                            email: customer?.email,
                                            logins: customer?.logins,
                                        });
                                    },
                                },
                                ,
                            ],
                        },
                        ref: customer.ref,
                        id: customer.id,
                        email: isRegistered ? (
                            <div className="flex items-center space-x-3" alt={customer.email}>
                                <Icon type="loyal-customers" size={1.125} className="text-zafiro-600 mt-1" />
                                <div>{customer.email}</div>
                            </div>
                        ) : (
                            customer.email
                        ),
                        emailRef: customer.email,
                        country: customer.country,
                        name: customer.name ? `${customer.name} ${customer.surname}` : "-",
                        properties,
                        signupApp: customer.signupApp,
                        signupWifi: customer.signupWifi,
                        logins: customer.logins,
                        registered: isRegistered,
                        access: (
                            <div className="whitespace-nowrap max-w-2xl overflow-hidden">
                                {(() => {
                                    const maxVisibleItems = 2; // Luego cambiar a 2
                                    const visibleItems = signinOrigins.slice(0, maxVisibleItems);
                                    const remainingItemsCount = signinOrigins.length - visibleItems.length;

                                    return (
                                        <div className="flex items-center gap-1">
                                            <Button
                                                id="more-accesses"
                                                className="t-link"
                                                onClick={() =>
                                                    properties?.length > 0
                                                        ? open(
                                                              <Accesses
                                                                  email={customer?.email}
                                                                  accesses={customer.logins}
                                                                  registered={isRegistered}
                                                                  signinOrigins={signinOrigins}
                                                                  projects={projects}
                                                                  properties={concatProperties}
                                                                  projectRef={customer.project}
                                                              />
                                                          )
                                                        : null
                                                }
                                            >
                                                {visibleItems.map((origin) => (
                                                    <TagApp
                                                        key={`tag-${origin?.name}`}
                                                        type={origin?.type}
                                                        prefix={origin?.prefix}
                                                        host={origin?.host}
                                                        icon={origin?.icon?.access}
                                                        tooltip={origin?.tooltip?.access}
                                                    />
                                                ))}
                                            </Button>
                                            {remainingItemsCount > 0 && (
                                                <span className="t-link">+{remainingItemsCount}</span>
                                            )}
                                        </div>
                                    );
                                })()}
                            </div>
                        ),
                        communications: (
                            <BooleanText
                                alt={marketingAccepted}
                                value={marketingAccepted}
                                on={t("accepted")}
                                off={t("not-accepted")}
                            />
                        ),
                        verification: emailVerified ? (
                            <div className="flex items-center justify-center relative">
                                <Icon type="guest" size="2xl" />
                                <div className="bg-green-100 rounded-full flex items-center justify-center w-3 h-3 absolute left-4 top-4">
                                    <Icon type="tick-right" className="text-white font-bold" size={0.55} />
                                </div>
                            </div>
                        ) : null,
                    }
                  : null;
          })
        : null;

    const batchActions = {
        options: [
            {
                id: "logout-devices",
                label: t("logout-devices"),
                disabled: (selectedRows) => !selectedRows?.every((row) => row?.logins?.length > 0),
                value: "logout-devices",
            },
            {
                id: "activate-commercial-communications",
                label: t("activate-commercial-communications"),
                value: "activate-commercial-communications",
            },
        ],
        onChange: (action, rows) => {
            switch (action) {
                case "logout-devices":
                    open(<LogOutDevices rows={rows} refetch={refetch} />);
                    break;
                case "activate-commercial-communications":
                    open(<ActivateCommercialCommunications rows={rows} refetch={refetch} />);
            }
        },
    };

    const filterOptionAll = { id: "all", value: null, label: `-- ${t("all")} --` };

    const accessesOptions = [
        {
            id: "check-in",
            label: t("check-in"),
            value: FILTER_CHECK_IN,
        },
        {
            id: "self-check-in",
            label: t("self-check-in"),
            value: FILTER_SELF_CHECK_IN,
        },
        {
            id: "mobile-app",
            label: t("mobile-app"),
            value: FILTER_APP,
        },
        {
            id: "wifi",
            label: t("portal-wifi"),
            value: FILTER_WIFI,
        },
    ];

    useEffect(() => {
        getCustomersData({
            variables: {
                page,
                orderBy,
                filter: {
                    search,
                    marketingAccepted,
                    emailVerified,
                    registered,
                    country: filterCountries,
                    //TODO use this when backend is ready
                    //accessType: accessFilter,
                    accessType: accessFilter?.length
                        ? accessFilter?.filter((f) => f !== FILTER_SELF_CHECK_IN)
                        : accessFilter,
                    ...(dateFrom &&
                        dateTo && {
                            loginDate: {
                                from: dateFrom,
                                to: dateTo,
                            },
                        }),
                    propertyRef: filterProperties,
                },
            },
        });
    }, [
        page,
        orderBy,
        search,
        marketingAccepted,
        emailVerified,
        registered,
        filterCountries,
        accessFilter,
        dateFrom,
        dateTo,
        filterProperties,
    ]);

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        setCustomers(
            customersData
                ? customersData.map((r) => {
                      // This is because Apollo does weird things if all records have the same ID
                      r.id = r.customerID;
                      r.registered = !!r.id;
                      if (!r.registered) {
                          // This is necessary because unregistered users don't have id or ref
                          r.id = r.email;
                          r.ref = r.email;
                      }
                      return r;
                  })
                : null
        );
    }, [customersData]);

    const countryFiltersOptions = [
        ...(countries
            ? countries
                  .filter((c) => c)
                  .map((country) => {
                      const ref = String(country?.ref);
                      return {
                          id: ref,
                          value: ref,
                          label: t(`country:${ref.toLowerCase()}`),
                      };
                  })
                  .sort((a, b) => a.label.localeCompare(b.label))
            : []),
    ];

    const setDateRange = (range) => {
        const dateFrom = range.from && new Date(range.from);
        const dateTo = range.to && new Date(range.to);
        setDateFrom(dateFrom && dateFrom.toISOString());
        setDateTo(dateTo && dateTo.toISOString());
    };

    return (
        <>
            <UseSectionHeader title={t("loyaltyCustomers")} />

            {error ? (
                <ErrorInfo>{error}</ErrorInfo>
            ) : (
                <>
                    <div className="flex justify-end space-x-10 mb-2">
                        <ButtonExportCustomers data={customersData} mobileApps={mobileApps} projects={projects}>
                            {t("export-all-customer-list")}
                        </ButtonExportCustomers>
                    </div>
                    <div className="bg-white p-5 rounded">
                        <ZafiroTable
                            id="customers-list"
                            key={"basic"}
                            search={true}
                            customSearch={{ value: search, onChange: setSearch }}
                            showCount={true}
                            paginate={true}
                            customSort={{ value: orderBy, onChange: setOrderBy }}
                            customCount={count || 0}
                            batchActions={batchActions}
                            customPagination={{ value: page, onChange: setPage }}
                            perPage={8}
                            customTexts={{ countResults: (count) => t("x customers", { count }) }}
                            cols={[
                                "email",
                                "name",
                                "access",
                                isCorporate && "properties",
                                "communications",
                                "verification",
                            ]}
                            header={{
                                email: { title: t("email"), sortable: true, width: "18vw", minWidth: "18rem" },
                                name: { title: t("fullname"), sortable: true, width: "12vw", minWidth: "9rem" },
                                access: { title: t("accesses-text"), maxWidth: "15rem", sortable: true },
                                communications: { title: t("communications"), sortable: true, width: "15rem" },
                                verification: { title: t(""), sortable: false, width: "15rem" },
                                properties: isCorporate
                                    ? { title: t("properties"), sortable: true, width: "20rem" }
                                    : undefined,
                            }}
                            loading={loading}
                            rows={tableData}
                            filters={[
                                {
                                    id: "filter-by-accesses",
                                    title: t("accesses"),
                                    multiple: true,
                                    options: accessesOptions,
                                    onChange: (value) => {
                                        setPage(1);
                                        setAccessFilter(value);
                                    },
                                },
                                {
                                    id: "filter-by-communications",
                                    title: t("communications"),
                                    options: [
                                        filterOptionAll,
                                        { id: "accepted", value: true, label: t("accepted") },
                                        { id: "not-accepted", value: false, label: t("not-accepted") },
                                    ],
                                    onChange: (value) => {
                                        setPage(1);
                                        setMarketingAccepted(value);
                                    },
                                },

                                {
                                    id: "filter-by-verification",
                                    title: t("verification"),
                                    options: [
                                        filterOptionAll,
                                        { id: "verified-user", value: true, label: t("verified-user") },
                                        { id: "unverified", value: false, label: t("unverified") },
                                    ],
                                    onChange: (value) => {
                                        setPage(1);
                                        setEmailVerified(value);
                                    },
                                },
                                {
                                    id: "filter-by-country",
                                    title: t("country"),
                                    multiple: true,
                                    options: countryFiltersOptions,
                                    onChange: (value) => {
                                        setPage(1);
                                        setFilterCountries(value);
                                    },
                                },

                                {
                                    id: "filter-by-registered",
                                    title: t("Registered"),
                                    options: [
                                        filterOptionAll,
                                        { id: "registered", value: true, label: t("Registered") },
                                        { id: "not-registered", value: false, label: t("Not registered") },
                                    ],
                                    onChange: (value) => {
                                        setPage(1);
                                        setRegistered(value);
                                    },
                                },
                                isCorporate && {
                                    id: "filter-by-properties",
                                    title: t("properties"),
                                    multiple: true,
                                    options: projects?.slice(1)?.map((project) => ({
                                        id: project.ref,
                                        value: project.ref,
                                        label: project.name,
                                    })),
                                    onChange: (value) => {
                                        setPage(1);
                                        setFilterProperties(value);
                                    },
                                },
                            ]}
                            customRangeSelector={{
                                value: {},
                                hideTimePicker: true,
                                onChange: setDateRange,
                            }}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default Loyalty;
