import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import TextInput from "components/TextInput";

const Search = forwardRef(
    ({ id, value, disabled, onChange, onKeyPress, className, placeholder, awaitMode = false }, ref) => {
        const inputRef = useRef(null);
        const { t } = useTranslation();

        const selectClass = classNames({
            "inline-block min-w-60 w-full": true,
            [className]: className,
        });

        useImperativeHandle(ref, () => ({
            focus: () => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            },
        }));

        return (
            <div className={selectClass}>
                <div className="w-full relative">
                    <TextInput
                        ref={inputRef}
                        id={id}
                        placeholder={placeholder || t("search-for") + "..."}
                        value={value}
                        disabled={disabled}
                        onInput={awaitMode ? null : onChange}
                        onChange={awaitMode ? onChange : null}
                        onKeyPress={onKeyPress}
                        icon={"search"}
                    />
                </div>
            </div>
        );
    }
);

export default Search;
