import React, { useState, useEffect } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

/**
 * TextSizeSelector component allows users to select a text size from predefined options.
 *
 * @component
 * @example
 * const handleSizeChange = (size) => {
 *   console.log(size);
 * };
 * return (
 *   <TextSizeSelector value="MEDIUM" onChange={handleSizeChange} />
 * );
 *
 * @param {Object} props - Component props
 * @param {string} [props.value=null] - The initial selected text size (e.g., "SMALL", "MEDIUM", "LARGE")
 * @param {function} props.onChange - Callback function to handle text size change
 *
 * @returns {JSX.Element} The rendered TextSizeSelector component
 */

const TextSizeSelector = ({ value = null, onChange }) => {
    const { t } = useTranslation();

    const sizes = {
        SMALL: { label: "small", className: "text-base" },
        MEDIUM: { label: "medium", className: "text-lg" },
        LARGE: { label: "large", className: "text-xl" },
    };

    const [sizeSelected, setSizeSelected] = useState(value);

    useEffect(() => {
        setSizeSelected(value);
    }, [value]);

    const handleSizeChange = (size) => {
        setSizeSelected(size);

        if (onChange) {
            onChange(size);
        }
    };

    return (
        <div className="w-full flex space-x-10">
            {Object.entries(sizes).map(([key, { label, className }]) => (
                <div key={key} className="py-5 w-full flex flex-col items-center justify-center">
                    <Button onClick={() => handleSizeChange(key)} id={`text-size-selector-${label}`} className="w-full">
                        <div
                            className={classNames(
                                "rounded w-full py-3",
                                key === sizeSelected
                                    ? "item-selected border border-transparent"
                                    : "border-gray-300 border"
                            )}
                        >
                            <div className={classNames("bg-gray-200 flex items-center py-1 justify-center", className)}>
                                {t("text")}
                            </div>
                        </div>
                    </Button>
                    <span className="block mt-2">{t(label)}</span>
                </div>
            ))}
        </div>
    );
};

export default TextSizeSelector;
