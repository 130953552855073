import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import uuid from "react-uuid";
import { useAuth } from "hooks/Session/auth";
import { setSectionStyle, showMainMenu, closeSidebar, openSidebar } from "../../../../../actions/uiActions";
import { useListMapsAndInterestPoints, useUpdatePinsInterestPoint } from "../graphql/useMaps";
import { TOOLBAR_MAP_OPTIONS } from "./MapContext";
import { useListCategories } from "../graphql/useCategories";
import { getFirstAvailablePosition } from "./utils";
import { Session } from "hooks/Utils/Session";

export const useHideMenuAndSidebar = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSectionStyle("p-0"));
        dispatch(showMainMenu(false));
        dispatch(closeSidebar());

        return () => {
            dispatch(showMainMenu(true));
            dispatch(openSidebar());
            dispatch(setSectionStyle(""));
        };
    }, [dispatch]);
};
export const useEditorMapResizeObserver = (ref, setArea, device) => {
    useEffect(() => {
        if (!ref?.current) return;

        const handleResize = (entries) => {
            for (let entry of entries) {
                setArea((current) => {
                    const currentAreaWidth = current?.width;
                    const currentWidth = entry?.contentRect?.width;
                    if (currentWidth && currentWidth !== currentAreaWidth && device?.ratio) {
                        return {
                            width: Math.min(currentWidth, window.innerWidth * 0.9),
                            height: Math.min(currentWidth / device.ratio, window.innerHeight * 0.75),
                        };
                    }
                    return current;
                });
            }
        };

        const observer = new ResizeObserver(handleResize);
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, setArea, device?.ratio]);
};

export const useFetchMapData = () => {
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [dataMap, setDataMap] = useState(null);
    const [dataInterestPoints, setDataInterestPoints] = useState(null);
    const [defaultLang, setDefaultLang] = useState(null);

    const { languages: projectLangs } = useAuth();

    const { id } = useParams();

    const {
        getMapsAndInterestPoints: fetchMapData,
        maps,
        error,
        interestPoints,
    } = useListMapsAndInterestPoints({
        onCompleted: () => setLoading(false),
        onError: () => setLoading(false),
    });

    useEffect(() => {
        if (projectLangs) {
            setDefaultLang(projectLangs.filter((lang) => lang.isDefault)[0].languageRef);
        }
    }, [projectLangs]);

    useEffect(() => {
        if (id && defaultLang) {
            setLoading(true);
            fetchMapData({ variables: { id, mapId: id, lang: defaultLang } });
        }
    }, [id, fetchMapData, defaultLang]);

    useEffect(() => {
        if (Array.isArray(maps) && maps.length > 0) {
            setDataMap(maps[0]);

            if (firstLoading) {
                setFirstLoading(false);
            }
        } else {
            setDataMap(null);
        }
    }, [maps]);

    useEffect(() => {
        if (Array.isArray(interestPoints)) {
            setDataInterestPoints(interestPoints);
        } else {
            setDataInterestPoints(null);
        }
    }, [interestPoints]);

    const refetch = () => {
        if (dataMap?.id) {
            setLoading(true);
            fetchMapData({ variables: { id: dataMap.id, mapId: dataMap.id, lang: defaultLang } });
        }
    };

    return {
        loading,
        dataMap,
        dataInterestPoints,
        error,
        refetch,
        firstLoading,
    };
};

export const useMapController = () => {
    const [toolbarMapOptionSelected, setToolbarMapOptionSelected] = useState(TOOLBAR_MAP_OPTIONS.INTEREST_POINTS);
    const [device, setDevice] = useState(null);

    const manageInteresPoints = useManageInterestPointsMap(device);

    return {
        toolbarMapOptionSelected,
        setToolbarMapOptionSelected,
        device,
        setDevice,
        manageInterestPoints: {
            ...manageInteresPoints,
        },
    };
};

export const useManageInterestPointsMap = (initialDevice) => {
    const [interestPoints, setInterestPoints] = useState([]);
    const [originalInterestPoints, setOriginalInterestPoints] = useState([]);
    const [showingHighlitedPoints, setShowingHighlitedPoints] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const { id } = useParams();
    const { defaultLanguage } = useAuth();

    const { updatePinsInterestPoint } = useUpdatePinsInterestPoint({
        onCompleted: () => {
            console.log("Pins updated successfully");
        },
        onError: (error) => {
            console.error("Error updating pins:", error);
        },
    });

    const handleLayoutChange = (newLayout) => {
        const updatedInterestPoints = newLayout.map((layoutData) => {
            const originalItem = interestPoints.find((ip) => ip.id === layoutData.i);
            return originalItem ? { ...originalItem, ...layoutData } : { ...layoutData, id: layoutData.i };
        });
        setInterestPoints(updatedInterestPoints);

        const updatedOriginalInterestPoints = originalInterestPoints.map((point) => {
            const updatedPins = updatedInterestPoints
                .filter((ip) => ip.originalId === point.id)
                .sort((a, b) => a.index - b.index)
                .map(({ id, x, y }) => ({ id, x, y }));
            return { ...point, pins: updatedPins };
        });
        setOriginalInterestPoints(updatedOriginalInterestPoints);

        const mutatedItems = updatedOriginalInterestPoints.filter((point) => {
            const originalPoint = originalInterestPoints.find((p) => p.id === point.id);

            return originalPoint && JSON.stringify(point.pins) !== JSON.stringify(originalPoint.pins);
        });

        if (mutatedItems.length > 0) {
            updatePinsInterestPoint({
                variables: {
                    mapID: id,
                    id: mutatedItems[0].id,
                    pins: mutatedItems[0].pins,
                },
            });
        }
        return mutatedItems;
    };

    const handleAddInterestPoint = () => {
        const newWidth = 2;
        const newHeight = 2;
        const newPosition = getFirstAvailablePosition({
            existingItems: interestPoints,
            newItemWidth: newWidth,
            newItemHeight: newHeight,
            totalCols: initialDevice?.cols,
            totalRows: initialDevice?.rows,
        });

        console.log("Initial device:", initialDevice);
        console.log("New position:", newPosition);

        if (newPosition) {
            setInterestPoints((prev) => [...prev, { id: uuid(), w: newWidth, h: newHeight, ...newPosition }]);
        } else {
            console.warn("No available position found for the new interest point");
        }
    };

    const handleCalculateInterestPointPosition = (points) => {
        const newPosition = getFirstAvailablePosition({
            existingItems: points,
            newItemWidth: 2,
            newItemHeight: 2,
            totalCols: initialDevice?.cols,
            totalRows: initialDevice?.rows,
        });
        return { ...newPosition, id: uuid() };
    };

    const handleRemovePinsInterestPoint = (item) => {
        const removePinFromPoint = (point, pinIdToRemove) => ({
            ...point,
            pins: point.pins.filter((pin) => pin.id !== pinIdToRemove),
        });

        const updatedOriginalInterestPoints = originalInterestPoints.map((point) =>
            point.id === item.originalId ? removePinFromPoint(point, item.i) : point
        );

        const updatedPoint = updatedOriginalInterestPoints.find((point) => point.id === item.originalId);

        if (!updatedPoint) {
            console.error("Updated point not found for id:", item.originalId);
            return;
        }

        console.log(`Updated pins for point with id ${updatedPoint.id}:`, updatedPoint.pins);

        return {
            pins: updatedPoint.pins,
            onConfirmRemove: () => {
                setSelectedItemId(null);
                updateMapInterestPoints(updatedOriginalInterestPoints);
            },
        };
    };

    const updateMapInterestPoints = (newData) => {
        setOriginalInterestPoints(newData);

        const transformedPoints = newData.flatMap(({ id: originalId, name, pins, pinIcon }) => {
            const normalizedName = name.replace(/^"(.*)"$/, "$1");

            if (!Array.isArray(pins) || pins.length === 0) return [];

            const totalPinsInInterestPoint = pins.length;

            const icon = pinIcon.lib && pinIcon.icon ? pinIcon : null;
            const image = pinIcon.libraryRef
                ? Session.getDasUrl(`${pinIcon.libraryRef}?lang=${defaultLanguage}`)
                : null;

            return pins.map((pin, index) => ({
                id: pin.id,
                originalId,
                index,
                name: normalizedName,
                icon,
                image,
                x: pin.x,
                y: pin.y,
                w: 2,
                h: 2,
                totalPinsInInterestPoint,
            }));
        });

        setInterestPoints(transformedPoints);
    };

    const handleHighlightPointOfInterest = (ids, isHover) => {
        setInterestPoints((prev) => {
            return prev.map((val) => ({ ...val, isHighlighted: ids.includes(val.originalId) }));
        });

        setShowingHighlitedPoints(isHover);
    };

    return {
        interestPoints,
        showingHighlitedPoints,
        originalInterestPoints,
        handleHighlightPointOfInterest,
        handleLayoutChange,
        handleAddInterestPoint,
        handleCalculateInterestPointPosition,
        handleRemovePinsInterestPoint,
        updateMapInterestPoints,
        selectedItemId,
        setSelectedItemId,
    };
};

export const useMapCategories = () => {
    const { getCategories, categories } = useListCategories({});
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getCategories({ variables: { mapId: id } });
        }
    }, [id, getCategories]);

    function refetch() {
        if (id) {
            getCategories({ variables: { mapId: id } });
        }
    }

    return {
        categories,
        refetchCategories: refetch,
    };
};
