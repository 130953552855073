import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import classNames from "classnames";
import Button from "./Button";

const RESULTS_INCREMENT = 50;

library.add(fas, fab);

const getIconsArray = (iconLib, excludes = []) => {
    const icons = Object.keys(library.definitions[iconLib]);
    return icons.filter((icon) => !excludes.includes(icon));
};

const allIcons = [
    { lib: "fa", icons: getIconsArray("fas", ["font-awesome-logo-full"]) },
    { lib: "fab", icons: getIconsArray("fab", ["font-awesome-logo-full", "angry"]) },
];

const IconPicker = ({ value, onChange }) => {
    const { t } = useTranslation();

    const [search, setSearch] = useState("");
    const [iconsShowing, setIconsShowing] = useState(RESULTS_INCREMENT);
    const [iconSelected, setIconSelected] = useState({
        icon: value?.icon || null,
        lib: value?.lib || null,
    });

    const scrollContainerRef = useRef(null);
    const isFetchingMoreIcons = useRef(false);

    useEffect(() => {
        setIconSelected({
            icon: value?.icon || null,
            lib: value?.lib || null,
        });
    }, [value]);

    const filteredIcons = useMemo(() => {
        if (!search) return allIcons;
        const lowerSearch = search.toLowerCase();
        return allIcons.map((set) => ({
            lib: set.lib,
            icons: set.icons.filter((icon) => icon.toLowerCase().includes(lowerSearch)),
        }));
    }, [search]);

    const totalFilteredIcons = useMemo(
        () => filteredIcons.reduce((total, set) => total + set.icons.length, 0),
        [filteredIcons]
    );

    const handleSearchChange = useCallback((e) => {
        setSearch(e.target.value);
        setIconsShowing(RESULTS_INCREMENT);
    }, []);

    const handleScroll = useCallback(() => {
        if (!scrollContainerRef.current || isFetchingMoreIcons.current) return;

        const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;

        if (isAtBottom && iconsShowing < totalFilteredIcons) {
            isFetchingMoreIcons.current = true;
            setTimeout(() => {
                setIconsShowing((prev) => prev + RESULTS_INCREMENT);
                isFetchingMoreIcons.current = false;
            }, 300);
        }
    }, [totalFilteredIcons, iconsShowing]);

    const handleIconSelect = (icon, lib) => {
        setIconSelected({ icon, lib });
        if (onChange) {
            onChange({ icon, lib });
        }
    };

    return (
        <div className="relative">
            <div className="relative">
                <input
                    type="text"
                    className="t-filter-search m-0 w-full"
                    placeholder={`${t("search")}...`}
                    onChange={handleSearchChange}
                    value={search}
                    id="icon-search-input"
                />
                <span className="field-icon icon-search mt-4 table" style={{ height: "2rem" }}></span>
            </div>

            <div
                ref={scrollContainerRef}
                className="mt-3 h-56 overflow-y-scroll flex flex-wrap gap-2"
                onScroll={handleScroll}
            >
                {totalFilteredIcons === 0 ? (
                    <div className="w-full text-center mt-5">{t("icons-not-found")}</div>
                ) : (
                    filteredIcons.map((set) =>
                        set.icons.slice(0, iconsShowing).map((icon) => (
                            <Button
                                key={`${set.lib}-${icon}`}
                                id={`${set.lib}-${icon}`}
                                className={classNames(
                                    "rounded w-12 h-12 flex items-center justify-center cursor-pointer p-3",
                                    {
                                        "bg-zafiro-600 text-white": iconSelected?.icon === icon,
                                    }
                                )}
                                onClick={() => handleIconSelect(icon, set.lib)}
                            >
                                <FontAwesomeIcon icon={[set.lib, icon]} size="lg" />
                            </Button>
                        ))
                    )
                )}
            </div>
        </div>
    );
};

export default IconPicker;
