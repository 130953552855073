import React, { forwardRef } from "react";
import { useMap, TOOLBAR_MAP_OPTIONS } from "../MapContext";
import { useTranslation } from "react-i18next";
import { useModalAddCategory } from "../../modals/categories/useModalAddCategory";
import Button from "components/Button";
import Icon from "components/Icon";
import classNames from "classnames";
import { useModalAddInterestPoint } from "../../modals/useModalAddInteresPoint";
import { useParams } from "react-router-dom";

const Footer = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { toolbarMapOptionSelected, manageInterestPoints, refetch, refetchCategories } = useMap();

    const { handleCalculateInterestPointPosition, interestPoints, setSelectedItemId } = manageInterestPoints;

    const { open: openModalAddCategory } = useModalAddCategory();
    const { open: openModalAddInteresPoint } = useModalAddInterestPoint();

    const footerClasses = classNames({
        "absolute bottom-0 left-0 w-full border-t border-gray-300 py-2 flex items-center justify-center flex-none bg-white": true,
    });

    if (toolbarMapOptionSelected === TOOLBAR_MAP_OPTIONS.SETTINGS_IMAGE) return null;

    const buttonConfigs = {
        [TOOLBAR_MAP_OPTIONS.INTEREST_POINTS]: {
            id: "add-point-of-interest",
            text: t("add-point-of-interest"),
        },
        [TOOLBAR_MAP_OPTIONS.CATEGORIES]: {
            id: "add-category",
            text: t("add-category"),
        },
    };

    const buttonAction = {
        [TOOLBAR_MAP_OPTIONS.INTEREST_POINTS]: () => {
            const newPin = handleCalculateInterestPointPosition(interestPoints);
            openModalAddInteresPoint({
                data: {
                    pins: [newPin],
                    id: id,
                },
                refetch: () => {
                    refetch();
                    newPin && setSelectedItemId(newPin.id);
                },
            });
        },
        [TOOLBAR_MAP_OPTIONS.CATEGORIES]: () => {
            openModalAddCategory({ fetch: refetchCategories, id });
        },
    };

    const config = buttonConfigs[toolbarMapOptionSelected];

    return (
        <div ref={ref} className={footerClasses}>
            {config && (
                <Button className="text-zafiro-600" id={config.id} onClick={buttonAction[toolbarMapOptionSelected]}>
                    <Icon type="add" size={1.5} />
                    <span>{config.text}</span>
                </Button>
            )}
        </div>
    );
});

export default Footer;
