import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//Utils
import Parser from "hooks/Utils/Parser";

//Components
import UseLoading from "../components/Table/useLoading";

//Actions
import { changeActionValues, setActionValidations } from "../actions/globalActions";
import UseSelectWithSearch from "./useSelectWithSearch";
import UseInputDateHour from "./useInputDateHour";
import UseInputDate from "./useInputDate";
import { multipleTranslate, capitalizeFirst } from "../hooks/Utils/Utils";
import { validateField } from "../hooks/Utils/Validations";
import UseTagSelector from "./useTagSelector";
import UseSwitch from "./Inputs/useSwitch";
import ReactTooltip from "components/ReactTooltip";
import uuid from "react-uuid";
import Icon from "./Icon";

const useEditableField = ({ data }) => {
    const { t } = useTranslation();

    const {
        id,
        label,
        noLabel,
        labelOnEdition,
        value,
        type,
        inEditionMode,
        focusInEdition,
        styleAdjust,
        dateAdjust,
        inputAdjust,
        selectAdjust,
        placeholder,
        optionData,
        selectedIds,
        oneSelected,
        hideSearch,
        numberMin,
        markSelected,
        numberMax,
        selectWithAddInput,
        loading,
        disabled,
        required,
        onBlurValidation,
        onBlurAction,
        noCapitalizedPH = false,
        noEditionAdjust,
        labelAdjust,
        tagsAvailable,
        onChangeAction,
        editOptions,
        adjustContainerOption,
        inputBgColor,
        noMaxHeight = true,
        tooltip,
        useOptionID = true,
        icon,
    } = data;

    //Store data
    const { values, validations } = useSelector((state) => state.action);
    const [idTooltip] = useState(uuid());

    //statid
    const [cValue, setCValue] = useState(value);
    const [passValidation, setPassValidation] = useState(true);

    useEffect(() => {
        setCValue(value);
    }, [value]);

    //Listeners
    useEffect(() => {
        if (inEditionMode && (!selectedIds || (selectedIds && selectedIds.length === 0))) {
            dispatch(changeActionValues({ [label]: value !== "-" ? value : "" }));
            if (value !== "-" && value !== "" && value !== null && label === "email" && type === "text") {
                setPassValidation(value);
            }
            setCValue(value);
        }

        if (inEditionMode && focusInEdition) {
            document.querySelector(`#${id}`).focus();
        }
        // eslint-disable-next-line
    }, [inEditionMode]);

    useEffect(() => {
        if (typeof validations[label] !== "undefined") {
            setPassValidation(validations[label]);
        }
        // eslint-disable-next-line
    }, [validations]);

    //Actions
    const dispatch = useDispatch();
    let checkValuesTimeout = useRef();

    const handleOnchange = (e) => {
        let _value = null;
        switch (type) {
            case "switch":
            case "select-with-search":
                _value = e;
                break;
            default:
                _value = e.target.value;
                break;
        }
        setCValue(_value);
        //ONLY give focus to element when type number
        if (type === "number") e.target.focus();
        if (onChangeAction) onChangeAction(e);
    };
    const updateActionValues = (value) => {
        if (onBlurValidation) {
            dispatch(
                setActionValidations({
                    ...validations,
                    [label]: validateField(label, value, required),
                })
            );
        }
        dispatch(
            changeActionValues({
                [label]: value !== "-" ? value : "",
            })
        );
    };
    const composeEditableField = () => {
        switch (type) {
            case "text":
                return (
                    <>
                        <input
                            id={id ? id : null}
                            type="text"
                            className={`w-full ${inputBgColor ? inputBgColor : " bg-gray-200"} rounded pl-3 p-2 ${
                                !passValidation ? "border border-red-100" : ""
                            } ${inputAdjust} ${disabled && "text-gray-600"}`}
                            value={cValue !== "-" ? cValue : ""}
                            placeholder={
                                noCapitalizedPH
                                    ? multipleTranslate(placeholder, t)
                                    : capitalizeFirst(multipleTranslate(placeholder, t))
                            }
                            onChange={(e) => handleOnchange(e)}
                            onBlur={(e) => {
                                updateActionValues(e.target.value);
                                if (onBlurAction) {
                                    onBlurAction(e);
                                }
                            }}
                            disabled={disabled}
                        ></input>
                        {tagsAvailable && !disabled ? (
                            <div>
                                <UseTagSelector
                                    id={id ? `${id}-button-tags-selector` : null}
                                    itemLabel={label}
                                    cValue={cValue}
                                    setCValue={setCValue}
                                />
                            </div>
                        ) : null}
                    </>
                );
            case "number":
                return (
                    <input
                        id={id ? id : null}
                        type="number"
                        min={numberMin}
                        max={numberMax}
                        className={`w-full bg-gray-200 rounded pl-3 p-2 ${
                            !passValidation ? "border border-red-100" : ""
                        } ${inputAdjust} ${disabled && "text-gray-600"}`}
                        value={cValue !== "-" ? cValue : ""}
                        placeholder={capitalizeFirst(multipleTranslate(placeholder, t))}
                        onChange={(e) => handleOnchange(e)}
                        onBlur={(e) => updateActionValues(e.target.value)}
                        disabled={disabled}
                    ></input>
                );
            case "textarea":
                return (
                    <>
                        <textarea
                            id={id ? id : null}
                            rows="3"
                            className={`w-full bg-gray-200 rounded pl-3 p-2 ${
                                !passValidation ? "border border-red-100" : ""
                            } ${inputAdjust} ${disabled && "text-gray-600"}`}
                            value={cValue}
                            placeholder={capitalizeFirst(multipleTranslate(placeholder, t))}
                            onChange={(e) => handleOnchange(e)}
                            onBlur={(e) => updateActionValues(e.target.value)}
                            disabled={disabled}
                        ></textarea>
                        {tagsAvailable && !disabled ? <div className="relative right-0"></div> : null}
                    </>
                );
            case "select-with-search":
                return (
                    <>
                        <UseSelectWithSearch
                            data={{
                                optionData: optionData,
                                selectedIds: selectedIds,
                                name: label,
                                hideSearch: hideSearch ? hideSearch : false,
                                noSelect: markSelected ? false : true,
                                cStyle: selectAdjust,
                                oneSelected: oneSelected,
                                selectPlaceHolder: capitalizeFirst(multipleTranslate(placeholder, t)),
                                optionsAdjust: "mt-11",
                                addInput: selectWithAddInput,
                                editOptions: editOptions,
                                noMaxHeight: noMaxHeight ? true : false,
                                id: id ? id : null,
                                adjustContainerOption: adjustContainerOption ? adjustContainerOption : null,
                                onChange: (e) => handleOnchange(e),
                                useOptionID: useOptionID,
                            }}
                        ></UseSelectWithSearch>
                        {disabled ? (
                            <div
                                className="absolute bg-gray-800 opacity-50 top-0 h-full z-10"
                                style={{ width: "95%" }}
                            ></div>
                        ) : null}
                    </>
                );
            case "date":
                return (
                    <UseInputDate
                        data={{
                            name: label,
                            placeholder: capitalizeFirst(multipleTranslate(placeholder, t)),
                            date: value,
                            styleAdjust: dateAdjust + " " + inputAdjust,
                            fullwidth: true,
                        }}
                    ></UseInputDate>
                );
            case "date&time":
                return (
                    <UseInputDateHour
                        data={{
                            name: label,
                            date: data.date,
                            hour: data.hour,
                            disabled: disabled,
                            minDate: data.minDate,
                            id: id ? id : null,
                        }}
                        adjustWidthHour={"w-48"}
                    ></UseInputDateHour>
                );
            case "switch":
                return (
                    <UseSwitch
                        checked={markSelected ? markSelected : false}
                        id={id}
                        action={(e) => {
                            handleOnchange(e);
                            updateActionValues(e);
                        }}
                    ></UseSwitch>
                );
            default:
                break;
        }
    };

    return (
        <div key={value} className={`relative ${styleAdjust}`}>
            {!noLabel ? (
                <div className={`fieldLabel ${labelAdjust}`}>
                    {t(inEditionMode && labelOnEdition ? labelOnEdition : label)} {required && "*"}
                    {icon ? (
                        <Icon
                            className={icon?.className || null}
                            type={icon?.type || null}
                            tooltip={icon?.tooltip || null}
                            size={icon?.size || null}
                        />
                    ) : null}
                </div>
            ) : null}
            {inEditionMode ? (
                loading ? (
                    <UseLoading adjust="contents" />
                ) : (
                    <>{composeEditableField()}</>
                )
            ) : (
                <div
                    id={id ? `editable-field-value-${id}` : ""}
                    className={`text-gray-900 text-lg font-normal ${noEditionAdjust}`}
                >
                    <span data-tip data-for={`htmlTextTooltip-${idTooltip}`}>
                        {value ? Parser(String(value)) : ""}
                    </span>
                    {tooltip ? (
                        <ReactTooltip id={`htmlTextTooltip-${idTooltip}`}>
                            {t("{{capitalize, capitalize}}", { capitalize: t(tooltip) })}
                        </ReactTooltip>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default useEditableField;
