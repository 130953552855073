import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { parse, isValid } from "date-fns";
import ReactInputMask from "react-input-mask";
import Icon from "components/Icon";
import Button from "components/Button";

export const CalendarNumberInput = ({ date, range, onDateChange, type, removeButtonAction, startDate }) => {
    const [inputValue, setInputValue] = useState(date ? formatDateToDisplay(date) : "");

    const { t } = useTranslation();

    const handleInputChange = (event) => {
        const newInputValue = event.target.value;
        setInputValue(newInputValue);
    };

    const handleInputBlur = () => {
        const parsedDate = parse(inputValue, "dd/MM/yyyy", new Date());
        const today = new Date();

        if (isValid(parsedDate) && parsedDate <= today && parsedDate >= startDate) {
            if (type === "start") {
                onDateChange({ from: parsedDate }, "start");
            } else if (type === "end") {
                onDateChange({ to: parsedDate }, "end");
            }
            setInputValue(formatDateToDisplay(parsedDate));
        } else {
            setInputValue(date ? formatDateToDisplay(date) : "");
        }
    };

    const handleRemoveClick = () => {
        if (removeButtonAction) {
            removeButtonAction();
        }
    };

    useEffect(() => {
        setInputValue(date ? formatDateToDisplay(date) : "");
    }, [date]);

    const handleInputClick = () => {
        if (type === "start" && !date) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            onDateChange({ from: currentDate }, "start");
            if (!range.to) {
                const currentEndTime = new Date();
                onDateChange({ to: currentEndTime }, "end");
            }
        }
    };

    return (
        <div
            id={`date-input-container-${type}`}
            className="date-input-container ml-6 h-10 relative mr-4 flex rounded bg-gray-100"
        >
            <Icon type="booking" className="absolute" style={{ top: "0.6rem", left: "0.5rem" }} />
            {date ? (
                <Button
                    id={`date-input-container-${type}-remove`}
                    onClick={handleRemoveClick}
                    style={{ top: "0.6rem", right: "0.5rem" }}
                    className=" absolute h-4 w-4 flex items-center focus:outline-none justify-center bg-zafiro-600 rounded-full"
                >
                    <Icon type="remove" className="text-white text-xxs" size="xxs" />
                </Button>
            ) : null}
            <ReactInputMask
                id={`date-input-container-${"start"}-input`}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onClick={handleInputClick}
                className=" pl-10 bg-gray-100 rounded w-full "
                mask={"99/99/9999"}
                value={inputValue}
                placeholder={t("date-abbreviation")}
            />
        </div>
    );
};

const formatDateToDisplay = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};
