import propertyRoutes from "routes/hotel";
import allPropertiesRoutes from "routes/allProperties";
import customerRoutes from "routes/customers";
import servicesRoutes from "routes/services";
import designRoutes from "routes/design";
import statsRoutes from "routes/stats";
import commonZonesRoutes from "routes/commonZones";
import infoRoutes from "routes/info";
import settingsRoutes from "routes/settings";
import authRoutes from "routes/auth";
import { devicesRoutes } from "routes/devices";

import Home from "sections";
import OldManager from "components/Section/OldManager";
import Support from "components/Section/Support";

const routes = [
    { path: "/", section: Home, initial: true },
    ...propertyRoutes,
    ...allPropertiesRoutes,
    ...customerRoutes,
    ...servicesRoutes,
    ...designRoutes,
    ...statsRoutes,
    ...commonZonesRoutes,
    ...infoRoutes,
    ...settingsRoutes,
    ...devicesRoutes,
    ...authRoutes,
    { path: "/version", reload: true },
    { path: "/ZAFIRO_TV", section: OldManager, fullscreen: true },
    { path: "/ZAFIRO_Support", section: Support, fullscreen: true },
    { path: "/playground/*", reload: true },
    { path: "*", section: Home },
];

export default routes;
