import React from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "hooks/Session/auth";

import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import ProjectsList from "components/ProjectsList";

const ProjectsWithoutDesign = () => {
    const { t } = useTranslation();
    const { close } = useModal();
    const { projects } = useAuth();

    return (
        <Modal
            id="properties-without-designs"
            className="w-1/3"
            title={t("Properties with no design applied")}
            footer={
                <Button design="blue" id="modal-button-close" onClick={close}>
                    {t("close")}
                </Button>
            }
        >
            <div className="flex flex-col gap-5">
                <div>
                    {t(
                        "The following properties have no design applied. The devices will display no screen to the guest"
                    )}
                </div>
                <ProjectsList projects={projects?.filter((p) => p.id && !p.designID)} />
            </div>
        </Modal>
    );
};

export default ProjectsWithoutDesign;
