import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { SalesContextDeprecated } from "contexts/Sales";
import { useMaxQuantityProduct } from "../../../modals/useMaxQuantityProduct";

function ProductSettingsSettingsCard({ data, disabled }) {
    const { data: dataContext } = useContext(SalesContextDeprecated);

    //Consts&states

    const { id, customFields } = dataContext?.productVariants?.items?.[0] || {};
    const { t } = useTranslation();
    const max_units_per_order = customFields ? customFields.max_units_per_order : null;
    const max_units_per_order_enabled = customFields ? customFields.max_units_per_order_enabled : false;

    const { open: openMaxQueantityProductModal } = useMaxQuantityProduct();

    const handleShowTranslateModal = () => {
        openMaxQueantityProductModal({
            VALUES: {
                max_units_per_order,
                max_units_per_order_enabled,
                id,
            },
        });
    };

    //renders

    //response
    return (
        <div className="w-full my-2" style={{ height: "8.4rem" }}>
            <div className="flex items-center w-full justify-between mb-5">
                <span className="font-bold text-gray-900 text-1xl">{t("settings")}</span>
                {disabled ? null : (
                    <span
                        id="edit-product-settings"
                        className={`icon icon-edit cursor-pointer text-1xl`}
                        onClick={() => {
                            handleShowTranslateModal();
                        }}
                    ></span>
                )}
            </div>
            <div className="flex items-center w-full justify-between mb-2 pr-20">
                <span className="">{t("max-quantity-per-order")}</span>
                <span className="font-bold">
                    {customFields && customFields.max_units_per_order && max_units_per_order_enabled
                        ? `${customFields.max_units_per_order}
                        ${t("{{capitalize, capitalize}}", {
                            capitalize: customFields.max_units_per_order > 1 ? t("products") : t("product"),
                        })}`
                        : `-`}
                </span>
            </div>
        </div>
    );
}

export default ProductSettingsSettingsCard;
