import React, { useState, useEffect } from "react";
import { Link, useNavigate as useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIdSubRow } from "../../../actions/tableActions";
import { getFromDAS, rgbaPattern } from "../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import BrowserMediaLibraryModal from "hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
import { setModalContent, openModal, closeModal } from "actions/uiActions";
import { changeGlobalAction, changeActionValues } from "actions/globalActions";

const UseEditImageCell = ({ cellData, colIndex, rowIndex, depth, subIndex }) => {
    const { linkText, hidden, adjust, onclickCallback, urlPath, adjustNotImage, iconError, editable } = cellData || {};
    const history = useHistory();
    const { idsSubrows } = useSelector((state) => state.table);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const prefixId = "image-cell-";
    //const content = GetCellContent(cellData);
    const [src, setSrc] = useState(urlPath);
    const { lang } = useSelector((state) => state.ui);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { values } = useSelector((state) => state.action);
    const action = "add-image-customised-channel";
    const idCard = "image-channel-selector";

    //const onErrorPath = "./../../assets/images/icons/image.svg";

    const openModalLibrary = () => {
        const modal = BrowserMediaLibraryModal(t, {
            text: "images-of-the-media-library",
            title: "choose-an-image",
            textAdjust: "",
            bodyAdjust: "w-10/12",
            executeSectionQuery: true,
            action: "widget-actions-media",
            buttonSelectedAssetName: "choose-an-image",
            closeAction: null,
            dataMediaLibrary: {
                lang: lang,
                selected_ref: null,
                type: "asset",
                select_multiple: false,
                add_asset: {
                    active: true,
                    return_added: false,
                },
            },
        });
        setTimeout(() => {
            dispatch(
                changeGlobalAction({
                    actionName: action,
                    values: { ...values, idCardImageChannelSelector: idCard },
                })
            );
        }, 500);
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    useEffect(() => {
        setSrc(urlPath);
    }, [cellData]);

    useEffect(() => {
        if (executeQuery && executeQuery.action === action && values["idCardImageChannelSelector"] === idCard) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            if (editable) {
                setSrc(values["browser-media-library-items-selected"][0]);
            }
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    return (
        <>
            {!hidden ? (
                <>
                    <div className=" flex items-center">
                        <div className=" relative" style={{ width: "fit-content" }}>
                            {src ? (
                                <img
                                    src={getFromDAS(src)}
                                    alt=""
                                    className={`${adjust} ${onclickCallback ? "cursor-pointer" : ""}`}
                                    onClick={onclickCallback}
                                    onError={(e) => {
                                        setSrc("");
                                    }}
                                ></img>
                            ) : (
                                <span className={`icon ${iconError} ${adjustNotImage} w-40 h-12`}></span>
                            )}
                            <div
                                className={`hidden ${adjustNotImage}`}
                                id={`${prefixId}img-c${colIndex}-r${rowIndex}${depth >= 0 ? `-d${depth}` : ""}`}
                            ></div>
                            {editable ? (
                                src ? (
                                    <button
                                        className="w-40 absolute bottom-0 left-0 bg-gray-800 bg-opacity-50  "
                                        onClick={() => {
                                            setSrc("");
                                            dispatch(
                                                changeActionValues({
                                                    ["browser-media-library-items-selected"]: ["/"],
                                                })
                                            );
                                        }}
                                    >
                                        <i className=" text-white icon-delete-editor"></i>
                                    </button>
                                ) : (
                                    <button
                                        className="w-40 absolute bottom-0 left-0 bg-gray-800 bg-opacity-50"
                                        onClick={openModalLibrary}
                                    >
                                        <i className=" text-white icon-upload"></i>
                                    </button>
                                )
                            ) : null}
                        </div>
                        {linkText && <p>{linkText}</p>}
                    </div>
                </>
            ) : null}
        </>
    );
};

export default UseEditImageCell;
