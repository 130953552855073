import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "hooks/Session/auth";
import { useSupportLink } from "hooks/Data/useSupportLink";

import NotFound from "components/Section/NotFound";
import ErrorInfo from "components/ErrorInfo";
import Button from "components/Button";

const Support = () => {
    const buttonRef = useRef(null);
    const { t } = useTranslation();

    const { isSuperUser, isImpersonating, lang } = useAuth();
    const link = useSupportLink();

    const isAvailable = !(isSuperUser || isImpersonating);
    const supportLink = link?.data;

    useEffect(() => {
        if (isAvailable) {
            link.load({ lang });
        }
    }, [isAvailable, lang]);

    useEffect(() => {
        if (buttonRef.current && supportLink) {
            buttonRef.current.click();
        }
    }, [buttonRef.current, supportLink]);

    if (!supportLink || !isAvailable) {
        return (
            <div className="bg-white p-10 overflow-auto" style={{ height: "100vh" }}>
                <NotFound
                    title={isAvailable ? t("Support link not found") : null}
                    desc={link?.error ? <ErrorInfo>{link?.error}</ErrorInfo> : null}
                />
            </div>
        );
    }

    return (
        <div className="bg-white p-10 overflow-auto" style={{ height: "100vh" }}>
            <div className="w-28">
                <Button
                    ref={buttonRef}
                    id="support"
                    href={supportLink}
                    target="_self"
                    design="text-link"
                    className="text-gray-200 text-xl"
                >
                    {t("Continue to support")}
                </Button>
            </div>
        </div>
    );
};

export default Support;
