import React, { forwardRef, useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
    useAddBooking,
    useAddCheckin,
    useAssignToGroup,
    useChangeRoom,
    useCheckout,
    useDeleteUpcomingCheckIn,
    useDeleteUpcomingCheckIns,
    useGuestGroups,
    useGuestGroupss,
    useResendPreCheckIn,
} from "hooks/Data/useGuests";
import Button from "components/Button";
import Select from "components/Select";
import Modal, { useModal } from "components/Modal";
import Icon from "components/Icon";
import { useRooms } from "hooks/Data/useRoomGroups";
import { ROOM_TYPE } from "constants/rooms";
import { editGuestGroupQuery } from "hooks/GraphqlCalls/Customers/utils/guestsQueries";
import { useSelector } from "react-redux";
import useGuestOrders from "hooks/Data/useGuestOrders";
import Checkbox from "components/Inputs/Checkbox";
import NumberInput from "components/NumberInput";
import DateInput from "components/Inputs/DateInput";
import TextInput from "components/TextInput";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/Session/auth";
import { Session } from "hooks/Utils/Session";
import useModalBulkCheckin from "../Precheckin/ReservationDetails/modals/useModalBulkCheckin";
import DropdownButton from "components/DropdownButton";

export const AssignAction = ({ rows, type, refetch }) => {
    const { guestGroups } = useGuestGroupss();
    const guestData = rows?.map((row) => row?.id) || {};
    const [guestGroupsId, setGuestGroupsId] = useState([]);
    const { close } = useModal();
    const { t } = useTranslation();

    const [executeMutation] = useMutation(editGuestGroupQuery);

    const updateRoomGroup = () => {
        executeMutation({
            variables: {
                guests: guestData,
                id: guestGroupsId,
            },
        }).then((response) => {
            if (response?.data?.updateGuestGroup?.ok) {
                if (refetch) {
                    refetch();
                }
                toast.success(t("operation-successful"));
            }
            close();
        });
    };

    return (
        <>
            <main>
                <h2 className="my-10 mb-3">
                    {type === ROOM_TYPE.COMMONZONE ? t("zones-to-add-to-group") : t("rooms-to-add-to-group")}
                </h2>
                <div className="flex gap-2 mb-5">
                    {rows?.map((room) => {
                        return <p className="px-3 py-1 bg-gray-200">{room?.roomName}</p>;
                    })}
                </div>
                <div>
                    <p>{t("select-group")}</p>
                    <Select
                        id="select-group"
                        label={t("select-group")}
                        options={guestGroups.map((group) => ({ value: group.id, label: group.name }))}
                        allowUnselect={true}
                        style="mt-4"
                        onChange={(value) => setGuestGroupsId(value)}
                    />
                </div>
            </main>
            <footer className="flex w-full gap-4 mt-10 justify-end">
                <Button id="cancel" design="white" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button id="alert-accept" design="blue" onClick={updateRoomGroup}>
                    {t("assign-to-group")}
                </Button>
            </footer>
        </>
    );
};

export const Checkout = ({ guest, refetch }) => {
    const { name, surname, rooms, id } = guest;
    const roomsIDs = rooms?.map((room) => room?.roomID) || {};
    const { permissions } = useSelector((state) => state.ui);
    const [hasWarnings, setHasWarnings] = useState(false);
    const { checkOut } = useCheckout();
    const { t } = useTranslation();
    const { close } = useModal();
    const [checked, setChecked] = useState({
        [id]: roomsIDs.map((room) => ({ checked: true, value: room })),
    });

    const hasOrdersManagement = permissions?.services?.shopsOrders;

    return (
        <Modal
            id="check-out"
            title={`${name} ${surname} - ${t("check-out")}`}
            className="w-2/7 p-10"
            onKeyPress={{ Enter: close }}
            footer={
                <div className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="cancel" design="white" onClick={close} className="font-bold">
                        {t("cancel")}
                    </Button>
                    <Button
                        id="check-out"
                        design="blue"
                        onClick={() => {
                            const rooms = Object.keys(checked)
                                .map((id) => checked?.[id]?.filter((room) => room.checked).map((room) => room.value))
                                .flat();
                            if (rooms) {
                                checkOut(rooms).then(() => {
                                    close();
                                    toast.success(t("operation-successful"));
                                });
                                setTimeout(() => refetch(), 500);
                            } else {
                                toast.error(t("error-select-at-least-one"));
                            }
                        }}
                    >
                        {t("check-out")}
                    </Button>
                </div>
            }
        >
            <p className="mt-4">{t("are-you-sure-you-want-to-check-out")}</p>
            <div>
                <p className="my-4">{`${name} ${surname}`}</p>
                {rooms?.map((room, index) => {
                    return (
                        <div key={room?.roomID}>
                            <p className="my-4">{room?.roomName}</p>
                            <CheckoutRooms
                                id={id}
                                roomID={room?.roomID}
                                name={`${name}-${index}`}
                                number={room?.number}
                                setHasWarnings={setHasWarnings}
                                hasWarnings={hasWarnings}
                                setChecked={setChecked}
                            />
                        </div>
                    );
                })}
            </div>
            {hasWarnings[id] ? (
                <div className="w-full mt-5 flex items-center whitespace-no-wrap">
                    <span className="icon block ml-1 mr-2 text-2xl icon-warning text-orange-100"></span>
                    <span>
                        {hasOrdersManagement ? t("there-are-checkout-warnings") : t("there-are-failed-charges")}
                    </span>
                </div>
            ) : null}
        </Modal>
    );
};

export const MoveRoom = ({ name, rooms, oldID, stayGuestID, refetch }) => {
    const { t } = useTranslation();
    const [newRoomID, setNewRoomID] = useState({});
    const [roomsOptions, setRoomsOptions] = useState([]);
    const { close } = useModal();
    const { load: loadRooms } = useRooms(
        ROOM_TYPE.GUESTROOM,
        {
            onSuccess: (response) => {
                setRoomsOptions(
                    response?.results?.map((room) => ({ id: room?.id, value: room?.id, label: room?.name }))
                );
            },
        },
        true
    );
    const { changeRoom } = useChangeRoom();

    useEffect(() => loadRooms(), []);
    console.log(oldID);
    return (
        <Modal
            title={`${name} - ${t("change-room")} `}
            className="w-1/4 p-10"
            footer={
                <div className="flex justify-end gap-2">
                    <Button id="modal-button-cancel" design="white" className="font-bold" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="modal-button-change-room"
                        design="blue"
                        onClick={() => {
                            const uniqueRoomIDs = new Set(Object.values(newRoomID));
                            if (uniqueRoomIDs.size !== rooms.length) {
                                toast.error(t("same-room-error"));
                                return;
                            }

                            Promise.all(rooms.map((_, i) => changeRoom(stayGuestID, oldID[i], newRoomID[i]))).then(
                                () => {
                                    close();
                                    refetch();
                                }
                            );
                        }}
                    >
                        {t("change-room")}
                    </Button>
                </div>
            }
        >
            {rooms?.map((room, index) => (
                <>
                    <p className="my-3">{`${t("room")} ${room?.number}. ${t("choose-new-room")} `}</p>
                    <Select
                        id="select-room"
                        placeholder={t("select-room")}
                        options={roomsOptions}
                        onChange={(value) =>
                            setNewRoomID((prev) => ({
                                ...prev,
                                [index]: value,
                            }))
                        }
                    />
                </>
            ))}
        </Modal>
    );
};

export const AssignToGroup = ({ name, guestID, refetch }) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const groups = useGuestGroups();
    const [groupID, setGroupID] = useState([]);
    const [groupsOptions, setGroupsOptions] = useState([]);

    useEffect(() => {
        if (guestID) {
            groups.load(guestID, {
                onSuccess: (response) => {
                    setGroupsOptions(
                        response?.data?.results?.map((group) => ({
                            id: group?.id,
                            value: group?.id,
                            label: group?.name,
                        }))
                    );
                    setGroupID(
                        response?.selected?.results?.flatMap((group) => group?.guestGroups?.map((group) => group?.id))
                    );
                },
            });
        }
    }, [guestID]);

    const { assignToGroup } = useAssignToGroup();

    return (
        <Modal
            title={`${name} - ${t("assign-to-group")} `}
            className="w-1/4 p-10"
            footer={
                <div className="flex justify-end gap-2">
                    <Button id="modal-button-cancel" design="white" className="font-bold" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="modal-button-assign-to-group"
                        design="blue"
                        onClick={() => {
                            assignToGroup(guestID, groupID).then(() => {
                                close();
                                refetch();
                            });
                        }}
                    >
                        {t("assign-to-group")}
                    </Button>
                </div>
            }
        >
            <p className="mb-4">{t("guest-groups")}</p>
            <Select
                id="select-group"
                placeholder={t("select-group")}
                multiple={true}
                options={groupsOptions}
                value={groupID}
                onChange={(value) => setGroupID(value ? value : [])}
            />
        </Modal>
    );
};

export const CheckInAction = ({ guest, refetch }) => {
    const [mainGuest, setMainGuest] = useState();
    const [validations, setValidations] = useState({});
    const [rooms, setRooms] = useState([]);
    const [roomsID, setRoomsID] = useState();
    const [guestsPerRoom, setGuestsPerRoom] = useState({});
    const { code, id, guests, numberOfRooms, numberOfGuests } = guest;
    const { t } = useTranslation();
    const { close } = useModal();
    const { addCheckin } = useAddCheckin();
    const { load: loadRooms } = useRooms(
        ROOM_TYPE.GUESTROOM,
        {
            onSuccess: (response) => {
                setRooms(response?.results?.map((room) => ({ id: room?.id, value: room?.id, label: room?.name })));
            },
        },
        true
    );
    const guestOptions =
        guests?.map((guest, index) => ({
            id: guest?.id,
            value: guest?.id,
            label: guest?.name ? `${guest?.name} ${guest?.surname ?? ""}` : `Guest ${index + 1}`,
        })) || [];

    console.log(guests);

    useEffect(() => {
        loadRooms();
        if (mainGuest) {
            setGuestsPerRoom((prev) => {
                return {
                    ...prev,
                    mainGuest,
                };
            });
        }
    }, [mainGuest]);

    const isOptionDisabled = (optionValue, index) => {
        if (optionValue === mainGuest) return true;
        return Object?.entries(guestsPerRoom)?.some(
            ([key, room]) => Array.isArray(room) && room.includes(optionValue) && key != index
        );
    };

    const save = () => {
        let validations = {};

        if (!mainGuest) {
            toast.error(t("errors-in-red"));
            validations.mainGuest = true;
        } else {
            validations.mainGuest = false;
        }
        for (let i = 0; i < numberOfRooms; i++) {
            if (!roomsID?.[i]) {
                toast.error(t("errors-in-red"));
                validations.roomsID = {
                    ...validations.roomsID,
                    [i]: true,
                };
            } else {
                validations.roomsID = {
                    ...validations.roomsID,
                    [i]: false,
                };
            }
        }
        for (let i = 0; i < numberOfRooms; i++) {
            if (!mainGuest && !guestsPerRoom[i]) {
                toast.error(t("errors-in-red"));
                validations.guestsPerRoom = {
                    ...validations.guestsPerRoom,
                    [i]: true,
                };
            } else {
                validations.guestsPerRoom = {
                    ...validations.guestsPerRoom,
                    [i]: false,
                };
            }
        }
        setValidations(validations);
        const allGuestsSelected = guestOptions.every((option) =>
            Object.values(guestsPerRoom).flat().includes(option.value)
        );
        if (!allGuestsSelected) {
            toast.error(t("all-guest-must-be-selected"));
        }

        const rooms = Array.from({ length: numberOfRooms }, (_, i) => ({
            roomID: roomsID?.[i],
            guestIDs: mainGuest ? [...new Set([...(guestsPerRoom?.[i] || []), mainGuest])] : guestsPerRoom?.[i] || [],
        }));

        const allValidationsFalse =
            !validations.mainGuest &&
            !Object.values(validations.roomsID).some(Boolean) &&
            !Object.values(validations.guestsPerRoom).some(Boolean);

        if (allValidationsFalse) {
            addCheckin(id, mainGuest, rooms).then(() => {
                close();
                toast.success(t("operation-successful"));
                refetch();
            });
        }
    };
    return (
        <Modal
            id="check-in"
            title={`${code} - ${t("check-in")}`}
            className="w-2/7 p-10"
            onKeyPress={{ Enter: close }}
            footer={
                <div className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="cancel" design="white" onClick={close} className="font-bold">
                        {t("cancel")}
                    </Button>
                    <Button id="check-in" design="blue" onClick={save}>
                        {t("check-in")}
                    </Button>
                </div>
            }
        >
            <div className="w-1/2">
                <label className="font-bold">{t("select-the-main-guest")}*</label>
                <Select
                    id="select-main-guest"
                    placeholder={t("select-an-option")}
                    options={guestOptions}
                    allowUnselect={false}
                    onChange={(value) => setMainGuest(value)}
                    error={validations?.mainGuest}
                />
            </div>
            <div>
                {Array.from({ length: numberOfRooms }, (_, i) => (
                    <div className="mt-5" key={i}>
                        <h2 className="font-bold text-lg mb-3">
                            {t("room")} {i + 1}
                        </h2>
                        <div className="flex gap-5">
                            <div className="w-full">
                                <label className="font-bold mb-3">{t("guest-name")}*</label>
                                <Select
                                    id={`select-room-${i}`}
                                    placeholder={t("select-room")}
                                    multiple={true}
                                    searchable={true}
                                    options={guestOptions.map((option) => ({
                                        ...option,
                                        disabled: isOptionDisabled(option.value, i),
                                    }))}
                                    error={validations?.guestsPerRoom?.[i]}
                                    value={
                                        mainGuest
                                            ? [...new Set([...(guestsPerRoom[i]?.flat() || []), mainGuest])]
                                            : guestsPerRoom[i] || []
                                    }
                                    onChange={(value) => {
                                        setGuestsPerRoom((prev) => {
                                            return {
                                                ...prev,
                                                [i]: value,
                                            };
                                        });
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <label className="font-bold mb-3">{t("room-number")}*</label>
                                <Select
                                    id={`select-room-number-${i}`}
                                    placeholder={t("select-an-option")}
                                    options={rooms.map((option) => ({
                                        ...option,
                                        disabled:
                                            Object.values(roomsID || {}).includes(option.id) &&
                                            roomsID[i] !== option.id,
                                    }))}
                                    error={validations?.roomsID?.[i]}
                                    onChange={(value) =>
                                        setRoomsID((prev) => {
                                            return {
                                                ...prev,
                                                [i]: value,
                                            };
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export const CheckoutAction = ({ rows, refetch }) => {
    const { permissions } = useSelector((state) => state.ui);
    const rowsIds = rows?.map((row) => row?.id) || {};
    const { close } = useModal();
    const { checkOut } = useCheckout();
    const hasOrdersManagement = permissions?.services?.shopsOrders;
    const [hasWarnings, setHasWarnings] = useState(false);
    const { t } = useTranslation();
    const [checked, setChecked] = useState(
        rowsIds.reduce((acc, id) => {
            acc[id] = rows?.find((row) => row.id === id).roomID.map((room) => ({ checked: true, value: room }));
            return acc;
        }, {})
    );

    return (
        <Modal
            id="check-out"
            title={t("check-out")}
            minWidth="30rem"
            className="w-1/2 p-10"
            onKeyPress={{ Enter: close }}
            footer={
                <div className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="cancel" design="white" onClick={close} className="font-bold">
                        {t("cancel")}
                    </Button>
                    <Button
                        id="check-out"
                        design="blue"
                        onClick={() => {
                            const rooms = Object.keys(checked)
                                .map((id) => checked?.[id]?.filter((room) => room.checked).map((room) => room.value))
                                .flat();
                            if (rooms) {
                                checkOut(rooms).then(() => {
                                    close();
                                    toast.success(t("operation-successful"));
                                });
                                setTimeout(() => refetch(), 500);
                            } else {
                                toast.error(t("error-select-at-least-one"));
                            }
                        }}
                    >
                        {t("check-out")}
                    </Button>
                </div>
            }
        >
            <p className="mt-4">{t("are-you-sure-you-want-to-check-out")}</p>
            {rows?.map((row) => {
                return (
                    <div key={row?.id}>
                        <p className="my-4">{row?.roomName}</p>
                        {row?.guestRooms?.map((room, index) => (
                            <CheckoutRooms
                                id={row?.id}
                                roomID={room?.roomID}
                                name={row?.roomName}
                                number={room?.number}
                                setHasWarnings={setHasWarnings}
                                hasWarnings={hasWarnings}
                                setChecked={setChecked}
                            />
                        ))}
                    </div>
                );
            })}

            {rowsIds.some((id) => hasWarnings[id]) ? (
                <div className="w-full mt-5 flex items-center whitespace-no-wrap">
                    <span className="icon block ml-1 mr-2 text-2xl icon-warning text-orange-100"></span>
                    <span>
                        {hasOrdersManagement ? t("there-are-checkout-warnings") : t("there-are-failed-charges")}
                    </span>
                </div>
            ) : null}
        </Modal>
    );
};

export const CheckoutRooms = ({ id, number, name, roomID, setHasWarnings, setChecked, hasWarnings }) => {
    const { load: loadGuestOrders, data, error } = useGuestOrders({ commonVars: { lang: "es" } });
    const { t } = useTranslation();

    useEffect(() => {
        loadGuestOrders({ guestID: id });
    }, []);
    //hasOrdersManagement
    useEffect(() => {
        if (data) {
            const hasWarnings = data?.orders?.info?.count > 0;

            setHasWarnings((prev) => {
                return {
                    ...prev,
                    [id]: hasWarnings,
                };
            });
        }
    }, [data]);

    return (
        <>
            <div className="flex items-center gap-4 my-2">
                <Checkbox
                    id={`checkout-${name}`}
                    label={`${t("room")} ${number}`}
                    value={roomID}
                    onChange={(value) =>
                        setChecked((prev) => {
                            return {
                                ...prev,
                                [id]: Array.isArray(prev[id])
                                    ? prev[id]?.map((room) =>
                                          room.value === value.value ? { ...room, checked: !room.checked } : room
                                      )
                                    : [{ value, checked: true }],
                            };
                        })
                    }
                    checked={true}
                    className="ml-1"
                />
                {hasWarnings?.[id] && <Icon type="warning" size="3xl" tooltip={t("there-are-undelivered-orders")} />}
            </div>
            {error ? (
                <div className="flex items-start space-x-2 pt-5">
                    <Icon type="warning" size="3xl" />
                    <div>
                        {t("There are technical problems with Zafiro Sales")}
                        <br />
                        {t("There could be undelivered orders")}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export const DeleteUpcomingCheckIn = ({ id, code, refetch }) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const { deleteUpcomingCheckIn } = useDeleteUpcomingCheckIn();

    return (
        <Modal
            title={`${code} - ${t("delete")}`}
            className="w-1/3 p-10"
            footer={
                <div className="flex justify-end gap-2">
                    <Button id="modal-button-cancel" design="white" className="font-bold" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="modal-button-delete"
                        design="red"
                        onClick={() => {
                            deleteUpcomingCheckIn(id).then(() => {
                                toast.success(t("operation-successful"));
                                close();
                                refetch();
                            });
                        }}
                    >
                        {t("delete")}
                    </Button>
                </div>
            }
        >
            <p className="mt-3">{t("delete-pre-check-in-description")}</p>
        </Modal>
    );
};

export const DeleteBatchAction = forwardRef(({ rows, refetch }, ref) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const { deleteUpcomingCheckIns } = useDeleteUpcomingCheckIns();
    const ids = rows?.map((row) => row?.id);

    return (
        <Modal
            id="delete"
            title={t("delete")}
            minWidth="30rem"
            className="w-1/2 p-10"
            footer={
                <div className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="cancel" design="white" onClick={close} className="font-bold">
                        {t("cancel")}
                    </Button>
                    <Button
                        id="delete"
                        design="red"
                        onClick={() => {
                            deleteUpcomingCheckIns(ids).then(() => {
                                toast.success(t("operation-successful"));
                                close();
                                refetch();
                                if (ref?.current) {
                                    ref.current.resetSelectedRows();
                                }
                            });
                        }}
                    >
                        {t("delete")}
                    </Button>
                </div>
            }
            onKeyPress={{ Enter: close }}
        >
            <div className="my-3">
                <p>{t("delete-pre-check-in-confirm")}</p>
                <div className="flex gap-3 mt-3">
                    {rows?.map((row) => (
                        <p key={row?.id} className="px-6 py-1 bg-gray-200 text-gray-900 rounded">
                            {row?.bookingCode}
                        </p>
                    ))}
                </div>
            </div>
        </Modal>
    );
});

export const ResendCheckIn = ({ id, code }) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const { resendSelfCheckIn } = useResendPreCheckIn();

    return (
        <Modal
            title={`${code} - ${t("resend-pre-check-in")}`}
            className="w-1/3 p-10"
            footer={
                <div className="flex justify-end gap-2">
                    <Button id="modal-button-cancel" design="white" className="font-bold" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="modal-button-resend"
                        design="blue"
                        onClick={() => {
                            resendSelfCheckIn(id).then(() => {
                                toast.success(t("operation-successful"));
                                close();
                            });
                        }}
                    >
                        {t("resend")}
                    </Button>
                </div>
            }
        >
            <p className="mt-3">{t("resend-pre-check-in-description")}</p>
        </Modal>
    );
};

export const AddBooking = ({ refetch }) => {
    const { t } = useTranslation();
    const { open } = useModal();

    return (
        <Button id="add-booking-button" design="blue" onClick={() => open(<AddBookingModal refetch={refetch} />)}>
            {t("add-booking")}
        </Button>
    );
};

const AddBookingModal = ({ refetch }) => {
    const [formState, setFormState] = useState({
        pmsID: "",
        code: "",
        stayHolderName: "",
        stayHolderSurname: "",
        stayHolderEmail: "",
        numberOfRooms: 1,
        numberOfGuests: 1,
        arrivalDate: "",
        departureDate: "",
    });

    const handleChange = (field, value) => {
        setFormState((prev) => ({ ...prev, [field]: value }));
    };

    const [validations, setValidations] = useState({
        code: false,
        stayHolderName: false,
        stayHolderSurname: false,
        stayHolderEmail: false,
        numberOfRooms: false,
        numberOfGuests: false,
        arrivalDate: false,
    });

    const { t } = useTranslation();
    const { close } = useModal();
    const { addBooking } = useAddBooking();

    const formatDate = (value) =>
        `${value.getFullYear()}-${(value.getMonth() + 1).toString().padStart(2, "0")}-${value
            .getDate()
            .toString()
            .padStart(2, "0")}`;

    const save = () => {
        const setTimeToOneAM = (date) => {
            const newDate = new Date(date);
            newDate.setHours(1, 0, 0, 0);
            return newDate;
        };
        const newValidations = {
            code: !formState.code,
            stayHolderName: !formState.stayHolderName,
            stayHolderSurname: !formState.stayHolderSurname,
            stayHolderEmail: !formState.stayHolderEmail,
            arrivalDate: !formState.arrivalDate || new Date(formState.arrivalDate) < setTimeToOneAM(new Date()),
        };

        setValidations(newValidations);

        if (Object.values(newValidations).every((v) => !v)) {
            addBooking(
                formState.code,
                formState.numberOfRooms,
                formState.numberOfGuests,
                formState.arrivalDate,
                formState.departureDate || null,
                formState.stayHolderSurname,
                formState.stayHolderName,
                formState.stayHolderEmail,
                formState.pmsID
            )
                .then((response) => {
                    if (response?.data?.createStayBooking?.ok) {
                        close();
                        toast.success(t("operation-successful"));
                        refetch();
                    }
                })
                .catch((err) => {
                    toast.error(t(err));
                });
        } else {
            toast.error(t("errors-in-red"));
        }
    };

    return (
        <Modal
            id="add-booking-modal"
            title={t("add-booking")}
            minWidth="30rem"
            className="w-1/3 p-10"
            footer={
                <div className="flex justify-end gap-3">
                    <Button id="cancel-booking" design="white" className="font-bold" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button id="add-booking" design="blue" onClick={save}>
                        {t("add")}
                    </Button>
                </div>
            }
        >
            <p className="my-3">{t("complete-the-following-information")}</p>
            <div className="grid grid-cols-2 gap-6">
                {[
                    { label: "pms-id", field: "pmsID", placeholder: "pms-example" },
                    {
                        label: "booking-code",
                        field: "code",
                        placeholder: "booking-example",
                        required: true,
                        id: "add-booking-code",
                    },
                    { label: "name", field: "stayHolderName", placeholder: "name", required: true },
                    { label: "surname", field: "stayHolderSurname", placeholder: "surname", required: true },
                    {
                        label: "email-address",
                        field: "stayHolderEmail",
                        placeholder: "email-example",
                        type: "email",
                        required: true,
                        id: "add-booking-email",
                    },
                    { label: "rooms-reserved", field: "numberOfRooms", type: "number", min: 1, required: true },
                    { label: "number-of-guests", field: "numberOfGuests", type: "number", min: 1, required: true },
                    { label: "arrival-date", field: "arrivalDate", type: "date", required: true },
                    { label: "departure-date", field: "departureDate", type: "date" },
                ].map(({ label, field, placeholder, type = "text", min, required, id }) => (
                    <div key={field} className={field === "stayHolderEmail" ? "col-span-2" : ""}>
                        <label className="font-bold">
                            {t(label)}
                            {required && "*"}
                        </label>
                        {type === "number" ? (
                            <div className="flex items-center gap-2">
                                <NumberInput
                                    id={id || `add-booking-${label}`}
                                    min={min}
                                    value={formState[field]}
                                    onChange={(value) => handleChange(field, value)}
                                />
                                <span>{t(field === "numberOfRooms" ? "rooms" : "guests")}</span>
                            </div>
                        ) : type === "date" ? (
                            <DateInput
                                id={id || `add-booking-${label}`}
                                placeholder={t(placeholder)}
                                onChange={(value) => handleChange(field, value && formatDate(value))}
                                className={validations[field] && "border border-red-100"}
                            />
                        ) : (
                            <TextInput
                                id={id || `add-booking-${label}`}
                                className={validations[field] && "border border-red-100"}
                                type={type}
                                placeholder={t(placeholder)}
                                onChange={(value) => handleChange(field, value)}
                            />
                        )}
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export const GuestActions = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { project } = useAuth();

    const isSuperUser = Session.isSuperUser();

    if (!project.blockGuestManagement) {
        return (
            <div className="p-3">
                {!isSuperUser ? (
                    <Button
                        id="new-guest-button"
                        design="blue"
                        onClick={() => navigate(`/customers/guests/guest-list/check-in`)}
                    >
                        {t("check-in")}
                    </Button>
                ) : (
                    <DropdownButton
                        id="check-in-dropdown"
                        handlerPadding="px-4 py-2"
                        label={{
                            id: "new-guest-button",
                            label: t("check-in"),
                            onClick: () => navigate(`/customers/guests/guest-list/check-in`),
                        }}
                        options={[<BulkCheckInOption key="bulk-check-in" />]}
                    />
                )}
            </div>
        );
    }

    return null;
};

const BulkCheckInOption = () => {
    const { t } = useTranslation();
    const { open: openModalBulkCheckin } = useModalBulkCheckin();

    return (
        <Button
            id="bulk-check-in-option"
            onClick={() => {
                openModalBulkCheckin();
            }}
            className="px-4 py-2 flex items-center cursor-pointer "
        >
            <Icon className=" mr-2" type="superuser" size={1.4} />
            {t("bulk-check-in")}
        </Button>
    );
};
