import { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { GET_WARNINGS_INFO } from "../services/getMonitorInfo";
import { useTranslation } from "react-i18next";

export const useMonitorWarnings = () => {
    const { t } = useTranslation();
    const [warnings, setWarnings] = useState([]);

    const [executeGetInfoQuery, { data, called, loading, networkStatus }] = useLazyQuery(GET_WARNINGS_INFO, {
        pollInterval: 200000,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeGetInfoQuery();
    }, []);

    useEffect(() => {
        const info = data?.getMonitorWarnings;
        if (!loading && info && networkStatus === 7) {
            setWarnings(warningModel(info, t));
        }
    }, [data]);

    return { warnings, called, loading };
};

const warningModel = (info, t) => {
    const { areCriticalNetworkWarnings, criticalWarnings } = info;
    const {
        castMdnsWarning,
        castNeverDiscoveredWarning,
        castProxyWarning,
        castRoutingToUserWarning,
        internetBandwidthWarning,
        otherDhcpServersWarning,
        userRoutingToCastWarning,
        usersConnectivityWarning,
        usersMdnsWarning,
        castingProblemWarning,
    } = criticalWarnings;

    return {
        areCriticalNetworkWarnings,
        castMdns: {
            alert: castMdnsWarning?.alert,
            text: t("mdns-inactive", { network: "cast" }),
            lastUpdate: castMdnsWarning?.lastUpdate,
        },
        userMdns: {
            alert: usersMdnsWarning?.alert,
            text: t("mdns-inactive", { network: "user" }),
            lastUpdate: usersMdnsWarning?.lastUpdate,
        },
        otherDchp: {
            alert: otherDhcpServersWarning?.alert,
            text: t("other-dhcp-found"),
            value: otherDhcpServersWarning?.otherServers,
            secondText: t("devices-not-function-properly"),
            lastUpdate: otherDhcpServersWarning?.lastUpdate,
        },
        userConnectivity: {
            alert: usersConnectivityWarning?.alert,
            text: t("arp-proxy-or-hotspot-mac", { network: "user", proxy: "ARP" }),
            secondaryText: t("hotspot-case"),
            lastUpdate: usersConnectivityWarning?.lastUpdate,
            firstValue: usersConnectivityWarning?.arpMacs,
            secondValue: usersConnectivityWarning?.serverIPS,
            thirdValue: usersConnectivityWarning?.serverMacs,
        },
        castProxy: {
            alert: castProxyWarning?.alert,
            text: t("mdns-proxy-problem"),
            lastUpdate: castProxyWarning?.lastUpdate,
        },
        castNeverDiscovered: {
            alert: castNeverDiscoveredWarning?.alert,
            text: t("ccs-never-discovered-warning", { ccNumber: castNeverDiscoveredWarning?.ccsNumber }),
            lastUpdate: castNeverDiscoveredWarning?.lastUpdate,
        },
        castRoutingToUser: {
            alert: castRoutingToUserWarning?.alert,
            text: t("cast-routing-to-user"),
            lastUpdate: castRoutingToUserWarning?.lastUpdate,
        },
        userRoutingToCast: {
            alert: userRoutingToCastWarning?.alert,
            text: t("user-routing-to-cast"),
            lastUpdate: userRoutingToCastWarning?.lastUpdate,
        },
        internetBandwidth: {
            alert: internetBandwidthWarning?.alert,
            text: t("internet-bandwidth-warning"),
            lastUpdate: internetBandwidthWarning?.lastUpdate,
        },
        shareContent: {
            alert: castingProblemWarning?.alert,
            text: `${t("pairing-process-not-possible")}. ${t("users-connected-dont-have-access-to-server")}`,
            lastUpdate: castingProblemWarning?.lastUpdate,
        },
    };
};
