import React from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";
import { useAuth } from "hooks/Session/auth";
import Dropdown from "components/Dropdown";
import classNames from "classnames";
import { TAG_MOBILE_APP, TAG_WIFI_PORTAL } from "components/Tag";

export const Accesses = ({ email, accesses, projects, properties }) => {
    const { t } = useTranslation();
    const { close } = useModal();

    const getAcceses = () => {
        if (!accesses?.length) {
            return [
                {
                    type: t("check-in"),
                    accesses: properties?.map((property) => ({ name: property.name, prefix: t("check-in") })),
                    prefix: t("check-in"),
                },
            ];
        }

        const groupedAccesses = accesses.reduce((acc, access) => {
            const project = projects.find((project) => project.ref === access.wifi || project.ref === access.app);
            const name = project?.name || access.wifi || access.app;
            const type = access.wifi ? TAG_WIFI_PORTAL : access.app ? TAG_MOBILE_APP : t("check-in");
            const prefix = access.wifi ? t("wifi-name") : access.app ? t("mobile-app") : t("check-in");

            if (!acc[type]) {
                acc[type] = { type, accesses: [], prefix };
            }

            if (!access.wifi && !access.app) {
                if (properties?.length) {
                    // eslint-disable-next-line no-unused-expressions
                    properties?.forEach((property) => {
                        acc[type].accesses.push({ name: property.name, prefix: t("check-in") });
                    });
                }
            } else {
                if (!acc[type].accesses.some((access) => access.name === name)) {
                    acc[type].accesses.push({ name, prefix });
                }
            }

            return acc;
        }, {});

        return Object.values(groupedAccesses);
    };

    const accessData = getAcceses();

    return (
        <Modal
            id="more-accesses"
            title={`${email} - ${t("accesses")}`}
            onKeyPress={{ Enter: close }}
            footer={
                <div className="flex justify-end space-x-4">
                    <Button id="cancel" design="blue" onClick={close}>
                        {t("close")}
                    </Button>
                </div>
            }
        >
            <p>{t("customer-performed-accesses")}</p>
            <div className="flex items-center justify-between mt-3">
                <p className="font-bold">{t("accesses")}</p>
                <p>{t(accessData.length > 1 ? "x acceses_other" : "x acceses_one", { accesses: accessData.length })}</p>
            </div>
            <div className="border border-gray-200 rounded py-2 px-4 mt-3">
                <AccessesList accesses={accessData} />
            </div>
        </Modal>
    );
};

const AccessesList = ({ accesses }) => {
    const { isCorporate } = useAuth();
    return (
        <ul
            className={classNames({
                "px-2 max-h-30 overflow-auto": true,
            })}
        >
            {accesses.map((ac) => {
                const type = ac?.type;
                const id = type?.prefix;
                const prefix = ac?.prefix;

                return (
                    <li
                        key={id}
                        className={classNames({
                            "py-3": true,
                            "border-t border-gray-200": accesses?.length > 1 && accesses[0] !== ac,
                        })}
                    >
                        {isCorporate ? (
                            <Dropdown
                                id="accesses"
                                autoClose={false}
                                arrowPosition="left"
                                handler={<strong>{prefix}</strong>}
                                designClass={{
                                    dropdown: "justify-start space-x-2",
                                }}
                            >
                                <div className="mt-2 pl-6">
                                    {ac?.accesses.map((access) => (
                                        <div
                                            key={access.name}
                                            className={`py-3 ${
                                                ac.accesses.length > 1 ? "border-b border-gray-200" : ""
                                            }`}
                                        >
                                            <p>
                                                {prefix} {access.name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </Dropdown>
                        ) : (
                            <p>{prefix}</p>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};
