import React from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import Table from "components/ZafiroTable";
import Loading from "components/Loading";

const ModalDuplicate = ({ batch, batchList, id, name, close, type, useDuplicateHook, callback }) => {
    const { t } = useTranslation();
    const { duplicate, loading } = useDuplicateHook({
        onCompleted: () => {
            callback();
            close();
        },
    });
    const handleSaveClick = () => {
        const ids = batch ? batchList.map((item) => item.id) : [id];
        duplicate({ variables: { ids } });
    };

    const modalTitle = batch ? t("duplicate") : `${name} - ${t("duplicate")}`;
    const confirmationMessage = batch
        ? t(`are-you-sure-you-want-to-duplicate-the-following-${type}s`)
        : t(`are-you-sure-you-want-to-duplicate-this-${type}`);

    const footer = (
        <>
            <Button design="blue-outline" id="modal-button-cancel" onClick={close} disabled={loading}>
                {t("cancel")}
            </Button>
            <Button design="blue" id="modal-button-save" onClick={handleSaveClick} disabled={loading}>
                {t("duplicate")}
            </Button>
        </>
    );

    return (
        <Modal title={modalTitle} footer={footer} className="w-5/12 p-10">
            {loading ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-2">
                    <p>{confirmationMessage}</p>
                    <div className="flex gap-2">
                        <Icon size="2xl" type="info" />
                        <p>{t(`please-note-that-only-the-content-will-be-duplicated-${type}`)}</p>
                    </div>
                    {batch && (
                        <Table
                            id={`${type}-duplicate-batch-table`}
                            cols={["name"]}
                            className="border border-gray-100 p-2"
                            maxHeight="max-h-30"
                            header={{ name: { title: "" } }}
                            rows={batchList}
                        />
                    )}
                </div>
            )}
        </Modal>
    );
};

export const UseModalDuplicate = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            open(<ModalDuplicate {...props} close={close} />);
        },
        close,
    };
};
