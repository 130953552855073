import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Loading from "components/Loading";
import { useUpdatePinsInterestPoint } from "../../graphql/useMaps";

const ModalDeletePinPointOfInterest = ({ refetch, variables, close, name, onCompleted }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const { updatePinsInterestPoint } = useUpdatePinsInterestPoint({
        onCompleted: () => {
            setLoading(false);
            toast.success(t("operation-successful"));
            if (close) {
                close();
            }

            if (onCompleted) {
                onCompleted();
            }

            if (refetch) {
                refetch();
            }
        },
        onError: (err) => {
            setLoading(false);
            toast.error(err.message || t("unknown-error"));
            if (close) {
                close();
            }
            if (refetch) {
                refetch();
            }
        },
    });

    const handleClose = () => {
        if (close) {
            close();
        }
    };

    const handleDeletePin = () => {
        setLoading(true);
        updatePinsInterestPoint({ variables });
    };

    return (
        <Modal
            id="modal-delete-pin-point-of-interest"
            title={`${name} - ${t("delete")} pin`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleClose}>
                        {t("cancel")}
                    </Button>
                    <Button design="red" id="modal-button-delete" disabled={loading} onClick={handleDeletePin}>
                        {t("delete")}
                    </Button>
                </>
            }
            className="w-4/12 p-10"
        >
            {loading ? (
                <Loading />
            ) : (
                <div className="flex flex-col pb-8">{t("are-you-sure-you-want-to-delete-this-pin")}</div>
            )}
        </Modal>
    );
};

export const useModalDeletePinPointOfInterest = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            open(<ModalDeletePinPointOfInterest {...props} close={close} />);
        },
    };
};
