import { TAG_MOBILE_APP, TAG_WIFI_PORTAL, TAG_CHECK_IN } from "components/Tag";

export const getOrigin = (projectRef, appRef, portalRef, t, mobileApps, projects) => {
    const getAppDetails = (appRef) => {
        const app = mobileApps?.find((app) => app.url === appRef);
        return {
            type: TAG_MOBILE_APP,
            name: app?.shortName || appRef,
            icon: {
                access: { name: "mobile", size: "2xl", className: "font-bold" },
                signup: app ? undefined : { name: "warning", size: "2xl" },
            },
            tooltip: {
                access: t("mobile-app"),
                signup: app ? undefined : t("unknown"),
            },
        };
    };

    const getPortalDetails = () => {
        const name = getProjectName(portalRef, projects) || portalRef;
        return {
            type: TAG_WIFI_PORTAL,
            name: `${t("wifi-name")} ${name}`,
            icon: { access: { name: "Wifi-access", size: "2xl" } },
            tooltip: { access: t("wifi-name") },
        };
    };

    const getCheckInDetails = (projectRef) => {
        const name = getProjectName(projectRef, projects) || projectRef || "";
        return {
            type: TAG_CHECK_IN,
            prefix: t("check-in"),
            name: `${t("check-in")} ${name}`,
            icon: name === projectRef ? "warning" : undefined,
        };
    };

    if (appRef) return getAppDetails(appRef);
    if (portalRef) return getPortalDetails();
    return getCheckInDetails(projectRef);
};

export const getProjectName = (projectRef, projects) => {
    return projectRef && Array.isArray(projects)
        ? projects?.find((project) => project.ref === projectRef)?.name
        : projects?.name;
};
