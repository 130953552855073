import { useState, useEffect } from "react";
import { getScreensOptions } from "../utils/utils";
import { useGetOptionsPredefinedSection } from "./useDestinationGraphCalls";
import { UseContentTree } from "components/Section/Design/Advertising/graphql/useSignages";
import { UseListLibraryContentsAndDesigns } from "components/Section/Design/Advertising/graphql/useSignages";

export default function useDestinationData() {
    const { getContentTree, contentTree, loading: loadingContentTree } = UseContentTree();

    const { optionsPredefinedSection, loadingOptionsPredefinedSection, mapsOptions } = useGetOptionsPredefinedSection();

    const { designsLocalAssigned, designsAssignedFromCorporate, getLibraryContentsAndDesigns } =
        UseListLibraryContentsAndDesigns();

    const [idDesignApplied, setIdDesignApplied] = useState("");
    const [screensOptions, setScreensOptions] = useState([]);

    useEffect(() => {
        getLibraryContentsAndDesigns();
    }, [getLibraryContentsAndDesigns]);

    useEffect(() => {
        if (designsLocalAssigned.length > 0) {
            setIdDesignApplied(designsLocalAssigned[0].id);
        } else if (designsAssignedFromCorporate.length > 0) {
            setIdDesignApplied(designsAssignedFromCorporate[0].id);
        }
    }, [designsLocalAssigned, designsAssignedFromCorporate]);

    useEffect(() => {
        if (idDesignApplied) {
            getContentTree({ variables: { designID: idDesignApplied } });
        }
    }, [idDesignApplied, getContentTree]);

    useEffect(() => {
        if (contentTree) {
            setScreensOptions(getScreensOptions(contentTree));
        }
    }, [contentTree]);

    return {
        screensOptions,
        mapsOptions,
        loadingScreens: loadingContentTree,
        predefinedOptions: optionsPredefinedSection,
        loadingPredefined: loadingOptionsPredefinedSection,
    };
}
