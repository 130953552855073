import React from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";

export const Projects = ({ customer, propertiesByCustomer }) => {
    const { t } = useTranslation();
    const { close } = useModal();

    return (
        <Modal
            id="properties"
            title={`${customer?.email} - ${t("properties")}`}
            onKeyPress={{ Enter: close }}
            footer={
                <div className="flex justify-end space-x-4">
                    <Button id="cancel" design="blue" onClick={close}>
                        {t("close")}
                    </Button>
                </div>
            }
        >
            <p>{t("customer-performed-properties")}</p>
            <div className="flex items-center justify-between mt-3">
                <p className="font-bold">{t("properties")}</p>
                <p>{t("x properties", { count: propertiesByCustomer?.length || 0 })}</p>
            </div>
            <div className="border border-gray-200 rounded py-2 px-4 mt-3 max-h-30 overflow-y-auto">
                <ul>
                    {propertiesByCustomer.map((property, index) => (
                        <li
                            key={property.id}
                            className={`py-4 px-2 ${
                                index !== propertiesByCustomer.length - 1 ? "border-b border-gray-200" : ""
                            }`}
                        >
                            {property.name}
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    );
};
