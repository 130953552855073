import React, { useEffect, useState } from "react";
import DraggableListItem from "components/DraggableListItem";
import SortableList from "components/SortableList";
import { useParams } from "react-router-dom";
import { useMap } from "../../MapContext";
import { useTranslation } from "react-i18next";
import { getParseMapName } from "../../../utils";
import { useAuth } from "hooks/Session/auth";
import { useModalEditName } from "../../../modals/useModalEditName";
import { useModalEditCategory } from "../../../modals/categories/useModalEditCategory";
import { useDeleteCategory, useUpdateCategories, useSetCategoryOrderInMap } from "../../../graphql/useCategories";
import Icon from "components/Icon";
import { useModalDelete } from "../../../modals/useModalDelete";
import { CATEGORY_TYPE } from "../../../constants";
import classNames from "classnames";

const SideBarCategories = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { categories, refetchCategories, dataInterestPoints, setSectionLoading, manageInterestPoints } = useMap();
    const { handleHighlightPointOfInterest } = manageInterestPoints;
    const [items, setItems] = useState([]);
    const { languages } = useAuth();

    const { open: openModalEditNameCategory } = useModalEditName();
    const { open: openModalDeleteCategory } = useModalDelete();
    const { open: openModalEditCategory } = useModalEditCategory();
    const { setCategoryOrder } = useSetCategoryOrderInMap({
        onCompleted: () => {
            setSectionLoading(false);
            refetchCategories();
        },
        onError: () => {
            setSectionLoading(false);
            refetchCategories();
        },
    });

    const defaultLanguage = languages?.find((l) => l.isDefault)?.languageRef;

    useEffect(() => {
        if (categories?.length) {
            setItems(normalizeCategories(categories));
        } else {
            setItems([]);
        }
    }, [categories]);

    function normalizeCategories(data) {
        return data
            .slice()
            .sort((a, b) => a.pos - b.pos)
            .map(({ id: catID, name, pos, mapCategoryInterestPoints }) => {
                const normalizedLabel = getParseMapName(name, defaultLanguage);
                return {
                    id: catID,
                    label: normalizedLabel,
                    addonClassName: "icon-category text-2xl",
                    customAction: (
                        <div
                            onMouseLeave={() => {
                                if (mapCategoryInterestPoints?.length) {
                                    handleHighlightPointOfInterest([], false);
                                }
                            }}
                            onMouseOver={() => {
                                if (mapCategoryInterestPoints?.length) {
                                    handleHighlightPointOfInterest(
                                        mapCategoryInterestPoints.map((poi) => poi.mapInterestPointID),
                                        true
                                    );
                                }
                            }}
                        >
                            <Icon
                                className={classNames({
                                    "cursor-pointer": mapCategoryInterestPoints.length,
                                    "text-gray-500": mapCategoryInterestPoints.length == 0,
                                })}
                                type={"target"}
                                size={1.5}
                            ></Icon>
                        </div>
                    ),
                    pos,
                    actions: [
                        {
                            id: "edit-category",
                            label: t("edit-category"),
                            onClick: () => {
                                openModalEditCategory({
                                    name: normalizedLabel,
                                    interestPoints: dataInterestPoints,
                                    selected: mapCategoryInterestPoints,
                                    id: catID,
                                    mapId: id,
                                    fetch: refetchCategories,
                                });
                            },
                        },
                        {
                            id: "edit-name",
                            label: t("edit-name"),
                            onClick: () => {
                                openModalEditNameCategory({
                                    name: normalizedLabel,
                                    names: name,
                                    fetch: refetchCategories,
                                    useUpdate: useUpdateCategories,
                                    mapId: id,
                                    id: catID,
                                });
                            },
                        },
                        {
                            id: "delete-category",
                            label: t("delete"),
                            onClick: () => {
                                openModalDeleteCategory({
                                    fetch: refetchCategories,
                                    useDelete: useDeleteCategory,
                                    type: CATEGORY_TYPE,
                                    id: catID,
                                    mapId: id,
                                    name: normalizedLabel,
                                });
                            },
                        },
                    ],
                };
            });
    }

    const handleSortEnd = ({ movedItem, newIndex }) => {
        setSectionLoading(true);
        setCategoryOrder({
            variables: { mapID: id, id: movedItem.id, pos: newIndex + 1 },
        });
    };

    return (
        <div className="relative h-full w-full flex flex-col">
            <SortableList
                items={items}
                lock="y"
                lockToContainerEdges
                SortableItemComponent={DraggableListItem}
                onSortEnd={handleSortEnd}
            />
        </div>
    );
};

export default SideBarCategories;
