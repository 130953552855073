export const SET_ACTIVE_HOTEL = "SET_ACTIVE_HOTEL";
export const CLEAR_PROJECT_REF = "CLEAR_PROJECT_REF";
export const SET_PROJECT_REF = "SET_PROJECT_REF";

export function setActiveHotel(currentHotelData) {
    //Action to load active hotel data in hotel store (id,ref,name,location)
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_HOTEL,
            payload: currentHotelData,
        });
    };
}

export function clearProjectRef() {
    // clears store.hotel.ref
    return (dispatch) => {
        dispatch({
            type: CLEAR_PROJECT_REF,
        });
    };
}
