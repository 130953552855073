import React, { useState } from "react";

import { Header, Examples, getRandomIcon } from "sections/playground";

import Icon from "components/Icon";
import Select from "components/Select";

import Button from "components/Button";

const SampleText = (id) => {
    switch (Number(id)) {
        case 1:
            return "Spaghetti";
        case 2:
            return "Grilled chicken with vegetables";
        case 3:
            return "Sed do eiusmodtemporincididuntutlabore et dolore magna aliqua.";
        case 4:
            return "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";
        case 11:
            return "The quick brown fox jumps over the lazy dog";
        case 33:
            return "I was born in a small town named Llanfairpwllgwyngyllgogerychwyrndrobwllllantysiliogogogoch";
        default:
            return `Option ${id}`;
    }
};

export const TYPES = [
    {
        id: "SELECT",
        title: "Select",
        variants: ["SINGLE", "MULTIPLE", "NEW_VALUES"],
        examples: ["DEFAULT", "DETAILED", "GROUPS", "SEARCH", "ICON", "DISABLED", "ERROR", "WARNING", "COLORS"],
    },
    {
        id: "SIZES",
        title: "Sizes",
        variants: ["SINGLE"],
        examples: ["SMALL", "REGULAR", "MEDIUM", "LARGE", "XLARGE"],
    },
    {
        id: "TESTS",
        title: "Tests",
        variants: ["Dynamic options", "Disabled options selected", "Custom options", "Dropdown always down"],
    },
];

const Dropdowns = ({ section }) => {
    return (
        <>
            <Header title="Dropdowns">
                Select gives the ability to make a single or multiple selections from different options.
            </Header>
            <Examples options={TYPES?.filter((s) => !section || s?.id === section)} display={DisplayExample} />
        </>
    );
};

const DisplayExample = ({ type, variant, example }) => {
    const id = `${type.id}-${variant}-${example}`;

    if (type.id === "TESTS") {
        if (variant === "Dropdown always down") {
            return (
                <Select
                    id={id}
                    options={singleOptions()}
                    onChange={(value) => console.log("Selected option:", value)}
                    className="mt-10"
                />
            );
        } else if (variant === "Disabled options selected") {
            return <DisabledOptions id={id} variant={variant} example={example} />;
        } else if (variant === "Dynamic options") {
            return <DynamicOptions id={id} variant={variant} example={example} />;
        } else {
            return (
                <Select id={id} onChange={(value) => console.log("Selected option:", value)}>
                    <div className="text-purple-200 p-1 px-5 bg-purple-700 hover:text-white cursor-pointer" value="A">
                        Option A
                    </div>
                    <div className="h-20 cursor-pointer p-1 bg-pink-300 hover:bg-pink-400" value="IMG-1">
                        <img src="https://picsum.photos/600/100" alt="random" className="w-fit-content h-full" />
                    </div>
                    <div className="cursor-pointer bg-black p-5 hover:bg-gray-800" value="IMG-2">
                        <img src="https://picsum.photos/120/68" alt="random" className="w-fit-content h-full" />
                    </div>
                    <div className="cursor-pointer hover:text-blue-200 p-3 px-5" value="B">
                        Option B
                    </div>
                    <div className="cursor-pointer hover:text-blue-200 p-3 px-5" value="C">
                        Option C
                    </div>
                </Select>
            );
        }
    }

    return <SelectExample id={id} variant={variant} example={example} />;
};

const SelectExample = ({ id, variant, example }) => {
    const disabled = example === "DISABLED";
    const allowCustomOptions = variant === "NEW_VALUES";
    const search = example === "SEARCH" ? true : undefined;
    const multiple = variant === "MULTIPLE" || allowCustomOptions;
    const detailed = example === "DETAILED";
    const error = example === "ERROR";
    const warning = example === "WARNING" ? "This field is important" : "";
    const customColor = example === "COLORS";

    const small = example === "SMALL";
    const regular = example === "REGULAR";
    const medium = example === "MEDIUM";
    const large = example === "LARGE";
    const xlarge = example === "XLARGE";

    const size = small
        ? "small"
        : medium
        ? "medium"
        : large
        ? "large"
        : xlarge
        ? "xlarge"
        : regular
        ? "regular"
        : undefined;

    const options = (() => {
        if (example === "GROUPS" || example === "SEARCH") {
            return groupOptions();
        }
        if (example === "ICON") {
            return iconOptions();
        }
        return singleOptions();
    })();

    const value = (detailed || customColor) && multiple ? [1, 2, 3, 4] : null;

    return (
        <Select
            id={id}
            search={search}
            disabled={disabled}
            multiple={multiple}
            allowCustomOptions={allowCustomOptions}
            customOptionFn={(value) => {
                return { value, label: SampleText(value) };
            }}
            showSelectedItems={customColor || detailed}
            options={options}
            value={value}
            size={size || "regular"}
            placeholder={example === "DEFAULT" && variant === "MULTIPLE" ? "Custom placeholder" : undefined}
            onChange={(value) => console.log("Selected value: ", value)}
            error={error}
            warning={warning}
            labelColor={customColor ? "bg-pink-500" : undefined}
        />
    );
};

const DynamicOptions = ({ id, variant, example }) => {
    const [testOptions, setTestOptions] = useState(null);
    return (
        <div className="flex space-x-3">
            <Button
                id={id}
                design={testOptions ? "red" : "blue-outline"}
                onClick={() => {
                    setTestOptions(
                        testOptions
                            ? null
                            : [
                                  {
                                      label: "Estadísticas de Cast",
                                      value: "statsCast",
                                  },
                                  {
                                      label: "estadísticas de WiFi",
                                      value: "statsWifi",
                                  },
                                  {
                                      label: "estadísticas de Mobile",
                                      value: "statsMobile",
                                  },
                                  {
                                      label: "estadísticas de ventas",
                                      value: "statsSales",
                                  },
                              ]
                    );
                }}
            >
                {testOptions ? "Clear options" : "Fill options"}
            </Button>
            <Select
                id={"dynamic-test"}
                multiple={true}
                value={testOptions ? ["statsCast"] : ["fakeValue"]}
                options={testOptions}
                onChange={(value) => console.log("TEST ON CHANGE value: ", value)}
            />
        </div>
    );
};

const DisabledOptions = ({ id, variant, example }) => {
    return (
        <div className="flex space-x-3">
            <Select
                id={"automatic-test"}
                multiple={true}
                value={["C", "F", "B", "H"]}
                options={[
                    { label: "Apple", value: "A" },
                    { label: "Banana", value: "B", disabled: true },
                    { label: "Cloud", value: "C" },
                    { label: "Duck", value: "D" },
                    { label: "Elephant", value: "E" },
                    { label: "Frog", value: "F" },
                    { label: "Giraffe", value: "G", disabled: true },
                    { label: "Horse", value: "H" },
                    { label: "Insect", value: "I" },
                    { label: "Jellyfish", value: "J" },
                    { label: "Kangaroo", value: "K" },
                    { label: "Lion", value: "L" },
                    { label: "Monkey", value: "M" },
                    { label: "Nest", value: "N", disabled: true },
                    { label: "Octopus", value: "O", disabled: true },
                    { label: "Penguin", value: "P" },
                    { label: "Quail", value: "Q" },
                    { label: "Rabbit", value: "R" },
                    { label: "Snake", value: "S" },
                    { label: "Tiger", value: "T" },
                    { label: "Umbrella", value: "U" },
                    { label: "Vulture", value: "V" },
                    { label: "Whale", value: "W" },
                    { label: "Xylophone", value: "X" },
                    { label: "Yak", value: "Y" },
                    { label: "Zebra", value: "Z" },
                ]}
                onChange={(value) => console.log("TEST ON CHANGE value: ", value)}
            />
        </div>
    );
};

const singleOptions = () =>
    new Array(5).fill(0).map((_, i) => ({
        label: SampleText(i + 1),
        value: i + 1,
    }));

const iconOptions = () =>
    new Array(2).fill(0).map((_, i) => ({
        label: `Header ${i + 1}`,
        options: new Array(3).fill(0).map((_, j) => ({
            id: `group-${i + 1}-option-${j + 1}`,
            label: (
                <div
                    className="flex items-start justify-start space-x-2 leading-none"
                    alt={SampleText(`${i + 1}${j + 1}`)}
                >
                    <Icon type={getRandomIcon()} />
                    <div>
                        <div className="leading-4">{SampleText(`${i + 1}${j + 1}`)}</div>
                    </div>
                </div>
            ),
            value: `${i + 1}${j + 1}`,
        })),
    }));

const groupOptions = () =>
    new Array(5).fill(0).map((_, i) => ({
        label: `Header ${i + 1}`,
        options: new Array(3).fill(0).map((_, j) => ({
            id: `group-${i + 1}-option-${j + 1}`,
            label: SampleText(`${i + 1}${j + 1}`),
            value: `${i + 1}${j + 1}`,
        })),
    }));

export default Dropdowns;
