import { useState } from "react";
import { GET_POINTS_OF_INTEREST } from "./queries/pointsOfInterest";
import { useLazyQuery, useMutation } from "react-apollo";
import { UPDATE_POINT_OF_INTEREST } from "./mutations/pointsOfInterest";

export const useListPointsOfInterest = (props) => {
    const [pointsOfInterest, setPointsOfInterest] = useState([]);

    const [getPointOfInterest, { loading, error }] = useLazyQuery(GET_POINTS_OF_INTEREST, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setPointsOfInterest(data?.mapInterestPoints?.results);
            if (props?.onCompleted && typeof props?.onCompleted == "function") {
                props.onCompleted();
            }
        },
    });

    return {
        getPointOfInterest,
        loading,
        error,
        pointsOfInterest,
    };
};

export const useUpdatePointOfInterest = (props) => {
    const [updatePointOfInterest, { loading, error }] = useMutation(UPDATE_POINT_OF_INTEREST, {
        onCompleted: () => {
            if (props.onCompleted && typeof props.onCompleted == "function") {
                props.onCompleted();
            }
        },
    });

    return {
        update: updatePointOfInterest,
        loading,
        error,
    };
};
