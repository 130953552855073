import Icon from "components/Icon";
import ReactTooltip from "components/ReactTooltip";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "components/Modal";
import { useLazyQuery } from "react-apollo";
import { GET_CZ_GROUPS, GET_CZ_LIST, GET_FLOORS } from "../utils/roomsListQueries";
import Button from "components/Button";
import EditRoomGroupModal from "../Rooms/modals/EditRoomGroupModal";

export const useListCZ = () => {
    const [rows, setRows] = useState([]);
    const [floors, setFloors] = useState(null);
    const [groups, setGroups] = useState(null);

    const { t } = useTranslation();
    const { open } = useModal();

    const [
        executeCZListQuery,
        { data: zones, error: errorZones, called: zonesCalled, loading: loadingZones, refetch },
    ] = useLazyQuery(GET_CZ_LIST, {
        fetchPolicy: "network-only",
    });

    const [
        executeCZGroupsQuery,
        {
            data: commonZonesGroupsData,
            error: errorGroups,
            called: commonZonesGroupsCalled,
            loading: commonZonesLoading,
        },
    ] = useLazyQuery(GET_CZ_GROUPS, { fetchPolicy: "network-only" });

    const [executeFloorsQuery, { data: floorsData, error: errorFloors, called: floorsCalled, loading: floorsLoading }] =
        useLazyQuery(GET_FLOORS, {
            fetchPolicy: "network-only",
        });

    const called = zonesCalled || commonZonesGroupsCalled || floorsCalled;
    const loading = loadingZones || commonZonesLoading || floorsLoading;
    const error = errorZones || errorGroups || errorFloors;
    const ready = called && !loading && !error;

    useEffect(() => {
        executeCZListQuery();
        executeCZGroupsQuery();
        executeFloorsQuery();
    }, []);

    useEffect(() => {
        if (ready) {
            setFloors(
                floorsData?.floors?.results?.length
                    ? floorsData.floors.results.map((floor) => {
                          const { id, name, floors: results } = floor;
                          return {
                              id,
                              label: name,
                              options: results?.map((floor) => ({
                                  id: floor.id,
                                  label: floor.name,
                                  value: floor.id,
                              })),
                          };
                      })
                    : []
            );
            const filterGroup = commonZonesGroupsData?.roomGroups?.results?.length
                ? commonZonesGroupsData.roomGroups.results.map((group) => ({
                      id: group.id,
                      value: group.name,
                      name: group.name,
                  }))
                : [];
            setGroups(filterGroup);
            setRows(getTableRows(zones?.rooms?.results, t, refetch, filterGroup, open));
        }
    }, [ready, zones]);

    return {
        ready,
        loading,
        error,
        rows,
        floors,
        groups,
        refetch,
    };
};

const getTableRows = (data, t, refetch, filterGroup, open) => {
    return data?.length
        ? data.map((row) => {
              return {
                  id: row.id,
                  floorFilter: row?.floorID,
                  roomName: row.name,
                  groupFilter: row?.roomGroups?.map((group) => group?.name),
                  name: (
                      <div className="flex flex-col" alt={`${row?.name} ${row?.number}`}>
                          <Button id={row?.id} to={`/hotel/zones/zone/detail/${row?.id}`} design="link">
                              <div>
                                  <p className="font-bold">{row?.name}</p>
                                  <span className="text-gray-900">{row?.number}</span>
                              </div>
                          </Button>
                      </div>
                  ),
                  group: getGroups(row?.roomGroups),
                  "device-notices": row?.warnings > 0 && (
                      <div className="flex items-center gap-3" sortvalue={row?.warnings}>
                          <Icon type="warning" />
                          <span>
                              {row?.warnings?.length} {row?.warnings?.length > 1 ? t("warnings") : t("warning")}
                          </span>
                      </div>
                  ),
                  content: getIcon(row?.roomTVs),
                  rowConfig: {
                      actions: [
                          {
                              id: "view-detail",
                              label: t("view-detail"),
                              onClick: () => {
                                  window.location.href = `#/hotel/zones/zone/detail/${row?.id}`;
                              },
                          },
                          {
                              id: "assign-to-group",
                              label: t("assign-to-group"),
                              onClick: () =>
                                  open(
                                      <EditRoomGroupModal
                                          roomsId={row?.id}
                                          refetch={refetch}
                                          groups={filterGroup}
                                          currentGroups={row?.roomGroups?.map((group) => group?.name)}
                                          roomName={row?.name}
                                      />
                                  ),
                          },
                      ],
                  },
              };
          })
        : [];
};

const getGroups = (groups) => {
    if (groups.length > 0) {
        return (
            <div className="flex items-center gap-1">
                <span>{groups[0]?.name}</span>
                <span
                    data-tip={
                        groups.length > 1
                            ? groups
                                  .slice(1, groups.length)
                                  .map((group) => group.name)
                                  .join("\n")
                            : ""
                    }
                    data-for={"groups.length"}
                    className="text-blue-300"
                >
                    {groups.length > 1 ? `+${groups.length - 1}` : ""}
                    <ReactTooltip id={"groups.length"} place="top" type="light" effect="solid" />
                </span>
            </div>
        );
    }
};

const getIcon = (roomsTVs) => {
    const contents = roomsTVs?.flatMap((roomTV) => roomTV?.contents);
    const uniqueContents = [...new Set(contents)];

    const iconTypes = {
        SIGNAGESEQUENCES: {
            type: "advertising",
            className: "text-purple-900",
            borderColor: "border-purple-900",
        },
        TVCHANNELS: {
            type: "channel",
            className: "text-teal-500",
            borderColor: "border-teal-500",
        },
        CASTINSTRUCTIONSCREEN: {
            type: "wificast",
            className: "text-zafiro-400",
            borderColor: "border-zafiro-400",
        },
    };
    return Object.keys(iconTypes)
        .filter((key) => uniqueContents.includes(key))
        .map((key) => {
            const icon = iconTypes[key];
            return (
                <div
                    key={key}
                    className={`flex items-center justify-center p-1 w-10 border-2 ${icon.borderColor} rounded-lg`}
                >
                    <Icon type={icon.type} className={`${icon.className} font-bold`} size="3xl" />
                </div>
            );
        });
};
