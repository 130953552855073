import { gql } from "apollo-boost";

export const GET_CATEGORIES = gql`
    query getCategories($mapId: Int64!) {
        mapCategories(mapID: $mapId, lang: "all") {
            results {
                id
                name
                pos
                mapCategoryInterestPoints {
                    mapCategoryID
                    mapInterestPointID
                }
            }
        }
    }
`;
