import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useMutation } from "react-apollo";

import { UseContentTree } from "../Advertising/graphql/useSignages";

import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Icon from "components/Icon";
import ProjectsList from "components/ProjectsList";
import Loading from "components/Loading";
import { useFlattenProjectsDestinations } from "hooks/Utils/DesignUtils";
import { useDesignLinkedContentAlerts } from "../Alerts/useDesignLinkedContentAlerts";
import { toast } from "react-toastify";

export const DeleteDesign = ({ designName, id, refetch, projects: forceProjects, destinations }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { deleteDesign } = useDeleteDesign();
    const flattenDestinations = useFlattenProjectsDestinations();
    const { getContentTree, contentTree, loading: loadingContentTree } = UseContentTree();

    const projects = forceProjects || flattenDestinations(destinations, false);
    const projectsInUse = projects?.filter((project) => !!project.designID);
    // ESTE SE CAMBIA

    const projectsInUseStrict = projectsInUse?.some((project) => id === project?.defaultDesignID);
    const projectsBlockingDeletion = projectsInUse.filter((project) => id === project?.defaultDesignID);

    useEffect(() => {
        getContentTree({ variables: { designID: id } });
    }, []);

    const contents = contentTree?.screens?.dir?.contents || [];

    const { loseNavigationAlert, alertCountWarning, singleWarnings } = useDesignLinkedContentAlerts({
        contents,
        projectsInUse,
        mode: "delete",
    });

    const loading = loadingContentTree;

    return (
        <Modal
            id="delete-design-modal"
            className="w-1/3"
            title={`${designName} - ${t("delete-design")}`}
            footer={
                projectsInUseStrict ? (
                    <Button design="blue" id="modal-button-close" onClick={close}>
                        {t("close")}
                    </Button>
                ) : (
                    <div className="flex justify-end gap-4">
                        <Button id="cancel" design="white" onClick={close} className="font-bold">
                            {t("cancel")}
                        </Button>
                        <Button
                            id="delete-design"
                            design="red"
                            disabled={loading}
                            onClick={() => {
                                deleteDesign({ variables: { id } }).then((res) => {
                                    if (res.data.deleteDesign.ok) {
                                        setTimeout(() => refetch(), 500);
                                        toast.success(t("operation-successful"));
                                    }
                                });
                                close();
                            }}
                        >
                            {t("delete")}
                        </Button>
                    </div>
                )
            }
        >
            {loading ? (
                <Loading />
            ) : (
                <>
                    {projectsInUseStrict ? (
                        <>
                            <Icon
                                type="warning"
                                size="lg"
                                text={t(
                                    "This design cannot be deleted since x properties would be left without a design applied",
                                    { count: projectsBlockingDeletion.length }
                                )}
                            />
                            <div className="mt-2">
                                <ProjectsList projects={projectsBlockingDeletion} />
                            </div>
                        </>
                    ) : (
                        <>
                            <p>{t("delete-design-confirm")}</p>
                            {loseNavigationAlert}
                            {alertCountWarning}
                            {singleWarnings}
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

const useDeleteDesign = () => {
    const DELETE_DESIGN = gql`
        mutation DeleteDesign($id: Int64!) {
            deleteDesign(id: $id) {
                error
                ok
            }
        }
    `;
    const [deleteDesign] = useMutation(DELETE_DESIGN);

    return { deleteDesign };
};
