import React from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import Button from "components/Button";
import Icon from "components/Icon";
import { useMap } from "./MapContext";
import { useModalDeletePinPointOfInterest } from "../modals/pointsOfInterest/useModalDeletePinPointOfInterest";
import PinLocation from "./PinLocation";

const MapPoint = ({ item, isSelected, isHighlighted = false, showingHighlitedPoints = false }) => {
    const { id } = useParams();

    const { manageInterestPoints, refetch } = useMap();
    const { handleRemovePinsInterestPoint } = manageInterestPoints;
    const { open: openModalDeletePointOfInterest } = useModalDeletePinPointOfInterest();

    const moveContainerClasses = classNames(
        "cursor-move",
        "flex items-center",
        "whitespace-no-wrap",
        "px-2 py-1",
        "drag-handle",
        "rounded bg-zafiro-200 text-white"
    );

    const deletesIsDisabled = item.totalPinsInInterestPoint === 1;

    const deleteButtonClasses = classNames("bg-white rounded px-1 ml-1", {
        "cursor-not-allowed text-gray-600": deletesIsDisabled,
        "text-gray-800 ": !deletesIsDisabled,
    });

    const containerClasses = classNames(
        "w-full h-full relative border relative flex justify-center items-center bg-transparent",
        {
            "border-zafiro-200": isSelected,
            "border-transparent": !isSelected,
        }
    );

    const nameContainerClasses = classNames("inline-block overflow-hidden whitespace-no-wrap ellipsis");

    const headerStyle = { position: "absolute", top: "-35px", left: 0 };
    const nameStyle = { maxWidth: "5rem", textOverflow: "ellipsis" };

    const handleDelete = () => {
        const { pins, onConfirmRemove } = handleRemovePinsInterestPoint(item);
        if (pins) {
            openModalDeletePointOfInterest({
                variables: { mapID: id, id: item.originalId, pins },
                refetch,
                name: item.name,
                onCompleted: onConfirmRemove,
            });
        }
    };

    return (
        <>
            {isSelected && (
                <div className="flex" style={headerStyle}>
                    <div className={moveContainerClasses}>
                        <Icon size={1.7} type="move-widget" className="mr-1" />
                        <span className={nameContainerClasses} style={nameStyle}>
                            {item?.name}
                        </span>
                    </div>
                    <Button
                        disabled={deletesIsDisabled}
                        onClick={handleDelete}
                        id="map-point-delete"
                        className={deleteButtonClasses}
                    >
                        <Icon size={1.7} type="delete-editor" />
                    </Button>
                </div>
            )}
            <div
                className="w-full h-full drag-handle"
                style={{ opacity: isHighlighted || !showingHighlitedPoints ? 1 : 0.5 }}
            >
                <div className={`${containerClasses} relative`}>
                    <PinLocation imageSrc={item.image} icon={item.icon} isHighlighted={isHighlighted} />
                </div>
            </div>
        </>
    );
};

export default MapPoint;
