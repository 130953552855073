import React from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";

import { useDeletePropertyGroup, useGetHasLinkedSignages } from "../GroupsProperties/useListPropertyGroups";
import Loading from "components/Loading";
import Icon from "components/Icon";

const ModalDeletePropertyGroups = ({
    close,
    refetch,
    names = [],
    ids = [],
    isBatch,
    refs = [],
    handleResetSelectedRows,
    projectsRefs = [],
}) => {
    const { t } = useTranslation();

    const { deletePropertyGroup, loadingDeletePropertyGroup } = useDeletePropertyGroup({
        close,
        refetch,
        names,
    });
    const { hasLinkedSignages, loading } = useGetHasLinkedSignages({ refs: refs, projectsRefs: projectsRefs });

    const handleClose = () => {
        close();
    };

    const handleSave = () => {
        deletePropertyGroup({ variables: { id: ids } });
        if (typeof handleResetSelectedRows === "function") {
            handleResetSelectedRows();
        }
    };

    return (
        <Modal
            footer={
                !loadingDeletePropertyGroup && !loading ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={handleClose}>
                            {t("cancel")}
                        </Button>
                        <Button design="red" id="modal-button-save" onClick={handleSave}>
                            {t("delete")}
                        </Button>
                    </>
                ) : null
            }
            className="w-4/12 p-10"
            title={isBatch ? t("delete-groups") : `${names[0]} - ${t("delete")}`}
        >
            {loadingDeletePropertyGroup || loading ? <Loading /> : null}
            {!isBatch && !loadingDeletePropertyGroup && !loading ? (
                <div>
                    <span>{t("Are you sure you want to delete this group of properties?")}</span>
                    {hasLinkedSignages && !isBatch ? (
                        <div className=" flex items-start mt-2">
                            <Icon size={1.5} type="warning" className=" mr-2" />
                            <div>{t("some-properties-using-global-design-screens-signage-unlinked-delete")}</div>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {isBatch && !loadingDeletePropertyGroup && !loading ? (
                <div>
                    <span className=" inline-block mb-2">{t("delete-properties-groups-confirm")}</span>

                    <div className=" flex flex-wrap w-full mt-2">
                        {names.map((name) => {
                            return <div className=" bg-gray-200 px-6 py-1 mr-2 mt-2 rounded">{name}</div>;
                        })}
                    </div>
                </div>
            ) : null}
        </Modal>
    );
};

export const useModalDeletePropertyGroups = () => {
    const { open, close } = useModal();

    return {
        open: (props) => open(<ModalDeletePropertyGroups {...props} close={close} />),
    };
};

export default useModalDeletePropertyGroups;
