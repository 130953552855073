import React from "react";
import Button from "components/Button";

export const DefaultColumn = (props) => {
    return props.isDefault ? (
        <p className=" bg-zafiro-500 rounded-md px-2 py-1 text-white">{props.t("default")}</p>
    ) : (
        <Button
            design={"link"}
            id={"make-as-default"}
            onClick={() => {
                props.openModal({ name: props.name, id: props.id, getSignages: props.getSignages });
            }}
        >
            {props.t("make-default")}
        </Button>
    );
};

export const StatusColumn = (props) => {
    const statusStyles = {
        AVAILABLE: "text-green-100",
        UNAVAILABLE: "text-red-100",
    };
    const classname = props.available ? statusStyles.AVAILABLE : statusStyles.UNAVAILABLE;
    const status = props.available ? props.t("available") : props.t("unavailable");
    return (
        <div className=" flex flex-col">
            <p className={classname}>{status}</p>
            {props?.available && props?.onGoing && <p className=" text-gray-500">{props.t("on-going")}</p>}
            {props?.available && !props?.onGoing && props?.expired && (
                <p className=" text-gray-500">{props.t("expired")}</p>
            )}
        </div>
    );
};

export const FiltersColumn = (props) => {
    const { numberOfFilters = 0, isDefault, id, name, t, filters, type } = props;
    return !isDefault && numberOfFilters > 0 ? (
        <Button
            design={"link"}
            id={"filters-button"}
            onClick={() => {
                props.openModalFilters({ id, name, filters, type });
            }}
        >
            {numberOfFilters > 1 ? t("filters-x", { count: numberOfFilters }) : `1 ${t("filter")}`}
        </Button>
    ) : !isDefault && numberOfFilters == 0 ? (
        <p>{t("filters-x", { count: numberOfFilters })}</p>
    ) : (
        "-"
    );
};

export const NameColumn = (props) => {
    return (
        <Button
            design={"link"}
            id={`button-${props.section}-name-${props.id}`}
            onClick={() => {
                props.navigate(`/design/advertising/${props.section}/details/${props.id}`);
            }}
        >
            {props.name}
        </Button>
    );
};
