import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Button from "components/Button";
import Translations from "components/Translations";
import Loading from "components/Loading";
import { useAuth } from "hooks/Session/auth";

const ModalEditName = (props) => {
    const { t } = useTranslation();
    const { languages } = useAuth();
    const defaultLanguage = languages?.find((l) => l.isDefault)?.languageRef;
    const [translations, setTranslations] = useState(
        Object.entries(JSON.parse(props?.names || {})).map(([languageCode, name]) => ({
            languageCode,
            name,
        }))
    );

    useEffect(() => {
        const defaultTranslation = translations.find((tr) => tr.languageCode == defaultLanguage);
        if (!defaultTranslation) {
            setTranslations((prev) => [...prev, { languageCode: defaultLanguage, error: true }]);
        }
    }, []);

    const [loading, setLoading] = useState(false);

    const { update } = props.useUpdate({
        onCompleted: () => {
            setLoading(false);
            props.fetch();
            props.close();
        },
    });

    const handleSaveClick = () => {
        const defaultName = translations.find((tr) => tr.languageCode == defaultLanguage);
        if (defaultName && !defaultName?.error) {
            setLoading(true);
            update({
                variables: {
                    id: props?.id,
                    mapId: props?.mapId,
                    name: languages.map((l) => ({
                        lang: l.languageRef,
                        text: translations.find((t) => t.languageCode == l.languageRef)?.name || "",
                    })), //translations.map(({ languageCode, name }) => ({ lang: languageCode, text: name })),
                },
            });
        } else {
            setTranslations((prev) => [...prev, { languageCode: defaultLanguage, error: true }]);
            toast.error(t("errors-in-red"));
        }
    };

    const handleCloseClick = () => {
        if (typeof props.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${props?.name} - ${t("edit-name")}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-save" disabled={loading} onClick={handleSaveClick}>
                        {t("save")}
                    </Button>
                </>
            }
            className="w-5/12 p-10"
        >
            {loading ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-2">
                    <p>{t("edit-the-name-and-the-translations")}</p>
                    <Translations
                        id="map-name-translations"
                        languages={languages.map((language) => language.languageRef)}
                        translations={translations}
                        defaultLang={defaultLanguage}
                        onChange={(translations) => {
                            const defaultTranslation = translations.find((tr) => tr.languageCode == defaultLanguage);
                            if (defaultTranslation) {
                                setTranslations(translations);
                            } else {
                                setTranslations([...translations, { languageCode: defaultLanguage, error: true }]);
                            }
                        }}
                        maxHeight="10rem"
                    />
                </div>
            )}
        </Modal>
    );
};

export const useModalEditName = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalEditName {...newProps} />);
        },
    };
};
