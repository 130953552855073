import React, { useContext } from "react";
import classNames from "classnames";

import { GlobalContext } from "contexts/Global";

import ParamsList from "components/SettingsCard/ParamsList";
import Section from "components/SettingsCard/Section";
import Switch from "components/Switch";
import Icon from "components/Icon";
import Button from "components/Button";
import Loading from "components/Loading";
import ErrorInfo from "components/ErrorInfo";

/**
 * This component is a card that is used to display settings or information. It can be used to display a list of parameters, a section of settings, or any other content.
 * @component
 * @param {string} id The id of the card
 * @param {string} title The title of the card
 * @param {string} info Is a info icon with a tooltip that will be displayed next to the title
 * @param {string} warning Is a warning icon with a tooltip that will be displayed next to the title
 * @params {object} toggle Is a toggle button that will be displayed at the top right of the card. It should have an id, a checked value, and an action function
 * @param toggle
 * @param {object} edit Is a button that will be displayed at the top right of the card. It should have an onClick function and an id
 * @param {string} right A element that will be displayed at the top right of the card
 * @param {string} description A description of the card
 * @params {boolean} loading If true, the card will be displayed a loading spinner
 * @param {string} className Additional classes
 * @param {JSX.Element} children The content of the card *
 @returns {JSX.Element} The UI of the component
 * @example <SettingsCard title="Card Title" info="Card Info" edit={{onClick: () => {}}}>Card Content here</SettingsCard>
 */
const SettingsCard = ({
    id,
    title,
    info,
    warning,
    toggle,
    edit,
    right,
    description,
    loading,
    error,
    className,
    children,
}) => {
    const { highlightComponent } = useContext(GlobalContext);

    const hasChildrens = typeof children?.filter === "function" && children.filter((child) => child).length > 0;

    const globalClass = classNames({
        "p-6 w-full bg-white rounded shadow-sm flex flex-col": true,
        "highlight-component": highlightComponent,
        [className]: className,
    });

    return (
        <div className={globalClass}>
            {loading || error ? (
                <>
                    {title ? <div className="text-xl font-bold">{title}</div> : null}
                    {error ? <ErrorInfo className="mt-3">{error}</ErrorInfo> : <Loading />}
                </>
            ) : (
                <>
                    {title || info || warning || edit || toggle || right ? (
                        <div
                            className={`flex justify-between items-center ${
                                description || hasChildrens ? "mb-5" : null
                            }`}
                        >
                            <div className="flex items-center">
                                {title ? <div className="text-xl font-bold">{title}</div> : null}
                                {info ? <Icon type="info" size="xl" tooltip={info} className="ml-2" /> : null}
                                {warning ? <Icon type="warning" size="xl" tooltip={warning} className="ml-2" /> : null}
                            </div>
                            {edit || toggle || right ? (
                                <div className="flex items-center space-x-5 text-gray-800">
                                    {toggle ? (
                                        <Switch
                                            id={toggle.id || `${id}-toggle-button`}
                                            disabled={toggle.disabled}
                                            checked={toggle.checked}
                                            onChange={({ checked }) => {
                                                if (toggle.action) {
                                                    toggle.action(checked);
                                                }
                                            }}
                                            label={toggle.label}
                                            className="flex items-center"
                                        />
                                    ) : null}
                                    {edit ? (
                                        <Button
                                            id={edit.id || `${id}-edit-button`}
                                            design="link"
                                            disabled={edit.disabled}
                                            onClick={edit.onClick}
                                        >
                                            <Icon type="edit" size="xl" className={edit.className} />
                                        </Button>
                                    ) : null}
                                    {right ? <div className="flex items-center">{right}</div> : null}
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    {description ? <div className="text-base mb-3">{description}</div> : null}
                    {children}
                </>
            )}
        </div>
    );
};
SettingsCard.displayName = "SettingsCard";

SettingsCard.ParamsList = ParamsList;
SettingsCard.Section = Section;

export default SettingsCard;
