import React, { useContext, useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Session } from "../../../../hooks/Utils/Session";
import { vw2px } from "../../../../hooks/Utils/DesignUtils";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";
import { DEVICE } from "constants/editor";

import Button from "components/Button";

const BULLETS_PER_PAGE = 3;

const WidgetCarousel = () => {
    const { deviceType, editorSize } = useContext(EditorContext);
    const { config, type, id: widgetID } = useContext(WidgetContext);

    const { lang } = useParams();
    const { gridItems } = useSelector((state) => state.design);

    const size = (s) => vw2px(s, editorSize?.width, deviceType === DEVICE.TYPE.MOBILE ? 3 : 1);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = config?.style || Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);
    const imagesCount = data && data.images ? data.images.length : 0;
    const intervalDuration = data && data.duration > 0 ? data.duration : 30;
    const isAutomatic = !!data?.automatic;
    const initialImage = data?.initialImage || 0;

    const [currentImage, setCurrentImage] = useState(initialImage);

    const current = data?.images?.[currentImage];
    const src = current?.libraryRef ? getFromDAS(current.libraryRef, lang) : current?.externalUrl;
    const bgSize = data
        ? data.adjust === "cover-adjust"
            ? "100% 100%"
            : data.adjust
            ? data.adjust
            : "cover"
        : "cover";

    const timer = useRef(null);

    const stopSlideshow = () => {
        if (timer.current) {
            clearInterval(timer.current);
        }
    };
    const startSlideshow = () => {
        stopSlideshow();
        timer.current = setInterval(() => {
            setCurrentImage((prev) => (prev >= imagesCount - 1 ? 0 : prev + 1));
        }, Math.max(1, intervalDuration) * 1000);
    };

    useEffect(() => {
        setCurrentImage(initialImage);
        if (isAutomatic) {
            startSlideshow();
        } else {
            stopSlideshow();
        }
    }, [initialImage, isAutomatic]);

    useEffect(() => {
        if (isAutomatic) {
            startSlideshow();
        } else {
            stopSlideshow();
        }
        return stopSlideshow;
    }, [intervalDuration]);

    useEffect(() => {
        const elm = document.querySelector(`#widget_zone_${widgetID}`);
        if (elm) {
            elm.style.background = "transparent no-repeat center center";
            elm.style.backgroundImage = src ? `url('${src}')` : "";
            elm.style.backgroundSize = bgSize;
            elm.style.borderRadius = style && style.radius ? `${style.radius}rem` : "";
        }
    }, [bgSize, style, src]);

    const bulletColor = style?.items?.bullets?.fgColor || widget?.widgetStyle?.items?.bullets?.fgColor;

    const bullet = (i) => {
        const isDirection = i === "prev" || i === "next";
        const isDisabled =
            (i === "prev" && currentImage === 0) ||
            (i === "next" && currentImage === imagesCount - 1) ||
            (!isDirection && i >= imagesCount);
        return (
            <Button
                id={`widget_${widgetID}-carrousel-image-${i}`}
                className={classNames({
                    "border-2 rounded-full inline-block leading-none": true,
                    invisible: isDirection && isDisabled,
                    "opacity-50": !isDirection && isDisabled,
                })}
                style={{
                    borderColor: bulletColor,
                    width: `${size(isDirection ? 0.45 : 0.9)}px`,
                    height: `${size(isDirection ? 0.45 : 0.9)}px`,
                    backgroundColor: isDirection || i === currentImage ? bulletColor : undefined,
                }}
                disabled={isDisabled}
                onClick={() => {
                    if (i === "prev") {
                        setCurrentImage((prev) => (prev === 0 ? imagesCount - 1 : prev - 1));
                    } else if (i === "next") {
                        setCurrentImage((prev) => (prev === imagesCount - 1 ? 0 : prev + 1));
                    } else {
                        setCurrentImage(i);
                    }
                    if (isAutomatic) {
                        startSlideshow();
                    }
                }}
            />
        );
    };

    const currentPage = Math.floor(currentImage / BULLETS_PER_PAGE);

    if (imagesCount > 0) {
        return isAutomatic ? null : (
            <div className="flex justify-evenly items-end h-full">
                <div className="flex items-center justify-center space-x-2 text-white p-5">
                    {imagesCount > BULLETS_PER_PAGE ? (
                        <>
                            {bullet("prev")}
                            {bullet(currentPage * BULLETS_PER_PAGE)}
                            {bullet(currentPage * BULLETS_PER_PAGE + 1)}
                            {bullet(currentPage * BULLETS_PER_PAGE + 2)}
                            {bullet("next")}
                        </>
                    ) : (
                        data.images.map((_, i) => bullet(i))
                    )}
                </div>
            </div>
        );
    }

    return <EmptyWidget radius={style ? style.radius : 0} type={type}></EmptyWidget>;
};

const getFromDAS = (libraryRef, languageRef) => {
    if (libraryRef) {
        return Session.getDasUrl(`${libraryRef}?lang=${languageRef}`);
    }
    return null;
};

export default WidgetCarousel;
