import React, { useContext } from "react";
import { isSameMonth, subMonths, addMonths } from "date-fns";
import { GlobalContext } from "contexts/Global";
import { capitalizeFirst } from "hooks/Utils/Utils";
import Button from "components/Button";
import Icon from "components/Icon";

export const CustomCalendarHeader = ({ month, setMonth, locale = "es-ES", startDate, hideFutureDates }) => {
    const { lang } = useContext(GlobalContext);
    const isCurrentMonth = isSameMonth(month, new Date());
    const isStartMonth = isSameMonth(month, startDate);
    const currentLocale = lang === "es" ? "es-ES" : locale;
    const monthName = month.toLocaleString(currentLocale, { month: "long" });
    const year = month.getFullYear();

    const handlePreviousMonth = () => {
        if (!isStartMonth) {
            const prevMonth = subMonths(month, 1);
            setMonth(prevMonth);
        }
    };

    const handleNextMonth = () => {
        const nextMonth = addMonths(month, 1);
        setMonth(nextMonth);
    };

    return (
        <div className="custom-nav-bar py-4 border-b ">
            <Button id="previous-month" onClick={handlePreviousMonth} disabled={isStartMonth}>
                <Icon
                    type="chevron-left"
                    className={`focus:outline-none text-gray-900 text-base ${isStartMonth && "opacity-50 "}`}
                />
            </Button>
            <span className="font-bold mx-2">{`${capitalizeFirst(monthName)} ${year}`}</span>
            <Button id="next-month" onClick={handleNextMonth} disabled={isCurrentMonth && hideFutureDates}>
                <Icon
                    type="chevron-right"
                    className={`focus:outline-none text-gray-900 text-base ${
                        isCurrentMonth && hideFutureDates && "opacity-50 "
                    }`}
                />
            </Button>
        </div>
    );
};
