import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { GlobalContext } from "contexts/Global";
import Icon from "components/Icon";
import Button from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/**
 * InteractiveImageDisplay Component
 *
 * Displays an image with optional actions such as add, delete, and tooltips for additional information.
 * If the image is missing, it shows a placeholder icon.
 *
 * @component
 * @param {Object} props - Component properties
 * @param {string} props.id - Unique identifier for the component (required)
 * @param {string} [props.title] - Title displayed above the image
 * @param {string} [props.src] - Image source URL
 * @param {Function} [props.onAdd] - Callback function triggered when the add button is clicked
 * @param {Function} [props.onDelete] - Callback function triggered when the delete button is clicked
 * @param {string} [props.info] - Tooltip text displayed on the info icon
 * @param {Object} [props.resolution={width: 1280, height: 720}] - Recommended image resolution
 * @param {number} [props.resolution.width] - Recommended width
 * @param {number} [props.resolution.height] - Recommended height
 * @param {string} [props.className] - Additional CSS classes for styling
 * @param {number} [props.noImageIconSize=1.5] - Size of the placeholder icon when no image is provided
 * @param {number} [props.deleteIconSize=1.3] - Size of the delete icon
 * @param {string} [props.deleteIconType="delete-editor"] - Type of icon used for deletion
 * @returns {JSX.Element} Interactive image display component
 */
const InteractiveImageDisplay = ({
    id,
    title,
    src,
    onAdd,
    onDelete,
    onError,
    info,
    resolution = { width: 1280, height: 720 },
    className,
    noImageIconSize = 1.5,
    deleteIconSize = 1.3,
    deleteIconType = "delete",
    containerImageClass,
    objectClassImage = "object-cover",
}) => {
    if (!id) throw new Error("id is required");

    const { t } = useTranslation();
    const { highlightComponent } = useContext(GlobalContext);
    const [onErrorImg, setOnErrorImg] = useState(false);

    const isIcon = (src) => typeof src === "object" && src?.icon && src?.lib;

    const getResolutionTooltip = () =>
        resolution
            ? t("recommended-resolution", { width: resolution?.width || 0, height: resolution?.height || 0 })
            : null;

    const infoIcon = info ? <Icon type="info" size={1.3} className="mt-1" tooltip={info} /> : null;

    const containerImage = classNames(
        "flex relative mt-2 items-center justify-center mb-2 rounded border border-gray-300 w-full bg-gray-200",
        { [onError ? "border border-red-100" : "border border-gray-300"]: true }
    );

    const barClass = classNames(
        "bg-gray-800 text-white bg-opacity-75 absolute bottom-0 flex items-center justify-between py-1 px-2",
        {
            "left-0 w-full": onDelete,
            "right-0 rounded-full": !onDelete,
        }
    );

    const imageClass = classNames("rounded", className, objectClassImage);
    const noImageClass = classNames("flex items-center justify-center", className);

    const getImageContent = () => {
        if (isIcon(src)) {
            return (
                <div className={noImageClass}>
                    <FontAwesomeIcon icon={[src?.lib, src?.icon]} size={src?.size || "lg"} />
                </div>
            );
        }

        return !src || onErrorImg ? (
            <div className={noImageClass}>
                <Icon type="no-image" className="text-gray-400" size={noImageIconSize} />
            </div>
        ) : (
            <img className={imageClass} src={src} onError={() => setOnErrorImg(true)} alt={title || "Image"} />
        );
    };

    return (
        <div
            className={classNames(
                "font-bold flex flex-col items-center",
                highlightComponent && "highlight-component",
                containerImageClass
            )}
        >
            {(title || resolution) && (
                <div className="flex w-full">
                    {title && <span className="block mr-1 text-lg">{title}</span>}
                    {resolution && <Icon tooltip={getResolutionTooltip()} type="info" size={1.8} />}
                </div>
            )}

            <div className={containerImage}>
                {getImageContent()}
                {src && (info || onDelete) && (
                    <div className={barClass}>
                        {onDelete && (
                            <>
                                <div className="w-5"></div>
                                <div>
                                    <Button id={`${id}-library-image-selector-delete`} onClick={onDelete}>
                                        <Icon type={deleteIconType} size={deleteIconSize} />
                                    </Button>
                                </div>
                            </>
                        )}
                        <div className="w-5">{infoIcon}</div>
                    </div>
                )}
            </div>

            {onAdd && (
                <Button className="w-full" onClick={onAdd} design="blue" id={`${id}-library-image-selector-add-image`}>
                    {t("add-image")}
                </Button>
            )}
        </div>
    );
};

InteractiveImageDisplay.displayName = "InteractiveImageDisplay";
export default InteractiveImageDisplay;
