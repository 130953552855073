import React, { useEffect, useState } from "react";
import ReactTooltip from "components/ReactTooltip";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { composeDestination } from "../../hooks/Utils/Utils";
import UseComponentLoading from "../useComponentLoading";

const UseModalGridChannelsFilters = ({ index }) => {
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const [destinationValues, setDestinationValues] = useState(null);
    const prefixId = "result-filters-applied-";

    const GET_INFO_DATA = gql(` {
            rooms(orderBy: { field: "name", criteria: "ASC" }, filter: { type: GUESTROOM }) {
                results {
                    id
                    name
                    number
                    roomTVs {
                        name
                        id
                    }
                }
            }
            occupiedRoomsInfo {
                name
                id
            }
            roomGroups(orderBy: { field: "name", criteria: "ASC" }, filter: { type: GUESTROOM }) {
                results {
                    id
                    name
                }
            }
            wings: installationMap {
                results {
                    id
                    name
                    floors {
                        id
                        name
                    }
                }
            }
            guests(mainGuest: true, filter: { checkedOut: false }, orderBy: { field: "name", criteria: "ASC" }) {
                results {
                    id
                    name
                    surname
                }
            }
            guestGroups(orderBy: { field: "name", criteria: "ASC" }) {
                results {
                    id
                    name
                }
            }
            languages {
                results {
                    ref
                }
            }
            countries {
                results {
                    information {
                        name
                    }
                    ref
                }
            }
            commonZone: rooms(orderBy: { field: "name", criteria: "ASC" }, filter: { type: COMMONZONE }) {
                results {
                    id
                    name
                    number
                    roomTVs {
                        name
                        id
                    }
                }
            }
            commonZoneGroup: roomGroups(orderBy: { field: "name", criteria: "ASC" }, filter: { type: COMMONZONE }) {
                results {
                    id
                    name
                    rooms{
                        name
                    }
                }
            }
        }`);

    const GET_DESTINATION_RESULTS = gql`{
        filterDestination(destination: ${destinationValues ? destinationValues : "{}"} ) {
            stayGuestRoomReasons {
                reason {
                    all
                    countryRefs
                    floorIDs
                    languageRefs
                    roomGroupIDs
                    roomIDs
                    stayGroupIDs
                    stayGuestIDs
                    wingIDs
                }
                roomId
                roomName
                stayGuestId
                stayGuestName
                stayGuestSurname
            }
            commonZoneReasons {
                roomId
                roomName
                 reason {
                    roomGroupTpCommonZoneIDs
                    roomTpCommonZoneIDs
                }
            }
            roomTVReasons {
                roomId
                roomName
                roomTvName
                roomTvId
                reason {
                   roomTvTpCommonZoneIDs
                   roomTvTpGuestRoomIDs
                }
            }  
        }
    }`;

    const [getInfoData, { data: dataInfo }] = useLazyQuery(GET_INFO_DATA, { fetchPolicy: "network-only" });
    const [getDestinationsResults, { data: dataDestinationResults }] = useLazyQuery(GET_DESTINATION_RESULTS, {
        fetchPolicy: "network-only",
    });

    const [filterSelected, setFilterSelected] = useState(null);
    const [filters, setFilters] = useState([]);
    const [name, setName] = useState("");
    const [tvLocationData, setTvLocationData] = useState({});
    const [roomsData, setRoomsData] = useState({});
    const [roomsGroupsData, setRoomsGroupsData] = useState({});
    const [wingsData, setWingsData] = useState({});
    const [guestData, setGuestData] = useState({});
    const [floorsData, setFloorsData] = useState({});
    const [guestGroupsData, setGuestGroupsData] = useState({});
    const [commonZoneData, setCommonZoneData] = useState({});
    const [commonZoneGroupData, setCommonZoneGroupData] = useState({});
    const [tvLocationCZData, setTvLocationCZData] = useState({});
    const [filterDestinationResults, setFilterDestinationResults] = useState([]);
    const [currentFilterDestination, setCurrentFilterDestination] = useState([]);
    const [numberRooms, setNumberRooms] = useState(0);
    const [loadingData, setLoadingData] = useState(true);
    const [numberFilters, setNumberFilters] = useState(0);
    const [loadingProcess, setLoadingProcess] = useState(true);

    const { t } = useTranslation();

    useEffect(() => {
        getInfoData();
    }, []);

    useEffect(() => {
        if (dataInfo) {
            if (dataInfo?.rooms?.results) {
                const roomTVs = dataInfo.rooms.results.flatMap((room) => room.roomTVs || []);
                setTvLocationData(convertArrayToObject(roomTVs));
            }
            if (dataInfo?.rooms?.results) {
                setRoomsData(convertArrayToObject(dataInfo?.rooms?.results));
            }
            if (dataInfo?.roomGroups?.results) {
                setRoomsGroupsData(convertArrayToObject(dataInfo?.roomGroups?.results));
            }
            if (dataInfo?.wings?.results) {
                setWingsData(convertArrayToObject(dataInfo?.wings?.results));
            }
            if (dataInfo?.guests?.results) {
                setGuestData(convertArrayToObjectNameSurname(dataInfo.guests.results));
            }
            if (dataInfo?.guestGroups?.results) {
                setGuestGroupsData(convertArrayToObject(dataInfo.guestGroups.results));
            }
            if (dataInfo?.wings?.results) {
                setFloorsData(convertArrayToObject(dataInfo?.wings?.results));
            }
            if (dataInfo?.commonZone?.results) {
                const roomTVs = dataInfo.commonZone.results.flatMap((zone) => zone.roomTVs || []);
                setTvLocationCZData(convertArrayToObject(roomTVs));
            }
            if (dataInfo?.commonZone?.results) {
                setCommonZoneData(convertArrayToObject(dataInfo?.commonZone?.results));
            }
            if (dataInfo?.commonZoneGroup?.results) {
                setCommonZoneGroupData(convertArrayToObject(dataInfo?.commonZoneGroup?.results));
            }
            setLoadingData(false);
        }
    }, [dataInfo]);

    useEffect(() => {
        if (data?.name) {
            setName(data.name);
        }
        if (data?.destination && !loadingData) {
            setFilters(mergeAndTagArrays(data?.destination), dataInfo);
            setDestinationValues(composeDestination({ values: arrangeDestinationValues(data.destination) }));
            getDestinationsResults();
            setNumberFilters(countNumberFilters(data.destination));
        }
    }, [data, loadingData]);

    useEffect(() => {
        if (dataDestinationResults) {
            const stayGuestRoomReasons = dataDestinationResults.filterDestination.stayGuestRoomReasons;
            const roomTVReasons = dataDestinationResults.filterDestination.roomTVReasons;
            const commonZoneReasons = dataDestinationResults.filterDestination.commonZoneReasons;

            const commonZones = dataInfo;

            const filteredStayGuestRooms = findMatchingRoomNames(stayGuestRoomReasons, filters);
            const filteredRoomTVReasons = findMatchingRoomNames(roomTVReasons, filters);
            const filteredCommonZones = findMatchingRoomNames(commonZoneReasons, filters, commonZones);

            setFilterDestinationResults({
                stayGuestRoomReasons: filteredStayGuestRooms,
                roomTVReasons: filteredRoomTVReasons,
                commonZoneReasons: filteredCommonZones,
            });
            setLoadingProcess(false);
        }
    }, [dataDestinationResults, filters]);

    useEffect(() => {
        if (filterSelected) {
            const allResults = Object.values(filterDestinationResults).flat();

            const currentFilterDestination = allResults.filter((el) => {
                if (filterSelected.ref) {
                    return el.ref === filterSelected.ref && el.type === filterSelected.type;
                } else if (filterSelected.id) {
                    return el.id === filterSelected.id && el.type === filterSelected.type;
                }
                return false;
            });

            setCurrentFilterDestination(currentFilterDestination);
            setNumberRooms(
                (currentFilterDestination?.[0]?.rooms?.length || 0) +
                    (currentFilterDestination?.[1]?.rooms?.length || 0) +
                    (currentFilterDestination?.[2]?.rooms?.length || 0)
            );
        }
    }, [filterSelected, filterDestinationResults]);

    useEffect(() => {
        if (
            filters.length > 0 &&
            !loadingData &&
            !loadingProcess &&
            !filterSelected
            // filterDestinationResults.length > 0
        ) {
            setFilterSelected(filters[0]);
        }
    }, [filters, loadingData, loadingProcess, filterSelected, filterDestinationResults]);

    return (
        <div className=" w-full relative flex">
            <div className=" w-1/2 ">
                <div
                    id={`${prefixId}filter-applied-text`}
                    dangerouslySetInnerHTML={{ __html: t("grid-has-the-filters-applied", { grid: name }) }}
                    className=" mb-6"
                ></div>
                <div className=" mb-2">
                    <span id={`${prefixId}number-filters-applied`} className=" font-bold">
                        {t("filters")}
                    </span>
                    <span id={`${prefixId}number-filters-applied-values`}>{`(${numberFilters})`}</span>
                </div>
                <div style={{ maxWidth: "34.2rem" }}>
                    {filters.map((el) => {
                        const numberRooms = getNumberRoomsByFilter(el, filterDestinationResults);
                        return (
                            <button
                                id={`${prefixId}button-select-filter-${el.id ? el.id : el.ref}-${el.type}`}
                                onClick={() => setFilterSelected(el)}
                                onMouseOver={() => ReactTooltip.rebuild()}
                                data-tip={`${numberRooms} ${t("rooms")} `}
                                className={` ${
                                    filterSelected === el ? " bg-zafiro-800 text-white" : "bg-gray-200 text-gray-900"
                                } focus:outline-none cursor-pointer  px-2 rounded mr-2`}
                                style={{ marginBottom: "0.7rem" }}
                            >
                                {getName({
                                    filter: el,
                                    dataInfo,
                                    tvLocationData,
                                    roomsData,
                                    roomsGroupsData,
                                    guestGroupsData,
                                    guestData,
                                    wingsData,
                                    floorsData,
                                    tvLocationCZData,
                                    commonZoneData,
                                    commonZoneGroupData,
                                    t,
                                })}
                            </button>
                        );
                    })}
                </div>
                <ReactTooltip
                    place="bottom"
                    type="light"
                    offset={{ top: -8, left: -8 }}
                    html={true}
                    border={true}
                    borderColor="#D3DAE1"
                />
            </div>
            <div className=" w-1/2 ">
                <div className=" flex justify-between">
                    <span>{t("result-of-the-selected-filter")}</span>
                    <span id={`${prefixId}number-rooms`} className=" text-gray-700">
                        {` ${numberRooms} ${t("rooms")}`}{" "}
                    </span>
                </div>
                <div
                    className=" w-full px-4 rounded mt-4 flex flex-col border border-gray-200  "
                    style={{ height: "27rem", overflowY: "scroll" }}
                >
                    <div>
                        {[
                            currentFilterDestination?.[0]?.rooms,
                            currentFilterDestination?.[1]?.rooms,
                            currentFilterDestination?.[2]?.rooms,
                        ].map((roomsArray, index) => {
                            return roomsArray?.map((el) => {
                                const tooltip = getTooltipRoom({
                                    infoRoomName: el,
                                    commonZoneData,
                                    commonZoneGroupData,
                                    tvLocationData,
                                    tvLocationCZData,
                                    roomsData,
                                    roomsGroupsData,
                                    wingsData,
                                    guestData,
                                    guestGroupsData,
                                    floorsData,
                                    t,
                                });

                                return (
                                    <div
                                        key={`${prefixId}room-${el.roomId}-${el.roomName}-${index}`}
                                        id={`${prefixId}room-${el.roomId}`}
                                        data-tip={tooltip}
                                        onMouseOver={() => ReactTooltip.rebuild()}
                                        data-for={"destination-types"}
                                        className="border-b border-gray-200 py-3 px-1"
                                    >
                                        {el.roomName}
                                    </div>
                                );
                            });
                        })}

                        <ReactTooltip
                            place="bottom"
                            type="light"
                            id="destination-types"
                            offset={{ top: -8, left: -8 }}
                            html={true}
                            border={true}
                            borderColor="#D3DAE1"
                        />
                    </div>
                    {numberRooms === 0 ? (
                        <div className=" h-full w-full flex items-center justify-center ">
                            {t("no-rooms-with-this-filter")}
                        </div>
                    ) : null}
                </div>
            </div>
            {loadingData || loadingProcess ? (
                <div className=" absolute bg-white h-full top-0 left-0 w-full">
                    <UseComponentLoading />
                </div>
            ) : null}
        </div>
    );
};

export default UseModalGridChannelsFilters;

const getNumberRoomsByFilter = (filter, dataDestinationResultsFilter) => {
    const allReasons = Object.values(dataDestinationResultsFilter).flat();

    const resultFilter = allReasons.find((item) => {
        if (filter.ref) {
            return item.ref === filter.ref && item.type === filter.type;
        } else if (filter.id) {
            return item.id === filter.id && item.type === filter.type;
        }
        return false;
    });

    return resultFilter ? resultFilter.rooms?.length || 0 : 0;
};

const convertArrayToObject = (array) => {
    const obj = {};
    array.forEach(({ id, name }) => {
        obj[id] = name;
    });
    return obj;
};

const getTooltipRoom = ({
    infoRoomName,
    commonZoneData,
    commonZoneGroupData,
    tvLocationData,
    tvLocationCZData,
    roomsData,
    roomsGroupsData,
    wingsData,
    guestData,
    guestGroupsData,
    floorsData,
    t,
}) => {
    let tooltip = "";
    const typesData = {
        commonZoneData,
        commonZoneGroupData,
        tvLocationData,
        tvLocationCZData,
        roomsData,
        roomsGroupsData,
        wingsData,
        guestData,
        guestGroupsData,
        floorsData,
    };
    const otherTypes = infoRoomName.otherTypes;

    for (let i = 0; i < otherTypes.length; i++) {
        const el = otherTypes[i];
        const typeData = el.typeData;
        const type = el.type;
        const mainType = infoRoomName.mainType;
        const id = el.id;

        let name = "";

        switch (typeData) {
            case TYPES_FILTERS_DATA.COUNTRIES:
                name = t(`country:${id.toLowerCase()}`);
                break;
            case TYPES_FILTERS_DATA.LANGUAGES:
                name = t(`language:${id}`);
                break;
            default:
                name = typesData?.[typeData]?.[id] || "";
                break;
        }

        if (name && mainType !== type) {
            if (tooltip !== "") {
                tooltip += " | ";
            }
            tooltip += name;
        }
    }

    return tooltip;
};

const getTypeInfo = (reason) => {
    if (reason === TYPE_FILTERS_KEYS.COUNTRIES) {
        return { typeData: TYPES_FILTERS_DATA.COUNTRIES, type: TYPES_FILTERS.COUNTRIES };
    } else if (reason === TYPE_FILTERS_KEYS.FLOORS_DATA) {
        return { typeData: TYPES_FILTERS_DATA.FLOORS_DATA, type: TYPES_FILTERS.FLOORS };
    } else if (reason === TYPE_FILTERS_KEYS.TVLOCATION) {
        return { typeData: TYPES_FILTERS_DATA.TVLOCATION, type: TYPES_FILTERS.TVLOCATION };
    } else if (reason === TYPE_FILTERS_KEYS.LANGUAGES) {
        return { typeData: TYPES_FILTERS_DATA.LANGUAGES, type: TYPES_FILTERS.LANGUAGES };
    } else if (reason === TYPE_FILTERS_KEYS.ROOMS_GROUPS) {
        return { typeData: TYPES_FILTERS_DATA.ROOMS_GROUPS, type: TYPES_FILTERS.ROOMS_GROUPS };
    } else if (reason === TYPE_FILTERS_KEYS.ROOMS) {
        return { typeData: TYPES_FILTERS_DATA.ROOMS, type: TYPES_FILTERS.ROOMS };
    } else if (reason === TYPE_FILTERS_KEYS.STAY_GROUPS) {
        return { typeData: TYPES_FILTERS_DATA.STAY_GROUPS, type: TYPES_FILTERS.STAY_GROUPS };
    } else if (reason === TYPE_FILTERS_KEYS.STAY_GUESTS) {
        return { typeData: TYPES_FILTERS_DATA.STAY_GUESTS, type: TYPES_FILTERS.STAY_GUESTS };
    } else if (reason === TYPE_FILTERS_KEYS.STAY_GUEST_ROOMS) {
        return { typeData: TYPES_FILTERS_DATA.STAY_GUEST_ROOMS, type: TYPES_FILTERS.STAY_GUEST_ROOMS };
    } else if (reason === TYPE_FILTERS_KEYS.WINGS) {
        return { typeData: TYPES_FILTERS_DATA.WINGS, type: TYPES_FILTERS.WINGS };
    } else if (reason === TYPE_FILTERS_KEYS.COMMONZONE) {
        return { typeData: TYPES_FILTERS_DATA.COMMONZONE, type: TYPES_FILTERS.COMMONZONE };
    } else if (reason === TYPE_FILTERS_KEYS.COMMONZONEGROUP) {
        return { typeData: TYPES_FILTERS_DATA.COMMONZONE, type: TYPES_FILTERS.COMMONZONE };
    } else if (reason === TYPE_FILTERS_KEYS.TVLOCATIONCZ) {
        return { typeData: TYPES_FILTERS_DATA.TVLOCATIONCZ, type: TYPES_FILTERS.TVLOCATIONCZ };
    } else {
        return { typeData: reason, type: reason };
    }
};

const convertArrayToObjectNameSurname = (array) => {
    const obj = {};
    array.forEach(({ id, name, surname }) => {
        obj[id] = `${name} ${surname}`;
    });
    return obj;
};

const mergeAndTagArrays = (data) => {
    return Object.entries(data).reduce((acc, [key, val]) => {
        if (Array.isArray(val)) {
            const enrichedArray = val.map((item) => ({ ...item, type: key }));
            return acc.concat(enrichedArray);
        }
        return acc;
    }, []);
};

const arrangeDestinationValues = (destination) => {
    const result = {};

    const getKeyDestination = (key) => {
        if (key === "countries") {
            return "country";
        } else if (key === "floors") {
            return "floor";
        } else if (key === "languages") {
            return "language";
        } else if (key === "roomGroups") {
            return "roomGroup";
        } else if (key === "stayGroups") {
            return "guestGroup";
        } else if (key === "stayGuests") {
            return "guest";
        } else if (key === "stayGuestRooms") {
            return "guestRoom";
        } else if (key === "rooms") {
            return "room";
        } else if (key === "wings") {
            return "wing";
        } else if (key === "tvLocation") {
            return "roomTvsTpGuestRoom";
        } else if (key === "commonZone") {
            return "roomsTpCommonZone";
        } else if (key === "tvLocationZone") {
            return "roomTvsTpCommonZone";
        } else if (key === "commonZoneGroup") {
            return "roomGroupsTpCommonZone";
        } else {
            return "";
        }
    };

    let index = 0;

    for (const key in destination) {
        if (destination.hasOwnProperty(key) && key !== "__typename") {
            if (key === "all" && destination["all"] === true) {
                result[`filter_${index}`] = ["all"];
                index++;
            } else if (key !== "all") {
                result[`filter_${index}`] = [getKeyDestination(key)];
                result[`filter_${index}_ids`] = [];
                if (Array.isArray(destination[key])) {
                    destination[key].forEach((objDest) => {
                        for (const key in objDest) {
                            if (key !== "__typename") {
                                result[`filter_${index}_ids`].push(objDest[key]);
                            }
                        }
                    });
                }
                index++;
            }
        }
    }

    return result;
};

const TYPES_FILTERS = {
    TVLOCATION: "tvLocation",
    ROOMS: "rooms",
    ROOMS_GROUPS: "roomGroups",
    STAY_GROUPS: "stayGroups",
    STAY_GUESTS: "stayGuests",
    STAY_GUEST_ROOMS: "stayGuestRooms",
    WINGS: "wings",
    FLOORS: "floors",
    LANGUAGES: "languages",
    COUNTRIES: "countries",
    TVLOCATIONCZ: "tvLocationZone",
    COMMONZONE: "commonZone",
    COMMONZONEGROUP: "commonZoneGroup",
};

const TYPES_FILTERS_DATA = {
    TVLOCATION: "tvLocationData",
    ROOMS: "roomsData",
    ROOMS_GROUPS: "roomsGroupsData",
    STAY_GROUPS: "guestGroupsData",
    STAY_GUESTS: "guestData",
    STAY_GUEST_ROOMS: "guestRoomData",
    WINGS: "wingsData",
    FLOORS_DATA: "floorsData",
    LANGUAGES: "language",
    COUNTRIES: "country",
    TVLOCATIONCZ: "tvLocationCZData",
    COMMONZONE: "commonZoneData",
    COMMONZONEGROUP: "commonZoneGroupData",
};

const TYPE_FILTERS_KEYS = {
    TVLOCATION: "roomTvTpGuestRoomIDs",
    ROOMS: "roomIDs",
    ROOMS_GROUPS: "roomGroupIDs",
    STAY_GROUPS: "stayGroupIDs",
    STAY_GUESTS: "stayGuestIDs",
    STAY_GUEST_ROOMS: "stayGuestRoomIDs",
    WINGS: "wingIDs",
    FLOORS_DATA: "floorIDs",
    LANGUAGES: "languageRefs",
    COUNTRIES: "countryRefs",
    TVLOCATIONCZ: "roomTvTpCommonZoneIDs",
    COMMONZONE: "roomTpCommonZoneIDs",
    COMMONZONEGROUP: "roomGroupTpCommonZoneIDs",
};

const getName = ({
    filter,
    dataInfo,
    roomsData,
    roomsGroupsData,
    guestGroupsData,
    guestData,
    wingsData,
    floorsData,
    commonZoneData,
    commonZoneGroupData,
    t,
}) => {
    if (filter.type === TYPES_FILTERS.ROOMS) {
        return roomsData[filter.id];
    } else if (filter.type === TYPES_FILTERS.ROOMS_GROUPS) {
        return roomsGroupsData[filter.id];
    } else if (filter.type === TYPES_FILTERS.STAY_GROUPS) {
        return guestGroupsData[filter.id];
    } else if (filter.type === TYPES_FILTERS.STAY_GUESTS) {
        return guestData[filter.id];
    } else if (filter.type === TYPES_FILTERS.WINGS) {
        return wingsData[filter.id];
    } else if (filter.type === TYPES_FILTERS.LANGUAGES) {
        return t(`language:${filter.ref}`);
    } else if (filter.type === TYPES_FILTERS.FLOORS) {
        return floorsData[filter.id];
    } else if (filter.type === TYPES_FILTERS.COUNTRIES) {
        return t(`country:${filter.ref.toLowerCase()}`);
    } else if (filter.type === TYPES_FILTERS.TVLOCATION) {
        if (dataInfo && dataInfo.rooms && dataInfo.rooms.results) {
            for (let room of dataInfo.rooms.results) {
                if (room.roomTVs && Array.isArray(room.roomTVs)) {
                    const tvFound = room.roomTVs.find((tv) => tv.id === filter.id);
                    if (tvFound) {
                        return `${room.name} - ${tvFound.name}`;
                    }
                }
            }
        }
        return "";
    } else if (filter.type === TYPES_FILTERS.TVLOCATIONCZ) {
        if (dataInfo && dataInfo.commonZone && dataInfo.commonZone.results) {
            for (let zone of dataInfo.commonZone.results) {
                if (zone.roomTVs && Array.isArray(zone.roomTVs)) {
                    const tvFound = zone.roomTVs.find((tv) => tv.id === filter.id);
                    if (tvFound) {
                        return `${zone.name} - ${tvFound.name}`;
                    }
                }
            }
        }
        return "";
    } else if (filter.type === TYPES_FILTERS.COMMONZONE) {
        return commonZoneData[filter.id];
    } else if (filter.type === TYPES_FILTERS.COMMONZONEGROUP) {
        return commonZoneGroupData[filter.id];
    } else {
        return "";
    }
};

const extractFloors = (data) => {
    const floorsArray = data.map((obj) => obj.floors);
    const flattenedFloorsArray = floorsArray.flat();
    return flattenedFloorsArray;
};

const countNumberFilters = (data) => {
    return Object.values(data).filter((prop) => Array.isArray(prop) && prop.length > 0).length;
};

const findMatchingRoomNames = (array1, array2, array3) => {
    let result = [];

    array2.forEach((item2) => {
        let searchKey = "";

        if (item2.type === TYPES_FILTERS.ROOMS) {
            searchKey = TYPE_FILTERS_KEYS.ROOMS;
        } else if (item2.type === TYPES_FILTERS.TVLOCATION) {
            searchKey = TYPE_FILTERS_KEYS.TVLOCATION;
        } else if (item2.type === TYPES_FILTERS.WINGS) {
            searchKey = TYPE_FILTERS_KEYS.WINGS;
        } else if (item2.type === TYPES_FILTERS.ROOMS_GROUPS) {
            searchKey = TYPE_FILTERS_KEYS.ROOMS_GROUPS;
        } else if (item2.type === TYPES_FILTERS.STAY_GUESTS) {
            searchKey = TYPE_FILTERS_KEYS.STAY_GUESTS;
        } else if (item2.type === TYPES_FILTERS.STAY_GUEST_ROOMS) {
            searchKey = TYPE_FILTERS_KEYS.STAY_GUEST_ROOMS;
        } else if (item2.type === TYPES_FILTERS.COUNTRIES) {
            searchKey = TYPE_FILTERS_KEYS.COUNTRIES;
        } else if (item2.type === TYPES_FILTERS.LANGUAGES) {
            searchKey = TYPE_FILTERS_KEYS.LANGUAGES;
        } else if (item2.type === TYPES_FILTERS.STAY_GROUPS) {
            searchKey = TYPE_FILTERS_KEYS.STAY_GROUPS;
        } else if (item2.type === TYPES_FILTERS.FLOORS) {
            searchKey = TYPE_FILTERS_KEYS.FLOORS_DATA;
        } else if (item2.type === TYPES_FILTERS.COMMONZONE) {
            searchKey = TYPE_FILTERS_KEYS.COMMONZONE;
        } else if (item2.type === TYPES_FILTERS.COMMONZONEGROUP) {
            searchKey = TYPE_FILTERS_KEYS.COMMONZONEGROUP;
        } else if (item2.type === TYPES_FILTERS.TVLOCATIONCZ) {
            searchKey = TYPE_FILTERS_KEYS.TVLOCATIONCZ;
        }

        if (
            item2.type === TYPES_FILTERS.COMMONZONE ||
            item2.type === TYPES_FILTERS.COMMONZONEGROUP ||
            item2.type === TYPES_FILTERS.TVLOCATIONCZ ||
            item2.type === TYPES_FILTERS.TVLOCATION
        ) {
            const matchingItems = [];
            const otherTypes = [];

            result = [...result.filter((entry) => entry.id !== item2.id)];

            if (array3?.commonZone?.results && Array.isArray(array3.commonZone.results)) {
                array3.commonZone.results.forEach((zone) => {
                    if (zone.id === item2.id) {
                        if (zone.roomTVs && zone.roomTVs.length > 0) {
                            zone.roomTVs.forEach((tv) => {
                                matchingItems.push({
                                    roomName: `${zone.name} - ${tv.name}`,
                                    otherTypes,
                                    mainType: item2.type,
                                    roomId: zone.id,
                                });
                            });
                        }
                    }
                });
            }

            if (array3?.commonZoneGroup?.results && Array.isArray(array3.commonZoneGroup.results)) {
                const matchingGroup = array3.commonZoneGroup.results.find((group) => group.id === item2.id);
                if (matchingGroup) {
                    if (matchingGroup.rooms && matchingGroup.rooms.length > 0) {
                        matchingGroup.rooms.forEach((room) => {
                            matchingItems.push({
                                roomName: `${room.name}`,
                                otherTypes,
                                mainType: item2.type,
                                roomId: matchingGroup.id,
                            });
                        });
                    }
                }
            }

            if (array3?.commonZone?.results && Array.isArray(array3.commonZone.results)) {
                array3.commonZone.results.forEach((zone) => {
                    if (zone.roomTVs && Array.isArray(zone.roomTVs)) {
                        zone.roomTVs.forEach((tv) => {
                            if (tv.id === item2.id) {
                                matchingItems.push({
                                    roomName: tv.name,
                                    otherTypes,
                                    mainType: item2.type,
                                    roomId: tv.id,
                                });
                            }
                        });
                    }
                });
            }

            if (array3?.rooms?.results && Array.isArray(array3.rooms.results)) {
                array3.rooms.results.forEach((zone) => {
                    if (zone.roomTVs && Array.isArray(zone.roomTVs)) {
                        zone.roomTVs.forEach((tv) => {
                            if (tv.id === item2.id) {
                                matchingItems.push({
                                    roomName: tv.name,
                                    otherTypes,
                                    mainType: item2.type,
                                    roomId: tv.id,
                                });
                            }
                        });
                    }
                });
            }

            result = [
                ...result.filter((entry) => entry.id !== item2.id),
                {
                    id: item2.id,
                    ref:
                        item2.type === TYPES_FILTERS.COUNTRIES || item2.type === TYPES_FILTERS.LANGUAGES
                            ? item2.ref
                            : null,
                    type: item2.type,
                    rooms: matchingItems,
                },
            ];
        } else if (searchKey) {
            const matchingItems = [];
            array1.forEach((item1) => {
                const otherTypes = [];

                if (item2.type === TYPES_FILTERS.COUNTRIES || item2.type === TYPES_FILTERS.LANGUAGES) {
                    if (
                        item1.reason &&
                        Array.isArray(item1.reason[searchKey]) &&
                        item1.reason[searchKey].includes(item2.ref)
                    ) {
                        for (const reasonKey in item1.reason) {
                            if (Array.isArray(item1.reason[reasonKey])) {
                                item1.reason[reasonKey].forEach((id) => {
                                    otherTypes.push({
                                        typeData: getTypeInfo(reasonKey).typeData,
                                        type: getTypeInfo(reasonKey).type,
                                        id: id,
                                    });
                                });
                            }
                        }
                        matchingItems.push({
                            roomName: item1.roomName,
                            otherTypes,
                            mainType: item2.type,
                            roomId: item1.roomId,
                        });
                    }
                } else {
                    if (
                        item1.reason &&
                        Array.isArray(item1.reason[searchKey]) &&
                        item1.reason[searchKey].includes(item2.id)
                    ) {
                        for (const reasonKey in item1.reason) {
                            if (Array.isArray(item1.reason[reasonKey])) {
                                item1.reason[reasonKey].forEach((id) => {
                                    otherTypes.push({
                                        typeData: getTypeInfo(reasonKey).typeData,
                                        type: getTypeInfo(reasonKey).type,
                                        id: id,
                                    });
                                });
                            }
                        }
                        matchingItems.push({
                            roomName: item1.roomName,
                            otherTypes,
                            mainType: item2.type,
                            roomId: item1.roomId,
                        });
                    }
                }
            });

            result = [
                ...result,
                {
                    id:
                        item2.type !== TYPES_FILTERS.COUNTRIES && item2.type !== TYPES_FILTERS.LANGUAGES
                            ? item2.id
                            : null,
                    ref:
                        item2.type === TYPES_FILTERS.COUNTRIES || item2.type === TYPES_FILTERS.LANGUAGES
                            ? item2.ref
                            : null,
                    type: item2.type,
                    rooms: matchingItems,
                },
            ];
        }
    });

    return [...result];
};
