import React, { useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import { useNavigate as useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setRefreshContentData, setExecuteQuery } from "actions/sectionActions";
import { setRefreshData } from "actions/tableActions";
import { setLoading } from "actions/tableActions";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { gql } from "apollo-boost";
import { showGlobalLoading, closeModal } from "actions/uiActions";
import { removeApolloCacheKeys } from "hooks/Utils/Utils";
import { cleanAction } from "actions/globalActions";
import { ACTIONS_MOBILE_SETTINGS, getFilterSocialMedia } from "./MobileSettingsUtils";
import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";
import { useNavigate } from "react-router-dom";

const UpdateMobileInformationCard = ({ client, setCardInEdition }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { values } = useSelector((state) => state.action);
    const { isCorporate } = useAuth();

    const navigateTo = useNavigate();

    const [mutation, setMutation] = useState(`
    mutation {
        createHotspot(name: "test") {
          ok
          error
          id
        }
      }`);

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            toast.error(err.message);
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            executeQuery.redirectOnSuccessUrl = null;
            execute = true;

            switch (executeQuery.action) {
                case ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_INFORMATION_CARD:
                    setMutation(`mutation{
                        updateMobileApp(
                            id: ${executeQuery.params.id}
                            configApp:{
                                urlAlias: "${executeQuery.params.urlAlias}"
                                iconAssetRef: "${executeQuery.params.logoRef}"
                            }
                            url: "${executeQuery.params.domain.toLowerCase()}.${executeQuery.params.subDomain}"
                            name: "${executeQuery.params.name}"
                            shortName: "${executeQuery.params.name}"
                        ){
                            error
                            ok
                        }
                    }`);
                    setCardInEdition(null);
                    dispatch(showGlobalLoading(true));
                    executeQuery.mutationName = "updateMobileApp";
                    break;
                case ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_CHAT_FEATURE:
                    const regex = /(\+\d+)\s+(\d+)/;
                    const match = values["phone"].match(regex);

                    let countryCode = "";
                    let localNumber = "";

                    if (match) {
                        countryCode = match[1];
                        localNumber = match[2];
                    }

                    setMutation(`mutation{
                        updateProjects(projects:[
                            {
                                id: ${executeQuery.params.id}
                                information:{ 
                                    chatNumber: "${localNumber}"
                                    chatNumberLada: "${countryCode}"
                                    }
                            }
                        ]
                        ){
                            error
                            ok
                        }
                    }`);
                    executeQuery.mutationName = "updateProjects";
                    setCardInEdition(null);
                    dispatch(showGlobalLoading(true));
                    break;
                case ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_SOCIAL_MEDIA:
                    const socialMedia = getFilterSocialMedia(executeQuery.params.dataSocialNetworksSelector);
                    setMutation(`mutation{
                        updateMobileApp(
                            id: ${executeQuery.params.id}
                            configApp:{
                                socialNetworks: ${socialMedia}
                            }
                        ){
                            error
                            ok
                        }
                    }`);
                    executeQuery.mutationName = "updateMobileApp";
                    setCardInEdition(null);
                    dispatch(showGlobalLoading(true));
                    break;
                case ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_DELETE_MOBILE_APP:
                    setMutation(`mutation {
                        deleteMobileApp(id: ${executeQuery.params.data.id} , ${
                        !Session.isCorporate() ? `projectRef: "${Session.getProject()?.ref}"` : ""
                    }) {
                            error
                            id
                            ok
                        }
                    }`);
                    executeQuery.mutationName = "deleteMobileApp";
                    dispatch(setRefreshContentData(true));
                    dispatch(showGlobalLoading(true));
                    break;
                case ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_PROPERTY_INFORMATION:
                    const number = values["mobile-phone"] ? values["mobile-phone"].split(" ")[1] : "";
                    const lada = values["mobile-phone"] ? values["mobile-phone"].split(" ")[0] : "";
                    const picture =
                        values["browser-media-library-items-selected"] &&
                        values["browser-media-library-items-selected"][0]
                            ? values["browser-media-library-items-selected"][0]
                            : executeQuery.params.data.image;
                    setMutation(`mutation{
                        updateProjects(projects:[
                            {
                                id: ${executeQuery.params.data.id}
                                information:{ 
                                    chatNumber: "${number}"
                                    chatNumberLada: "${lada}"
                                    picture: "${picture}"
                                }
                            }
                        ]
                        ){
                            error
                            ok
                        }
                    }`);
                    executeQuery.mutationName = "updateProjects";
                    dispatch(setRefreshContentData(true));
                    dispatch(showGlobalLoading(true));
                    break;
                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && executeQuery && data[executeQuery.mutationName] && data[executeQuery.mutationName].ok === true) {
            toast.success(t("operation-successful"));
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(showGlobalLoading(false));
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
            dispatch(closeModal());

            if (executeQuery.action === ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_DELETE_MOBILE_APP && isCorporate) {
                navigateTo("/all-properties/mobile-apps");
            }

            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateMobileInformationCard);
