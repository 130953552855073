import { t } from "i18next";
import useModalSelectLibraryContent from "./useModalSelectLibraryContent";

export const useSelectLibraryContent = ({
    afterSaveAction,
    libraryModalTitle = t("choose-an-image"),
    libraryModalSubtitle = t("select-an-image-of-the-media-library"),
    filters = "asset",
    hideFilter = true,
    isMultiple = false,
}) => {
    const { open: openLibraryModal, close: closeLibraryModal } = useModalSelectLibraryContent();

    const openSelectLibraryModal = () => {
        return openLibraryModal({
            title: libraryModalTitle,
            subtitle: libraryModalSubtitle,
            isMultiple,
            params: {
                filters,
                hideFilter,
                isMultiple,
                onSaveAction: (selectedRefs) => {
                    if (!selectedRefs?.[0]?.ref) return;
                    closeLibraryModal();
                    afterSaveAction(selectedRefs[0].ref);
                },
            },
        });
    };

    return openSelectLibraryModal;
};
