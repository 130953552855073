export const getParseMapName = (name, langDefault) => {
    try {
        const parsed = name ? JSON.parse(name) : null;
        return parsed && typeof parsed === "object"
            ? parsed[langDefault]
                ? parsed[langDefault]
                : Object.values(parsed).find((val) => val != "" && val != null)
            : "";
    } catch (error) {
        console.error("Error parsed name", error);
        return null;
    }
};
export const parseMapsOptions = (data, lang = "en") => {
    if (!data || !data.maps || !Array.isArray(data.maps.results)) return [];

    return data.maps.results.map((item) => {
        let nameObj = {};
        try {
            nameObj = JSON.parse(item.name);
        } catch (e) {
            console.error("Error parsed name", e);
        }
        const firstAvailableLang = Object.keys(nameObj)[0];
        const label = nameObj[lang] || (firstAvailableLang ? nameObj[firstAvailableLang] : "");

        return {
            label: label,
            value: item.id,
        };
    });
};

export const setDefaultValues = (data, value, defaultL, setter) => {
    if (data?.[value]) {
        const result = JSON.parse(data?.[value])?.[defaultL];
        if (result) {
            setter(result);
        } else {
            setter("");
        }
    }
};
