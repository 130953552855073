import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

// Components
import UseSectionHeader from "../../useSectionHeader";
import Table from "components/ZafiroTable";
import Button from "components/Button";
import ProjectName from "components/ProjectName";

// Hooks
import useModalAddEditGroup from "hooks/GraphqlCalls/Settings/modals/useModalAddEditGroup";
import useModalDeletePropertyGroups from "hooks/GraphqlCalls/Settings/modals/useModalDeletePropertyGroups";
import useModalDuplicatePropertyGroup from "hooks/GraphqlCalls/Settings/modals/useModalDuplicatePropertyGroup";

import { useAuth } from "hooks/Session/auth";
import useBrands from "hooks/Data/useBrands";

const PropertyGroups = () => {
    const tableRef = useRef(null);

    const { t } = useTranslation();

    const { allProjects } = useAuth();
    const brands = useBrands();

    const { open: openModalAddEditGroup } = useModalAddEditGroup();
    const { open: openModalDeleteGroup } = useModalDeletePropertyGroups();
    const { open: openModalDuplicateGroup } = useModalDuplicatePropertyGroup();

    const columns = ["name", "properties"];

    const header = {
        name: { title: t("name"), sortable: true, width: "10%" },
        properties: { title: t("properties"), sortable: true, width: "90%" },
    };

    const filters = [
        {
            id: "properties-filter",
            title: t("properties"),
            multiple: true,
            options: allProjects?.map((project) => ({
                id: `property-filter-option-${project.id}`,
                value: project.id,
                label: project.name,
            })),
            onFilter: (values, row) => {
                return row?.projects?.some((p) => values?.includes(p?.id)) ? row : null;
            },
        },
    ];

    const rows = brands?.data?.map((brand) => {
        const rowActions = [
            {
                id: "edit-group",
                label: t("edit-group"),
                onClick: () => {
                    openModalAddEditGroup({
                        brand,
                        refetch: brands?.refetch,
                        projectsRefs: brand.projects?.map((p) => p.ref),
                    });
                },
            },
            {
                id: "duplicate-group",
                label: t("duplicate-group"),
                onClick: () => {
                    openModalDuplicateGroup({
                        name: brand.name,
                        refetch: brands?.refetch,
                        id: brand.id,
                    });
                },
            },

            {
                id: "delete-group",
                label: t("delete-group"),
                onClick: () => {
                    openModalDeleteGroup({
                        names: [brand.name],
                        refetch: brands?.refetch,
                        ids: [brand.id],
                        refs: [brand.ref],
                        projectsRefs: brand.projects?.map((p) => p.ref),
                    });
                },
            },
        ];
        return {
            ...brand,
            properties: (
                <PropertiesRow alt={brand?.projects?.length || 0} projects={brand.projects} key={brand.projects} />
            ),
            rowConfig: { actions: rowActions },
        };
    });

    const handleResetSelectedRows = () => {
        if (tableRef.current) {
            tableRef.current.resetSelectedRows();
        }
    };

    useEffect(() => {
        brands.load();
    }, []);

    return (
        <>
            <UseSectionHeader title={t("manager-propertyGroups")} />
            <main className="main-container">
                <Table
                    id="properties-groups-table"
                    ref={tableRef}
                    header={header}
                    search
                    cols={columns}
                    showCount
                    rows={rows}
                    filters={filters}
                    topRightCorner={
                        <Button
                            id="add-new-group"
                            className="btn btn-blue"
                            onClick={() => {
                                openModalAddEditGroup({ refetch: brands?.refetch });
                            }}
                        >
                            {t("add-group")}
                        </Button>
                    }
                    paginate
                    batchActions={{
                        options: [
                            {
                                id: "delete",
                                value: "delete",
                                label: t("delete"),
                            },
                        ],
                        onChange: (action, selectedRows) => {
                            if (action === "delete") {
                                openModalDeleteGroup({
                                    names: selectedRows.map((row) => row.name),
                                    refetch: brands?.refetch,
                                    isBatch: true,
                                    ids: selectedRows.map((row) => row.id),
                                    refs: selectedRows.map((row) => row.ref),
                                    handleResetSelectedRows,
                                });
                            }
                        },
                    }}
                />
            </main>
        </>
    );
};

const PropertiesRow = ({ projects }) => {
    const containerRef = useRef(null);
    const badgesRef = useRef([]);
    const [visibleCount, setVisibleCount] = useState(projects.length);

    const calculateVisibleProjects = () => {
        if (containerRef.current && badgesRef.current.length > 0) {
            const containerWidth = containerRef.current.offsetWidth;
            let totalWidth = 0;
            let count = 0;

            for (const badge of badgesRef.current) {
                if (badge) {
                    totalWidth += badge.offsetWidth;
                    if (totalWidth > containerWidth) {
                        break;
                    }
                    count++;
                }
            }
            setVisibleCount(count);
        }
    };

    useLayoutEffect(() => {
        calculateVisibleProjects();
    }, [projects]);

    const visibleProjects = projects.slice(0, visibleCount);
    const hiddenCount = projects.length - visibleCount;

    const generateTooltipContent = () => {
        return `<div class="mt-4">${projects
            .map((project) => {
                return `<div class="flex items-center mb-2">
                            <i class="icon icon-corporate-access mr-2"></i>
                            <div>${project.name}</div>
                        </div>`;
            })
            .join("")}</div>`;
    };

    return (
        <div ref={containerRef} style={{ maxWidth: "90%" }} className="w-full flex items-center gap-2 overflow-visible">
            {visibleProjects?.map((project) => (
                <div
                    key={project.id}
                    className={"bg-teal-500 text-white inline-flex items-center space-x-2 px-4 py-1 rounded"}
                >
                    <ProjectName name={project?.name} hasChainModule={project?.hasChainModule} />
                </div>
            ))}
            {hiddenCount > 0 && (
                <span
                    data-for="dangerous-html-tooltip"
                    data-tip={generateTooltipContent()}
                    className="text-zafiro-600 flex-shrink-0 whitespace-nowrap"
                >
                    +{hiddenCount}
                </span>
            )}
        </div>
    );
};

export default PropertyGroups;
