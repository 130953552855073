import React, { useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Destination from "../../Advertising/modals/modalDestination/Destination/Destination";
import { useSelectLibraryContent } from "../../Advertising/modals/signages/selectLibraryContent";
import { useUpdatePointOfInterest } from "../graphql/usePointOfInterest";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { DestinationType } from "../../Advertising/modals/modalDestination/Destination/utils/utils";
import { isAValidHTTPS } from "hooks/Utils/Services/ChannelsUtils";

export const NavigationCard = ({ data = {}, fetch }) => {
    const { t } = useTranslation();
    const { id, pointinterestid } = useParams();
    const { navigationAction } = data;
    const destinationRef = useRef(null);
    const hasInitialized = useRef(false);

    useEffect(() => {
        if (!hasInitialized.current && navigationAction && destinationRef.current) {
            destinationRef.current.updateDestinationDataWithReset({
                type: navigationAction.type,
                value: navigationAction.value || null,
            });
            destinationRef.current.setDestinationType(navigationAction.type);
            hasInitialized.current = true;
        }
    }, [navigationAction]);

    const { update } = useUpdatePointOfInterest({
        onCompleted: () => {
            toast.success(t("operation-successful"));
            if (fetch) fetch();
        },
    });

    const updateNavigation = useCallback(
        (navigation) => {
            update({
                variables: {
                    id: pointinterestid,
                    mapId: id,
                    navigation,
                },
            });
        },
        [pointinterestid, id, update]
    );

    const handleAssetSelection = useSelectLibraryContent({
        afterSaveAction: (selectedRef) => {
            const navigationValue = {
                type: DestinationType.MEDIA_FILE,
                value: selectedRef,
            };

            updateNavigation(navigationValue);
            destinationRef.current.updateDestinationDataWithReset(navigationValue);
        },
    });

    const handleChange = useCallback(
        (value) => {
            destinationRef.current.updateDestinationDataWithReset(value);
            updateNavigation(value);
        },
        [updateNavigation]
    );

    const handleBlurLink = useCallback(
        (value) => {
            if (!destinationRef.current) return;
            destinationRef.current.setLinkError(false);
            if (!isAValidHTTPS(value)) {
                toast.error(t("type-url"));
                destinationRef.current.setLinkError(true);
                return;
            }
            const navigationValue = {
                type: DestinationType.LINK,
                value,
            };

            destinationRef.current.updateDestinationDataWithReset(navigationValue);
            updateNavigation(navigationValue);
        },
        [updateNavigation, t]
    );

    return (
        <div className="bg-white rounded-md p-4 flex flex-col gap-4">
            <p className="font-bold text-2xl">{t("navigation")}</p>
            <p>{t("select-navigation-destination")}</p>
            <Destination
                ref={destinationRef}
                initialType={navigationAction?.type}
                initialDestinationValue={navigationAction?.value}
                onChange={handleChange}
                onHandleChooseAsset={handleAssetSelection}
                onBlurLink={handleBlurLink}
                excludeOptionsInMaps={[Number(id)]}
                alertMessages={{
                    [DestinationType.SCREEN]: {
                        text: t("note-that-only-screens-from-the-applied-design-can-be-selected"),
                        className: "text-gray-700",
                    },
                }}
            />
        </div>
    );
};

export default NavigationCard;
