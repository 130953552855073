import React, { useContext, useState, useRef, useEffect } from "react";
import Icon from "./Icon";
import Dropdown from "./Dropdown";
import Button from "./Button";
import classNames from "classnames";

import { GlobalContext } from "contexts/Global";

/**
 * DragAndDropMediaCard
 *
 * A draggable media card component that displays an image or a placeholder when the image fails to load.
 * It provides a dropdown menu for actions and shows a warning icon if specified.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.item - The media item data to be displayed.
 * @param {string} props.item.id - Unique identifier for the item.
 * @param {string} [props.item.label] - Label text for the item.
 * @param {string} [props.item.imgSrc] - Image source URL for the item.
 * @param {boolean} [props.item.showWarning=false] - Flag to show a warning icon.
 * @param {string} [props.item.tooltipWarning] - Tooltip text for the warning icon.
 * @param {string} [props.item.noImageIcon="no-image"] - Icon type to display when the image is missing.
 * @param {string} [props.item.noImageIconClass="text-gray-400"] - CSS class for the icon displayed when the image is missing.
 * @param {number} [props.item.noImageIconSize=3] - Size of the icon displayed when the image is missing.
 * @param {Object[]} props.actions - Array of actions for the dropdown menu.
 * @param {string} props.actions[].id - Unique identifier for the action.
 * @param {string} props.actions[].label - Visible text for the action.
 * @param {Function} props.actions[].onClick - Function to execute when the action is clicked.
 * @param {boolean} [props.actions[].disabled=false] - Whether the action is disabled.
 * @param {string} [props.actions[].tooltip] - Tooltip text for the action.
 * @param {number|string} props.indexItem - Index or identifier of the item in the sortable list.
 * @param {string} [props.className] - Additional CSS classes for the component container.
 *
 * @returns {JSX.Element} The rendered DragAndDropMediaCard component.
 */
const DragAndDropMediaCard = ({ item, actions, indexItem, className }) => {
    const { highlightComponent } = useContext(GlobalContext);
    const ddActionsRef = useRef(null);
    const nameRef = useRef(null);
    const [imageError, setImageError] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const containerClass = classNames({
        "py-8 select-none relative rounded bg-gray-900": true,
        "h-52": !className,
        "highlight-component": highlightComponent,
        [className]: className,
    });
    const dragAndDropIconClass = classNames("text-white cursor-move");
    const rowActionsIconClass = classNames("text-white hover:text-zafiro-300");
    const containerActionsClass = classNames(
        "select-none mt-1 rounded shadow-lg text-left border bg-white border-gray-100 text-gray-900 flex flex-col"
    );
    const buttonActionClass = (disabled) =>
        classNames("px-4 py-2 w-full text-left", {
            "text-gray-500 hover:text-gray-500 cursor-not-allowed": disabled,
            "text-gray-900 hover:bg-gray-100": !disabled,
        });
    const containerMediaClass = classNames("w-full h-full flex items-center justify-center");
    const imageActionsClass = classNames("object-cover w-full h-full");
    const footerClass = classNames("absolute bottom-0 text-white p-1 w-full flex items-center");
    const warningIconClass = classNames("mr-1");
    const noImageClass = classNames("w-full bg-gray-200 h-full flex items-center justify-center");

    const handleMouseOver = () => {
        if (nameRef.current) {
            const isTruncated = nameRef.current.scrollWidth > nameRef.current.clientWidth;
            setShowTooltip(isTruncated);
        }
    };

    useEffect(() => {
        if (item.imgSrc) {
            setImageError(false);
        }
    }, [item]);

    return (
        <div className={containerClass}>
            <div className="absolute top-0 p-1 w-full flex justify-between">
                <Icon className={dragAndDropIconClass} type="drag-and-drop" size="1.3" />
                <Dropdown
                    id={`content-actions-dropdown-${item.id}`}
                    ref={ddActionsRef}
                    float={true}
                    showArrow={false}
                    width={"1rem"}
                    handler={<Icon type="row-actions" size="1.3" className={rowActionsIconClass} />}
                    overflowX="unset"
                >
                    <div
                        style={{ position: "absolute", left: "-9rem", width: "10rem" }}
                        className={containerActionsClass}
                    >
                        {actions.map((action, index) => {
                            const id = `opt-actions-${action.id || index + 1}`;
                            return (
                                <Button
                                    id={id}
                                    key={id}
                                    disabled={action.disabled}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        action.onClick(e, item);
                                        if (ddActionsRef.current) {
                                            ddActionsRef.current.close();
                                        }
                                    }}
                                    tooltip={action.tooltip}
                                    className={buttonActionClass(action.disabled)}
                                >
                                    {action.label}
                                </Button>
                            );
                        })}
                    </div>
                </Dropdown>
            </div>
            <div className={containerMediaClass}>
                {item.imgSrc && !imageError ? (
                    <img
                        src={item.imgSrc}
                        alt={item.label}
                        draggable={false}
                        className={imageActionsClass}
                        onError={() => setImageError(true)}
                    />
                ) : (
                    <div className={noImageClass}>
                        <Icon
                            className={item?.noImageIconClass || "text-gray-400"}
                            size={item?.noImageIconSize || 3}
                            type={item?.noImageIcon || "no-image"}
                        />
                    </div>
                )}
            </div>

            <div className={footerClass}>
                {!isNaN(Number(indexItem)) ? <div className="mr-1">{Number(indexItem) + 1}.</div> : null}
                {item?.showWarning ? (
                    <Icon tooltip={item?.tooltipWarning || null} className={warningIconClass} type="warning" />
                ) : null}
                <div
                    data-tip={showTooltip ? item?.name : ""}
                    data-for={"default-tooltip"}
                    ref={nameRef}
                    onMouseLeave={() => setShowTooltip(false)}
                    onMouseOver={handleMouseOver}
                    className="whitespace-nowrap overflow-hidden overflow-ellipsis"
                >
                    {item?.name || ""}
                </div>
            </div>
        </div>
    );
};

export default DragAndDropMediaCard;
