import React, { useEffect, useState } from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import { useListGroups } from "hooks/GraphqlCalls/Customers/useListGroups";
import Table from "components/ZafiroTable";
import Loading from "components/Loading";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";
import { AddGroup } from "components/Section/Hotel/Rooms/AddGroup";

const GuestGroups = () => {
    const [search, setSearch] = useState("");
    const { t } = useTranslation();
    const { open, close } = useModal();
    const { groupData, ready, loadingRefresh, setLoadingRefresh, refetch, getGuestGroup } = useListGroups();

    useEffect(() => {
        getGuestGroup({
            variables: {
                search,
            },
        });
    }, [search]);

    return (
        <>
            <UseSectionHeader title={"guestgroups"} />
            <div className="main-container">
                {!loadingRefresh && groupData ? (
                    <Table
                        id="guest-groups"
                        customSearch={{ value: search, onChange: setSearch }}
                        search={true}
                        showCount={true}
                        header={{
                            "group-name": { title: t("group-name"), sortable: true, width: "30%" },
                            "number-of-guests": { title: t("number-of-guests"), sortable: true, width: "40%" },
                            edit: { title: "", width: "10%" },
                            delete: { title: "", width: "10%" },
                        }}
                        cols={["group-name", "number-of-guests", "edit", "delete"]}
                        rows={groupData}
                        topRightCorner={
                            <Button
                                id="add-group"
                                className="absolute right-8 btn-blue z-50"
                                onClick={() =>
                                    open(
                                        <Modal
                                            id="add-room-group"
                                            title={<h1 className="text-2xl font-bold">{t("new-group-of-guests")}</h1>}
                                            minWidth="30rem"
                                            className="w-8/12 p-10"
                                            onKeyPress={{ Enter: close }}
                                        >
                                            <AddGroup
                                                refetch={refetch}
                                                setLoadingRefresh={setLoadingRefresh}
                                                type="guest"
                                            />
                                        </Modal>
                                    )
                                }
                            >
                                {t("new-group")}
                            </Button>
                        }
                    />
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </div>
        </>
    );
};

export default GuestGroups;
