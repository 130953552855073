import Button from "components/Button";
import Checkbox from "components/Inputs/Checkbox";
import Loading from "components/Loading";
import NumberInput from "components/NumberInput";
import TextInput from "components/TextInput";
import { useHotelMap, useTopologyValidation, useWing } from "hooks/GraphqlCalls/Hotel/useTopologyMap";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import UseSectionHeader from "../../../useSectionHeader";
import { Session } from "hooks/Utils/Session";
import { updateTvsPerRoom } from "hooks/GraphqlCalls/Hotel/utils/topologyUtils";
import { FloorDetails } from "./TopologyComponents/FloorsDetail";

export const AddWing = () => {
    const { t } = useTranslation();
    const [floorSettings, setFloorSettings] = useState([]);
    const [wingName, setWingName] = useState("");
    const [pmsNumber, setPmsNumber] = useState("[room_number]");
    const [checked, setChecked] = useState(false);
    const [wingOrder, setWingOrder] = useState(0);
    const [floorsNumber, setFloorsNumber] = useState(1);

    const { loading } = useHotelMap();
    const { createWing } = useWing();
    const { validate, wingNameValidation, floorNameValidation, tvsPerRoomValidation, starterRoomValidation } =
        useTopologyValidation();
    const projectRef = Session.getProject()?.ref;
    const prevLocation = `/hotel/property-settings/topology`;

    const handleSave = () => {
        const newWing = {
            wingName,
            wingOrder,
            pmsNumber,
            floors: checked
                ? floorSettings.map((floor, index) => ({
                      floorOrder: index + 1,
                      floorName: floor.floorName,
                      numbersOfRooms: floor.numbersOfRooms,
                      starterRoom: floor.starterRoom,
                      tvsPerRoom: floor.tvsPerRoom,
                  })) || []
                : Array.from({ length: floorsNumber }, (_, i) => ({
                      floorOrder: floorSettings[0].floorOrder + i,
                      floorName: `Floor ${floorSettings[0].floorOrder + i}`,
                      numbersOfRooms: floorSettings[0].numbersOfRooms,
                      starterRoom: floorSettings[0].starterRoom + i * 100,
                      tvsPerRoom: floorSettings[0].tvsPerRoom,
                  })) || [],
        };
        if (validate(newWing)) {
            createWing(projectRef, newWing);
        }
    };

    return (
        <>
            <UseSectionHeader title="add-wing" navToPreviousPage={true} />

            <div className="bg-white p-6">
                {!loading ? (
                    <>
                        <div className="mt-6">
                            <WingDetails
                                floorsNumber={floorsNumber}
                                setFloorsNumber={setFloorsNumber}
                                floorSettings={floorSettings}
                                setFloorSettings={setFloorSettings}
                                wingName={wingName}
                                setWingName={setWingName}
                                pmsNumber={pmsNumber}
                                setPmsNumber={setPmsNumber}
                                setWingOrder={setWingOrder}
                                checked={checked}
                                wingNameValidation={wingNameValidation}
                                floorNameValidation={floorNameValidation}
                                tvsPerRoomValidation={tvsPerRoomValidation}
                                starterRoomValidation={starterRoomValidation}
                                setChecked={setChecked}
                            />
                        </div>
                        <div className="flex gap-3 mt-6 justify-end">
                            <Button id="cancel-wing-room" className="btn btn-white" to={prevLocation}>
                                {t("cancel")}
                            </Button>
                            <Button id="add-wing-room" className="btn btn-blue" onClick={handleSave}>
                                {t("save")}
                            </Button>
                        </div>
                    </>
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </div>
        </>
    );
};

const WingDetails = ({
    floorSettings,
    floorsNumber,
    setFloorsNumber,
    setFloorSettings,
    wingName,
    setWingName,
    pmsNumber,
    setPmsNumber,
    setWingOrder,
    checked,
    setChecked,
    wingNameValidation,
    floorNameValidation,
    tvsPerRoomValidation,
    starterRoomValidation,
}) => {
    const [tvsPerRoom, setTvsPerRoom] = useState({ 0: [{ name: "", CEC: true }] });

    const { results: installationMap } = useHotelMap();
    const { t } = useTranslation();

    useEffect(() => {
        if (installationMap) {
            setWingOrder(Number(installationMap.length + 1));
            setWingName(`Wing ${installationMap.length + 1}`);
        }
    }, [installationMap]);

    const handleFloorsNumberChange = useCallback(
        (value) => {
            setFloorsNumber(Number(value));
            if (checked) {
                updateTvsPerRoom(Number(value), setTvsPerRoom, setFloorSettings);
            }
        },
        [setTvsPerRoom, setFloorSettings, checked]
    );

    return (
        <>
            <div className="flex gap-3">
                <div className="flex flex-col w-1/5">
                    <label className="fieldLabel">{t("wing-name")}</label>
                    <TextInput
                        value={wingName}
                        onChange={(value) => setWingName(value)}
                        className={wingNameValidation}
                    />
                </div>
                <div className="flex flex-col w-1/5">
                    <label className="fieldLabel">{t("pms-number")}</label>
                    <TextInput value={pmsNumber} onChange={(value) => setPmsNumber(value)} />
                </div>
                <FloorsNumber
                    setChecked={setChecked}
                    floorsNumber={floorsNumber}
                    handleFloorsNumberChange={handleFloorsNumberChange}
                />
            </div>
            {floorsNumber === 0
                ? null
                : Array.from({ length: checked ? floorsNumber : 1 }, (_, i) => (
                      <FloorDetails
                          isRoom={true}
                          key={i}
                          id={i}
                          checked={checked}
                          starterRoomValidation={starterRoomValidation}
                          installationMap={installationMap}
                          setFloorSettings={setFloorSettings}
                          floorNameValidation={floorNameValidation}
                          tvsPerRoomValidation={tvsPerRoomValidation}
                      />
                  ))}
        </>
    );
};

export const FloorsNumber = ({ setChecked, floorsNumber, handleFloorsNumberChange }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex flex-col ">
                <label className="fieldLabel">{t("floors-number")}</label>
                <NumberInput value={floorsNumber} onChange={handleFloorsNumberChange} />
            </div>
            <Checkbox
                label={t("customize-each-floor")}
                className="mt-4"
                onChange={(value) => setChecked(value?.checked)}
            />
        </>
    );
};
