export const GetDesignTableHeaders = (section, isCorporateAccess) => {
    let res = [];
    //Sort icon
    const tableSortIcon = { name: "table-sort" };

    //Special width classes
    const w0p512 = "w-0.5/12";
    const w112 = "w-1/12";
    const w212 = "w-2/12";
    const w312 = "w-3/12";
    const w412 = "w-4/12";
    const w512 = "w-5/12";
    const w612 = "w-6/12";
    const w812 = "w-8/12";
    // const w512 = "w-5/12";
    //const w612 = "w-6/12";

    //Header types
    let emptyCell = { name: "" };
    let nameLower = { name: "name", sortName: "lower(name)" };
    let name = { name: "name", sortName: "name" };
    let target = { name: "type", sortName: "target" };
    let property = { name: "property", sortName: "projectName" };
    let value = { name: "value", sortName: "value" };
    let description = { name: "description", sortName: "description" };
    let templates = { name: "templates", sortName: "name" };
    let screenType = { name: "screen-type", sortName: "type" };
    let viewport = { name: "viewport" };
    let lastModified = { name: "last-modified", sortName: "time" };
    let type = { name: "type", sortName: "type" };
    let size = { name: "size" };
    let design = { name: "design", sortName: "name" };
    let author = { name: "author" };
    let lastUpdate = { name: "last-update", sortName: "lastUpdatedTime" };
    let creator = { name: "creator" };
    let properties = { name: "properties" };
    let redirection = { name: "redirection", sortName: "link" };
    let macExceptions = { name: "mac-exceptions" };

    switch (section) {
        case "list-media-library":
            nameLower.icon = tableSortIcon;
            lastModified.icon = tableSortIcon;
            size.icon = tableSortIcon;
            type.icon = tableSortIcon;

            emptyCell.specialWidth = w112;
            nameLower.specialWidth = w412;
            lastModified.specialWidth = w312;
            type.specialWidth = w212;
            size.specialWidth = w212;
            res.push(emptyCell, nameLower, type, lastModified, size);
            break;
        case "list-templates":
            templates.icon = tableSortIcon;
            screenType.icon = tableSortIcon;

            templates.specialWidth = w512;
            screenType.specialWidth = w412;
            viewport.specialWidth = w112;
            emptyCell.specialWidth = w212;

            res.push(templates, screenType, viewport, emptyCell);
            break;
        case "list-tags":
            name.icon = tableSortIcon;
            target.icon = tableSortIcon;
            description.icon = tableSortIcon;

            name.specialWidth = w312;
            target.specialWidth = w212;
            description.specialWidth = w612;
            emptyCell.specialWidth = w112;

            res.push(name, target, description, emptyCell);
            break;
        case "list-tags-values":
            property.icon = tableSortIcon;

            property.specialWidth = w312;
            value.specialWidth = w812;
            emptyCell.specialWidth = w0p512;

            res.push(property, value, emptyCell);
            break;
        case "list-hotspots":
            name.specialWidth = w412;
            name.icon = tableSortIcon;
            redirection.specialWidth = w412;
            redirection.icon = tableSortIcon;
            macExceptions.specialWidth = w412;
            res.push(name, redirection, macExceptions);
            break;
        default:
            res = [""];
    }
    return res;
};

export default GetDesignTableHeaders;
