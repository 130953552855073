import { gql } from "apollo-boost";

export const GUEST_GROUPS = gql`
    {
        data: guestGroups {
            results {
                id
                name
            }
        }
    }
`;

export const GET_GUESTS = gql`
    query Guests(
        $page: Int!
        $room: [Int64]
        $guestGroup: [Int64]
        $search: String
        $stayFrom: String
        $stayTo: String
        $orderBy: OrderBy
    ) {
        guests: guests(
            page: $page
            size: 6
            orderBy: $orderBy
            filter: {
                checkedOut: false
                room: $room
                guestGroup: $guestGroup
                search: $search
                stayFrom: $stayFrom
                stayTo: $stayTo
            }
            mainGuest: true
        ) {
            info {
                count
            }
            results {
                birthDate
                countryRef
                email
                id
                languageRef
                name
                phone
                surname
                loyaltyRef
                lastSatisfactionLevel
                rooms {
                    StayGuestRoomID
                    roomID
                    number
                    stayFrom
                    stayTo
                }
                guestGroups {
                    id
                    name
                }
            }
        }
    }
`;

export const FILTERS_INFO = gql`
    {
        rooms: guests(mainGuest: true, filter: { checkedOut: false }) {
            results {
                rooms {
                    roomID
                    name
                    number
                }
                guestGroups {
                    id
                    name
                }
            }
        }
    }
`;

export const CHANGE_ROOM = gql`
    mutation ChangeRoom($stayGuestID: Int64!, $oldRoomID: Int64!, $newRoomID: Int64!) {
        changeRoom(stayGuestID: $stayGuestID, oldRoomID: $oldRoomID, newRoomID: $newRoomID) {
            error
            id
            ok
        }
    }
`;

export const GUEST_GROUPS_SELECTED = gql`
    query GuestGroups($id: Int64) {
        data: guestGroups {
            results {
                id
                name
            }
        }
        selected: guests(id: $id) {
            results {
                guestGroups {
                    id
                    name
                }
            }
        }
    }
`;

export const ASSIGN_TO_GROUP = gql`
    mutation UpdateGuest($id: Int64!, $groups: [Int64]) {
        updateGuest(id: $id, groups: $groups) {
            error
            id
            ok
        }
    }
`;

//TODO FILTERS
export const stayBookingQuery = gql`
    query StayBookings($page: Int!, $state: [String], $orderBy: OrderBy, $stayFrom: String, $stayTo: String) {
        guests: stayBookings(
            page: $page
            size: 6
            orderBy: $orderBy
            filter: { state: $state, stayFrom: $stayFrom, stayTo: $stayTo }
        ) {
            info {
                count
                page
                size
            }
            results {
                id
                code
                reservationDate
                arrivalDate
                departureDate
                numberOfGuests
                numberOfRooms
                pendingChangesSend
                observations
                state
                stayHolderEmail
                stayHolderName
                stayHolderSurname
                guests {
                    birthDate
                    countryRef
                    documentExtraInformation
                    documentNumber
                    documentType
                    email
                    gender
                    guestRole
                    id
                    languageRef
                    name
                    phoneNumber
                    stayBookingRoomIndex
                    surname
                }
            }
        }
    }
`;

export const CHECKOUT = gql`
    mutation CheckOut($rooms: [Int64]) {
        checkOut(rooms: $rooms) {
            error
            id
            ok
        }
    }
`;

export const editGuestGroupQuery = gql`
    mutation updateGuestGroup($guests: [Int64]!, $id: Int64!) {
        updateGuestGroup(id: $id, batch: false, guests: $guests) {
            error
            id
            ok
        }
    }
`;

export const DELETE_UPCOMING_CHECK_IN = gql`
    mutation DeleteStayBooking($id: Int64!) {
        deleteStayBooking(id: $id) {
            error
            id
            ok
        }
    }
`;

export const DELETE_UPCOMING_CHECK_INS = gql`
    mutation DeleteStayBookings($ids: [Int64]!) {
        deleteStayBookings(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const RESEND_SELF_CHECK_IN = gql`
    mutation SendStayBookingReminder($id: Int64!) {
        sendStayBookingReminder(id: $id) {
            error
            ok
        }
    }
`;

export const ADD_BOOKING = gql`
    mutation CreateStayBooking(
        $code: String!
        $numberOfRooms: Int!
        $numberOfGuests: Int!
        $arrivalDate: Date!
        $departureDate: Date
        $stayHolderSurname: String!
        $stayHolderName: String!
        $stayHolderEmail: String!
        $pmsID: String
    ) {
        createStayBooking(
            code: $code
            numberOfRooms: $numberOfRooms
            numberOfGuests: $numberOfGuests
            arrivalDate: $arrivalDate
            departureDate: $departureDate
            stayHolderSurname: $stayHolderSurname
            stayHolderName: $stayHolderName
            stayHolderEmail: $stayHolderEmail
            pmsID: $pmsID
        ) {
            error
            id
            ok
        }
    }
`;

export const ADD_CHECKIN = gql`
    mutation CheckInFromStayBooking(
        $stayBookingID: Int64!
        $mainGuestID: Int64!
        $rooms: [CheckInFromStayBookingRoom]!
    ) {
        checkInFromStayBooking(stayBookingID: $stayBookingID, mainGuestID: $mainGuestID, rooms: $rooms) {
            error
            id
            ok
        }
    }
`;
