import React from "react";
import { useTranslation } from "react-i18next";

import Modal, { useModal } from "components/Modal";
import ProjectsList from "components/ProjectsList";
import Button from "components/Button";
import { useFlattenProjectsDestinations } from "hooks/Utils/DesignUtils";

const DesignProjects = ({ projects: forceProjects, destinations, creator }) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const flattenDestinations = useFlattenProjectsDestinations();

    const projects = forceProjects || flattenDestinations(destinations);

    return (
        <Modal
            id="properties-with-access"
            className="w-1/3"
            title={t("Properties")}
            footer={
                <Button design="blue" id="modal-button-close" onClick={close}>
                    {t("close")}
                </Button>
            }
        >
            <div className="flex flex-col gap-5">
                <div>{t("The following properties have access to the global design")}</div>
                <ProjectsList projects={projects} creator={creator} />
            </div>
        </Modal>
    );
};

export default DesignProjects;
