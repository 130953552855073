import { useState, useEffect } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { SessionKibana } from "../../../hooks/Utils/SessionKibana";

const LOGIN_KIBANA = gql`
    query loginKibana($projectRefs: [String]) {
        loginKibana(projectRefs: $projectRefs) {
            isOk
            cookie
            dashboards {
                id
                name
                url
                masterDashboard
                categoryName
                order
            }
        }
    }
`;

const LoginKibana = () => {
    return GetKibana({ login: true });
};

export const DashboardsKibana = () => {
    const { login, loading, error, dashboards } = GetKibana({ login: false });
    return {
        getDashboards: login,
        loading,
        error,
        dashboards,
    };
};

const GetKibana = ({ login }) => {
    const [kibana, setKibana] = useState(null);
    const [query, { data, loading, error }] = useLazyQuery(LOGIN_KIBANA, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (data && data.loginKibana) {
            setKibana(data.loginKibana);
        }
    }, [setKibana, data]);

    const dashboards = (kibana ? kibana.dashboards : null) || null;
    if (!loading && !error && login) {
        const cookie = (kibana ? kibana.cookie : null) || "";
        SessionKibana.open(cookie);
    }
    let groups = {};
    if (dashboards && dashboards.length > 0) {
        dashboards
            .map((item) => {
                // Master dashboards (grouped by category)
                if (item.categoryName && !item.masterDashboard) {
                    if (!groups[item.categoryName]) {
                        groups[item.categoryName] = { name: item.categoryName, items: {} };
                    }
                    groups[item.categoryName].items[item.id] = { ...item, items: [] };
                }
                return item;
            })
            .map((item) => {
                // Reports with no master found, treated as dashboards
                if (
                    item.categoryName &&
                    item.masterDashboard &&
                    (!groups[item.categoryName] || !groups[item.categoryName].items[item.masterDashboard])
                ) {
                    if (!groups[item.categoryName]) {
                        groups[item.categoryName] = { name: item.categoryName, items: {} };
                    }
                    groups[item.categoryName].items[item.id] = { ...item, items: [] };
                }
                return item;
            })
            .map((item) => {
                // Reports
                if (
                    item.categoryName &&
                    item.masterDashboard &&
                    groups[item.categoryName].items[item.masterDashboard]
                ) {
                    item.default = item.order != null;
                    groups[item.categoryName].items[item.masterDashboard].items.push(item);
                }
                return item;
            });

        if (groups) {
            if (groups["Internal"]) {
                const internalItems = groups["Internal"].items;
                if (groups["Cast"]) {
                    Object.keys(internalItems).forEach((key) => {
                        groups["Cast"].items[key] = internalItems[key];
                    });
                } else {
                    groups["Cast"] = { name: "Cast", items: internalItems };
                }
                delete groups["Internal"];
            }
        }

        Object.keys(groups).forEach((key) => {
            let group = groups[key];
            let items = [];
            Object.keys(group.items).forEach((k) => {
                let i = group.items[k];
                i.default = i.order != null;
                items.push(i);
            });
            items.sort((a, b) => {
                if (a.default && b.default) {
                    return a.order === b.order ? 0 : a.order < b.order ? -1 : 1;
                } else if (a.default) {
                    return -1;
                } else if (b.default) {
                    return 1;
                }
                return 0;
            });
            group.items = items;
            groups[key] = group;
        });
    }

    return {
        login: (vars) => {
            return query({ variables: vars });
        },
        loading: loading,
        error: error,
        dashboards: groups,
    };
};

export default LoginKibana;
