import React, { useEffect, useState } from "react";
import Translations from "components/Translations";
import { CARDS_SETTINGS, isInEdition, SATISFACTION_QUESTION_TEMPLATE, cancelEdit, UPDATE_SETTINGS } from "../utils";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/Session/auth";
import Button from "components/Button";
import useModalConfirmTranslationQuestion from "../modals/useModalConfirmTranslationQuestion";

const SettingsSatisfactionQuestion = ({ cardInEdition, setCardInEdition, SET_EXECUTE, dataModified }) => {
    const { languages: projectLangs } = useAuth();
    const defaultLang = projectLangs?.find((lang) => lang.isDefault)?.languageRef;
    const otherlangs = projectLangs?.filter((lang) => lang.isDefault === false).map((lang) => lang.languageRef);

    const { open: openModalConfirmTranslationQuestion, close: closeModalConfirmTranslationQuestion } =
        useModalConfirmTranslationQuestion();

    const { t } = useTranslation();

    const [translations, setTranslations] = useState([]);

    const questionTranslation = translations.find((el) => el.languageCode === defaultLang)?.name || "";
    const questionDefaultEn = translations.find((el) => el.languageCode === "en")?.name || "";
    const questionDefaultEs = translations.find((el) => el.languageCode === "es")?.name || "";
    const questionDefault = translations.find((el) => el.name)?.name || "";

    useEffect(() => {
        if (typeof dataModified?.satisfactionQuestion === "string") {
            try {
                const translationsObject = JSON.parse(dataModified?.satisfactionQuestion);

                if (
                    translationsObject &&
                    typeof translationsObject === "object" &&
                    !Array.isArray(translationsObject)
                ) {
                    const projectLangsArray = projectLangs?.length ? projectLangs.map((p) => p.languageRef) : [];
                    const translationsArray = Object.entries(translationsObject).map(([languageCode, name]) => ({
                        languageCode,
                        name,
                    }));

                    setTranslations(translationsArray.filter((t) => projectLangsArray.includes(t.languageCode)));
                }
            } catch (error) {
                console.error("Error parsing JSON:", error);
                setTranslations([]);
            }
        }
    }, [dataModified, cardInEdition]);

    return (
        <div>
            <div className=" mb-3">{t("set-up-the-question-that-will-appear-on-the-guest-interface")}</div>
            <div className=" text-gray-700">
                {!isInEdition({ card: CARDS_SETTINGS.SATISFACTION_QUESTION, cardInEdition })
                    ? questionTranslation || questionDefaultEn || questionDefaultEs || questionDefault
                    : null}
            </div>

            {isInEdition({ card: CARDS_SETTINGS.SATISFACTION_QUESTION, cardInEdition }) ? (
                <Translations
                    id="settings-satisfaction-question-translations"
                    languages={otherlangs}
                    defaultLang={defaultLang}
                    placeholder={questionDefaultEn}
                    onChange={(translations) => {
                        const projectLangsArr = projectLangs.map((el) => el.languageRef);
                        const updatedTranslations = projectLangsArr.map((lang) => {
                            const translation = translations.find((el) => el.languageCode === lang);
                            return translation || { languageCode: lang, name: "" };
                        });

                        const isEmptyTranslations = updatedTranslations.every((lang) => lang.name?.trim() === "")
                            ? true
                            : false;

                        if (isEmptyTranslations) {
                            setTimeout(() => {
                                const resetTemplateButton = document.querySelector(
                                    "#settings-satisfaction-question-translations-template"
                                );
                                if (resetTemplateButton) {
                                    resetTemplateButton.click();
                                }
                            }, 100);
                        }

                        setTranslations(!isEmptyTranslations ? updatedTranslations : SATISFACTION_QUESTION_TEMPLATE);
                    }}
                    translations={translations}
                    maxLength={100}
                    displayCount={true}
                    templateContainerClassName={" flex justify-end"}
                    template={SATISFACTION_QUESTION_TEMPLATE}
                />
            ) : null}

            {isInEdition({ card: CARDS_SETTINGS.SATISFACTION_QUESTION, cardInEdition }) && (
                <div className="flex justify-end space-x-2 mt-10">
                    <Button
                        onClick={() => cancelEdit(setCardInEdition)}
                        id="settings-satisfaction-level-cancel-button"
                        design="blue-outline"
                    >
                        {t("cancel")}
                    </Button>

                    <Button
                        onClick={() => {
                            openModalConfirmTranslationQuestion({
                                onSave: () => {
                                    const allEmpty = translations.every((item) => item.name.trim() === "");
                                    const TRANSLATIONS = allEmpty
                                        ? SATISFACTION_QUESTION_TEMPLATE
                                        : translations.map((item) => ({
                                              text: item.name.trim(),
                                              lang: item.languageCode,
                                          }));
                                    SET_EXECUTE({
                                        ACTION: UPDATE_SETTINGS.SATISFACTION_QUESTION,
                                        PARAMS: {
                                            TRANSLATIONS,
                                        },
                                    });
                                    closeModalConfirmTranslationQuestion();
                                },
                            });
                        }}
                        id="settings-satisfaction-level-save-button"
                        design="blue"
                    >
                        {t("save")}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default SettingsSatisfactionQuestion;
