import React, { useState, useEffect, useRef } from "react";
import Button from "components/Button";
import Icon from "components/Icon";
import classNames from "classnames";

/**
 * ImagePlayer component for displaying an image with playback controls, including a progress bar and optional navigation buttons.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {number} [props.duration=0] - Total duration of the "playback" in seconds.
 * @param {Function} [props.onPrev] - Callback function executed when the "Previous" button is clicked.
 * @param {Function} [props.onNext] - Callback function executed when the "Next" button is clicked.
 * @param {string} props.imgSrc - Source URL for the image to be displayed.
 * @param {string} [props.id="image-player"] - Base ID used to construct unique IDs for various elements.
 * @param {boolean} [props.hideButtonsNavigations=false] - Determines if navigation buttons (previous/next) should be hidden.
 *
 * @example
 * <ImagePlayer
 *   duration={60}
 *   onPrev={() => console.log("Previous")}
 *   onNext={() => console.log("Next")}
 *   imgSrc="https://example.com/image.jpg"
 *   id="custom-player"
 *   hideButtonsNavigations={true}
 * />
 *
 * @returns {JSX.Element} The rendered ImagePlayer component.
 */

const ImagePlayer = ({
    duration = 0,
    onPrev,
    onNext,
    imgSrc,
    id = "image-player",
    hideButtonsNavigations = false,
    type = "ASSET",
}) => {
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [isPlaying, setIsPlaying] = useState(true);
    const hasFinishedRef = useRef(false);
    const [imageError, setImageError] = useState(false);
    const progressBarRef = useRef(null);
    const [showControls, setShowControls] = useState(true);
    const hideControlsTimeoutRef = useRef(null);

    const updateProgress = () => {
        setTimeElapsed((prevTime) => {
            const newTime = prevTime + 0.1;
            if (newTime >= duration) {
                if (!hasFinishedRef.current) {
                    hasFinishedRef.current = true;
                    setIsPlaying(false);
                    if (onNext) onNext();
                }
                return 0;
            }
            return newTime;
        });
    };

    const togglePlayPause = () => {
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
        if (!isPlaying) hasFinishedRef.current = false;
    };

    const seek = (e) => {
        const progressBar = progressBarRef.current;
        const rect = progressBar.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const newTime = (clickX / rect.width) * duration;
        setTimeElapsed(newTime);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? "0" + secs : secs}`;
    };

    useEffect(() => {
        let timer;
        if (isPlaying) {
            timer = setInterval(updateProgress, 100);
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [isPlaying]);

    const progressPercentage = (timeElapsed / duration) * 100;

    const handleMouseEnter = () => {
        if (hideControlsTimeoutRef.current) {
            clearTimeout(hideControlsTimeoutRef.current);
            hideControlsTimeoutRef.current = null;
        }
        setShowControls(true);
    };

    const handleMouseLeave = () => {
        hideControlsTimeoutRef.current = setTimeout(() => {
            setShowControls(false);
        }, 2000);
    };

    useEffect(() => {
        return () => {
            if (hideControlsTimeoutRef.current) {
                clearTimeout(hideControlsTimeoutRef.current);
            }
        };
    }, []);

    return (
        <div
            id={`${id}-container`}
            className="image-player-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Button onClick={togglePlayPause} id={`${id}-button`} className="w-full h-full">
                {imgSrc && !imageError ? (
                    <img
                        className={classNames("cursor-pointer", {
                            "image-player-image": type === "ASSET",
                            "image-player-screen": type === "SCREEN",
                        })}
                        src={imgSrc}
                        onError={() => setImageError(true)}
                        alt="Media content"
                    />
                ) : (
                    <div className="h-full w-full bg-gray-200 flex items-center justify-center">
                        <Icon className="text-gray-400" size={5} type="no-image" />
                    </div>
                )}
            </Button>
            <div className={classNames("image-player-controls", { "controls-hidden": !showControls })}>
                {!hideButtonsNavigations && (
                    <Button id={`${id}-prev-button`} className="image-player-prev-button" onClick={onPrev}>
                        <Icon className="image-player-icon-prev" type="PlayOptions-previous" size={2} />
                    </Button>
                )}
                <Button id={`${id}-play-pause-button`} onClick={togglePlayPause}>
                    {isPlaying ? (
                        <Icon className="image-player-toggle-play-pause" type="Pause" size={1.8} />
                    ) : (
                        <Icon className="image-player-toggle-play-pause" type="Play" size={1.8} />
                    )}
                </Button>
                {!hideButtonsNavigations && (
                    <Button id={`${id}-next-button`} className="image-player-next-button" onClick={onNext}>
                        <Icon className="image-player-icon-next" type="PlayOptions-next" size={2} />
                    </Button>
                )}

                <div className="image-player-progress-container">
                    <div className="image-player-progress-bar" ref={progressBarRef} onClick={seek}>
                        <div className="image-player-progress" style={{ width: `${progressPercentage}%` }}>
                            <div className="image-player-progress-handle" style={{ left: `100%` }}></div>
                        </div>
                        <div className="image-player-tooltip-time" style={{ left: `${progressPercentage}%` }}>
                            {formatTime(timeElapsed)}
                        </div>
                    </div>
                    <div className="image-player-time">
                        <span>{formatTime(timeElapsed)}</span> <span>{formatTime(duration)}</span>
                    </div>
                </div>
                <Button id={`${id}-mute-button`} className="image-player-mute-button text-gray-700" disabled={true}>
                    <Icon className="image-player-icon-mute" type="mute" size={1.3} />
                </Button>
            </div>
        </div>
    );
};

export default ImagePlayer;
