import React, { useState, forwardRef, useImperativeHandle, useRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import IconPicker from "./IconPicker";
import { Radiobutton } from "./Inputs/Radiobuttons";
import Button from "./Button";
import InteractiveImageDisplay from "./InteractiveImageDisplay";
import TextInput from "./TextInput";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Session } from "hooks/Utils/Session";

export const TYPE_IMAGES = {
    LIBRARY: "library",
    LINK: "link",
    ICON: "icon",
};

export const ORIENTATION = {
    HORIZONTAL: "horizontal",
    VERTICAL: "vertical",
};

const generateId = (base, suffix) => `${base}-${suffix}`;

const getInitialSelectionState = () => ({
    [TYPE_IMAGES.LIBRARY]: { value: null, validation: true, src: null },
    [TYPE_IMAGES.LINK]: { value: null, validation: true, src: null },
    [TYPE_IMAGES.ICON]: { value: null, validation: true, src: null },
});

export const isAValidImageURL = (url) => {
    const regex = /^https:\/\/.+(\/img\/|\/image\/|\/media\/).+/i;
    const extensionRegex = /^https:\/\/.+\.(png|jpg|jpeg|gif|webp)(\?.*)?$/i;
    return regex.test(url) || extensionRegex.test(url);
};

const SelectorImageIcon = forwardRef(
    (
        {
            id,
            allowedImageTypes = Object.values(TYPE_IMAGES),
            nameAsset,
            onImageSelect,
            onIconSelect,
            onLinkSelect,
            onDelete,
            onError,
            onTypeSelect,
            orientation = ORIENTATION.HORIZONTAL,
            imageContainerHeightWidth = "h-32 w-32",
            buttonChooseImageDesign = "blue",
        },
        ref
    ) => {
        if (!id) throw new Error("id is required");
        const { t } = useTranslation();
        const textInputRef = useRef(null);

        const [activeImageType, setActiveImageType] = useState(allowedImageTypes[0]);
        const [selectionState, setSelectionState] = useState(getInitialSelectionState());
        const [isValidLinkImage, setIsValidLinkImage] = useState(false);

        useEffect(() => {
            if (onTypeSelect && typeof onTypeSelect == "function") {
                onTypeSelect(activeImageType);
            }
        }, [activeImageType]);

        const updateSelectionState = useCallback(({ type, value, validation = true, src = null }) => {
            setSelectionState((prevState) => {
                return {
                    ...prevState,
                    [TYPE_IMAGES.LIBRARY]: {
                        ...prevState[TYPE_IMAGES.LIBRARY],
                        ...(type === TYPE_IMAGES.LIBRARY && {
                            value,
                            validation,
                            src: src !== null ? src : prevState[TYPE_IMAGES.LIBRARY].src,
                        }),
                    },
                    [TYPE_IMAGES.LINK]: {
                        ...prevState[TYPE_IMAGES.LINK],
                        ...(type === TYPE_IMAGES.LINK && {
                            value,
                            validation,
                            src: src !== null ? src : prevState[TYPE_IMAGES.LINK].src,
                        }),
                    },
                    [TYPE_IMAGES.ICON]: {
                        ...prevState[TYPE_IMAGES.ICON],
                        ...(type === TYPE_IMAGES.ICON && {
                            value,
                            validation,
                            src: src !== null ? src : prevState[TYPE_IMAGES.ICON].src,
                        }),
                    },
                };
            });
        }, []);

        const resetSelectionState = useCallback(() => {
            setSelectionState(getInitialSelectionState());
        }, []);

        useImperativeHandle(ref, () => ({
            updateData: updateSelectionState,
            resetData: resetSelectionState,
            getData: () => selectionState,
            setActiveType: (type) => {
                if (allowedImageTypes.includes(type)) {
                    setActiveImageType(type);
                }
            },
            getActiveType: () => activeImageType,
            getActiveSelectionValue: () => {
                if (selectionState[activeImageType] && selectionState[activeImageType].value !== null) {
                    return {
                        type: activeImageType,
                        value: selectionState[activeImageType].value,
                        src: selectionState[activeImageType].src,
                    };
                }
                return null;
            },
            updateImageData: (data, currentLang, fetchLibraryAssetName, validation = true) => {
                if (!data?.type) return;

                const { type, value } = data;
                const updateData = (src) => {
                    ref.current.setActiveType(type);
                    ref.current.updateData({ type, value, validation, src });
                };

                switch (type) {
                    case TYPE_IMAGES.LIBRARY:
                        updateData(Session.getDasUrl(`${value}?lang=${currentLang}`));
                        if (fetchLibraryAssetName) fetchLibraryAssetName({ variables: { ref: value } });
                        break;
                    case TYPE_IMAGES.ICON:
                        updateData({ ...value, size: "6x" });
                        break;
                    case TYPE_IMAGES.LINK:
                        updateData(value);
                        break;
                    default:
                        console.warn(`Unsupported image type: ${type}`);
                }
            },
        }));

        const handleIconSelect = useCallback(
            (value) => {
                if (onIconSelect) {
                    onIconSelect(value);
                } else {
                    updateSelectionState({
                        type: TYPE_IMAGES.ICON,
                        value,
                        validation: true,
                        src: { ...value, size: "6x" },
                    });
                }
            },
            [onIconSelect, updateSelectionState]
        );

        const handleLinkBlur = useCallback(
            (value) => {
                if (!isAValidImageURL(value)) {
                    toast.error(t("incorrect-url"));
                    if (onLinkSelect) {
                        onLinkSelect(value, false);
                    } else {
                        updateSelectionState({
                            type: TYPE_IMAGES.LINK,
                            value,
                            validation: false,
                        });
                    }
                    return;
                }
                if (onLinkSelect) {
                    onLinkSelect(value, true);
                } else {
                    updateSelectionState({
                        type: TYPE_IMAGES.LINK,
                        value,
                        validation: true,
                        src: value,
                    });
                }
            },
            [onLinkSelect, t, updateSelectionState]
        );

        const handleDelete = useCallback(() => {
            if (onDelete) onDelete();
            setSelectionState((prevState) => ({
                ...prevState,
                [activeImageType]: { value: null, validation: true, src: null },
            }));
        }, [onDelete, activeImageType]);

        const renderLibrarySection = () =>
            allowedImageTypes.includes(TYPE_IMAGES.LIBRARY) && (
                <div
                    className={classNames("flex", {
                        "mb-6": orientation === ORIENTATION.HORIZONTAL,
                        "flex-col mb-3": orientation === ORIENTATION.VERTICAL,
                    })}
                >
                    <Radiobutton
                        value={TYPE_IMAGES.LIBRARY}
                        checked={activeImageType === TYPE_IMAGES.LIBRARY}
                        onChange={({ value }) => setActiveImageType(value)}
                        id={generateId(id, "radio-library")}
                        label={orientation === ORIENTATION.VERTICAL ? t("add") : null}
                    />
                    <Button
                        disabled={activeImageType !== TYPE_IMAGES.LIBRARY}
                        className={classNames({
                            "font-bold my-3": orientation === ORIENTATION.VERTICAL,
                            "ml-3": orientation === ORIENTATION.HORIZONTAL,
                        })}
                        design={buttonChooseImageDesign}
                        id={generateId(id, "button-select-image")}
                        onClick={onImageSelect}
                    >
                        {t("choose-an-image")}
                    </Button>
                </div>
            );

        const renderLinkSection = () =>
            allowedImageTypes.includes(TYPE_IMAGES.LINK) && (
                <div className="mb-6 w-full">
                    <Radiobutton
                        label={t("link-url-https-only")}
                        value={TYPE_IMAGES.LINK}
                        checked={activeImageType === TYPE_IMAGES.LINK}
                        onChange={({ value }) => setActiveImageType(value)}
                        id={generateId(id, "radio-link")}
                    />
                    <TextInput
                        ref={textInputRef}
                        placeholder="https://"
                        id={generateId(id, "input-link")}
                        disabled={activeImageType !== TYPE_IMAGES.LINK}
                        value={selectionState[TYPE_IMAGES.LINK]?.value || ""}
                        className={classNames("mt-2 my-3 border", {
                            "border-red-500":
                                selectionState[TYPE_IMAGES.LINK]?.validation === false &&
                                activeImageType === TYPE_IMAGES.LINK,
                            "border-transparent": selectionState[TYPE_IMAGES.LINK]?.validation !== false,
                        })}
                        onChange={(value) => {
                            if (orientation === ORIENTATION.VERTICAL) {
                                setIsValidLinkImage(isAValidImageURL(value));
                            }
                        }}
                        onBlur={(value) => {
                            if (orientation === ORIENTATION.HORIZONTAL) {
                                handleLinkBlur(value);
                            }
                        }}
                    />
                    {orientation === ORIENTATION.VERTICAL && (
                        <div className="w-full flex justify-end">
                            <Button
                                id={generateId(id, "button-insert-link")}
                                className="font-bold"
                                design="blue-outline"
                                disabled={activeImageType !== TYPE_IMAGES.LINK || !isValidLinkImage}
                                onClick={() => {
                                    const textInputValue = textInputRef.current.getValue();
                                    handleLinkBlur(textInputValue);
                                }}
                            >
                                {t("insert")}
                            </Button>
                        </div>
                    )}
                </div>
            );

        const renderIconSection = () =>
            allowedImageTypes.includes(TYPE_IMAGES.ICON) && (
                <div className="mb-6">
                    <Radiobutton
                        label={t("predefined-icon")}
                        value={TYPE_IMAGES.ICON}
                        checked={activeImageType === TYPE_IMAGES.ICON}
                        onChange={({ value }) => setActiveImageType(value)}
                        id={generateId(id, "radio-icon")}
                    />
                </div>
            );

        return (
            <div className="w-full">
                <div
                    className={classNames("flex", {
                        "items-center gap-8 w-full": orientation === ORIENTATION.HORIZONTAL,
                        "flex-col": orientation === ORIENTATION.VERTICAL,
                    })}
                >
                    <div
                        className={classNames(imageContainerHeightWidth, {
                            "flex items-center justify-center ml-1": true,
                            "mb-4": orientation === ORIENTATION.VERTICAL,
                        })}
                    >
                        <InteractiveImageDisplay
                            onError={onError}
                            noImageIconSize={3}
                            resolution={null}
                            className={imageContainerHeightWidth}
                            deleteIconSize={1.8}
                            deleteIconType="delete-editor"
                            id={generateId(id, "image-selector")}
                            src={selectionState[activeImageType]?.src}
                            onDelete={handleDelete}
                            containerImageClass="w-full"
                            info={
                                selectionState[TYPE_IMAGES.LIBRARY]?.value &&
                                activeImageType === TYPE_IMAGES.LIBRARY &&
                                nameAsset
                                    ? nameAsset
                                    : null
                            }
                        />
                    </div>

                    <div className="flex flex-col">
                        {renderLibrarySection()}
                        {renderLinkSection()}
                        {renderIconSection()}
                    </div>
                </div>

                {activeImageType === TYPE_IMAGES.ICON && (
                    <div className="mt-6">
                        <IconPicker
                            onChange={handleIconSelect}
                            value={{
                                icon: selectionState[TYPE_IMAGES.ICON]?.src?.icon,
                                lib: selectionState[TYPE_IMAGES.ICON]?.src?.lib,
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
);

export default SelectorImageIcon;
