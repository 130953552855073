export const COLUMNS_MAPS = ["name", "updated_at", "linked_maps_num"];
export const HEADERS = ({ t }) => {
    return {
        name: { title: t("maps"), sortable: true },
        updated_at: { title: t("last-update"), sortable: true },
        linked_maps_num: { title: t("linked-maps"), sortable: true },
    };
};

export const MAP_TYPE = "map";
export const CATEGORY_TYPE = "category";
