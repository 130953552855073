import React, { createContext, useContext, useState } from "react";
import { useFetchMapData, useHideMenuAndSidebar, useMapController, useMapCategories } from "./hooks";

const MapContext = createContext();

export const TOOLBAR_MAP_OPTIONS = {
    INTEREST_POINTS: "INTEREST_POINTS",
    CATEGORIES: "CATEGORIES",
    SETTINGS_IMAGE: "SETTINGS_IMAGE",
};

export const MapProvider = ({ children }) => {
    useHideMenuAndSidebar();

    const fetchMapData = useFetchMapData();
    const mapController = useMapController();
    const fetchCategories = useMapCategories();

    const [sectionLoading, setSectionLoading] = useState(false);

    return (
        <MapContext.Provider
            value={{
                ...fetchMapData,
                ...mapController,
                ...fetchCategories,
                sectionLoading,
                setSectionLoading,
            }}
        >
            {children}
        </MapContext.Provider>
    );
};

export const useMap = () => {
    const context = useContext(MapContext);
    if (!context) {
        throw new Error("useMap must be used within a MapProvider");
    }
    return context;
};

export default MapContext;
