import React, { useCallback, useState } from "react";
import UseSectionHeader from "../../../useSectionHeader";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FloorsNumber } from "./AddWing";
import { useFloor, useHotelMap, useTopologyValidation } from "hooks/GraphqlCalls/Hotel/useTopologyMap";
import Button from "components/Button";
import { updateTvsPerRoom } from "hooks/GraphqlCalls/Hotel/utils/topologyUtils";
import { FloorDetails } from "./TopologyComponents/FloorsDetail";

export const AddFloor = () => {
    const [checked, setChecked] = useState(false);
    const [floorsNumber, setFloorsNumber] = useState(1);
    const [floorSettings, setFloorSettings] = useState([]);

    const { results: installationMap } = useHotelMap();
    const { id } = useParams();
    const { t } = useTranslation();
    const { validate, floorNameValidation, tvsPerRoomValidation, starterRoomValidation } = useTopologyValidation();
    const { createFloor } = useFloor();

    const wing = installationMap?.find((wing) => parseInt(wing.id) === parseInt(id));
    const wingName = wing?.name;

    const prevLocation = `/hotel/property-settings/topology`;

    return (
        <>
            <UseSectionHeader title={["add-floor", "(", wingName, ")"]} navToPreviousPage={true} />
            <div className="bg-white p-6">
                <div className="mt-6">
                    <FloorsDetails
                        checked={checked}
                        setChecked={setChecked}
                        floorsNumber={floorsNumber}
                        setFloorsNumber={setFloorsNumber}
                        setFloorSettings={setFloorSettings}
                        floorSettings={floorSettings}
                        installationMap={installationMap}
                        wing={wing}
                        floorNameValidation={floorNameValidation}
                        starterRoomValidation={starterRoomValidation}
                        tvsPerRoomValidation={tvsPerRoomValidation}
                    />
                </div>
                <div className="flex gap-3 mt-6 justify-end">
                    <Button id="cancel-add-floor" className="btn btn-white" to={prevLocation}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="add-floor"
                        className="btn btn-blue"
                        onClick={() => {
                            const newFloor = {
                                wingID: id,
                                floors: checked
                                    ? floorSettings.map((floor) => ({
                                          order: floorSettings[0].floorOrder + 1,
                                          floorName: floor.floorName,
                                          numbersOfRooms: floor.numbersOfRooms,
                                          starterRoom: floor.starterRoom,
                                          tvsPerRoom: floor.tvsPerRoom,
                                      })) || []
                                    : Array.from({ length: floorsNumber }, (_, i) => ({
                                          order: floorSettings[0].floorOrder + i,
                                          floorName: `Floor ${floorSettings[0].floorOrder + i}`,
                                          numbersOfRooms: floorSettings[0].numbersOfRooms,
                                          starterRoom: floorSettings[0].starterRoom + i * 100,
                                          tvsPerRoom: floorSettings[0].tvsPerRoom,
                                      })) || [],
                            };
                            if (validate(newFloor)) {
                                createFloor(id, newFloor);
                            }
                        }}
                    >
                        {t("save")}
                    </Button>
                </div>
            </div>
        </>
    );
};

const FloorsDetails = ({
    setFloorsNumber,
    setChecked,
    floorsNumber,
    checked,
    setFloorSettings,
    floorSettings,
    installationMap,
    wing,
    floorNameValidation,
    tvsPerRoomValidation,
    starterRoomValidation,
}) => {
    const [tvsPerRoom, setTvsPerRoom] = useState({ 0: [{ name: "", CEC: true }] });

    const handleFloorsNumberChange = useCallback(
        (value) => {
            setFloorsNumber(Number(value));
            if (checked) {
                updateTvsPerRoom(Number(value), setTvsPerRoom, setFloorSettings);
            }
        },
        [setTvsPerRoom, setFloorSettings, checked]
    );

    return (
        <>
            <div className="flex gap-3">
                <FloorsNumber
                    setChecked={setChecked}
                    floorsNumber={floorsNumber}
                    handleFloorsNumberChange={handleFloorsNumberChange}
                />
            </div>
            {floorsNumber === 0
                ? null
                : Array.from({ length: checked ? floorSettings?.length : 1 }, (_, i) => (
                      <FloorDetails
                          isRoom={true}
                          key={i}
                          id={i}
                          checked={checked}
                          installationMap={installationMap}
                          setFloorSettings={setFloorSettings}
                          floorLength={wing?.floors?.length}
                          pos={wing?.pos}
                          floorNameValidation={floorNameValidation}
                          starterRoomValidation={starterRoomValidation}
                          tvsPerRoomValidation={tvsPerRoomValidation}
                      />
                  ))}
        </>
    );
};
