import React, { useContext, useState, useEffect } from "react";

import { useSelector } from "react-redux";

//Componentes
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";

//Utils
import { Session } from "../../../../hooks/Utils/Session";

const WidgetVideo = () => {
    const { deviceType, lang } = useContext(EditorContext);
    const { config, type, id: widgetID } = useContext(WidgetContext);

    const { gridItems } = useSelector((state) => state.design);

    //States
    const [videoUrl, setVideoUrl] = useState(null);
    const [posterUrl, setPosterUrl] = useState(null);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);

    useEffect(() => {
        checkVideoUrl();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        checkVideoUrl();
        // eslint-disable-next-line
    }, [gridItems]);

    useEffect(() => {
        if (document.querySelector(`#widget_zone_${widgetID}`)) {
            document.querySelector(`#widget_zone_${widgetID}`).style.backgroundColor = videoUrl
                ? "transparent"
                : "inherit";
        }
        // eslint-disable-next-line
    }, [videoUrl]);

    const checkVideoUrl = () => {
        if (data && data.video && data.video[0].externalUrl) {
            setVideoUrl(data.video[0].externalUrl);
            setPosterUrl(null);
        } else if (data && data.video && data.video[0] && data.video[0].libraryRef) {
            const fileRef = data.video[0].libraryRef;
            const posterSrc = Session.getDasUrl(`thumb/${fileRef}-${lang}?w=633&h=336`);
            const videoSrc = Session.getDasUrl(`stream/${fileRef}-${lang}.m3u8`);
            setVideoUrl(videoSrc);
            setPosterUrl(posterSrc);
        } else {
            setVideoUrl(null);
            setPosterUrl(null);
        }
    };

    return (
        <>
            {videoUrl ? (
                <>
                    {videoUrl && videoUrl.indexOf("youtube") > -1 ? (
                        <>
                            <img
                                alt=""
                                src={`https://img.youtube.com/vi/${
                                    videoUrl.match(/^.*(\/embed\/)(.*)/) ? videoUrl.match(/^.*(\/embed\/)(.*)/)[2] : ""
                                }/0.jpg`}
                                title="video"
                                className="w-full h-full"
                            ></img>
                            <div className="absolute top-0 left-0  w-full h-full table text-center ">
                                <div className="table-cell align-middle">
                                    <div className={`icon  icon-play2 text-6xl`}></div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className=" h-full w-full relative">
                            <img className=" w-full h-full" src={posterUrl}></img>
                        </div>
                    )}
                </>
            ) : (
                <EmptyWidget type={type}></EmptyWidget>
            )}
        </>
    );
};

export default WidgetVideo;
