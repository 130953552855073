import React, { useRef, useLayoutEffect, useState } from "react";
import classNames from "classnames";
import { useMap, TOOLBAR_MAP_OPTIONS } from "../MapContext";
import SideBarInteresPoints from "./Content/SideBarInteresPoints";
import SideBarCategories from "./Content/SideBarCategories";
import SideBarSettingsImage from "./Content/SideBarSettingsImage";
import Header from "./Header";
import Footer from "./Footer";

const SideBar = () => {
    const { toolbarMapOptionSelected } = useMap();

    const containerClasses = classNames(
        "w-96 bg-white overflow-hidden rounded shadow-md flex flex-col h-full relative"
    );

    const headerRef = useRef(null);
    const footerRef = useRef(null);

    const [padding, setPadding] = useState({ top: 0, bottom: 0 });

    useLayoutEffect(() => {
        const updatePadding = () => {
            const headerHeight = headerRef.current ? headerRef.current.getBoundingClientRect().height : 0;
            const footerHeight = footerRef.current ? footerRef.current.getBoundingClientRect().height : 0;
            setPadding({ top: headerHeight, bottom: footerHeight });
        };

        updatePadding();
        window.addEventListener("resize", updatePadding);
        return () => window.removeEventListener("resize", updatePadding);
    }, [toolbarMapOptionSelected]);

    return (
        <div className={containerClasses}>
            <Header ref={headerRef} />
            <div className="flex-grow overflow-auto" style={{ marginTop: padding.top, marginBottom: padding.bottom }}>
                {toolbarMapOptionSelected === TOOLBAR_MAP_OPTIONS.INTEREST_POINTS && <SideBarInteresPoints />}
                {toolbarMapOptionSelected === TOOLBAR_MAP_OPTIONS.CATEGORIES && <SideBarCategories />}
                {toolbarMapOptionSelected === TOOLBAR_MAP_OPTIONS.SETTINGS_IMAGE && <SideBarSettingsImage />}
            </div>
            <Footer ref={footerRef} />
        </div>
    );
};

export default SideBar;
