import React, { useContext, useEffect, useState } from "react";
import { CastError } from "./Monitor/CastError";
import UseSectionHeader from "components/useSectionHeader";
import { useTranslation } from "react-i18next";
import { useIsDhcp } from "../Monitor/hooks/useIsDhcp";
import { useDispatch, useSelector } from "react-redux";
import NSStatus from "hooks/GraphqlCalls/Hotel/Monitoring/NSStatus";
import Loading from "components/Loading";
import { Session } from "hooks/Utils/Session";
import { AreYouSure } from "../Monitor/modalContent/ValidateNetworkModal";
import { NetworkAlertsContext, NetworkAlertsProvider } from "contexts/NetworkWarnings/NetworkAlerts";
import { useDhcpInfo } from "../Monitor/hooks/useDhcpInfo";
import { useMdnsInfo } from "../Monitor/hooks/useMdnsInfo";
import { useNetworkInfo } from "../Monitor/hooks/useNetworkInfo";
import { NetworkComponent } from "../Monitor/NetworkComponent";
import { CastingWarning } from "../Monitor/CastingWarning";
import { ValidateNetworkContext } from "contexts/NetworkWarnings/ValidateNetwork";
import Button from "components/Button";
import { DropdownSection } from "components/DropdownSection";

import { GlobalContext } from "contexts/Global";
import { useModal } from "components/Modal";

export const NetworkWarningWithContext = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [castWarning, setCastWarning] = useState();
    const [userWarning, setUserWarning] = useState();
    const { token } = useContext(GlobalContext);
    const { isPending, updateNetwork, controlToRefetch } = useContext(ValidateNetworkContext);
    const { open } = useModal();
    const {
        dhcpCriticalWarning,
        mdnsCriticalWarning,
        networkCriticalWarning,
        dhcpLowWarning,
        mdnsLowWarning,
        networkLowWarning,
    } = useContext(NetworkAlertsContext);

    const { serverError } = useSelector((state) => state.table);

    const { isDhcp } = useIsDhcp();
    const { dhcpInfo, called: dhcpCalled, loading: dhcpLoading, refetch: dhcpRefetch } = useDhcpInfo();
    const { mdnsInfo, called: mdnsCalled, loading: mdnsLoading, refetch: mdnsRefetch } = useMdnsInfo();
    const { networkInfo, called: networkCalled, loading: networkLoading, refetch: networkRefetch } = useNetworkInfo();
    const { isDhcpData, isAllData } = isDhcp || {};

    const castNetworkClass = isDhcpData ? "grid-cols-2" : "";
    const hasDhcp = isDhcpData ? (
        <NetworkComponent dhcpInfo={dhcpInfo} called={dhcpCalled} loading={dhcpLoading} type="DhcpInfo" />
    ) : null;

    const castSectionHeader = {
        title: {
            content: t("cast-network"),
            className: "font-bold",
        },
        titleIcon: {
            type: castWarning,
        },
    };

    const userSectionHeader = {
        title: {
            content: t("user-network"),
            className: "font-bold",
        },
        titleIcon: {
            type: userWarning,
        },
    };

    const dispatch = useDispatch();

    NSStatus({ customServerError: true });

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (dhcpCriticalWarning || mdnsCriticalWarning) {
            setCastWarning("critical");
        } else if (dhcpLowWarning || mdnsLowWarning) {
            setCastWarning("warning");
        }
    }, [dhcpCriticalWarning, mdnsCriticalWarning, dhcpLowWarning, mdnsLowWarning]);

    useEffect(() => {
        if (networkCriticalWarning) {
            setUserWarning("critical");
        } else if (networkLowWarning) {
            setUserWarning("warning");
        }
    }, [networkLowWarning, networkCriticalWarning]);

    const handleClick = () => open(<AreYouSure updateNetwork={updateNetwork} />);

    const exportNetworkReport = async () => {
        const projectName = Session.getProject()?.name || "Project";

        const URL = `${
            window.location.hostname === "localhost" ? "https://testing.zafiro.link" : ""
        }/upload/network-warnings-report`;
        const response = await fetch(URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/pdf",
                Authorization: token,
            },
        });

        if (response.ok) {
            const data = await response.blob();
            const url = window.URL.createObjectURL(data);
            const downloadLink = document.createElement("a");

            downloadLink.href = url;
            downloadLink.setAttribute("download", `${projectName}.pdf`);
            document.body.appendChild(downloadLink);
            downloadLink.click();

            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                downloadLink.remove();
            }, 100);
        } else {
            console.error("Error fetching the PDF");
        }
    };

    useEffect(() => {
        if (controlToRefetch === false && !isPending) {
            dhcpRefetch();
            mdnsRefetch();
            networkRefetch();
        }
    }, [controlToRefetch, isPending]);

    const text = (
        <div className="flex flex-col items-center">
            <p className="font-bold text-gray-900">{t("take-few-minutes")}</p>
            <p className="text-gray-700">{t("continue-navigating")}</p>
        </div>
    );

    return (
        <>
            <div className="flex items-center justify-between">
                <UseSectionHeader title={t("network-warnings")} />
                <div className="flex gap-4 items-center">
                    <Button
                        id="export-button"
                        className="btn-white px-2 border border-zafiro-600"
                        disabled={serverError || !isAllData}
                        onClick={exportNetworkReport}
                    >
                        {t("export-report")}
                    </Button>
                    <Button
                        id="update-network-button"
                        className="btn-blue"
                        disabled={isPending || serverError}
                        onClick={handleClick}
                    >
                        {t("update")}
                    </Button>
                </div>
            </div>
            {!isLoading && !isPending ? (
                <>
                    {isAllData && !serverError ? (
                        <CastError collapsible={true} />
                    ) : (
                        <CastingWarning text={serverError ? t("no-network-available") : t("awaiting-information")} />
                    )}
                    {isAllData && !serverError ? (
                        <>
                            <p className="mb-8 flex items-center gap-2">
                                <span className="icon-info text-2xl"></span>
                                {t("network-warnings-email")}
                            </p>
                            <div className="bg-white">
                                <DropdownSection open={true} id={"cast"} dropdownSectionHeader={castSectionHeader}>
                                    <div className={`grid ${castNetworkClass}`}>
                                        {hasDhcp}
                                        <NetworkComponent
                                            mdnsInfo={mdnsInfo}
                                            called={mdnsCalled}
                                            loading={mdnsLoading}
                                            type="Traffic"
                                        />
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <NetworkComponent
                                            mdnsInfo={mdnsInfo}
                                            called={mdnsCalled}
                                            loading={mdnsLoading}
                                            type="MdnsInfo"
                                        />
                                        <NetworkComponent
                                            mdnsInfo={mdnsInfo}
                                            called={mdnsCalled}
                                            loading={mdnsLoading}
                                            type="ChromeCastStatus"
                                        />
                                    </div>
                                </DropdownSection>
                            </div>
                            <div className="bg-white my-8">
                                <DropdownSection open={true} id={"user"} dropdownSectionHeader={userSectionHeader}>
                                    <NetworkComponent
                                        networkInfo={networkInfo}
                                        called={networkCalled}
                                        loading={networkLoading}
                                        type={"NetworkInfo"}
                                    />
                                    <div className="grid grid-cols-2">
                                        <NetworkComponent
                                            networkInfo={networkInfo}
                                            called={networkCalled}
                                            loading={networkLoading}
                                            type={"Traffic"}
                                        />
                                        <NetworkComponent
                                            networkInfo={networkInfo}
                                            called={networkCalled}
                                            loading={networkLoading}
                                            type={"Issues"}
                                        />
                                    </div>
                                </DropdownSection>
                            </div>
                        </>
                    ) : null}
                </>
            ) : (
                <>
                    {isPending ? (
                        <Loading text={text} adjust={"section-loading"} textPosition="flex-col" />
                    ) : (
                        <Loading adjust="section-loading" />
                    )}
                </>
            )}
        </>
    );
};

export const NetworkWarning = () => {
    return (
        <NetworkAlertsProvider>
            <NetworkWarningWithContext />
        </NetworkAlertsProvider>
    );
};
