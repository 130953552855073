import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import debounce from "lodash/debounce";
import classNames from "classnames";

import TextSizeSelector from "components/TextSizeSelector";
import ColorInput from "components/ColorInput";
import ScrollSpeedSelector from "components/ScrollSpeedSelector";
import Button from "components/Button";
import { useUpdateDisplayTickerTape } from "../graphql/useTickerTapes";
import { useModalPreviewTickerTape } from "../modals/tickerTapes/useModalPreviewTickerTape";
import { SPEEDS } from "../constants";

const TickerTapeDisplayMode = ({ displayScrollSpeed, displayTextSize, displayStyles, theme, hasContent }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { updateDisplayTickerTape } = useUpdateDisplayTickerTape();
    const { open: openModalPreviewTickerTape } = useModalPreviewTickerTape();

    const [parsedDisplayStyles, setParsedDisplayStyles] = useState(() => {
        try {
            return typeof displayStyles === "string" ? JSON.parse(displayStyles) : displayStyles || {};
        } catch {
            return {};
        }
    });

    useEffect(() => {
        try {
            setParsedDisplayStyles(typeof displayStyles === "string" ? JSON.parse(displayStyles) : displayStyles || {});
        } catch {
            setParsedDisplayStyles({});
        }
    }, [displayStyles]);

    const bgTheme = theme?.defaultBackground;
    const fgTheme = theme?.defaultForeground;

    const debouncedUpdateTickerTape = useCallback(
        debounce((variables) => updateDisplayTickerTape({ variables }), 100),
        [updateDisplayTickerTape]
    );

    const updateDisplay = useCallback(
        (variables) => debouncedUpdateTickerTape({ id, ...variables }),
        [id, debouncedUpdateTickerTape]
    );

    const handleStyleChange = (key, value) => {
        setParsedDisplayStyles((prev) => {
            const updatedStyles = { ...prev, [key]: value };
            updateDisplay({ displayStyles: updatedStyles });
            return updatedStyles;
        });
    };

    const resetThemeColors = () => {
        setParsedDisplayStyles((prev) => {
            const updatedStyles = { ...prev, bgColor: null, fgColor: null };
            updateDisplay({ displayStyles: updatedStyles });
            return updatedStyles;
        });
    };

    const handleTextSizeChange = (size) => updateDisplay({ displayTextSize: size });
    const handleScrollSpeedChange = (speed) => updateDisplay({ displayScrollSpeed: speed });

    useEffect(() => {
        return () => debouncedUpdateTickerTape.cancel();
    }, [debouncedUpdateTickerTape]);

    return (
        <div className="w-full flex">
            <div className="w-full rounded bg-white p-6">
                <div className="w-full flex">
                    <span className="text-2xl font-bold">{t("display-mode")}</span>
                    <Button
                        className="ml-4"
                        id="display-mode-position-preview"
                        disabled={!hasContent}
                        onClick={() => openModalPreviewTickerTape({ id })}
                    >
                        <span
                            className={classNames({
                                "font-bold ": true,
                                "text-zafiro-600": hasContent,
                                "text-gray-500": !hasContent,
                            })}
                        >
                            {t("preview")}
                        </span>
                    </Button>
                </div>

                <div className="border-b border-gray-300">{t("set-up-the-ticker-tape-display-mode")}</div>

                <div className="w-full flex flex-col items-center justify-center">
                    <span className="block w-full font-bold text-left mt-4">{t("text-size")}</span>
                    <TextSizeSelector value={displayTextSize} onChange={handleTextSizeChange} />
                </div>

                <div>
                    <span className="block w-full font-bold text-left mt-3">{t("scroll-speed")}</span>
                    <div className="mt-5">
                        <ScrollSpeedSelector
                            duration={{
                                slow: SPEEDS.SLOW.duration,
                                medium: SPEEDS.MEDIUM.duration,
                                fast: SPEEDS.FAST.duration,
                            }}
                            value={displayScrollSpeed}
                            onChange={handleScrollSpeedChange}
                        />
                    </div>
                </div>

                <div className="w-full">
                    <div className="flex mb-4">
                        <span className="block w-full font-bold text-left mt-4">{t("colours")}</span>
                        {parsedDisplayStyles?.bgColor !== null || parsedDisplayStyles?.fgColor !== null ? (
                            <Button className="ml-4" onClick={resetThemeColors} id="display-mode-colours-reset">
                                <div className="whitespace-no-wrap font-bold text-zafiro-600">
                                    {t("use-appareance-colours")}
                                </div>
                            </Button>
                        ) : null}
                    </div>

                    <div className="w-4/5">
                        <ColorPicker
                            id="color-picker-background"
                            label={t("background")}
                            value={parsedDisplayStyles?.bgColor}
                            defaultValue={bgTheme}
                            onChange={(color) => handleStyleChange("bgColor", color)}
                        />

                        <ColorPicker
                            id="color-picker-text"
                            label={t("text")}
                            value={parsedDisplayStyles?.fgColor}
                            defaultValue={fgTheme}
                            onChange={(color) => handleStyleChange("fgColor", color)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const ColorPicker = ({ id, label, value, defaultValue, onChange }) => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center w-full justify-between pr-32 mt-3">
            <p
                className={classNames({ "text-gray-800 italic": value !== null })}
                data-tip={value !== null ? t("The appearance values are not being used here") : ""}
                data-for="default-tooltip"
            >
                {label + (value !== null ? "*" : "")}
            </p>
            <ColorInput id={id} value={value || defaultValue} onChange={onChange} />
        </div>
    );
};
export default TickerTapeDisplayMode;
