import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Button from "components/Button";
import Translations from "components/Translations";
import Loading from "components/Loading";
import { useAuth } from "hooks/Session/auth";
import { useUpdatePointOfInterest } from "../../graphql/usePointOfInterest";

const ModalEditDescriptionPointOfInterest = (props) => {
    const { t } = useTranslation();

    const [translations, setTranslations] = useState(
        Object.entries(JSON.parse(props?.descriptions)).map(([languageCode, name]) => ({
            languageCode,
            name,
        })) || []
    );
    const [loading, setLoading] = useState(false);
    const { languages, defaultLanguage } = useAuth();
    const { update } = useUpdatePointOfInterest({
        onCompleted: () => {
            setLoading(false);
            toast.success(t("operation-successful"));
            props.fetch();
            props.close();
        },
    });

    const handleSaveClick = () => {
        setLoading(true);
        update({
            variables: {
                description: languages.map((l) => ({
                    lang: l.languageRef,
                    text: translations.find((t) => t.languageCode == l.languageRef)?.name || "",
                })),
                mapId: props.id,
                id: props.pointinterestid,
            },
        });
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${props?.name} - ${t("translate-description")}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-save" disabled={loading} onClick={handleSaveClick}>
                        {t("save")}
                    </Button>
                </>
            }
            className="w-10/12 p-10"
        >
            {loading ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-2">
                    <Translations
                        id="point-of-interest-description-translations"
                        languages={languages.map((language) => language.languageRef)}
                        design="cols"
                        multiline={true}
                        translations={translations}
                        defaultLang={defaultLanguage}
                        onChange={(translations) => {
                            setTranslations(translations);
                        }}
                        maxHeight="15rem"
                    />
                </div>
            )}
        </Modal>
    );
};

export const useModalEditDescriptionPointOfInterest = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalEditDescriptionPointOfInterest {...newProps} />);
        },
    };
};
