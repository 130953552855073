import React, { useState } from "react";
import Button from "components/Button";
import Select from "components/Select";
import Modal, { useModal } from "components/Modal";
import { useUpdateToGroup } from "../useListRooms";
import { useTranslation } from "react-i18next";

const EditRoomGroupModal = ({ roomsId, refetch, groups, currentGroups, roomName }) => {
    const { t } = useTranslation();
    const { close } = useModal();

    // Normalizamos las opciones de grupos
    const normalizedFilterGroup = groups.map((option) => ({
        label: option.name,
        value: option.id,
    }));

    const groupOptions =
        normalizedFilterGroup.length > 0
            ? [
                  {
                      id: "select-all",
                      label: t("select-all"),
                      options: normalizedFilterGroup,
                  },
              ]
            : [];

    const getInitialSelectedIds = () =>
        normalizedFilterGroup.filter((opt) => currentGroups.includes(opt.label)).map((opt) => opt.value);

    const [selectedGroupIds, setSelectedGroupIds] = useState(getInitialSelectedIds());

    const handleSelectionChange = (selectedOptions) => {
        if (!selectedOptions) {
            setSelectedGroupIds([]);
            return;
        }
        setSelectedGroupIds(selectedOptions);
    };

    const { updateRoomGroup } = useUpdateToGroup({
        roomsGroupsId: selectedGroupIds,
        roomsId,
        refetch,
    });

    const selectProps = {
        id: "select-group",
        placeholder: t("select-group"),
        value: selectedGroupIds,
        options: groupOptions,
        multiple: true,
        search: true,
        style: "mt-4",
        onChange: handleSelectionChange,
    };

    return (
        <Modal
            id="add-to-group"
            title={
                <h1 className="text-2xl font-bold">
                    {roomName} - {t("assign-to-group")}
                </h1>
            }
            minWidth="30rem"
            className="w-1/3 p-10"
            footer={
                <footer className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="modal-button-cancel" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button id="modal-button-assign-to-group" design="blue" onClick={() => updateRoomGroup()}>
                        {t("add-to-group")}
                    </Button>
                </footer>
            }
        >
            <div className="mt-10">
                <Select {...selectProps} />
            </div>
        </Modal>
    );
};

export default EditRoomGroupModal;
