import React, { useEffect, useRef, useState } from "react";
import { useListPointsOfInterest, useUpdatePointOfInterest } from "../graphql/usePointOfInterest";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import HeaderMapEditor from "../MapEditor/HeaderMapEditor";
import { MapProvider } from "../MapEditor/MapContext";
import { NameAndDescriptionCard } from "./NameAndDescriptionCard";
import { ImageCard } from "./ImageCard";
import { PinCard } from "./PinCard";
import { NavigationCard } from "./NavigationCard";
import { useAuth } from "hooks/Session/auth";
import Loading from "components/Loading";
import { useTranslation } from "react-i18next";
import { getParseMapName } from "../utils";

const PointOfInterest = () => {
    const { t } = useTranslation();
    const { pointinterestid, id } = useParams();
    const { defaultLanguage } = useAuth();
    const [loading, setLoading] = useState();

    const { getPointOfInterest, pointsOfInterest } = useListPointsOfInterest({
        onCompleted: () => {
            if (defaultLanguage) {
                setLoading(false);
            }
        },
    });
    function fetchPointOfInterest() {
        setLoading(true);
        getPointOfInterest({ variables: { mapId: id, poiId: pointinterestid } });
    }
    const { update: updatePointOfInterest } = useUpdatePointOfInterest({
        onCompleted: () => {
            toast.success(t("operation-successful"));
            fetchPointOfInterest();
        },
    });

    const pointOfInterest = pointsOfInterest[0];
    const name = getParseMapName(pointOfInterest?.name, defaultLanguage);

    useEffect(() => {
        fetchPointOfInterest();
    }, []);

    useEffect(() => {
        if (defaultLanguage && pointOfInterest) {
            setLoading(false);
        }
    }, [defaultLanguage]);

    return (
        <MapProvider>
            <div className=" bg-gray-100 w-full min-h-full px-14 py-4">
                {loading && <Loading adjust={"full"} overlay={true}></Loading>}
                <HeaderMapEditor edit={false} />
                <div className=" mt-20">
                    <p className="section-title mb-2">{name}</p>
                    <div className=" grid grid-cols-2 gap-x-8 gap-y-4">
                        <NameAndDescriptionCard
                            data={pointOfInterest}
                            update={updatePointOfInterest}
                            fetch={fetchPointOfInterest}
                            constantName={name}
                        ></NameAndDescriptionCard>
                        <ImageCard
                            data={pointOfInterest}
                            update={updatePointOfInterest}
                            fetch={fetchPointOfInterest}
                        ></ImageCard>
                        <NavigationCard
                            data={pointOfInterest}
                            update={updatePointOfInterest}
                            fetch={fetchPointOfInterest}
                        ></NavigationCard>
                        <PinCard
                            data={pointOfInterest}
                            update={updatePointOfInterest}
                            fetch={fetchPointOfInterest}
                        ></PinCard>
                    </div>
                </div>
            </div>
        </MapProvider>
    );
};

export default PointOfInterest;
