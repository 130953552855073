import { removeDiacritics } from "hooks/Utils/Utils";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import {
    CREATE_FLOOR,
    CREATE_ROOM_MAP,
    CREATE_WING,
    DELETE_FLOOR,
    DELETE_WING,
    GET_HOTEL_MAP,
    MOVE_CARD,
    MOVE_FLOOR,
    UPDATE_FLOOR,
    UPDATE_WING,
} from "./utils/topologyQueries";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { navToPrevious } from "components/Section/Hotel/Settings/FloorWings";
import { getDashboardCards, getTotals, isWarning } from "./utils/topologyUtils";
import { ROOM_TYPE } from "constants/rooms";

export const useHotelMap = () => {
    const [results, setResults] = useState([]);
    const [filters, setFilters] = useState("");
    const [dashboardCardsData, setDashboardCardsData] = useState(null);

    const [executeQuery, { data, loading, error, refetch: mapRefetch }] = useLazyQuery(GET_HOTEL_MAP, {
        // pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });
    const [getGRLocations, { data: grLocations, loading: grLocationsLoading }] = useLazyQuery(
        gql`
            {
                tvLocations: tvLocations(filter:{roomType: ${ROOM_TYPE.GUESTROOM}}) {
                    results
                }
            }
        `,
        { fetchPolicy: "network-only" }
    );

    const [getCZLocations, { data: czLocations, loading: czLocationsLoading }] = useLazyQuery(
        gql`
            {
                tvLocations: tvLocations(filter:{roomType: ${ROOM_TYPE.COMMONZONE}}) {
                    results
                }
            }
        `,
        { fetchPolicy: "network-only" }
    );

    const locationsData = {
        tvLocations: {
            results: (grLocations?.tvLocations?.results || []).concat(czLocations?.tvLocations?.results || []),
        },
    };

    useEffect(() => {
        executeQuery();
        getGRLocations();
        getCZLocations();
    }, []);

    useEffect(() => {
        if (!loading && !error && data?.installationMap) {
            loopWings();
            const hotelMap = getTotals(data);
            const { wings, floors, rooms, commonZones, warnings } = hotelMap;
            setDashboardCardsData(getDashboardCards(wings, floors, rooms, commonZones, warnings));
        }
    }, [data, filters]);

    const loopWings = () => {
        let mapData = [];
        //Temp data format due to lack of status and warning data
        // eslint-disable-next-line
        data.installationMap.results.map((wing) => {
            let tempWing = {
                id: wing.id,
                name: wing.name,
                pos: wing.pos,
                defaultPmsFormat: wing.defaultPmsFormat,
                isWing: true,
                warnings:
                    wing.floors.filter(
                        (floor) =>
                            floor.rooms.filter(
                                (room) => room.warnings && room.warnings.filter((warn) => isWarning(warn)).length > 0
                            ).length > 0
                    ).length > 0,
                floors: [],
            };
            // eslint-disable-next-line
            wing.floors.map((floor) => {
                let tempFloor = {
                    id: floor.id,
                    name: floor.name,
                    order: floor.order,
                    isFloor: true,
                    warnings:
                        floor.rooms.filter(
                            (room) => room.warnings && room.warnings.filter((warn) => isWarning(warn)).length > 0
                        ).length > 0,
                    rooms: [],
                };
                // eslint-disable-next-line
                floor.rooms.map((room) => {
                    let tempRoom = {
                        type: room?.type,
                        id: room.id,
                        name: room.name,
                        numRoomTV: room.numRoomTV,
                        number: room.number,
                        isRoom: room?.type === "GUESTROOM",
                        isCommonZone: room?.type === "COMMONZONE",
                        status: room.residents && room.residents > 0 ? true : false,
                        recentGuests: room.recentGuests,
                        warnings:
                            room.warnings && room.warnings.filter((warn) => isWarning(warn)).length > 0
                                ? room.warnings
                                : false,
                    };
                    if (filters && filters !== "") {
                        if (
                            removeDiacritics(room.name.toLowerCase()).startsWith(
                                removeDiacritics(filters.toLowerCase())
                            ) ||
                            String(room.number).startsWith(removeDiacritics(filters.toLowerCase()))
                        ) {
                            tempFloor.rooms.push(tempRoom);
                        }
                    } else {
                        tempFloor.rooms.push(tempRoom);
                    }
                });
                tempWing.floors.push(tempFloor);
            });
            mapData.push(tempWing);
        });
        setResults(mapData);
    };

    const guestRoomLocations =
        grLocations?.tvLocations?.results?.map((location) => ({ type: ROOM_TYPE.GUESTROOM, name: location })) || [];
    const commonZonesLocations =
        czLocations?.tvLocations?.results?.map((location) => ({ type: ROOM_TYPE.COMMONZONE, name: location })) || [];

    return {
        results,
        loading,
        locationsData,
        locationsLoading: grLocationsLoading || czLocationsLoading,
        locations: guestRoomLocations.concat(commonZonesLocations),
        mapRefetch,
        setFilters,
        filters,
        dashboardCardsData,
    };
};

export const useWing = () => {
    const { t } = useTranslation();
    const [executeMutation] = useMutation(CREATE_WING);

    const getWingData = (floorsData) => {
        const floors =
            floorsData?.map((floor) => ({
                roomsSetup: {
                    type: "GUESTROOM",
                    Locations: floor?.tvsPerRoom?.map(({ id, ...rest }) =>
                        Object.keys(rest).length === 0 ? { name: "" } : rest
                    ),
                    numberRooms: floor?.numbersOfRooms,
                    starterRoom: floor?.starterRoom,
                    TVsPerRoom: floor?.tvsPerRoom?.length,
                },
                order: floor?.floorOrder,
                name: floor?.floorName,
            })) || [];
        return floors;
    };

    return {
        createWing: (projectRef, newWing) => {
            const { wingOrder, wingName, pmsNumber, floors } = newWing;
            const floorData = getWingData(floors);
            executeMutation({
                variables: {
                    projectRef,
                    order: wingOrder,
                    name: wingName,
                    pmsNumber,
                    floors: floorData,
                },
            })
                .then(() => {
                    toast.success(t("operation-successful"));
                    navToPrevious();
                })
                .catch((err) => {
                    const errorMessage = err?.message?.split(":").pop().trim();
                    toast.error(t(errorMessage));
                });
        },
    };
};

export const useMoveCard = () => {
    const [executeMutation, { data, loading, error }] = useMutation(MOVE_CARD);

    const moveCard = (id, pos) => {
        executeMutation({ variables: { id, pos } });
    };

    return { moveCard, data, loading, error };
};

export const useMoveFloor = () => {
    const [executeMutation, { data, loading, error }] = useMutation(MOVE_FLOOR);

    const moveFloor = (id, pos) => {
        executeMutation({ variables: { id, pos } });
    };

    return { moveFloor, data, loading, error };
};

export const useTopologyValidation = () => {
    const [wingNameValidation, setWingNameValidation] = useState(false);
    const [floorNameValidation, setFloorNameValidation] = useState(false);
    const [tvsPerRoomValidation, setTvsPerRoomValidation] = useState(false);
    const [starterRoomValidation, setStarterRoomValidation] = useState(false);

    const { t } = useTranslation();

    const validate = (wing) => {
        const { wingName, floors, newRoomTVs } = wing;
        let isValid = true;

        if (!wingName && wingName !== undefined) {
            setWingNameValidation("border border-red-100");
            toast.error(t("input error"));
            isValid = false;
        }
        if (newRoomTVs?.length >= 1) {
            const duplicateNames = newRoomTVs
                .map((roomTv) => roomTv.name)
                .filter((name, index, self) => self.indexOf(name) !== index);

            //eslint-disable-next-line
            newRoomTVs?.forEach((roomTv, roomIndex) => {
                if (!roomTv?.name) {
                    setTvsPerRoomValidation((prevState) => ({
                        ...prevState,
                        [roomIndex]: true,
                    }));
                    toast.error(t("input error"));
                    isValid = false;
                } else if (duplicateNames.includes(roomTv.name)) {
                    setTvsPerRoomValidation((prevState) => ({
                        ...prevState,
                        [roomIndex]: { validation: "border border-red-100" },
                    }));
                    isValid = false;
                } else {
                    setTvsPerRoomValidation((prevState) => ({
                        ...prevState,
                        [roomIndex]: { validation: "" },
                    }));
                }
            });

            if (duplicateNames.length > 0) toast.error(t("names-must-be-unique"));
        }

        if (floors?.length >= 1) {
            let duplicateRoomNames;
            // eslint-disable-next-line
            floors?.forEach((floor, index) => {
                if (floor?.starterRoom > 99999) {
                    toast.error(t("The number must be a maximum of 5 digits. Please enter a valid number"));
                    setStarterRoomValidation((prevState) => ({
                        ...prevState,
                        [index]: "border border-red-100",
                        adRoomValidation: "border border-red-100",
                    }));
                    isValid = false;
                } else {
                    setStarterRoomValidation((prevState) => ({
                        ...prevState,
                        [index]: "",
                        adRoomValidation: "",
                    }));
                }
                if (!floor.floorName && floor.floorName !== undefined) {
                    setFloorNameValidation((prevState) => ({
                        ...prevState,
                        [index]: "border border-red-100",
                    }));
                    toast.error(t("input error"));
                    isValid = false;
                } else {
                    setFloorNameValidation((prevState) => ({
                        ...prevState,
                        [index]: "",
                    }));
                }
                //eslint-disable-next-line
                floor?.tvsPerRoom?.forEach((room, roomIndex) => {
                    if (!room?.name && floor?.numbersOfRooms) {
                        setTvsPerRoomValidation((prevState) => ({
                            ...prevState,
                            [index]: {
                                ...prevState[index],
                                [roomIndex]: { validation: "border border-red-100" },
                            },
                        }));
                        toast.error(t("input error"));
                        isValid = false;
                    } else {
                        duplicateRoomNames =
                            floor.tvsPerRoom.filter(
                                (r, i) => floor.tvsPerRoom.findIndex((room) => room.name === r.name) !== i
                            ).length > 0;

                        if (duplicateRoomNames) {
                            setTvsPerRoomValidation((prevState) => ({
                                ...prevState,
                                [index]: {
                                    ...prevState[index],
                                    [roomIndex]: { validation: "border border-red-100" },
                                },
                            }));

                            isValid = false;
                        } else {
                            setTvsPerRoomValidation((prevState) => ({
                                ...prevState,
                                [index]: {
                                    ...prevState[index],
                                    [roomIndex]: { validation: "" },
                                },
                            }));
                        }
                    }
                });
            });
            if (duplicateRoomNames) toast.error(t("names-must-be-unique"));
        }

        return isValid;
    };

    return { validate, wingNameValidation, floorNameValidation, tvsPerRoomValidation, starterRoomValidation };
};

export const useUpdateWing = () => {
    const { t } = useTranslation();
    const [executeMutation] = useMutation(UPDATE_WING);
    const [executeDelete] = useMutation(DELETE_WING);

    return {
        updateWing: (id, name, pmsNumber) => {
            executeMutation({
                variables: {
                    id,
                    ...(name && { name }),
                    pmsNumber,
                },
            })
                .then(() => {
                    toast.success(t("operation-successful"));
                })
                .catch((err) => {
                    const errorMessage = err?.message;
                    if (errorMessage.includes("WING_NAME_ALREADY_EXISTS")) {
                        toast.error(t("Wing name already exists"));
                    } else {
                        toast.error(t(err));
                    }
                });
        },
        deleteWing: (id) => {
            executeDelete({
                variables: {
                    id,
                },
            })
                .then((res) => {
                    const hasError = res?.data?.deleteWing?.error;
                    if (hasError) {
                        toast.error(t("at-least-one-stayguest-checked-in", { zone: t("wing") }));
                    } else {
                        toast.success(t("operation-successful"));
                    }
                })
                .catch((err) => {
                    toast.error(t(err));
                });
        },
    };
};

export const useUpdateFloor = () => {
    const { t } = useTranslation();
    const [executeMutation] = useMutation(UPDATE_FLOOR);
    const [executeDelete] = useMutation(DELETE_FLOOR);

    return {
        updateFloor: (id, name, pmsNumber) => {
            executeMutation({
                variables: {
                    id,
                    name,
                    pmsNumber,
                },
            })
                .then(() => {
                    toast.success(t("operation-successful"));
                })
                .catch((err) => {
                    const errorMessage = err?.message?.split(":").pop().trim();
                    toast.error(t(errorMessage));
                });
        },
        deleteFloor: (id) => {
            executeDelete({
                variables: {
                    id,
                },
            })
                .then(() => {
                    toast.success(t("operation-successful"));
                })
                .catch((err) => {
                    const errorMessage = err?.message?.split(":").pop().trim();
                    if (errorMessage) {
                        toast.error(t("at-least-one-stayguest-checked-in", { zone: t("floor") }));
                    } else {
                        toast.error(t(err));
                    }
                });
        },
    };
};

export const useFloor = () => {
    const { t } = useTranslation();
    const [executeMutation] = useMutation(CREATE_FLOOR);

    const getFloorData = (floorsData) => {
        const floors =
            floorsData?.map((floor) => ({
                roomsSetup: {
                    type: "GUESTROOM",
                    Locations: floor?.tvsPerRoom?.map(({ id, ...rest }) =>
                        Object.keys(rest).length === 0 ? { name: "" } : rest
                    ),
                    numberRooms: floor?.numbersOfRooms,
                    starterRoom: floor?.starterRoom,
                    TVsPerRoom: floor?.tvsPerRoom?.length,
                },
                order: floor?.order,
                name: floor?.floorName,
            })) || [];
        return floors;
    };

    return {
        createFloor: (wingID, newFloor) => {
            const { floors } = newFloor;
            const floorData = getFloorData(floors);
            executeMutation({
                variables: {
                    wingID,
                    floors: floorData,
                },
            })
                .then(() => {
                    toast.success(t("operation-successful"));
                    navToPrevious();
                })
                .catch((err) => {
                    const errorMessage = err?.message?.split(":").pop().trim();
                    toast.error(t(errorMessage));
                });
        },
    };
};

export const useRoom = () => {
    const { t } = useTranslation();
    const [executeMutation] = useMutation(CREATE_ROOM_MAP);

    return {
        createRoom: (newRoom) => {
            const { floorID, floors } = newRoom;
            const { numbersOfRooms, starterRoom, tvsPerRoom, name, type } = floors?.[0] || {};
            executeMutation({
                variables: {
                    floorID,
                    numberRooms: numbersOfRooms,
                    starterRoom,
                    name,
                    locations: tvsPerRoom?.map(({ id, ...rest }) => rest),
                    tvsPerRoom: tvsPerRoom?.length,
                    type,
                },
            })
                .then(() => {
                    toast.success(t("operation-successful"));
                    navToPrevious();
                })
                .catch((err) => {
                    const errorMessage = err?.message?.split(":").pop().trim();
                    toast.error(t(errorMessage));
                });
        },
    };
};
