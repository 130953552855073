import React, { useState } from "react";
import Icon from "components/Icon";
import ReactTooltip from "components/ReactTooltip";
import { parseBoolean } from "hooks/Utils/Utils";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Select from "components/Select";
import { useUpdateToGroup } from "../useListRooms";
import { useTranslation } from "react-i18next";

export const HotelRoomsModel = (hotelRoom, guestAccess, t, open, filterGroup, refetch) => {
    const iconWarning =
        hotelRoom?.warnings > 0 ? (
            <div className="flex items-center gap-3" sortvalue={hotelRoom?.warnings}>
                <Icon type="warning" />
                <span>
                    {hotelRoom?.warnings} {hotelRoom?.warnings > 1 ? t("warnings") : t("warning")}
                </span>
            </div>
        ) : null;

    const getGroups = (groups) => {
        if (groups.length > 0) {
            return (
                <div className="flex items-center gap-1">
                    <span>{groups[0]}</span>
                    <span
                        data-tip={groups.length > 1 ? groups.slice(1, groups.length).join("\n") : ""}
                        data-for={"groups.length"}
                        className="text-blue-300"
                    >
                        {groups.length > 1 ? `+${groups.length - 1}` : ""}
                        <ReactTooltip id={"groups.length"} place="top" type="light" effect="solid" />
                    </span>
                </div>
            );
        }
    };

    const format = {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    };

    const stayList = () => {
        const stayFrom = new Date(hotelRoom?.stayFrom);
        const stayTo = new Date(hotelRoom?.stayTo);
        const lastStay = new Date(hotelRoom?.lastStay);
        if (parseBoolean(hotelRoom?.status)) {
            return (
                <div className="flex flex-col" datevalue={stayFrom.toLocaleDateString("en-US", format)}>
                    <p>{stayFrom.toLocaleDateString(undefined, format)}</p>
                    <p>{hotelRoom?.stayTo ? stayTo.toLocaleDateString(undefined, format) : "-"}</p>
                </div>
            );
        } else {
            return (
                <div className="flex flex-col first-capital" datevalue={lastStay.toLocaleDateString("en-US", format)}>
                    <p>{t("last-stay")}:</p>
                    <p>{hotelRoom?.lastStay ? lastStay.toLocaleDateString(undefined, format) : "-"}</p>
                </div>
            );
        }
    };

    const guestCells = guestAccess && {
        status: (
            <StatusCell
                status={parseBoolean(hotelRoom?.status)}
                t={t}
                sortvalue={parseBoolean(hotelRoom?.status) ? "occupied" : "empty"}
            />
        ),
        guest: hotelRoom?.guests?.length > 0 ? <GuestCell guests={hotelRoom?.guests} /> : null,
        stay: stayList(),
    };

    const hotelRoomModel = {
        statusFilter: parseBoolean(hotelRoom?.status) ? "occupied" : "empty",
        floorFilter: hotelRoom?.floorID,
        rowConfig: {
            actions: [
                {
                    id: "view-detail",
                    label: t("view-detail"),
                    onClick: () => {
                        window.location.href = `#/hotel/rooms/room/detail/${hotelRoom?.id}`;
                    },
                },
                {
                    id: "assign-to-group",
                    label: t("assign-to-group"),
                    onClick: () =>
                        open(
                            <Modal
                                id="add-to-group"
                                title={
                                    <h1 className="text-2xl font-bold">
                                        {hotelRoom?.name} - {t("assign-to-group")}
                                    </h1>
                                }
                                minWidth="30rem"
                                className="w-1/3 p-10"
                            >
                                <AssignToGroupModal
                                    filterGroup={filterGroup}
                                    refetch={refetch}
                                    roomsId={hotelRoom?.id}
                                    groupsSelected={hotelRoom?.group}
                                />
                            </Modal>
                        ),
                },
            ],
        },
        groupFilter: hotelRoom?.group,
        roomName: hotelRoom?.name,
        room: (
            <a
                href={`#/hotel/rooms/room/detail/${hotelRoom.id}`}
                id={`detail-${hotelRoom?.id}`}
                alt={hotelRoom?.name + " " + hotelRoom?.roomNumber}
                sortvalue={hotelRoom?.roomNumber}
            >
                <p className="text-blue-300 font-bold">{hotelRoom?.name}</p>
                <p className="text-gray-500">{hotelRoom?.roomNumber}</p>
            </a>
        ),
        group: getGroups(hotelRoom?.group),
        ...guestCells,
        "device-status": iconWarning,
        id: hotelRoom?.id,
    };

    return hotelRoomModel;
};

const StatusCell = ({ status, t }) => {
    return (
        <>
            {status ? (
                <span sortvalue={"occupied"} className={`text-white py-1 px-4 first-capital rounded bg-zafiro-300`}>
                    {t("occupied")}
                </span>
            ) : (
                <span sortvalue={"empty"} className={`text-white py-1 px-4 first-capital rounded bg-gray-500`}>
                    {t("empty")}
                </span>
            )}
        </>
    );
};

const GuestCell = ({ guests }) => {
    return (
        <>
            {guests.map((guest, index) => {
                return (
                    <div key={index} className="flex items-center gap-2">
                        <a href={`#/customers/guests/guest-list/guest/${guest.id}`} id={`guest-${guest.id}`}>
                            <Icon
                                type="guest"
                                size="xl"
                                className="text-zafiro-500 hover:text-zafiro-300"
                                tooltip={guest?.name}
                            />
                        </a>
                    </div>
                );
            })}
        </>
    );
};

export const AssignToGroupModal = ({ filterGroup, refetch, roomsId, groupsSelected }) => {
    const [selectedGroupValues, setSelectedGroupValues] = useState(() => {
        return filterGroup.filter((option) => groupsSelected?.includes(option.label)).map((option) => option.id);
    });
    const [roomsGroupsId, setRoomsGroupsId] = useState([]);
    const { t } = useTranslation();
    const { close } = useModal();
    const { updateRoomGroup } = useUpdateToGroup({ roomsGroupsId, roomsId, refetch });

    const normalizedFilterGroup = filterGroup.map((option) => ({
        ...option,
        value: option.id,
    }));

    const selectProps = {
        id: "select-group",
        placeholder: t("select-group"),
        value: selectedGroupValues,
        options: normalizedFilterGroup,
        multiple: true,
        style: "mt-4",
        onChange: (value) => {
            const newValue = value || [];
            setSelectedGroupValues(newValue);
            setRoomsGroupsId(newValue);
        },
    };

    return (
        <div className="mt-10">
            <Select {...selectProps} />
            <footer className="flex w-full gap-4 mt-10 justify-end">
                <Button id="cancel" design="white" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button id="alert-accept" design="blue" onClick={updateRoomGroup}>
                    {t("add-to-group")}
                </Button>
            </footer>
        </div>
    );
};
