import Tag from "components/Tag";
import React from "react";

export const TagApp = ({ name, type, icon, color: forceColor, tooltip, prefix }) => {
    return (
        <Tag
            key={`tag-${name}`}
            type={type}
            color={forceColor}
            prefix={prefix}
            icon={icon}
            className={"p-2"}
            tooltip={tooltip}
            tooltipType={"default-tooltip"}
        >
            {name}
        </Tag>
    );
};
