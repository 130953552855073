export const THEME_TYPE = {
    MOBILE: "MOBILE",
    DESIGN: "DESIGN",
};

export const DESIGN_TYPE = {
    CORPORATE: "CORPORATE",
    LOCAL: "LOCAL",
};

export const VISIBILITY = {
    ALL_PROJECTS: "ALL_PROJECTS",
    BRAND: "BRAND",
    PROJECT: "PROJECT",
};

export const BRAND_ERROR = "BRAND_CANT_DELETE";

export const LINK_DESIGN_TO_PROJECT = {
    ASSIGN: "ASSIGN",
    UNASSIGN: "UNASSIGN",
    SET_DEFAULT: "SET_DEFAULT",
    UNSET_DEFAULT: "UNSET_DEFAULT",
};
