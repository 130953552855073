import React from "react";
import classNames from "classnames";

import Icon from "components/Icon";

export const TAG_MOBILE_APP = "app";
export const TAG_WIFI_PORTAL = "wifi";
export const TAG_CHECK_IN = "check-in";

// Enum CustomerAccessType
export const FILTER_CHECK_IN = "CHECKIN";
export const FILTER_SELF_CHECK_IN = "SELF-CHECK-IN";
export const FILTER_APP = "APP";
export const FILTER_WIFI = "WIFI";

export const TAG_PROPERTY = "property";

const Tag = ({ children, type, color, icon, className, tooltip, tooltipType, prefix }) => {
    const defaultColor = "#2286EF";
    let typeColor;
    switch (type) {
        case TAG_WIFI_PORTAL:
            typeColor = "#0F63BD";
            break;
        case TAG_CHECK_IN:
            typeColor = "#A7B5C4";
            break;
        case TAG_MOBILE_APP:
            typeColor = "#06376B";
            break;
        case TAG_PROPERTY:
        default:
            typeColor = defaultColor;
    }
    if (color !== undefined) {
        // If color is provided, override the default color with it
        typeColor = color;
    }
    if (typeColor === "default") {
        typeColor = defaultColor;
    }

    const tagClass = classNames({
        "inline-flex items-center space-x-3 rounded text-white": true,
        "break-normal": typeof children != "string" || children?.length <= 10,
        "break-words": typeof children === "string" && children?.length > 10,
        "mr-2": !className?.match(/m-|ml-|mr-/),
        "px-4 py-2": !className?.match(/px-|py-|p-/),
        [typeColor]: /^bg-/.test(typeColor),
        [className]: className,
    });

    return (
        <div
            className={tagClass}
            style={/^bg-/.test(typeColor) ? null : { backgroundColor: typeColor }}
            data-tip={tooltip}
            data-for={tooltip ? tooltipType || "dangerous-html-tooltip" : null}
        >
            {children || prefix ? (
                <span className="w-full">
                    {prefix ? prefix + " " : ""}
                    {children}
                </span>
            ) : null}
            {icon ? <Icon type={icon?.name} size={icon?.size} className={icon?.className} /> : null}
        </div>
    );
};
Tag.displayName = "Tag";

export default Tag;
