import React, { useEffect, useState } from "react";
import UseSectionHeader from "components/useSectionHeader";
import { useTranslation } from "react-i18next";
import { SearchInput } from "components/SearchInput";
import Button from "components/Button";
import Section from "components/SettingsCard/Section";
import Icon from "components/Icon";
import { useLazyQuery } from "react-apollo";
import { QUERY_MOBILE_APPS_FILTER } from "../constants";
import { Session } from "hooks/Utils/Session";
import UseCustomPhoneInput from "components/Inputs/UseCustomPhoneInput";
import { useParams } from "react-router-dom";
import { POLLINTERVAL_15S } from "hooks/Utils/Utils";
import useDeleteAssociatedPropertie from "../modals/useDeleteAssociatedPropertie";
import useAddModalApp from "../modals/useAddModalApp";
import UpdateAssociatedProperties from "./UpdateAssociatedProperties";
import { useLoading } from "components/Loading";
import { useNavigate } from "react-router-dom";
import InteractiveImageDisplay from "components/InteractiveImageDisplay";
import useGetInfoMultipleAssets from "hooks/GraphqlCalls/MediaLibrary/useGetInfoMultipleAssets";
import BrowserMediaLibraryModal from "hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
import { useDispatch } from "react-redux";
import { openModal } from "actions/uiActions";
import { setModalContent, closeModal } from "actions/uiActions";
import { useSelector } from "react-redux";
import { cleanActionValues } from "actions/globalActions";

const AssociatedProperties = () => {
    const { t } = useTranslation();

    const [pictureAssetRefs, setPictureAssetRefs] = useState([]);

    const { open: openModalDeleteAssociatedPropertie } = useDeleteAssociatedPropertie();
    const { open: openModalAddApp } = useAddModalApp();
    const { loading, setLoading } = useLoading();
    const { values } = useSelector((state) => state.action);
    const { data: dataAssets, loading: loadingAssets, error: errorAssets } = useGetInfoMultipleAssets(pictureAssetRefs);
    const navigate = useNavigate();
    const lang = Session.getLang() || "en";
    const { permissions } = useSelector((state) => state.ui);
    const hasChain = permissions?.product?.chain ? true : false;

    const dispatch = useDispatch();
    const projectsData = Session.getProjects() || [];
    const [initialProjectsInfo, setInitialProjectsInfo] = useState([]);
    const [projectsInfo, setProjectsInfo] = useState([]);
    const [filteredProjects, setFilteredProjectsInfo] = useState([]);
    const [search, setSearch] = useState("");
    const [execute, setExecute] = useState(null);
    const [onSelectedImage, setOnSelectedImageProperty] = useState(null);
    const [nameApp, setNameApp] = useState(null);
    const { id } = useParams();
    const [executeQuery, { data }] = useLazyQuery(QUERY_MOBILE_APPS_FILTER(id), {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    useEffect(() => {
        executeQuery();
        setLoading(true);
    }, []);

    useEffect(() => {
        const projects = data?.mobileApps?.results?.[0]?.projects;
        const nameApp = data?.mobileApps?.results?.[0]?.name;

        if (nameApp) {
            setNameApp(nameApp);
        }

        if (projects) {
            setLoading(false);
            const newProjectsInfo = arrangeProjects(projects, projectsData);
            setProjectsInfo(newProjectsInfo);
            setInitialProjectsInfo(newProjectsInfo);
        }
    }, [data]);

    useEffect(() => {
        if (filteredProjects.length > 0) {
            const newPictureAssetRefs = filteredProjects.filter((p) => p?.picture).map((p) => p?.picture);
            setPictureAssetRefs(newPictureAssetRefs);
        }
    }, [filteredProjects]);

    useEffect(() => {
        if (onSelectedImage) {
            setOnSelectedImageProperty(null);
            if (values?.["browser-media-library-items-selected"]?.[0]) {
                setFilteredProjectsInfo(
                    updatePictureById(
                        filteredProjects,
                        onSelectedImage,
                        values?.["browser-media-library-items-selected"]?.[0]
                    )
                );
                handleProjectChange(onSelectedImage, {
                    picture: values?.["browser-media-library-items-selected"]?.[0],
                });
            }
            // setOnErrorImg(false);
            dispatch(closeModal());
            dispatch(setModalContent(false));
            dispatch(cleanActionValues());
        }
    }, [values, onSelectedImage]);

    const openModalLibrary = (id) => {
        const modal = BrowserMediaLibraryModal(t, {
            text: "images-of-the-media-library",
            title: "choose-an-image",
            bodyAdjust: "w-10/12",
            buttonSelectedAssetName: "choose-an-image",
            action: "widget-actions-media",
            textAdjust: "",
            executeSectionQuery: true,
            closeAction: null,
            buttonAceptAction: () => {
                setOnSelectedImageProperty(id);
            },
            dataMediaLibrary: {
                lang: lang,
                selected_ref: null,
                type: "asset",
                select_multiple: false,
                add_asset: {
                    active: true,
                    return_added: false,
                },
            },
        });
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    useEffect(() => {
        if (search) {
            const filteredProjects = projectsInfo.filter((project) =>
                project.name.toLowerCase().includes(search.toLowerCase())
            );
            setFilteredProjectsInfo(filteredProjects);
        } else {
            setFilteredProjectsInfo(projectsInfo);
        }
    }, [search, projectsInfo]);

    const handleProjectChange = (id, changes) => {
        setProjectsInfo((prevProjectsInfo) =>
            prevProjectsInfo.map((project) => (project.id === id ? { ...project, ...changes } : project))
        );
    };

    const toggleAppAvailable = (id, currentStatus) => {
        handleProjectChange(id, { appAvailable: !currentStatus });
    };

    return (
        <>
            <UseSectionHeader title={t("associated-properties")} navToSection={`/all-properties/mobile-apps/${id}`} />
            <div className="p-6 bg-white rounded">
                <div className="w-full">
                    {hasChain ? (
                        <div className=" flex ">
                            <Icon type="warning" size={1.5} />
                            <p className=" inline-block ml-4 mb-4">
                                {t(
                                    "Please note that you will only be able to manage properties with Zafiro Chain licenses"
                                )}
                            </p>
                        </div>
                    ) : null}
                    <p>{t("write-the-phone-number-associated-to-the-whatapp-of-each-property")}</p>
                    <div className="flex justify-between mt-3 w-full">
                        <div>
                            <SearchInput setSearchValue={setSearch} />
                        </div>
                        <div>
                            <Button
                                design="blue"
                                onClick={() => {
                                    openModalAddApp({
                                        manageProperties: {
                                            currentProjectsRefs: projectsInfo.map((project) => project.projectRef),
                                            appId: Number(id),
                                        },

                                        action: (params) => {
                                            const newProjectsInfoToAdd = [];

                                            const projectsDataMap = projectsData.reduce((acc, project) => {
                                                acc[project.ref] = {
                                                    location: project.location,
                                                    name: project.name,
                                                    id: project.id,
                                                };
                                                return acc;
                                            }, {});

                                            if (params?.propertiesChecked) {
                                                const updatedProjectsInfo = projectsInfo.filter((project) =>
                                                    params.propertiesChecked.includes(project.projectRef)
                                                );

                                                params.propertiesChecked.forEach((el) => {
                                                    const projectAlreadyAdded = projectsInfo.find(
                                                        (project) => project.projectRef === el
                                                    );

                                                    if (!projectAlreadyAdded) {
                                                        newProjectsInfoToAdd.push({
                                                            id: projectsDataMap[el]?.id,
                                                            phone: null,
                                                            picture: null,
                                                            projectLocation: projectsDataMap[el]?.location || null,
                                                            appAvailable: true,
                                                            name: projectsDataMap[el]?.name || null,
                                                            projectRef: el,
                                                            isNew: true,
                                                        });
                                                    }
                                                });

                                                setProjectsInfo([...updatedProjectsInfo, ...newProjectsInfoToAdd]);
                                            }
                                        },
                                    });
                                }}
                                id="manage-properties"
                            >
                                {t("manage-properties")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div
                    className="w-full pt-5 border-t"
                    style={{ minHeight: "50vh", maxHeight: "50vh", overflow: "scroll" }}
                >
                    {filteredProjects.map((p, index) => {
                        return (
                            <div className="mb-8" key={p.id}>
                                <Section
                                    id={`assosiated-propertie-${index}`}
                                    title={p.name}
                                    toggle={{
                                        label: t("available"),
                                        disabled: false,
                                        action: () => toggleAppAvailable(p.id, p.appAvailable),
                                        checked: p.appAvailable,
                                    }}
                                    edit={{
                                        icon: "delete",
                                        className: "text-gray-800",
                                        onClick: () => {
                                            const handleDeleteProject = () => {
                                                setProjectsInfo((prevProjectsInfo) =>
                                                    prevProjectsInfo.filter((project) => project.id !== p.id)
                                                );
                                            };
                                            openModalDeleteAssociatedPropertie({
                                                action: handleDeleteProject,
                                                nameProperty: p?.name,
                                                nameApp: nameApp,
                                            });
                                        },
                                    }}
                                />
                                <div className="flex w-full">
                                    <div className="w-1/3 pr-48">
                                        <InteractiveImageDisplay
                                            id={`assosiated-propertie-${index}`}
                                            title={t("property-image")}
                                            src={p?.picture ? Session.getDasUrl(`${p?.picture}?lang=${lang}`) : null}
                                            info={dataAssets?.[p.picture]?.name || null}
                                            className="w-full h-20"
                                            onAdd={() => openModalLibrary(p.id)}
                                            onDelete={() =>
                                                setFilteredProjectsInfo(updatePictureById(filteredProjects, p.id, null))
                                            }
                                        />
                                    </div>
                                    <div className="w-1/3 pr-48">
                                        <span className="block text-lg font-bold mb-2">{t("location")}</span>
                                        <div className="flex items-center">
                                            <Icon type="location" size={1.5} />
                                            <span className="block ml-2">{p.projectLocation || "-"}</span>
                                        </div>
                                    </div>
                                    <div className="w-1/3 pr-48">
                                        <span className="block text-lg font-bold mb-2">{t("phone-number")}</span>
                                        <div className="flex items-center">
                                            <UseCustomPhoneInput
                                                key={p.id}
                                                value={p.phone}
                                                id={`assosiated-propertie-${index}`}
                                                onChange={(val) => {
                                                    handleProjectChange(p.id, { phone: val });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    <div className=" w-full flex justify-center text-gray-600 text-center ">
                        {filteredProjects.length === 0 && search && !loading ? t("no-results-found") : null}
                        {filteredProjects.length === 0 && !search && !loading
                            ? t("no-associated-properties-yet")
                            : null}
                    </div>
                </div>
                <div className="w-full flex justify-end pt-6 border-t">
                    <Button
                        onClick={() => {
                            navigate(`/all-properties/mobile-apps/${id}/`);
                        }}
                        id={"cancel-associated-properties"}
                        design="blue-outline"
                        className="mr-4"
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        id={"save-associated-properties"}
                        onClick={() => {
                            const execute = [];

                            if (Object.keys(findUpdatedProjects(initialProjectsInfo, filteredProjects)).length > 0) {
                                execute.push({
                                    action: "updateProjects",
                                    params: findUpdatedProjects(initialProjectsInfo, filteredProjects),
                                });
                            }

                            if (!haveSameIdsProjectsInfo(projectsInfo, initialProjectsInfo)) {
                                execute.push({
                                    action: "addProjects",
                                    params: projectsInfo,
                                });
                            }

                            if (execute.length > 0) {
                                setExecute(execute);
                            } else {
                                navigate(`/all-properties/mobile-apps/${id}/`);
                            }
                        }}
                        design="blue"
                    >
                        {t("save")}
                    </Button>
                </div>
            </div>
            <UpdateAssociatedProperties execute={execute} />
        </>
    );
};

export default AssociatedProperties;

const findUpdatedProjects = (initialProjects, modifiedProjects) => {
    const updatedProjects = [];

    const isEqual = (val1, val2) => {
        if (val1 === val2) return true;
        if ((val1 === null || val1 === "") && (val2 === null || val2 === "")) return true;
        return false;
    };

    initialProjects.forEach((initialProject) => {
        const modifiedProject = modifiedProjects.find((project) => project.id === initialProject.id);
        if (modifiedProject) {
            const projectChanges = {};
            Object.keys(initialProject).forEach((key) => {
                if (!isEqual(initialProject[key], modifiedProject[key])) {
                    projectChanges[key] = modifiedProject[key];
                }
            });
            if (Object.keys(projectChanges).length > 0) {
                updatedProjects.push({
                    id: initialProject.id,
                    changes: projectChanges,
                });
            }
        }
    });

    return updatedProjects;
};

const haveSameIdsProjectsInfo = (initialProjectsInfo, projectsInfo) => {
    const getIdsProjects = (projects) => projects.map((project) => project.id);

    const idsInitialProjectsInfo = new Set(getIdsProjects(initialProjectsInfo));
    const idsProjectsInfo = new Set(getIdsProjects(projectsInfo));

    if (idsInitialProjectsInfo.size !== idsProjectsInfo.size) {
        return false;
    }

    for (let id of idsInitialProjectsInfo) {
        if (!idsProjectsInfo.has(id)) {
            return false;
        }
    }

    return true;
};

const arrangeProjects = (projects, projectsData) => {
    if (!projects) return [];

    const projectsDataMap = projectsData.reduce((acc, project) => {
        acc[project.ref] = project.location;
        return acc;
    }, {});

    return projects.map((project) => {
        const { ref, id, information, name } = project;
        const { chatNumber, chatNumberLada, picture, appAvailable } = information || {};

        return {
            id: id || null,
            phone: formatPhoneNumber(chatNumberLada, chatNumber) || null,
            picture: picture || null,
            projectLocation: projectsDataMap[ref] || null,
            appAvailable: appAvailable ? true : false,
            name: name || "",
            projectRef: ref,
        };
    });
};

const formatPhoneNumber = (lada, number) => {
    if (lada && number) {
        return `${lada} ${number}`;
    }
    return null;
};

const updatePictureById = (projects, id, newPicture) => {
    return projects.map((project) => {
        if (project.id === id) {
            return {
                ...project,
                picture: newPicture,
            };
        }
        return project;
    });
};
