import React, { useState } from "react";
import { useSelector } from "react-redux";
import CountUp from "../../../hooks/Utils/CountUp";
import { useTranslation } from "react-i18next";

function UseWarningCard({ data }) {
    const { headerText, warnings } = data;
    const [warningState] = useState(warnings);
    const { t } = useTranslation();

    return (
        <div className={`dc-card-container bg-white text-blue-400 grid grid-cols-3`}>
            <div className="col-span-2 first-capital">{t(headerText)}</div>
            <div className="">
                <div className="dc-icon text-45xl mx-auto">
                    <span className=" icon icon-warning"></span>
                    <span className=" icon icon-warning1 -ml-1em text-zafiro-300"></span>
                    <span className=" icon icon-warning2 -ml-1em text-zafiro-300"></span>
                </div>
            </div>
            <div className="col-span-2 first-capital text-lg font-bold mt-5">
                <CountUp number={warningState} />
                {` `}
                {warnings === "1" ? t("warning") : t("warnings")}
            </div>
        </div>
    );
}

export default UseWarningCard;
