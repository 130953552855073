import React, { useEffect, useRef } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { toast } from "react-toastify";
import { t } from "i18next";
import { validateUrl } from "hooks/Utils/Validations";
import Destination from "./Destination/Destination";
import { DestinationType } from "./Destination/utils/utils";
import { useSelectLibraryContent } from "../signages/selectLibraryContent";

const ModalAddDestination = (props) => {
    const { close, closeAllModals, useUpdateDestination, onCompleted, id, data, initialTypeDestinations, title } =
        props;

    const destinationRef = useRef(null);

    const initialDestination =
        initialTypeDestinations && initialTypeDestinations.length > 0 ? initialTypeDestinations[0] : null;
    const initialType = initialDestination ? initialDestination.type : null;
    const initialDestinationValue = initialDestination
        ? { [initialDestination.type]: initialDestination.value || null }
        : {};

    const { open: reopenModal } = useModalAddDestination();

    useEffect(() => {
        if (initialDestination && destinationRef.current) {
            console.log("initialDestination", initialDestination);

            destinationRef.current.setDestinationData({
                [initialDestination.type || DestinationType.DISABLED]: initialDestination.value || null,
            });
            destinationRef.current.setDestinationType(initialDestination.type || DestinationType.DISABLED);
        }
    }, [initialDestination]);

    const handleAssetSelection = useSelectLibraryContent({
        afterSaveAction: (selectedRef) => {
            close();
            reopenModal({
                ...props,
                initialTypeDestinations: [
                    {
                        type: DestinationType.MEDIA_FILE,
                        value: selectedRef,
                    },
                ],
            });
        },
    });

    const handleAddClick = () => {
        const { type, value } = destinationRef.current.getCurrentDestination();

        if (type === DestinationType.LINK && !validateUrl(value)) {
            toast.error(t("invalid url"));
            destinationRef.current.setLinkError(true);
            return;
        }

        executeUpdate({
            variables: {
                id,
                contentAction: { type, value },
            },
        });
    };

    const { executeUpdate, loadingUpdate } = useUpdateDestination({
        onCompleted: () => {
            toast.success(t("operation-successful"));
            closeAllModals();
            if (onCompleted) onCompleted();
        },
        onError: (error) => {
            toast.error(error.message || "unknown-error");
            closeAllModals();
        },
    });

    return (
        <Modal
            id="modal-add-destination"
            title={title || "modal-add-destination"}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-add" onClick={handleAddClick}>
                        {t("add")}
                    </Button>
                </>
            }
            className="w-4/12 p-10"
        >
            <div className=" mb-2 font-bold">{t("select-the-navigation-destination")}*</div>
            <Destination
                ref={destinationRef}
                initialType={initialType}
                initialDestinationValue={initialDestinationValue}
                avoidOnBlurLink={true}
                onHandleChooseAsset={() => {
                    close();
                    handleAssetSelection();
                }}
                excludeOptions={[DestinationType.MAPS]}
                alertMessages={{
                    [DestinationType.SCREEN]: {
                        text: t("note-that-only-screens-from-the-applied-design-can-be-selected"),
                        className: "text-gray-700",
                    },
                }}
            />
        </Modal>
    );
};

export const useModalAddDestination = () => {
    const { open, close, closeAllModals } = useModal();
    return {
        open: (props) => open(<ModalAddDestination {...props} close={close} closeAllModals={closeAllModals} />),
    };
};

export default useModalAddDestination;
