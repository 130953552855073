import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import PositionSelector from "components/PositionSelector";
import SizeSelector from "components/SizeSelector";
import { useUpdateSizePositionBanner } from "../graphql/useBanners";
import { useParams } from "react-router-dom";
import { useModalPreviewBannners } from "../modals/banners/useModalPreviewBanners";
import classNames from "classnames";

const BannerDisplayMode = ({ displaySize: initialSize, displayPosition: initialPosition, onCompleted, hasImage }) => {
    const { t } = useTranslation();
    const { updateSizePositionBanner } = useUpdateSizePositionBanner();
    const { open: openModalPreviewBanner } = useModalPreviewBannners();
    const { id } = useParams();

    const [displayOptions, setDisplayOptions] = useState({
        size: initialSize,
        position: initialPosition,
    });

    useEffect(() => {
        setDisplayOptions({
            size: initialSize,
            position: initialPosition,
        });
    }, [initialSize, initialPosition]);

    const handleOptionChange = (option, newValue) => {
        const newOptions = { ...displayOptions, [option]: newValue };
        setDisplayOptions(newOptions);
        updateSizePositionBanner({
            variables: {
                id,
                displaySize: newOptions.size,
                displayPosition: newOptions.position,
            },
        });
    };

    return (
        <div className="w-full rounded bg-white px-6 py-8">
            <div className="w-full flex">
                <span className="text-3xl font-bold">{t("display-mode")}</span>
                <Button
                    className="ml-4"
                    id={"display-mode-position-preview"}
                    disabled={!hasImage}
                    onClick={() => {
                        openModalPreviewBanner({ id });
                    }}
                >
                    <span
                        className={classNames({
                            "font-bold": true,
                            "text-zafiro-600": hasImage,
                            "text-gray-500": !hasImage,
                        })}
                    >
                        {t("preview")}
                    </span>
                </Button>
            </div>
            <div className="mt-2 pb-4 border-b border-gray-300">
                <span>{t("set-up-the-banner-display-mode")}</span>
            </div>

            <div className="font-bold mt-5">
                <span>{t("position")}</span>
            </div>
            <div className="mt-2">{t("select-the-posion-where-you-want-the-banner-to-be-displayed")}</div>
            <div className="flex items-center justify-center mt-5">
                <div className="w-2/5">
                    <PositionSelector
                        value={displayOptions.position}
                        onChange={(newPosition) => handleOptionChange("position", newPosition)}
                    />
                </div>
            </div>
            <div>
                <div className="font-bold mt-5">
                    <span>{t("size")}</span>
                </div>
                <div className="mt-2">{t("select-the-size-you-would-like-the-banner-to-be-displayed")}</div>
                <div className="flex items-center justify-center mt-5">
                    <SizeSelector
                        value={displayOptions.size}
                        onChange={(newSize) => handleOptionChange("size", newSize)}
                    />
                </div>
            </div>
        </div>
    );
};

export default BannerDisplayMode;
