import React from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Icon from "components/Icon";

const useModalConfirmTranslationQuestion = () => {
    const { t } = useTranslation();
    const { open, close } = useModal();

    const openModal = (props) => {
        open(
            <Modal
                id="modal-confirm-translation-question"
                title={`${t("satisfaction-question")} - ${t("edit")}`}
                footer={
                    <>
                        <Button
                            design="blue-outline"
                            id="modal-confirm-translation-question-button-close"
                            onClick={close}
                        >
                            {t("close")}
                        </Button>

                        <Button
                            design="blue"
                            id="modal-confirm-translation-question-button-save"
                            onClick={() => {
                                if (typeof props.onSave === "function") {
                                    props.onSave();
                                }
                            }}
                        >
                            {t("save")}
                        </Button>
                    </>
                }
                className="w-4/12 p-10"
            >
                <p className=" block mb-4">{t("are-you-sure-you-want-to-edit-the-satisfaction-question")}</p>
                <div className=" flex items-start gap-3">
                    <Icon type="warning" size="2xl" />
                    <p className=" block">
                        {t("note-that-if-you-save-the-changes-all-previous-customers-responses-will-be-deleted")}
                    </p>
                </div>
            </Modal>
        );
    };

    return {
        open: openModal,
        close,
    };
};

export default useModalConfirmTranslationQuestion;
