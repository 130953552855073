import { gql } from "apollo-boost";

export const ADD_TICKER_TAPE = gql`
    mutation createTickerTape($name: String!) {
        createTickerTape(name: $name) {
            error
            id
            ok
        }
    }
`;

export const DELETE_TICKER_TAPES = gql`
    mutation deleteTickerTapes($ids: [Int64]!) {
        deleteTickerTapes(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_TICKER_TAPE = gql`
    mutation updateTickerTape($id: Int64!, $name: String, $destination: destinationSET, $content: [TranslateString]) {
        updateTickerTape(id: $id, name: $name, contentInput: $content, destination: $destination) {
            error
            id
            ok
        }
    }
`;

export const SET_TICKER_TAPE_AS_AVAILABLE = gql`
    mutation setTickerTapesAsAvailable($ids: [Int64]!) {
        setTickerTapesAsAvailable(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const SET_TICKER_TAPE_AS_UNAVAILABLE = gql`
    mutation setTickerTapesAsUnavailable($ids: [Int64]!) {
        setTickerTapesAsUnavailable(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const DUPLICATE_TICKER_TAPE = gql`
    mutation duplicateTickerTape($ids: [Int64]!) {
        duplicateTickerTapes(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_DISPLAY_TICKER_TAPE = gql`
    mutation updateDisplayTickerTape(
        $id: Int64!
        $displayTextSize: TickerTapeTextSize
        $displayScrollSpeed: TickerTapeScrollSpeed
        $displayStyles: JSON
    ) {
        updateTickerTape(
            id: $id
            displayTextSize: $displayTextSize
            displayScrollSpeed: $displayScrollSpeed
            displayStyles: $displayStyles
        ) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_SCHEDULE_TICKER_TAPE = gql`
    mutation updateScheduleTickerTape(
        $id: Int64!
        $scheduleStart: Date
        $scheduleEnd: NullableDate
        $timeRanges: [TickerTapeTimeRangeSET]
        $scheduleObtrusiveMode: Boolean
        $scheduleFrequencyLimited: Boolean
        $scheduleDaysOfWeek: [Int]
        $scheduleFrequencyRepetitions: Int
        $scheduleFrequencyDisplay: String
        $scheduleDaysType: TickerTapeScheduleDaysType
    ) {
        updateTickerTape(
            id: $id
            scheduleStart: $scheduleStart
            scheduleEnd: $scheduleEnd
            timeRanges: $timeRanges
            scheduleObtrusiveMode: $scheduleObtrusiveMode
            scheduleFrequencyLimited: $scheduleFrequencyLimited
            scheduleDaysOfWeek: $scheduleDaysOfWeek
            scheduleFrequencyRepetitions: $scheduleFrequencyRepetitions
            scheduleFrequencyDisplay: $scheduleFrequencyDisplay
            scheduleDaysType: $scheduleDaysType
        ) {
            error
            id
            ok
        }
    }
`;
