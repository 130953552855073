import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { Session } from "../../hooks/Utils/Session";
import UseLoading from "../Table/useLoading";
import UseVideoPlayerCustom from "../Inputs/UseVideoPlayerCustom";
export const UseModalPreviewChannel = ({ index }) => {
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const [videosRef, setVideosRef] = useState([]);
    const [query, setQuery] = useState(`query{
        fake{
            error
            id
            ok
        }
    }`);

    const GET_DATA = gql`
        ${query}
    `;

    const [getDetailsVideos, { data: dataDetailsVideos, loading }] = useLazyQuery(GET_DATA, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (data?.videosRef) {
            let query = `query{`;
            data.videosRef.forEach((v, index) => {
                query += `libraryVideo_${index}:  
                libraryVideo(
                           ref: "${v}"
                        ) {
                          error
                          ok
                          response {
                            defaultLanguage
                            name
                            ref
                           }
                         }
                `;
            });
            query += `}`;
            setQuery(query);
            getDetailsVideos();
        }
    }, [data]);

    useEffect(() => {
        if (dataDetailsVideos) {
            setVideosRef(transformObjectToArray(dataDetailsVideos));
        }
    }, [dataDetailsVideos]);

    return !loading ? (
        <div className=" h-96 w-full flex ">
            <UseVideoPlayerCustom hideButtonsNavigations={false} videosRef={videosRef} getSrc={getSrc} />
        </div>
    ) : (
        <div className=" flex items-center justify-center">
            <UseLoading adjust={"contents "} />
        </div>
    );
};

export default UseModalPreviewChannel;

const transformObjectToArray = (inputObj) => {
    const outputArray = [];

    for (const key in inputObj) {
        if (inputObj.hasOwnProperty(key)) {
            if (inputObj[key].ok) {
                const response = inputObj[key].response;
                outputArray.push(response);
            }
        }
    }

    return outputArray;
};

const getSrc = (videosRef, currentIndexRef) => {
    return Session.getDasUrl(
        `stream/${videosRef[currentIndexRef.current].ref}-${videosRef[currentIndexRef.current].defaultLanguage}.m3u8`
    );
};
