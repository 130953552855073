import React, { useContext, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { GlobalContext } from "contexts/Global";

import Search from "components/TextInput/Search";
import Button from "components/Button";

import { normalizeToSearch } from "hooks/Utils/Utils";

const icons = Object.values(fas)
    .concat(Object.values(fab))
    .map((i) => ({ prefix: i.prefix, name: i.iconName }))
    .filter((i) => !["font-awesome", "fort-awesome"].some((t) => i.name.includes(t)));

const IconsPicker = ({ id, selected, onSuccess }) => {
    const { t } = useTranslation();

    const { highlightComponent } = useContext(GlobalContext);

    const [search, setSearch] = useState(null);

    const results = search
        ? [...icons].filter((icon) =>
              search
                  .split(" ")
                  .map((word) => word && normalizeToSearch(icon.name).includes(normalizeToSearch(word)))
                  .some((i) => i)
          )
        : icons;

    return (
        <div
            className={classNames({
                "w-full flex flex-col space-y-2": true,
                "highlight-component": highlightComponent,
            })}
        >
            <Search id={`${id}-search`} onChange={setSearch} className="w-full" />
            <div className="flex flex-wrap justify-evenly items-center gap-2 p-2 max-h-64 overflow-x-hidden overflow-y-scroll bg-white border border-gray-200 shadow-inner">
                {results?.length ? (
                    results.map((icon) => {
                        const isSelected = selected?.type === icon.prefix && selected?.icon === icon.name;
                        return (
                            <Button
                                key={`${icon.prefix}-${icon.name} `}
                                id={`${icon.prefix}-${icon.name} `}
                                className={classNames({
                                    "p-3 bg-white shadow-sm border rounded text-center hover:text-zafiro-400 ": true,
                                    "border-zafiro-200": isSelected,
                                    "border-gray-200": !isSelected,
                                })}
                                onClick={() => {
                                    if (onSuccess) {
                                        onSuccess(icon.prefix, icon.name);
                                    }
                                }}
                                tooltip={icon.name}
                            >
                                <FontAwesomeIcon
                                    size="2x"
                                    icon={[icon.prefix, icon.name]}
                                    style={{
                                        zoom: 0.8,
                                    }}
                                />
                            </Button>
                        );
                    })
                ) : (
                    <div className="text-gray-600">{t("results-not-found")}</div>
                )}
            </div>
        </div>
    );
};
IconsPicker.displayName = "IconsPicker";

export default IconsPicker;
