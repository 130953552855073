import React, { useState, useEffect } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import Icon from "components/Icon";
import Button from "components/Button";
import { useModalEditProperty } from "./modals/useModalEditProperty";
import i18n from "i18n";

import ProjectName from "components/ProjectName";

const useListProperties = () => {
    const [getPropertiesAndBrands, { loading, data, error, refetch }] = useLazyQuery(QUERY_GET_PROPERTIES_AND_BRANDS);

    const { open: openModalEditProperty } = useModalEditProperty();

    const [properties, setProperties] = useState([]);
    const [propertiesGroups, setPropertiesGroups] = useState([]);

    useEffect(() => {
        if (getPropertiesAndBrands) {
            getPropertiesAndBrands();
        }
    }, []);

    useEffect(() => {
        if (data) {
            const newOptionsPropertiesGroups = [
                {
                    label: i18n.t("manageBrands"),
                    options: data?.brands?.results.map((b) => ({ value: b.id, label: b.name })) || [],
                },
            ];

            const newPropertiesGroups = data?.brands?.results.map((b) => ({ value: b.id, label: b.name })) || [];

            const newProperties =
                data.properties?.results.map((property) =>
                    createProperty(property, openModalEditProperty, newOptionsPropertiesGroups, refetch)
                ) || [];

            setPropertiesGroups(newPropertiesGroups);
            setProperties(newProperties);
        }
    }, [data]);

    return { properties, propertiesGroups, loading, error };
};

const createProperty = (project, openModalEditProperty, propertiesGroups, refetch) => ({
    property: <ProjectName alt={project?.name} hasChainModule={project?.hasChainModule} name={project?.name} />,
    group: (
        <div className="flex">
            {project?.brands?.length
                ? project.brands.map((brand) => (
                      <div
                          key={brand.id}
                          className="ml-2 whitespace-no-wrap rounded bg-orange-100 px-3 py-1 text-white"
                      >
                          {brand.name}
                      </div>
                  ))
                : null}
        </div>
    ),
    groups: project?.brands?.length ? project.brands.map((brand) => brand.id) : [],
});

const QUERY_GET_PROPERTIES_AND_BRANDS = gql`
    query getPropertiesAndBrands {
        properties {
            info {
                count
                __typename
            }
            results {
                id
                name
                hasChainModule
                brands {
                    id
                    name
                }
            }
        }
        brands {
            results {
                id
                name
                ref
            }
        }
    }
`;

export default useListProperties;
