import React from "react";
import classNames from "classnames";
import HeaderMapEditor from "./HeaderMapEditor";
import MapEditorContainer from "./MapEditorContainer";
import { MapProvider, useMap } from "./MapContext";
import Loading from "components/Loading";

const MapContent = () => {
    return (
        <MapProvider>
            <MapContainer />
        </MapProvider>
    );
};

const MapContainer = () => {
    const { firstLoading, sectionLoading } = useMap();

    const containerClasses = classNames("w-full pt-20 h-full bg-gray-200 overflow-x-hidden", {
        "opacity-0": firstLoading,
        "opacity-100": !firstLoading,
    });

    const renderLoading = () => {
        return <Loading adjust="absolute" />;
    };

    return (
        <>
            <div className={containerClasses}>
                <HeaderMapEditor />
                <MapEditorContainer />
                {sectionLoading && renderLoading()}
            </div>
            {firstLoading && renderLoading()}
        </>
    );
};

export default MapContent;
