import React, { useContext, useState, useEffect } from "react";
import { useNavigate as useHistory, useLocation } from "react-router-dom";
import { SeleniumChecker } from "zdev";

import Header from "./components/Header";
import Section from "./components/Section";
import Sidebar from "./components/Sidebar";
import Impersonated from "./components/Section/Impersonated";
import Warnings from "./components/Section/Warning";
import Notifications from "./components/Section/Notifications";

import UseGlobalLoading from "./components/useGlobalLoading";
import UseModal from "./components/useModal";
import Permissions from "./hooks/Utils/Permissions";
import Userguiding from "./hooks/Utils/Userguiding";
import SalesBackgroundUpdate from "./hooks/GraphqlCalls/Sales/SalesBackgroundUpdate";
import LoadFonts from "./hooks/GraphqlCalls/MediaLibrary/LoadFonts";
import { includeWidgetRenderLibrary } from "./hooks/Utils/Utils";

import { GlobalContext } from "contexts/Global";

const AppContent = () => {
    const { token, paths, environment, project, features, isProjectLogged, isProjectReady, isZafiroV3 } =
        useContext(GlobalContext);
    const location = useLocation();
    const history = useHistory();

    const [availablePaths, setAvailablePaths] = useState(paths);

    const awaitingPaths = !Array.isArray(availablePaths);
    const autoRedirectAvailable = !["/", "/ZAFIRO_TV"].includes(location?.pathname);

    useEffect(() => {
        includeWidgetRenderLibrary();
    }, []);

    useEffect(() => {
        // When paths reload and isProjectLogged, go to start page
        if (awaitingPaths && autoRedirectAvailable && isProjectLogged && paths) {
            history("/");
        }
        setAvailablePaths(paths);
    }, [paths]);

    const key = project?.ref || "none";

    return (
        <div className="relative grid overflow-auto" style={{ height: "100vh", gridTemplateRows: "max-content" }}>
            <Permissions token={token} key={`permissions-${key}`} />
            {!isZafiroV3 ? <LoadFonts key={`fonts-${key}`} /> : null}
            <SalesBackgroundUpdate />
            {features?.userguiding ? <Userguiding /> : null}
            {features?.notifications ? <Notifications /> : null}
            {features?.selenium ? <SeleniumChecker mode={environment} /> : null}
            <div className="relative">
                <Impersonated />
                <Warnings />
            </div>
            <div className="relative overflow-hidden">
                <UseGlobalLoading />
                {isProjectLogged && isProjectReady ? (
                    <>
                        <Header key={`header-${key}`} />
                        <div className="flex">
                            <Sidebar key={`sidebar-${key}`} />
                            <Section key={`section-${key}`} />
                        </div>
                    </>
                ) : (
                    <Section key={`section-${key}`} />
                )}
                <UseModal />
            </div>
        </div>
    );
};

export default AppContent;
