import React, { useRef, useState } from "react";
import { sortableElement } from "react-sortable-hoc";
import Icon from "./Icon";
import Dropdown from "./Dropdown";
import Button from "./Button";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * DraggableListItem Component
 *
 * @param {object} props - The component props.
 * @param {string|number} props.id - Unique identifier for the item.
 * @param {string} props.label - The text to be displayed in the item.
 * @param {object} [props.style] - Inline styles for the item.
 * @param {string} [props.addonClassName="addon-circle"] - CSS class for the additional container.
 * @param {Array} [props.actions=[]] - Array of actions available for the item.
 * @param {string} [props.imageSrc] - URL of the image (if available).
 * @param {object} [props.iconData] - FontAwesome icon object containing library and icon name.
 * @param {JSX.Element} [props.customAction]
 *
 * @returns {JSX.Element} The draggable item component.
 */
const DraggableListItem = sortableElement(
    ({
        id,
        label,
        style,
        addonClassName = "addon-circle",
        actions = [],
        actionsStyles = { width: "12rem", position: "absolute", left: "-11rem" },
        imageSrc,
        iconData,
        customAction = null,
    }) => {
        const actionsRef = useRef(null);
        const textRef = useRef(null);
        const [showTooltip, setShowTooltip] = useState(false);

        const listItemClasses = "relative px-6 py-4 bg-white select-none flex items-center";
        const dragIconClasses = "mr-4 text-gray-800";
        const rowActionsIconClasses = "text-gray-800 hover:text-zafiro-300";
        const textContainerClasses =
            "whitespace-nowrap overflow-hidden overflow-ellipsis block ml-3 w-full select-none";

        const onActionClick = (action, event) => {
            event.stopPropagation();
            if (actionsRef.current) {
                actionsRef.current.close();
            }
            if (typeof action.onClick === "function") {
                action.onClick(event);
            }
        };

        const handleTextMouseOver = () => {
            if (textRef.current) {
                setShowTooltip(textRef.current.scrollWidth > textRef.current.clientWidth);
            }
        };

        return (
            <li className={listItemClasses} style={style}>
                <Icon type="drag-and-drop" size={1.5} className={dragIconClasses} />
                <div id={`${id}-addon-container`} className={addonClassName}>
                    {imageSrc && <img className="object-cover w-full h-full" src={imageSrc} alt={label} />}
                    {iconData && (
                        <FontAwesomeIcon
                            icon={[iconData?.lib, iconData?.icon]}
                            style={{ width: "60%", height: "60%" }}
                        />
                    )}
                </div>
                <div
                    data-tip={showTooltip ? label : ""}
                    data-for="default-tooltip"
                    ref={textRef}
                    onMouseOver={handleTextMouseOver}
                    onMouseLeave={() => setShowTooltip(false)}
                    className={textContainerClasses}
                >
                    {label}
                </div>
                {customAction}
                <Dropdown
                    id={`${id}-content-actions-dropdown`}
                    ref={actionsRef}
                    float={true}
                    showArrow={false}
                    width="1rem"
                    handler={<Icon type="row-actions" size={1.3} className={rowActionsIconClasses} />}
                    overflowX="unset"
                >
                    <div
                        style={actionsStyles}
                        className="select-none mt-1 rounded w-40 bg-white shadow-lg text-left border border-gray-100 text-gray-900 flex flex-col"
                    >
                        {actions.map((action, index) => {
                            const actionId = `${id}-draggable-list-actions-${action.id || index + 1}`;
                            return (
                                <Button
                                    id={actionId}
                                    key={actionId}
                                    disabled={action.disabled}
                                    onClick={(event) => onActionClick(action, event)}
                                    tooltip={action.tooltip}
                                    className={classNames("px-4 py-2 w-full text-left", {
                                        "text-gray-500 hover:text-gray-500 cursor-not-allowed": action.disabled,
                                        "text-gray-900 hover:bg-gray-100": !action.disabled,
                                    })}
                                >
                                    {action.label}
                                </Button>
                            );
                        })}
                    </div>
                </Dropdown>
            </li>
        );
    }
);

export default DraggableListItem;
