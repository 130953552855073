import { gql } from "apollo-boost";

export const UPDATE_CATEGORIES = gql`
    mutation updateCategory($id: Int64!, $mapId: Int64!, $name: [TranslateString], $interestPointIDs: [Int64]) {
        updateMapCategory(id: $id, mapID: $mapId, nameInput: $name, mapInterestPointIDs: $interestPointIDs) {
            error
            id
            ok
        }
    }
`;

export const ADD_CATEGORY = gql`
    mutation addCategory($id: Int64!, $name: [TranslateString]!) {
        createMapCategory(mapID: $id, nameInput: $name) {
            error
            id
            ok
        }
    }
`;

export const DELETE_CATEGORY = gql`
    mutation deleteCategory($id: Int64!, $mapId: Int64!) {
        deleteMapCategory(id: $id, mapID: $mapId) {
            error
            ok
        }
    }
`;

export const UPDATE_CATEGORY_POS = gql`
    mutation updateCategoryPosition {
        createMapCategory {
            error
            id
            ok
        }
    }
`;

export const SET_CATEGORY_ORDER_IN_MAP = gql`
    mutation SetCategoryOrderInMap($mapID: Int64!, $id: Int64!, $pos: Int!) {
        setCategoryOrderInMap(mapID: $mapID, id: $id, pos: $pos) {
            error
            id
            ok
        }
    }
`;
