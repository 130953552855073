import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import { useLazyQuery } from "react-apollo";
import { TYPE_IMAGES } from "components/SelectorImageIcon";
import SelectorImageIcon from "components/SelectorImageIcon";
import { GET_LIBRARY_ASSET_NAME } from "../../Advertising/modals/modalDestination/Destination/Hooks/useDestinationGraphCalls";
import useModalSelectLibraryContent from "../../Advertising/modals/signages/useModalSelectLibraryContent";
import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";
import { useParams } from "react-router-dom";

export const ImageCard = ({ data, update }) => {
    const { t } = useTranslation();
    const selectorImageIconRef = useRef(null);
    const { pointinterestid, id } = useParams();
    const { defaultLanguage } = useAuth();
    const { open: openLibrary, close: closeLibrary } = useModalSelectLibraryContent();
    const [getLibraryAssetName, { data: dataLibraryAssetName }] = useLazyQuery(GET_LIBRARY_ASSET_NAME, {
        fetchPolicy: "network-only",
    });
    useEffect(() => {
        if (data?.image) {
            if (data?.image?.libraryRef) {
                if (
                    selectorImageIconRef?.current?.getActiveType() != TYPE_IMAGES.LIBRARY ||
                    (selectorImageIconRef?.current?.getActiveType() == TYPE_IMAGES.LIBRARY &&
                        selectorImageIconRef?.current?.getActiveSelectionValue()?.value != data?.image?.libraryRef)
                ) {
                    handleUpdateImageData(
                        { type: TYPE_IMAGES.LIBRARY, value: data?.image?.libraryRef },
                        selectorImageIconRef,
                        defaultLanguage
                    );
                }
            }
            if (data?.image?.externalUrl) {
                if (
                    selectorImageIconRef?.current?.getActiveType() != TYPE_IMAGES.LINK ||
                    (selectorImageIconRef?.current?.getActiveType() != TYPE_IMAGES.LINK &&
                        selectorImageIconRef?.current?.getActiveSelectionValue()?.value != data?.image?.externalUrl)
                ) {
                    handleUpdateImageData(
                        { type: TYPE_IMAGES.LINK, value: data?.image?.externalUrl },
                        selectorImageIconRef,
                        defaultLanguage
                    );
                }
            }
        }
    }, [data]);

    function handleUpdateImageData(data, ref, lang) {
        if (!data?.type || !data?.value || !ref?.current) return;
        const { type, value } = data;
        const updateImageData = (src) => {
            ref.current.setActiveType(type);
            ref.current.updateData({ type, value, validation: true, src });
        };

        switch (type) {
            case TYPE_IMAGES.LIBRARY:
                updateImageData(Session.getDasUrl(`${value}?lang=${lang}`));
                getLibraryAssetName({ variables: { ref: value } });
                break;
            case TYPE_IMAGES.LINK:
                updateImageData(value);
                break;
            default:
                console.warn(`Type image not supported: ${type}`);
        }
    }
    return (
        <div className="bg-white rounded-md p-4">
            <div className=" flex gap-2 items-center">
                <p className="font-bold text-2xl">{t("image")}</p>
                <Icon type={"info"} tooltip={t("image-recommended-resolution")}></Icon>
            </div>

            <p>{t("select-the-image")}</p>
            <SelectorImageIcon
                ref={selectorImageIconRef}
                allowedImageTypes={[TYPE_IMAGES.LIBRARY, TYPE_IMAGES.LINK]}
                id="edit-map-selector-image-icon"
                nameAsset={dataLibraryAssetName?.libraryAsset?.response?.name}
                onImageSelect={() => {
                    openLibrary({
                        title: t("choose-an-image"),
                        subtitle: t("select-an-image-of-the-media-library"),
                        isMultiple: false,
                        params: {
                            filters: "asset",
                            hideFilter: true,
                            isMultiple: false,
                            onSaveAction: (selectedRefs) => {
                                if (!selectedRefs?.[0]?.ref) return;
                                handleUpdateImageData(
                                    { type: TYPE_IMAGES.LIBRARY, value: selectedRefs[0].ref },
                                    selectorImageIconRef,
                                    defaultLanguage
                                );
                                update({
                                    variables: {
                                        image: {
                                            libraryRef: selectedRefs[0].ref,
                                            externalUrl: "",
                                        },
                                        mapId: id,
                                        id: pointinterestid,
                                    },
                                });
                                closeLibrary();
                            },
                        },
                    });
                }}
                onLinkSelect={(value, passValidation) => {
                    if (passValidation) {
                        handleUpdateImageData(
                            { type: TYPE_IMAGES.LINK, value: value },
                            selectorImageIconRef,
                            defaultLanguage
                        );
                        update({
                            variables: {
                                image: {
                                    libraryRef: "",
                                    externalUrl: value,
                                },
                                mapId: id,
                                id: pointinterestid,
                            },
                        });
                    }
                }}
                onDelete={() => {
                    update({
                        variables: {
                            image: {
                                externalUrl: "",
                                libraryRef: "",
                            },
                            mapId: id,
                            id: pointinterestid,
                        },
                    });
                }}
            />
        </div>
    );
};
