import React, { forwardRef, isValidElement, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import TableContext from "./Context";

import Button from "components/Button";
import Select from "components/Select";
import Search from "components/TextInput/Search";
import UseFilterRangeDate from "components/Table/UseFilterRangeDate";
import Icon from "components/Icon";
import { RangeDate } from "components/Inputs/RangeDate";

const Top = ({
    id,
    search,
    title,
    description,
    warnings,
    filters,
    daterangeSelector,
    rangeDatePlaceholder,
    batchActions,
    selectedRows,
    topRightCorner,
    folders,
    hideDateRangeTimePicker = false,
    hideFutureDates = true,
    setSelectedRows,
    rows,
}) => {
    const { t } = useTranslation();
    const { search: searchValue, setSearch: setSearchValue, fastSearch, disabled } = useContext(TableContext);

    const batchActionsComponent = batchActions?.options?.length ? (
        <BatchActions
            config={batchActions}
            disabled={disabled}
            selectedRows={selectedRows}
            rows={rows}
            setSelectedRows={setSelectedRows}
        />
    ) : null;

    const foldersComponent = isValidElement(folders) ? folders : null;

    const searchComponent = search ? (
        <Search
            id={`table-search-${id}`}
            value={searchValue}
            disabled={disabled}
            onChange={setSearchValue}
            awaitMode={!fastSearch}
        />
    ) : null;

    const filtersComponent = filters ? (
        <FilterComponent
            daterangeSelector={daterangeSelector}
            rangeDatePlaceholder={rangeDatePlaceholder}
            hideDateRangeTimePicker={hideDateRangeTimePicker}
            hideFutureDates={hideFutureDates}
            filters={filters}
        />
    ) : null;

    const topWarnings = isValidElement(warnings) ? warnings : null;

    const filterPosition = (
        <div className="flex justify-between w-full overflow-hidden">
            <div className="flex gap-5 w-full min-w-0">
                {searchComponent ? <div className="flex items-center space-x-8">{searchComponent}</div> : null}
                {filtersComponent ? (
                    <div className="flex items-center space-x-8 w-full overflow-x-auto">{filtersComponent}</div>
                ) : null}
            </div>
        </div>
    );

    return searchComponent || filtersComponent || batchActionsComponent || topRightCorner ? (
        <div
            className={classNames({
                "w-full": true,
                "mb-8": batchActionsComponent || topRightCorner,
                "mb-5": !(batchActionsComponent || topRightCorner),
            })}
        >
            <div className="flex justify-between items-center">
                <div className="mb-5">
                    {title ? <div className="text-2xl font-bold mb-1">{title}</div> : null}
                    {description ? <div className="mb-3">{description}</div> : null}
                </div>
                {topRightCorner ? (
                    <div className="flex flex-col items-end justify-between mb-4">{topRightCorner || null}</div>
                ) : null}
            </div>
            <div className="relative flex flex-wrap gap-5 w-full justify-between items-stretch overflow-hidden">
                <div className="flex flex-col gap-5 w-full">
                    {searchComponent || filtersComponent ? filterPosition : null}
                    {batchActionsComponent ? (
                        <div className="flex flex-row justify-between w-full">{batchActionsComponent || null}</div>
                    ) : null}
                    {topWarnings ? <div className="mt-4">{topWarnings || null}</div> : null}
                    {foldersComponent ? <div className="mt-4">{foldersComponent || null}</div> : null}
                </div>
            </div>
        </div>
    ) : null;
};

const FilterComponent = ({
    filters,
    daterangeSelector,
    hideDateRangeTimePicker,
    hideFutureDates,
    rangeDatePlaceholder,
}) => {
    const containerRef = useRef(null);
    const dateRangeRef = useRef(null);
    const [filterWidth, setFilterWidth] = useState([]);
    const { t } = useTranslation();
    const { filterUsed } = useContext(TableContext);
    const scrollFilters = (direction) => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: direction * 100, behavior: "smooth" });
        }
    };

    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    useEffect(() => {
        if (dateRangeRef.current) {
            setTimeout(() => setFilterWidth((prev) => [dateRangeRef.current.offsetWidth, ...prev]), 500);
        }
        const handleScroll = () => {
            if (containerRef.current) {
                setCanScrollLeft(containerRef.current.scrollLeft > 0);
                setCanScrollRight(
                    containerRef.current.scrollLeft + containerRef.current.clientWidth <
                        containerRef.current.scrollWidth
                );
            }
        };

        handleScroll();
        containerRef.current.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);

        return () => {
            containerRef.current.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleScroll);
        };
    }, []);

    return (
        <div className="inline-flex items-center space-x-2 overflow-hidden">
            <span className="rounded-lg bg-zafiro-400 text-sm text-white px-3">{filterUsed}</span>
            <span className="first-capital">{t("filter")}:</span>
            <div className="flex space-x-6 overflow-x-hidden whitespace-nowrap" ref={containerRef}>
                {daterangeSelector && (
                    <div ref={dateRangeRef} id="range-date">
                        <RangeDate id="date-of-activity" placeHolder={rangeDatePlaceholder || t("date-of-activity")} />
                    </div>
                )}
                {filters.map(
                    (filter, index) =>
                        filter && <Filter key={filter?.id} id={index} config={filter} setFilterWidth={setFilterWidth} />
                )}
            </div>
            {(canScrollLeft || canScrollRight) && (
                <div className="flex items-center">
                    <Button
                        id="scroll-left"
                        className={canScrollLeft ? "text-zafiro-600" : "text-gray-500"}
                        disabled={!canScrollLeft}
                        onClick={() => scrollFilters(-1)}
                    >
                        <Icon type="chevron-left" size="2xl" />
                    </Button>
                    <Button
                        id="scroll-right"
                        className={canScrollRight ? "text-zafiro-600" : "text-gray-500"}
                        disabled={!canScrollRight}
                        onClick={() => scrollFilters(1)}
                    >
                        <Icon type="chevron-right" size="2xl" />
                    </Button>
                </div>
            )}
        </div>
    );
};

const Filter = ({ id, config, setFilterWidth }) => {
    const { setPage, setActiveFilters, activeFilters, disabled, setFilterUsed } = useContext(TableContext);
    const dropdownRef = useRef(null);
    const initialValue = config?.value;
    const currentValue = activeFilters?.[id]?.value ?? initialValue;

    const handleFilter = (value) => {
        if (config?.onChange) {
            setActiveFilters((prev) => ({ ...prev, [id]: { value, fn: config?.onFilter } }));
            config.onChange(value);
        }
        if (config?.onFilter) {
            setActiveFilters((prev) => ({ ...prev, [id]: { value, fn: config?.onFilter } }));
            setPage(1);
        }
    };

    useEffect(() => {
        if (activeFilters) {
            let filterCount = 0;
            Object.keys(activeFilters).forEach((filter) => {
                if (activeFilters[filter]?.value != null || activeFilters[filter]?.to != undefined) {
                    filterCount++;
                }
            });
            setFilterUsed(filterCount);
        }
    }, [activeFilters]);

    useEffect(() => {
        if (dropdownRef?.current?.offsetWidth) {
            setTimeout(() => {
                setFilterWidth((prevWidths) => {
                    const newWidth = dropdownRef?.current?.offsetWidth;
                    if (!prevWidths.includes(newWidth)) {
                        return [...prevWidths, newWidth];
                    }
                    return prevWidths;
                });
            }, 500);
        }
    }, [dropdownRef?.current?.offsetWidth]);

    useEffect(() => {
        handleFilter(initialValue);
    }, [initialValue]);

    return (
        <div ref={dropdownRef}>
            <Select
                id={`options-${id}`}
                placeholder={config?.title}
                tooltip={config?.title}
                multiple={config?.multiple}
                disabled={disabled}
                value={currentValue}
                allowUnselect={config?.allowUnselect}
                selectGroup={config?.selectGroup}
                onChange={handleFilter}
                options={config?.options}
            />
        </div>
    );
};

export default Top;

const BatchActions = ({ config, selectedRows, setSelectedRows, rows, disabled }) => {
    const { t } = useTranslation();
    const { options, onChange } = config;

    const [evaluatedOptions, setEvaluatedOptions] = useState([]);
    const [selectedAction, setSelectedAction] = useState(null);
    const noneSelected = !selectedRows?.length;

    useEffect(() => {
        const newOptions = options.map((option) => {
            if (typeof option.disabled === "function") {
                const disabled = option.disabled(rows?.filter((row) => selectedRows?.includes(row.id)));
                return {
                    ...option,
                    disabled,
                    tooltip: disabled && t("action-not-available"),
                };
            }
            return option;
        });
        setEvaluatedOptions(newOptions);

        if (selectedAction && newOptions.find((val) => val.value == selectedAction)?.disabled) {
            setSelectedAction(null);
        }
    }, [selectedRows, options]);

    return (
        <div className="flex items-center space-x-2">
            <Select
                id="batch-actions"
                value={selectedAction}
                placeholder={t("batch-title")}
                disabled={disabled || noneSelected}
                options={evaluatedOptions}
                onChange={setSelectedAction}
            />
            <Button
                id="batch-execute"
                design="blue"
                disabled={!selectedAction || noneSelected || disabled}
                onClick={() => {
                    if (onChange) {
                        onChange(
                            selectedAction,
                            rows?.length ? rows.filter((row) => selectedRows?.includes(row.id)) : [],
                            () => {
                                setSelectedAction(null);
                                setSelectedRows([]);
                            }
                        );
                    }
                }}
            >
                {t("batch-execute")}
            </Button>
        </div>
    );
};
