import { t } from "i18next";
import { getProjectLangs, parseTranslation } from "hooks/Utils/SalesUtils";

export const DestinationType = Object.freeze({
    DISABLED: "disabled",
    SCREEN: "content",
    PREDEFINED_SECTION: "section",
    LINK: "link",
    MEDIA_FILE: "library",
    MAPS: "map",
});

export const APPS_OPTIONS = {
    netflix: { label: "Netflix", value: "netflix" },
    appletv: { label: "AppleTV", value: "appletv" },
    youtube: { label: "YouTube", value: "youtube" },
    resetCredentials: { label: "reset-credentials", value: "resetCredentials" },
};

export const TV_OPTIONS = {
    tv: { label: "channels", value: "tv" },
    "ccinstruction-screen": { label: "ccinstruction-screen", value: "ccinstruction-screen" },
    languageSelection: { label: "welcomeScreen-lang", value: "languageSelection" },
    inputs: { label: "inputs", value: "inputs" },
};

export const MOVIES_OPTIONS = {
    movies: { label: "all-movies", value: "movies" },
    "hollywood-movies": { label: "hollywood-movies", value: "hollywood-movies" },
    "adult-movies": { label: "adult-movies", value: "adult-movies" },
};

export const GUEST_STAYS_OPTIONS = {
    bill: { label: "bill", value: "bill" },
    checkout: { label: "express-checkout", value: "checkout" },
    parentalcode: { label: "parental code", value: "parentalcode" },
    feedback: { label: "feedback", value: "feedback" },
};

export function getScreensOptions(contentTree) {
    const rootDir = contentTree?.screens?.dir;
    if (!rootDir) return [];

    function collectScreens(dir) {
        let screens = [];
        if (dir.contents && Array.isArray(dir.contents)) {
            dir.contents.forEach((screen) => {
                screens.push({
                    label: screen.name,
                    value: screen.id,
                });
            });
        }
        if (dir.subDirs && Array.isArray(dir.subDirs)) {
            dir.subDirs.forEach((subDir) => {
                screens = screens.concat(collectScreens(subDir));
            });
        }
        return screens;
    }

    return collectScreens(rootDir);
}

export function extractPermissions(permissions, isCorporate) {
    // Product
    const hasChannels = permissions?.product?.channels;
    const hasMobile = permissions?.product?.mobile;
    const hasStay = permissions?.product?.guest;
    const hasCast = permissions?.product?.cast;
    const hasSales = permissions?.product?.sales;
    const hasCommonZones = permissions?.product?.house;
    const hasPOS = permissions?.integrations?.pos;
    const hasMyOrders = permissions?.services?.myOrders;
    const hasLegacyTV = permissions?.legacy?.tv;
    const hasLegacySales = permissions?.legacy?.sales;
    const hasApps = permissions?.design?.apps;
    const hasSignage = permissions?.product?.signage;
    const hasMovies = permissions?.movies?.hollywood;
    const hasAdultMovies = permissions?.movies?.adult;
    const hasMaps = permissions?.design?.maps;

    // Actions
    const hasActionInputs = permissions?.actions?.tvInputs;
    const hasActionBill = permissions?.actions?.bill && hasStay;
    const hasActionCheckout = permissions?.actions?.checkout && hasStay;
    const hasActionAreas = permissions?.actions?.areas && hasCommonZones;
    const hasActionBookings = permissions?.actions?.bookings && hasCommonZones;
    const hasActionPOS = permissions?.actions?.pos && hasPOS;
    const hasActionChannelList = permissions?.actions?.listChannels;
    const hasActionChannel = permissions?.actions?.channel && !isCorporate;
    const hasActionSales = permissions?.actions?.sales && hasSales;
    const hasActionVendureSales = hasActionSales && !hasLegacySales;
    const hasActionLegacySales = hasActionSales && hasLegacySales;
    const hasParentalCode = permissions?.actions?.actParental;
    const hasActionFeedback = permissions?.actions?.actFeedback && hasStay;

    return {
        hasChannels,
        hasMobile,
        hasStay,
        hasCast,
        hasSales,
        hasCommonZones,
        hasPOS,
        hasMyOrders,
        hasLegacyTV,
        hasLegacySales,
        hasApps,
        hasSignage,
        hasMovies,
        hasAdultMovies,
        hasMaps,

        hasActionInputs,
        hasActionBill,
        hasActionCheckout,
        hasActionAreas,
        hasActionBookings,
        hasActionPOS,
        hasActionChannelList,
        hasActionChannel,
        hasActionSales,
        hasActionVendureSales,
        hasActionLegacySales,
        hasParentalCode,
        hasActionFeedback,
    };
}

export function arrangeOptionsPredefinedSection({
    vendureShopsData,
    languages,
    hasMyOrders,
    hasApps,
    hasActionChannelList,
    hasStay,
    hasCast,
    hasActionInputs,
    hasMovies,
    hasAdultMovies,
    hasActionVendureSales,
    hasActionLegacySales,
    roomShopsData,
    hasParentalCode,
    hasActionBill,
    hasActionFeedback,
    hasActionCheckout,
    tvChannelsData,
    commonZonesData,
}) {
    const langDefault = getProjectLangs(languages, t)?.langDefault || "en";
    const optionsPredefinedSection = [];

    appsPredefinedSection({ t, hasStay, optionsPredefinedSection, hasApps });
    tvPredefinedSection({ t, hasActionChannelList, hasCast, hasActionInputs, optionsPredefinedSection });
    moviesPredefinedSection({ t, hasMovies, hasAdultMovies, optionsPredefinedSection });
    vendurePredefinedSection({
        t,
        hasActionVendureSales,
        vendureShopsData,
        optionsPredefinedSection,
        hasMyOrders,
        langDefault,
    });
    legacySalesPredefinedSection({ t, hasActionLegacySales, roomShopsData, optionsPredefinedSection });
    guestStayPredefinedSection({
        t,
        hasStay,
        hasActionBill,
        hasActionCheckout,
        hasActionFeedback,
        hasParentalCode,
        optionsPredefinedSection,
    });

    return optionsPredefinedSection;
}

export function appsPredefinedSection({ t, hasStay, optionsPredefinedSection, hasApps }) {
    if (hasApps) {
        const options = [
            { ...APPS_OPTIONS.netflix, label: t(APPS_OPTIONS.netflix.label) },
            { ...APPS_OPTIONS.appletv, label: t(APPS_OPTIONS.appletv.label) },
            { ...APPS_OPTIONS.youtube, label: t(APPS_OPTIONS.youtube.label) },
        ];
        if (hasStay) {
            options.push({ ...APPS_OPTIONS.resetCredentials, label: t(APPS_OPTIONS.resetCredentials.label) });
        }
        optionsPredefinedSection.push({
            label: t("apps"),
            options,
        });
    }
}

export function tvPredefinedSection({ t, hasActionChannelList, hasCast, hasActionInputs, optionsPredefinedSection }) {
    const options = [];
    if (hasActionChannelList) {
        options.push({ ...TV_OPTIONS.tv, label: t(TV_OPTIONS.tv.label) });
    }
    if (hasCast) {
        options.push({ ...TV_OPTIONS["ccinstruction-screen"], label: t(TV_OPTIONS["ccinstruction-screen"].label) });
    }
    if (hasActionInputs) {
        options.push({ ...TV_OPTIONS.inputs, label: t(TV_OPTIONS.inputs.label) });
    }
    optionsPredefinedSection.push({
        label: t("tv"),
        options,
    });
}

export function moviesPredefinedSection({ t, hasMovies, hasAdultMovies, optionsPredefinedSection }) {
    if (hasMovies || hasAdultMovies) {
        const options = [];
        options.push({ ...MOVIES_OPTIONS.movies, label: t(MOVIES_OPTIONS.movies.label) });
        if (hasMovies) {
            options.push({ ...MOVIES_OPTIONS["hollywood-movies"], label: t(MOVIES_OPTIONS["hollywood-movies"].label) });
        }
        if (hasAdultMovies) {
            options.push({ ...MOVIES_OPTIONS["adult-movies"], label: t(MOVIES_OPTIONS["adult-movies"].label) });
        }
        optionsPredefinedSection.push({
            label: t("movies"),
            options,
        });
    }
}

export function vendurePredefinedSection({
    t,
    hasActionVendureSales,
    vendureShopsData,
    optionsPredefinedSection,
    hasMyOrders,
    langDefault,
}) {
    if (hasActionVendureSales) {
        const options = [];
        if (vendureShopsData?.shops?.results) {
            options.push({ label: t("shops"), value: "roomshops" });
            vendureShopsData.shops.results.forEach((s) => {
                if (s?.customFields?.isActive) {
                    options.push({
                        label: parseTranslation(s.name, langDefault) || s.token,
                        value: "roomshop:" + s.token,
                    });
                }
            });
            if (hasMyOrders) {
                options.push({
                    label: t("my-orders-history"),
                    value: "myOrders",
                });
            }
        }
        optionsPredefinedSection.push({ label: t("sales"), options });
    }
}

export function legacySalesPredefinedSection({ t, hasActionLegacySales, roomShopsData, optionsPredefinedSection }) {
    if (hasActionLegacySales) {
        const options = [];
        if (roomShopsData?.roomShops?.results) {
            roomShopsData.roomShops.results.forEach((s) => {
                if (s.available) {
                    options.push({
                        label: t("roomshop {{name}}", { name: s.name }),
                        value: "roomshop:" + s.id,
                    });
                }
            });
        }
        optionsPredefinedSection.push({ label: t("sales"), options });
    }
}

export function guestStayPredefinedSection({
    t,
    hasStay,
    hasActionBill,
    hasActionCheckout,
    hasActionFeedback,
    hasParentalCode,
    optionsPredefinedSection,
}) {
    if (hasStay) {
        const options = [];
        if (hasActionBill) {
            options.push({ ...GUEST_STAYS_OPTIONS.bill, label: t(GUEST_STAYS_OPTIONS.bill.label) });
        }
        if (hasActionCheckout) {
            options.push({ ...GUEST_STAYS_OPTIONS.checkout, label: t(GUEST_STAYS_OPTIONS.checkout.label) });
        }
        if (hasParentalCode) {
            options.push({ ...GUEST_STAYS_OPTIONS.parentalcode, label: t(GUEST_STAYS_OPTIONS.parentalcode.label) });
        }
        if (hasActionFeedback) {
            options.push({ ...GUEST_STAYS_OPTIONS.feedback, label: t(GUEST_STAYS_OPTIONS.feedback.label) });
        }
        optionsPredefinedSection.push({ label: t("guest-stay"), options });
    }
}

export function updateLoadingAndErrors({
    ready,
    setLoading,
    loadingRoomShops,
    loadingTvChannels,
    loadingVendureShops,
    loadingCommonZones,
    hasActionChannel,
    tvChannelsData,
    roomShopsData,
    hasActionLegacySales,
    errorRoomShops,
    vendureShopsData,
    errorVendureShops,
    hasActionVendureSales,
    errorCommonZones,
    errorTvChannels,
    loadingMaps,
    errorMaps,
    hasMaps,
}) {
    if (!ready) return;

    const isLoading =
        loadingRoomShops ||
        loadingTvChannels ||
        loadingVendureShops ||
        loadingCommonZones ||
        loadingMaps ||
        (hasActionChannel && !tvChannelsData && !errorTvChannels) ||
        (hasActionLegacySales && !roomShopsData && !errorRoomShops) ||
        (hasActionVendureSales && !vendureShopsData && !errorVendureShops) ||
        (hasMaps && loadingMaps && !errorMaps);
    setLoading(isLoading);

    [errorRoomShops, errorTvChannels, errorVendureShops, errorCommonZones, errorMaps].forEach((err) => {
        if (err) console.error(err);
    });
}
