import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import Select from "components/Select";
import TextInput from "components/TextInput";
import Button from "components/Button";
import { DestinationType } from "./utils/utils";
import useDestinationData from "./Hooks/useDestinationData";
import Icon from "components/Icon";
import { useTranslation } from "react-i18next";
import InteractiveImageDisplay from "components/InteractiveImageDisplay";
import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";
import { isAValidHTTPS } from "hooks/Utils/Services/ChannelsUtils";
import classNames from "classnames";

const FieldAlert = ({ alertMessages, field }) => {
    const messageObj = alertMessages && alertMessages[field];
    if (!messageObj) return null;
    const messageText = typeof messageObj === "string" ? messageObj : messageObj.text;
    const alertClass =
        typeof messageObj === "string" ? "text-red-600 mt-1" : `mt-1 ${messageObj.className || "text-red-600"}`;
    return <p className={alertClass}>{messageText}</p>;
};

const Destination = forwardRef((props, ref) => {
    const {
        filterOptions,
        alertMessages,
        onHandleChooseAsset,
        avoidOnBlurLink = false,
        excludeOptions = [],
        onBlurLink,
        onChange,
        excludeOptionsInMaps = [],
    } = props;

    const { defaultLanguage } = useAuth();
    const { t } = useTranslation();
    const defaultDestinationValues = {
        [DestinationType.SCREEN]: "",
        [DestinationType.PREDEFINED_SECTION]: "",
        [DestinationType.LINK]: "",
        [DestinationType.MEDIA_FILE]: "",
        [DestinationType.MAPS]: "",
    };

    const [selectedDestinationType, setSelectedDestinationType] = useState(DestinationType.DISABLED);
    const [hasLinkError, setHasLinkError] = useState(false);
    const [destinationValues, setDestinationValues] = useState({ ...defaultDestinationValues });

    const { screensOptions, loadingScreens, predefinedOptions, loadingPredefined, mapsOptions } = useDestinationData();
    const availablePredefinedOptions = filterOptions ? filterOptions(predefinedOptions) : predefinedOptions;

    const handleDestinationTypeChange = ({ value }) => {
        setSelectedDestinationType(value);

        if (onChange && value === DestinationType.DISABLED) {
            onChange({ type: value, value: null });
        }
    };

    const handleDestinationValueChange = (type, newValue) => {
        setDestinationValues((prevValues) => {
            const updatedValues = { ...prevValues, [type]: newValue };
            if (onChange && type !== DestinationType.LINK) {
                onChange({ type, value: newValue });
            }
            return updatedValues;
        });
    };

    const handleAssetSelection = () => {
        if (onHandleChooseAsset) onHandleChooseAsset();
    };

    const getCurrentDestination = () => ({
        type: selectedDestinationType,
        value: destinationValues[selectedDestinationType],
    });

    const isOnlyScreenFilled = () => {
        return (
            destinationValues[DestinationType.SCREEN] &&
            Object.entries(destinationValues).every(([key, value]) => {
                return key === DestinationType.SCREEN ? true : value === "";
            })
        );
    };

    const renderScreenValidationError = () => {
        const screenValue = destinationValues[DestinationType.SCREEN];
        if (screenValue && !screensOptions?.find((screen) => screen.value === screenValue) && isOnlyScreenFilled()) {
            return (
                <div className="flex items-center mt-1">
                    <Icon type="warning" className="mr-1 text-orange-100" />
                    <p className="text-base">{t("unlinked-image")}</p>
                </div>
            );
        }
        return null;
    };

    useImperativeHandle(ref, () => ({
        setDestinationData: (newData) => setDestinationValues((prevValues) => ({ ...prevValues, ...newData })),
        setDestinationType: (newType) => setSelectedDestinationType(newType),
        getDestinationData: () => destinationValues,
        getCurrentDestination: getCurrentDestination,
        setLinkError: (error) => setHasLinkError(error),
        updateDestinationDataWithReset: (val) =>
            setDestinationValues({ ...defaultDestinationValues, [val.type]: val.value }),
    }));

    const filteredMapsOptions = mapsOptions.filter((option) => !excludeOptionsInMaps.includes(option.value));

    return (
        <div className="flex flex-col gap-5">
            {!excludeOptions.includes(DestinationType.DISABLED) && (
                <div>
                    <Radiobutton
                        label={t("disabled")}
                        value={DestinationType.DISABLED}
                        checked={selectedDestinationType === DestinationType.DISABLED}
                        onChange={handleDestinationTypeChange}
                    />
                </div>
            )}

            {!excludeOptions.includes(DestinationType.SCREEN) && (
                <div>
                    <div className="w-full flex justify-between">
                        <Radiobutton
                            label={t("screen")}
                            value={DestinationType.SCREEN}
                            checked={selectedDestinationType === DestinationType.SCREEN}
                            onChange={handleDestinationTypeChange}
                        />
                        {renderScreenValidationError()}
                    </div>
                    <div className="mt-2">
                        <Select
                            key={destinationValues[DestinationType.SCREEN]}
                            id="destination-screen-select"
                            placeholder={t("select screen")}
                            value={destinationValues[DestinationType.SCREEN]}
                            options={screensOptions}
                            onChange={(value) => handleDestinationValueChange(DestinationType.SCREEN, value)}
                            disabled={selectedDestinationType !== DestinationType.SCREEN}
                            designClass={{
                                validation:
                                    destinationValues[DestinationType.SCREEN] &&
                                    !screensOptions?.find(
                                        (screen) => screen.value === destinationValues[DestinationType.SCREEN]
                                    ) &&
                                    selectedDestinationType === DestinationType.SCREEN &&
                                    isOnlyScreenFilled()
                                        ? "border border-red-100"
                                        : "",
                            }}
                        />
                        {selectedDestinationType === DestinationType.SCREEN && (
                            <FieldAlert alertMessages={alertMessages} field={DestinationType.SCREEN} />
                        )}
                    </div>
                </div>
            )}

            {!excludeOptions.includes(DestinationType.PREDEFINED_SECTION) && (
                <div>
                    <Radiobutton
                        label={t("predefined-section")}
                        value={DestinationType.PREDEFINED_SECTION}
                        checked={selectedDestinationType === DestinationType.PREDEFINED_SECTION}
                        onChange={handleDestinationTypeChange}
                    />
                    <div className="mt-2">
                        <Select
                            id="destination-predefined-select"
                            placeholder={t("select predefined section")}
                            value={destinationValues[DestinationType.PREDEFINED_SECTION]}
                            options={availablePredefinedOptions}
                            onChange={(value) =>
                                handleDestinationValueChange(DestinationType.PREDEFINED_SECTION, value)
                            }
                            disabled={selectedDestinationType !== DestinationType.PREDEFINED_SECTION}
                        />
                        <FieldAlert alertMessages={alertMessages} field={DestinationType.PREDEFINED_SECTION} />
                    </div>
                </div>
            )}

            {!excludeOptions.includes(DestinationType.LINK) && (
                <div>
                    <Radiobutton
                        label={t("link-url-https-only")}
                        value={DestinationType.LINK}
                        checked={selectedDestinationType === DestinationType.LINK}
                        onChange={handleDestinationTypeChange}
                    />
                    <div className="mt-2">
                        <TextInput
                            id="destination-link-input"
                            placeholder="http://"
                            value={destinationValues[DestinationType.LINK]}
                            onChange={(value) => handleDestinationValueChange(DestinationType.LINK, value)}
                            disabled={selectedDestinationType !== DestinationType.LINK}
                            className={classNames("w-full", {
                                "border-red-100 border":
                                    hasLinkError && selectedDestinationType === DestinationType.LINK,
                            })}
                            onBlur={(value) => {
                                if (!avoidOnBlurLink) {
                                    if (!isAValidHTTPS(value)) {
                                        setHasLinkError(true);
                                    } else {
                                        setHasLinkError(false);
                                    }
                                }
                                if (onBlurLink) onBlurLink(value);
                            }}
                        />
                        <FieldAlert alertMessages={alertMessages} field={DestinationType.LINK} />
                    </div>
                </div>
            )}

            {!excludeOptions.includes(DestinationType.MEDIA_FILE) && (
                <div>
                    <Radiobutton
                        label={`${t("media-file")}:`}
                        value={DestinationType.MEDIA_FILE}
                        checked={selectedDestinationType === DestinationType.MEDIA_FILE}
                        onChange={handleDestinationTypeChange}
                    />
                    <div className="mt-2">
                        <Button
                            disabled={selectedDestinationType !== DestinationType.MEDIA_FILE}
                            id="destination-media-file-button"
                            design="blue-outline"
                            className="w-full"
                            onClick={handleAssetSelection}
                        >
                            {t("choose-an-asset")}
                        </Button>
                        {destinationValues[DestinationType.MEDIA_FILE] && (
                            <InteractiveImageDisplay
                                id="media-file-destination"
                                src={Session.getDasUrl(
                                    `${destinationValues[DestinationType.MEDIA_FILE]}?lang=${defaultLanguage}`
                                )}
                                title={null}
                                resolution={null}
                                className="w-full h-28"
                                onDelete={() => {
                                    setDestinationValues((prevValues) => ({
                                        ...prevValues,
                                        [DestinationType.MEDIA_FILE]: "",
                                    }));
                                    if (onChange) {
                                        onChange({ type: DestinationType.MEDIA_FILE, value: "" });
                                    }
                                }}
                            />
                        )}
                        <FieldAlert alertMessages={alertMessages} field={DestinationType.MEDIA_FILE} />
                    </div>
                </div>
            )}

            {!excludeOptions.includes(DestinationType.MAPS) && (
                <div>
                    <Radiobutton
                        label={t("maps")}
                        value={DestinationType.MAPS}
                        checked={selectedDestinationType === DestinationType.MAPS}
                        onChange={handleDestinationTypeChange}
                    />
                    <div className="mt-2">
                        <Select
                            key={filteredMapsOptions}
                            id="destination-map-select"
                            placeholder={t("select-map")}
                            value={destinationValues[DestinationType.MAPS]}
                            options={filteredMapsOptions}
                            onChange={(value) => handleDestinationValueChange(DestinationType.MAPS, value)}
                            disabled={selectedDestinationType !== DestinationType.MAPS}
                        />
                        <FieldAlert alertMessages={alertMessages} field="maps" />
                    </div>
                </div>
            )}
        </div>
    );
});

export default Destination;
