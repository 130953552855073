import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { UseContentTree } from "components/Section/Design/Advertising/graphql/useSignages";
import { useProjectsWithDesigns, useAssignToProject } from "hooks/Data/useDesigns";

import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Loading from "components/Loading";
import { useAuth } from "hooks/Session/auth";
import { useDesignLinkedContentAlerts } from "components/Section/Design/Alerts/useDesignLinkedContentAlerts";
import { LINK_DESIGN_TO_PROJECT } from "constants/design";

const ApplyDesign = ({ id, name, projectsRefs, onSuccess }) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const { project } = useAuth();

    const update = useAssignToProject(id, {
        onSuccess: () => {
            toast.success(t("operation-successful"));
            if (onSuccess) {
                onSuccess();
            }
            close();
        },
        onError: () => toast.error(t("mutation-error")),
    });

    const projectsWithDesigns = useProjectsWithDesigns();
    const projectsDesignsInUse = projectsWithDesigns?.data;

    const { getContentTree, contentTree, loading: loadingContentTree } = UseContentTree();

    const propertyDesignsIDs = projectsRefs?.length
        ? projectsRefs
              ?.map((projectRef) => projectsDesignsInUse?.find((pd) => pd?.projectRef === projectRef)?.designID)
              ?.filter(Boolean)
        : [];

    const loading = projectsRefs?.length && (!projectsWithDesigns.ready || loadingContentTree);

    const contents = contentTree?.screens?.dir?.contents || [];

    const { loseNavigationAlert } = useDesignLinkedContentAlerts({
        contents,
        projectsInUse: [],
        mode: "apply",
    });

    useEffect(() => {
        if (projectsRefs?.length) {
            projectsWithDesigns.load();
        }
    }, []);

    useEffect(() => {
        if (projectsWithDesigns?.ready && propertyDesignsIDs?.length) {
            //TODO DESIGNS evaluate all propertyDesignsIDs elements (better: evaluate this on back)
            getContentTree({ variables: { designID: propertyDesignsIDs?.[0] } });
        }
    }, [projectsWithDesigns?.ready]);

    return (
        <Modal
            title={`${t("apply-design")} - ${name}`}
            className="p-10 w-1/3"
            footer={
                <div className="flex justify-end space-x-2">
                    <Button id="cancel" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="apply"
                        design="blue"
                        onClick={() => {
                            update.post({
                                action: LINK_DESIGN_TO_PROJECT.ASSIGN,
                                projectRefs: [project?.ref],
                            });
                        }}
                    >
                        {t("apply")}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Loading />
            ) : (
                <>
                    <p className="mt-6">{t("design-apply-instructions")}</p>
                    {loseNavigationAlert}
                </>
            )}
        </Modal>
    );
};

export default ApplyDesign;
