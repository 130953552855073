import React, { useContext, useState, useRef } from "react";
import classNames from "classnames";
import { GlobalContext } from "contexts/Global";
import { Session } from "hooks/Utils/Session";
import Icon from "components/Icon";

/**
 * Displays a selectable media item within a grid or list, supporting folder, asset, and video types.
 * This component uses the GlobalContext to retrieve language settings and applies conditional styling
 * based on selection state.
 *
 * @component
 * @param {Object} props - The properties for the ItemSelectionMedia component.
 * @param {Object} props.el - The media item data, including its type and reference.
 * @param {string} props.el.type - The type of media item, can be 'folder', 'asset', or 'video'.
 * @param {string} props.el.ref - The reference ID for the media item.
 * @param {boolean} props.isSelected - Indicates if the item is currently selected.
 * @param {function} props.handleClick - Callback function triggered on item click.
 * @param {string} props.id - Unique identifier for the item; required for each media item.
 *
 * @throws {Error} Throws an error if the `id` prop is not provided.
 *
 * @returns {JSX.Element} A clickable item component with optional tooltip and conditional styling.
 *
 * @example
 * <ItemSelectionMedia
 *    el={{ type: 'asset', ref: 'media-123' }}
 *    index={1}
 *    isSelected={true}
 *    handleClick={() => console.log('Media clicked')}
 *    id="media-123"
 * />
 */
const ItemSelectionMedia = ({ el, isSelected, handleClick, id }) => {
    if (!id) {
        throw new Error("ItemSelectionMedia requires an id prop");
    }

    const { lang } = useContext(GlobalContext);
    const [showTooltip, setShowTooltip] = useState(false);
    const [imageError, setImageError] = useState(false);
    const nameRef = useRef(null);

    const containerClass = classNames("w-full h-full border rounded flex items-center justify-center object-cover", {
        "bg-gray-200": el.type !== "folder",
        "border-blue-600": isSelected,
    });

    const imageClass = classNames("h-32", {
        "w-24": el.type === "folder",
        "object-cover": el.type !== "folder",
    });
    const noImageClass = classNames("h-32 w-full bg-gray-200 flex items-center justify-center");
    const nameClass = "mt-2 text-zafiro-600 w-full text-center whitespace-nowrap overflow-hidden overflow-ellipsis";

    const handleMouseOver = () => {
        if (nameRef.current) {
            const isTruncated = nameRef.current.scrollWidth > nameRef.current.clientWidth;
            setShowTooltip(isTruncated);
        }
    };

    const imageSrc = (() => {
        switch (el.type) {
            case "folder":
                return require("../../../../../assets/images/icons/folder.svg");
            case "asset":
                return Session.getDasUrl(`${el.ref}?w=138&h=138&${lang}`);
            case "video":
                return Session.getDasUrl(`thumb/${el.ref}-${lang}?w=138&h=138`);
            case "screen":
                return `${Session.getDasUrl()}/snapshot/content-${el.ref}.jpg?${lang}`;
            default:
                return null;
        }
    })();

    return (
        <div className="flex flex-col">
            <button id={`button-item-media-${id}`} onClick={handleClick} className={containerClass}>
                {imageSrc && !imageError ? (
                    <img
                        key={id}
                        id={`button-item-media-${id}`}
                        src={imageSrc}
                        onError={() => setImageError(true)}
                        className={imageClass}
                        alt={el.label || "media item"}
                    />
                ) : (
                    <div className={noImageClass}>
                        <Icon className="text-gray-400" size={3} type="no-image" />
                    </div>
                )}
            </button>
            <div
                ref={nameRef}
                onMouseOver={handleMouseOver}
                id={`name-item-media-${id}`}
                onMouseLeave={() => setShowTooltip(false)}
                data-tip={showTooltip ? el.name : ""}
                className={nameClass}
                data-for={"default-tooltip"}
            >
                {el.name}
            </div>
        </div>
    );
};

export default ItemSelectionMedia;
