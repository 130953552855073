import React, { forwardRef } from "react";
import classNames from "classnames";
import { useMap, TOOLBAR_MAP_OPTIONS } from "../MapContext";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";

const Header = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { toolbarMapOptionSelected } = useMap();

    const headerClasses = classNames({
        "px-6 pt-6 w-full absolute top-0 left-0 select-none pb-1 bg-white flex-none": true,
        "border-b border-gray-300": toolbarMapOptionSelected !== TOOLBAR_MAP_OPTIONS.SETTINGS_IMAGE,
    });

    return (
        <div ref={ref} className={headerClasses}>
            {toolbarMapOptionSelected === TOOLBAR_MAP_OPTIONS.INTEREST_POINTS && (
                <>
                    <div className="font-bold">{t("manage-points-of-interest")}</div>
                    <div className="mt-4">
                        {t("add-sort-and-edit-the-points-of-interest-you-want-displayed-on-the-map")}
                    </div>
                </>
            )}
            {toolbarMapOptionSelected === TOOLBAR_MAP_OPTIONS.CATEGORIES && (
                <>
                    <div className="flex gap-2">
                        <p className="font-bold">{t("add-category")}</p>
                        <Icon
                            type={"info"}
                            size={"2xl"}
                            tooltip={t("these-categories-will-allow-users-to-filter-points-of-interest")}
                        ></Icon>
                    </div>
                    <div className="mt-4">{t("add-sort-and-edit-the-categories-you-want-displayed-on-the-map")}</div>
                </>
            )}
            {toolbarMapOptionSelected === TOOLBAR_MAP_OPTIONS.SETTINGS_IMAGE && (
                <>
                    <div className="flex gap-2 pb-3">
                        <p className="font-bold">{t("image")}</p>
                        <Icon type={"info"} size={"2xl"} tooltip={t("recommend-resolution-minimum-77")}></Icon>
                    </div>
                </>
            )}
        </div>
    );
});

export default Header;
