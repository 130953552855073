import React, { useEffect, useState } from "react";
import { useModalEditNamePointOfInterest } from "../modals/pointsOfInterest/useModalEditNamePointOfInterest";
import { useModalEditDescriptionPointOfInterest } from "../modals/pointsOfInterest/useModalEditDescriptionPointOfInterest";
import { useTranslation } from "react-i18next";
import TextInput from "components/TextInput";
import Textarea from "components/Textarea";
import Button from "components/Button";
import { useAuth } from "hooks/Session/auth";
import { setDefaultValues } from "../utils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getParseMapName } from "../utils";

export const NameAndDescriptionCard = ({ data, update, fetch, constantName }) => {
    const { t } = useTranslation();
    const { pointinterestid, id } = useParams();
    const { defaultLanguage } = useAuth();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const { open: openModalEditNamePointOfInterest } = useModalEditNamePointOfInterest();
    const { open: openModalEditDescriptionPointOfInterest } = useModalEditDescriptionPointOfInterest();

    useEffect(() => {
        setDefaultValues(data, "name", defaultLanguage, setName);
        setDefaultValues(data, "description", defaultLanguage, setDescription);
    }, [data]);

    return (
        <div className=" bg-white rounded-md p-4 flex flex-col gap-4">
            <div className="flex justify-between">
                <p className=" font-bold text-2xl">{t("name-and-description")}</p>
                <p>
                    {t(`language:${defaultLanguage}`)}
                    {" ("}
                    <span>{t("default-language")}</span>
                    {")"}
                </p>
            </div>

            <div>
                <div className=" flex justify-between">
                    <p className=" font-bold">{t("name")}*</p>
                    <Button
                        id="translate-name-button"
                        design={"link"}
                        onClick={() => {
                            openModalEditNamePointOfInterest({
                                names: data.name,
                                name: constantName,
                                mapId: id,
                                id: pointinterestid,
                                fetch,
                            });
                        }}
                    >
                        {t("translate")}
                    </Button>
                </div>
                <TextInput
                    id="poi-input-name"
                    value={name}
                    error={!name}
                    onBlur={(val) => {
                        if (val && val != name) {
                            update({
                                variables: {
                                    name: [{ lang: defaultLanguage, text: val }],
                                    mapId: id,
                                    id: pointinterestid,
                                },
                            });
                        } else {
                            toast.error(t("errors-in-red"));
                        }
                        setName(val);
                    }}
                />
            </div>
            <div>
                <div className=" flex justify-between">
                    <p className=" font-bold">{t("description")}</p>
                    <Button
                        id="translate-description-button"
                        design={"link"}
                        onClick={() => {
                            openModalEditDescriptionPointOfInterest({
                                descriptions: data.description,
                                name: constantName,
                                id,
                                pointinterestid,
                                fetch,
                            });
                        }}
                    >
                        {t("translate")}
                    </Button>
                </div>
                <Textarea
                    id="poi-input-description"
                    resize={false}
                    readOnly={false}
                    onBlur={(val) => {
                        update({
                            variables: {
                                description: [{ lang: defaultLanguage, text: val }],
                                mapId: id,
                                id: pointinterestid,
                            },
                        });
                    }}
                    onChange={(val) => {
                        setDescription(val);
                    }}
                    value={description}
                />
            </div>
        </div>
    );
};
