import React, { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import {
    MUTATION_CREATE_GROUP,
    DELETE_PROPERTY_GROUP,
    DUPLICATE_PROPERTY_GROUP,
    UPDATE_PROPERTY_GROUP,
    GET_CORPORATE_DESIGNS,
    GET_DESIGNS_WITH_LINKED_SIGNAGES,
} from "./mutationsQueries";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { checkHasLinkedSignages } from "components/Section/Design/Advertising/utils";
import { BRAND_ERROR } from "constants/design";
import Modal, { useModal } from "components/Modal";
import Icon from "components/Icon";
import Button from "components/Button";

export const useGetHasLinkedSignages = ({ refs, projectsRefs }) => {
    const [designIds, setDesignIds] = useState([]);
    const [hasLinkedSignagesState, setHasLinkedSignagesState] = useState(false);

    const [getDesignByProperties, { data: dataDesignByProperties, loading: loadingDesigns }] = useLazyQuery(
        GET_CORPORATE_DESIGNS,
        { fetchPolicy: "network-only" }
    );

    const [getContentTree, { data: dataContentTree, loading: loadingContentTree }] = useLazyQuery(
        GET_DESIGNS_WITH_LINKED_SIGNAGES(designIds),
        { fetchPolicy: "network-only" }
    );

    const loading = loadingDesigns || loadingContentTree;

    useEffect(() => {
        getDesignByProperties();
    }, []);

    useEffect(() => {
        const results = dataDesignByProperties?.designs?.results ?? [];

        const updatedResults = results.map((design) => ({
            ...design,
            projectsRefsInUse:
                design.projectsInUse?.filter((project) => !project.isDefault).map((project) => project.projectRef) ??
                [],
        }));

        const filteredIds = updatedResults
            .filter((design) =>
                design.visibilityScope.some((scope) => scope.type === "BRAND" && refs?.includes(scope.ref))
            )
            .filter((design) => projectsRefs.some((ref) => design.projectsRefsInUse.includes(ref)))
            .map((design) => design.id);

        if (filteredIds.length > 0) {
            setDesignIds(filteredIds);
        }
    }, [dataDesignByProperties]);

    useEffect(() => {
        if (designIds.length > 0) {
            getContentTree();
        }
    }, [designIds]);

    useEffect(() => {
        if (dataContentTree) {
            setHasLinkedSignagesState(checkHasLinkedSignages(dataContentTree));
        }
    }, [dataContentTree]);

    return { hasLinkedSignages: hasLinkedSignagesState, loading };
};

const useMutationWithHandlers = (mutation, { close, refetch, names }) => {
    const { t } = useTranslation();
    const { open } = useModal();

    const [mutateFunction, { loading }] = useMutation(mutation, {
        onCompleted: () => {
            toast.success(t("operation-successful"));
            if (typeof refetch === "function") {
                refetch();
            }
            if (typeof close === "function") {
                close();
            }
        },
        onError: (error) => {
            const brandError = error?.graphQLErrors?.[0]?.extensions?.Code === BRAND_ERROR;
            const errorMessage = error?.graphQLErrors?.[0]?.message || error.message || "An unknown error occurred";
            if (typeof close === "function") {
                close();
            }
            if (brandError) {
                open(
                    <Modal
                        title={`${names?.[0]} - ${t("delete")}`}
                        className="w-4/12 p-10"
                        footer={
                            <div className="flex justify-end">
                                <Button id="modal-button-cancel" onClick={close} className="btn btn-blue">
                                    {t("accept")}
                                </Button>
                            </div>
                        }
                    >
                        <div className="flex items-center gap-3">
                            <Icon type="warning" size="2xl" />
                            <p>{t("cant-delete-group")}</p>
                        </div>
                    </Modal>
                );
            } else {
                toast.error(errorMessage);
            }
        },
    });

    return { mutateFunction, loading };
};

export const useCreateGroupProperty = (params) => {
    const { mutateFunction: createGroupProperty, loading } = useMutationWithHandlers(MUTATION_CREATE_GROUP, params);
    return { createGroupProperty, loadingCreateGroupProperty: loading };
};

export const useDeletePropertyGroup = (params) => {
    const { mutateFunction: deletePropertyGroup, loading } = useMutationWithHandlers(DELETE_PROPERTY_GROUP, params);
    return { deletePropertyGroup, loadingDeletePropertyGroup: loading };
};

export const useDuplicatePropertyGroup = (params) => {
    const { mutateFunction: duplicatePropertyGroup, loading } = useMutationWithHandlers(
        DUPLICATE_PROPERTY_GROUP,
        params
    );
    return { duplicatePropertyGroup, loadingDuplicatePropertyGroup: loading };
};

export const useUpdatePropertyGroup = (params) => {
    const { mutateFunction: updatePropertyGroup, loading } = useMutationWithHandlers(UPDATE_PROPERTY_GROUP, params);
    return { updatePropertyGroup, loadingUpdatePropertyGroup: loading };
};
