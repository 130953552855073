import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import Button from "./Button";
import { useTranslation } from "react-i18next";

/**
 * SizeSelector Component
 *
 * Allows selecting a size from predefined options (SMALL, MEDIUM, LARGE).
 *
 * @component
 * @param {Object} props - Component properties
 * @param {string} [props.value] - Initial selected size ("SMALL", "MEDIUM", or "LARGE").
 * @param {Function} [props.onChange] - Callback function triggered when the size changes.
 * @returns {JSX.Element} Size selection component
 */
const SizeSelector = ({ value, onChange }) => {
    const { t } = useTranslation();
    const sizes = useMemo(() => ["SMALL", "MEDIUM", "LARGE"], []);
    const [selectedSize, setSelectedSize] = useState(value || "SMALL");

    useEffect(() => {
        if (value && sizes.includes(value)) {
            setSelectedSize(value);
        }
    }, [value, sizes]);

    const handleChange = (size) => {
        setSelectedSize(size);
        if (onChange) onChange(size);
    };

    return (
        <div className="flex w-full space-x-4">
            {sizes.map((size) => (
                <Button
                    key={size}
                    onClick={() => handleChange(size)}
                    id={`size-selector-${size.toLowerCase()}`}
                    className="w-full flex flex-col"
                >
                    <div
                        className={classNames(
                            "border-2 h-40 w-full flex items-center justify-center rounded-md transition-all duration-200",
                            {
                                "border-gray-300": selectedSize !== size,
                                "border-4 border-zafiro-600": selectedSize === size,
                            }
                        )}
                    >
                        <div
                            className={classNames("rounded bg-gray-200", {
                                "w-2/12 h-1/6": size === "SMALL",
                                "w-6/12 h-3/6": size === "MEDIUM",
                                "w-9/12 h-5/6": size === "LARGE",
                            })}
                        ></div>
                    </div>
                    <div className="text-lg text-center">{t(size.toLowerCase())}</div>
                </Button>
            ))}
        </div>
    );
};

SizeSelector.displayName = "SizeSelector";
export default SizeSelector;
