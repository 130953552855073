import React, { Fragment } from "react";
import classNames from "classnames";
import { Route, Routes, useLocation, useSearchParams, useNavigate as useHistory } from "react-router-dom";
import uuid from "react-uuid";

import Button from "components/Button";
import Icon from "components/Icon";
import Dropdown from "components/Dropdown";
import Warning from "components/Warning";
import Table from "components/ZafiroTable";

import Info from "./info";
import Inputs from "./inputs";
import Modals from "./modals";
import Others from "./others";
import Editor from "./editor";
import Tables from "./tables";
import Gitlab from "./gitlab";
import Toasts from "./toasts";
import Buttons, { TYPES as BUTTONS_TYPES } from "./buttons";
import Dropdowns, { TYPES as DROPDOWN_TYPES } from "./dropdowns";
import SelectionControls, { TYPES as SELECTION_TYPES } from "./selection-controls";

/**
 * This is the main playground component
 */
const Playground = () => {
    const location = useLocation();

    const sections = [
        { id: "buttons", title: "Buttons", element: <Buttons />, menu: BUTTONS_TYPES },
        //{ id: "cards", title: "Cards", element: <UXWarning /> },
        //{ id: "datepickers", title: "Date pickers", element: <UXWarning /> },
        { id: "dropdowns", title: "Dropdowns", element: <Dropdowns />, menu: DROPDOWN_TYPES },
        { id: "editor", title: "Content Editor", element: <Editor />, hideMenu: true },
        {
            id: "inputs",
            title: "Inputs",
            element: (
                <>
                    {" "}
                    <UXWarning />
                    <Inputs />{" "}
                </>
            ),
        },
        {
            id: "modals",
            title: "Modals",
            element: (
                <>
                    <UXWarning />
                    <Modals />
                </>
            ),
        },
        {
            id: "selection-controls",
            title: "Selection controls",
            element: <SelectionControls />,
            menu: SELECTION_TYPES,
        },
        {
            id: "tables",
            title: "Tables",
            element: (
                <>
                    <UXWarning />
                    <Tables />
                </>
            ),
        },
        /*
        { id: "tabs", title: "Tabs", element: <UXWarning /> },
        { id: "tags", title: "Tags", element: <UXWarning /> },
        { id: "textfield", title: "Text field", element: <UXWarning /> },
        */
        { id: "toasts", title: "Toasts", element: <Toasts /> },
        /*
        { id: "tooltips", title: "Tooltips", element: <UXWarning /> },
        { id: "translations", title: "Translations", element: <UXWarning /> },        
        */
        {
            id: "warnings",
            title: "Warnings",
            element: (
                <>
                    <UXWarning />
                    <Info />
                </>
            ),
        },
        {
            id: "others",
            title: "Others",
            element: (
                <>
                    <UXWarning />
                    <Others />
                </>
            ),
        },
        { id: "gitlab", title: "Gitlab issues", element: <Gitlab />, hideMenu: true },
    ];

    const hideMenu = location?.search?.startsWith("?menu=false");

    return (
        <div className="flex overflow-hidden" style={{ height: "100vh" }}>
            {!hideMenu ? (
                <div className="flex flex-col items-center justify-start bg-white shadow-xs min-h-screen p-5 min-w-60 overflow-auto">
                    <h1 className="text-2xl text-center font-bold pb-5">Playground</h1>
                    <div className="w-full flex flex-col items-center justify-center space-y-1">
                        {sections.map((section) => {
                            const id = section.id;
                            const to = `playground/${section.id}${section.hideMenu ? "?menu=false" : ""}`;

                            const isSelected = location?.pathname?.startsWith(`/${to}`);
                            const hasMenu = section?.menu?.length > 0;

                            const itemClass = (selected, subitem) =>
                                classNames({
                                    "w-full font-bold hover:text-zafiro-400": true,
                                    "text-gray-900": !selected,
                                    "text-zafiro-600": selected,
                                    "px-3 py-1": subitem,
                                    "p-3 border-l-2 rounded-tr-lg": !subitem,
                                    "border-white text-gray-900 bg-white": !selected && !subitem,
                                    "border-zafiro-400 bg-gray-100": selected && !subitem,
                                });

                            const submenuClass = classNames({
                                "py-3 px-2 space-y-4 border-l-2 shadow-inner": true,
                                "border-white bg-gray-100": !isSelected,
                                "bg-gray-200": isSelected,
                            });

                            return (
                                <Fragment key={id}>
                                    {!hasMenu ? (
                                        <Button key={id} id={id} to={to} className={itemClass(isSelected, false)}>
                                            {section.title}
                                        </Button>
                                    ) : (
                                        <Dropdown
                                            key={id}
                                            id={`dropdown-${id}`}
                                            handler={section.title}
                                            designClass={{
                                                dropdown: itemClass(isSelected, false),
                                                handler: `font-bold pr-3 hover:text-zafiro-400 ${
                                                    isSelected ? "text-zafiro-600" : ""
                                                }`,
                                            }}
                                            initialOpen={isSelected}
                                            autoClose={false}
                                        >
                                            <div className={submenuClass}>
                                                {section.menu.map((menu) => {
                                                    const to = `playground/${section.id}/${menu.id}${
                                                        menu.hideMenu ? "?menu=false" : ""
                                                    }`;
                                                    const isSelected = location?.pathname?.startsWith(`/${to}`);
                                                    return (
                                                        <Button
                                                            key={menu.id}
                                                            id={menu.id}
                                                            to={to}
                                                            className={itemClass(isSelected, true)}
                                                        >
                                                            {menu.title}
                                                        </Button>
                                                    );
                                                })}
                                            </div>
                                        </Dropdown>
                                    )}
                                </Fragment>
                            );
                        })}
                    </div>
                </div>
            ) : null}
            <div id="sectionContent" className="w-full min-h-screen overflow-auto">
                <Routes>
                    {sections.map((section) => (
                        <Fragment key={section?.id}>
                            {section?.element ? (
                                <Route
                                    key={section.id}
                                    id={section.id}
                                    path={`playground/${section.id}`}
                                    element={section.element}
                                />
                            ) : null}
                            {section?.menu?.length > 0
                                ? section.menu.map((menu) => (
                                      <Route
                                          key={menu.id}
                                          id={menu.id}
                                          path={`playground/${section.id}/${menu.id}`}
                                          element={
                                              menu.element || React.cloneElement(section.element, { section: menu.id })
                                          }
                                      />
                                  ))
                                : null}
                        </Fragment>
                    ))}
                </Routes>
            </div>
        </div>
    );
};

/**
 * This component is used to group components in the playground
 */
export const ListComponents = ({ children, cols = 3 }) => {
    const child = Array.isArray(children) ? children : [children];
    const groupedComponents = child.reduce((acc, component) => {
        let title =
            component?.type?.displayName || component?.type?.name + " ⚠" || component?.type + " ⚠" || "Unknown ⚠";
        if (title === "Component" && component?.props?.title) {
            title = component.props.title;
        }
        if (!acc[title]) {
            acc[title] = [];
        }
        acc[title].push(component);
        return acc;
    }, {});

    return (
        <>
            <div className={`grid grid-cols-1 gap-4 p-10`}>
                {Object.keys(groupedComponents).map((title) => {
                    if (groupedComponents[title]?.[0]?.type?.displayName === "Component") {
                        return groupedComponents[title];
                    }
                    return (
                        <Component key={title} title={title}>
                            {groupedComponents[title]}
                        </Component>
                    );
                })}
            </div>
        </>
    );
};

/**
 * This component displays a group of components with a title and a warning if the component is not validated
 * @param {JSX.Element} children - The components to display
 * @param {string} title - The title of the group
 * @param {boolean} validated - If the components are validated or not (default: true)
 * @returns {JSX.Element} Final component
 */
export const Component = ({ children, title, validated = true }) => {
    const child = (Array.isArray(children) ? children : [children])
        .reduce((acc, child) => {
            const isFragment = !child?.type && Array.isArray(child);
            if (isFragment) {
                if (child) {
                    acc.push(...(Array.isArray(child) ? child : [child]));
                }
            } else {
                if (child) {
                    acc.push(child);
                }
            }
            return acc;
        }, [])
        .filter((c) => c);

    return (
        <div className="p-5 rounded border" style={{ background: "rgba(255,255,255,.5)" }}>
            <h1 className="text-2xl font-bold pb-5">
                {title}
                {!validated && (
                    <span className="text-orange-500 float-right text-sm ml-2 flex items-center space-x-1">
                        <Icon type="warning" size="xl" style={{ zoom: "70%" }} />
                        <span data-tip="This component is not ready for production" data-for="default-tooltip">
                            Not validated yet
                        </span>
                    </span>
                )}
            </h1>
            <div className="items-start justify-start flex flex-wrap">
                {child.length > 1
                    ? child
                          .filter((c) => c)
                          .map((component) => {
                              const key = component?.key || component?.props?.id || uuid();
                              return (
                                  <div key={key} className="inline-block p-2 m-1 border border-dashed">
                                      {component}
                                  </div>
                              );
                          })
                    : child}
            </div>
        </div>
    );
};
Component.displayName = "Component";

export const HEADER_DESIGN = {
    DEFAULT: "default",
    LIGHT: "light",
    COMPACT: "compact",
};

/**
 * This component displays a header with a title and a description
 * @param {string} title - The title of the header
 * @param {string} children - The description of the header
 * @returns {JSX.Element} Final component
 */
export const Header = ({ title, children, design = HEADER_DESIGN.DEFAULT }) => {
    const [urlParams] = useSearchParams();
    const history = useHistory();

    if (!title && !children) {
        return null;
    }

    const defaultColors = [HEADER_DESIGN.DEFAULT, HEADER_DESIGN.COMPACT].includes(design);
    const lightColors = design === HEADER_DESIGN.LIGHT;

    const back = urlParams.get("menu") === "false" ? () => history("?") : null;

    const designClass = classNames({
        "shadow-inner": true,
        "p-10": design && design !== HEADER_DESIGN.COMPACT,
        "px-10 py-2": design === HEADER_DESIGN.COMPACT,
        "text-white bg-blue-300": defaultColors,
        "text-black bg-gray-100": lightColors,
    });

    return (
        <div className={designClass}>
            <div className="flex items-center space-x-2">
                {back ? (
                    <Button id="header-back" onClick={back}>
                        <Icon type="alignJustify" size="3xl" />
                    </Button>
                ) : null}
                {title ? <h2 className="text-2xl font-bold">{title}</h2> : null}
            </div>
            {children ? <p>{children}</p> : null}
        </div>
    );
};

export const UXWarning = () => {
    return (
        <Warning
            id="warning-test-B"
            title="This section is pending review for UX / Front alignment"
            icon="info"
            design="black"
            readMore={() =>
                window.open(
                    "https://www.figma.com/design/9PAAAlbTtVNL7UI90FhOhe/Developers---Design-System-Manager?node-id=7895-48755&t=Okf7rCxZXEyOQ6ks-1",
                    "_blank",
                    "noopener"
                )
            }
        >
            It is recommended to review the presentation of the elements in this section.
        </Warning>
    );
};

export const getRandomIcon = () => {
    const icons = ["user", "delete", "category", "media", "alarm", "product", "zafiro-solid", "rooms"];
    return icons[Math.floor(Math.random() * icons.length)];
};

export const Examples = ({ options, display: DisplayComponent }) => {
    return (
        <>
            {options
                ? options.map((type) => {
                      const examples = type.examples || ["DEFAULT"];
                      const variants = type.variants || ["DEFAULT"];

                      const multipleExamples = examples?.length > 1;
                      const multipleVariants = variants?.length > 1;

                      return (
                          <div key={type.id} className="bg-white shadow-inner text-gray-900 p-10">
                              <h3 className="text-xl font-bold uppercase mb-5">{type.title}</h3>
                              <Table
                                  id="table-buttons-regular"
                                  cols={[...[multipleExamples ? ["variant"] : null], ...variants]}
                                  header={
                                      multipleExamples || multipleVariants
                                          ? {
                                                ...(multipleExamples ? { variant: { width: "6rem" } } : {}),
                                                ...(multipleVariants
                                                    ? variants.reduce((acc, type) => {
                                                          acc[type] = {
                                                              title: <Small>{type?.replaceAll("_", " ")}</Small>,
                                                          };
                                                          return acc;
                                                      }, {})
                                                    : {}),
                                            }
                                          : null
                                  }
                                  rows={examples.map((example) => ({
                                      variant: <Small>{example}</Small>,
                                      ...variants.reduce((acc, variant) => {
                                          acc[variant] = <DisplayComponent {...{ type, variant, example }} />;
                                          return acc;
                                      }, {}),
                                  }))}
                              />
                          </div>
                      );
                  })
                : null}
        </>
    );
};

const Small = ({ children }) => (
    <span className="text-gray-700 text-sm font-normal whitespace-no-wrap">{children}</span>
);

export default Playground;
