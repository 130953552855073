import React from "react";
import Button from "components/Button";
import Icon from "components/Icon";
import classNames from "classnames";
import { useMap, TOOLBAR_MAP_OPTIONS } from "./MapContext";

const Toolbar = () => {
    const { toolbarMapOptionSelected, setToolbarMapOptionSelected } = useMap();

    const handleOptionClick = (option) => {
        setToolbarMapOptionSelected(option);
    };

    const toolbarItems = [
        {
            id: "toolbar-interest-point",
            option: TOOLBAR_MAP_OPTIONS.INTEREST_POINTS,
            icon: "location",
        },
        {
            id: "toolbar-categories",
            option: TOOLBAR_MAP_OPTIONS.CATEGORIES,
            icon: "category",
        },
        {
            id: "toolbar-settings-map",
            option: TOOLBAR_MAP_OPTIONS.SETTINGS_IMAGE,
            icon: "settings-editor",
        },
    ];

    return (
        <div className="w-48 h-20 rounded shadow-md px-3 py-1 text-gray-800 flex justify-between bg-white">
            {toolbarItems.map((item) => (
                <Button
                    key={item.id}
                    id={item.id}
                    onClick={() => handleOptionClick(item.option)}
                    className={classNames(
                        "w-full flex items-center justify-center rounded transition-colors duration-300",
                        {
                            "bg-gray-200": toolbarMapOptionSelected === item.option,
                        }
                    )}
                >
                    <Icon type={item.icon} size={1.5} />
                </Button>
            ))}
        </div>
    );
};

export default Toolbar;
