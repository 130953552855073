import { useEffect, useState } from "react";
import { GET_CONTENT_TREE, GET_CUSTOM_TAGS, GET_LANDING, GET_THEME, GET_TICKER_TAPES } from "./queries/tickerTapes";
import {
    ADD_TICKER_TAPE,
    DELETE_TICKER_TAPES,
    DUPLICATE_TICKER_TAPE,
    SET_TICKER_TAPE_AS_AVAILABLE,
    SET_TICKER_TAPE_AS_UNAVAILABLE,
    UPDATE_TICKER_TAPE,
    UPDATE_SCHEDULE_TICKER_TAPE,
    UPDATE_DISPLAY_TICKER_TAPE,
} from "./mutations/tickertapes";
import { useLazyQuery, useMutation } from "react-apollo";

export const useListTickerTapes = () => {
    const [tickerTapes, setTickerTapes] = useState([]);
    const [idDesignApplied, setIdDesignApplied] = useState(null);

    const [listTickerTapes, { loading, error, refetch, data }] = useLazyQuery(GET_TICKER_TAPES, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setTickerTapes(data.tickerTapes.results);
        },
    });

    useEffect(() => {
        if (!data) return;
        setTickerTapes(data.tickerTapes?.results || []);
        const designsAssigned = data.designsLocalAssigned?.results;
        const corporateDesigns = data.designs?.results?.filter((design) => design.isAssignedFromCorporate);
        setIdDesignApplied(designsAssigned?.[0]?.id || corporateDesigns?.[0]?.id || null);
    }, [data]);

    return {
        getTickerTapes: listTickerTapes,
        loading: loading,
        error: error,
        tickerTapes: tickerTapes,
        idDesignApplied: idDesignApplied,
        refetchTickerTapes: refetch,
    };
};

export const useTheme = () => {
    const [theme, setTheme] = useState(null);

    const [getTheme, { loading, error, refetch, data }] = useLazyQuery(GET_THEME, {
        nextFetchPolicy: "cache-first",
        onCompleted: (data) => {
            if (data?.themes?.results?.length > 0) {
                const colors = data.themes.results[0].config.colors;
                setTheme(colors);
            }
        },
    });

    return {
        getTheme,
        theme,
    };
};

export const useHomePage = (props) => {
    const [id, setId] = useState();
    const [backgroundImage, setBackgroundImage] = useState();
    const [backgroundImageExternal, setBackgroundImageExternal] = useState();
    const [backgroundColor, setBackgroundColor] = useState();
    const [opacity, setOpacity] = useState(100);

    const fetchHomePage = (props) => {
        setId(props.id);
        getContentTree({ variables: { id: props.id } });
    };
    const [getLanding, { loading: loadingLanding }] = useLazyQuery(GET_LANDING, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setBackgroundColor(JSON.parse(data?.content?.results?.[0]?.content?.contentStyle)?.bgColor);
            setOpacity(JSON.parse(data?.content?.results?.[0]?.content?.contentStyle)?.opacity);
            setBackgroundImage(JSON.parse(data?.content?.results?.[0]?.content?.contentStyle)?.images?.[0]?.libraryRef);
            setBackgroundImageExternal(
                JSON.parse(data?.content?.results?.[0]?.content?.contentStyle)?.images?.[0]?.externalUrl
            );
            if (props?.onCompleted && typeof props?.onCompleted == "function") {
                props.onCompleted();
            }
        },
    });

    const [getContentTree, { loading: loadingContentTree }] = useLazyQuery(GET_CONTENT_TREE, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            const landingId = data?.contentTree?.screens?.categories
                ?.find((cat) => cat?.category == "TV")
                ?.types?.find((type) => type?.type == "TV")
                ?.landings?.find(({ isAssigned, isDefault, isEnabled }) => {
                    return isAssigned && isDefault && isEnabled;
                })?.landingId;
            if (landingId) {
                getLanding({ variables: { id: landingId, designId: id } });
            } else if (props?.onCompleted && typeof props?.onCompleted == "function") {
                props.onCompleted();
            }
        },
    });

    return {
        fetch: fetchHomePage,
        backgroundImage,
        backgroundColor,
        backgroundImageExternal,
        opacity,
        loading: loadingContentTree || loadingLanding,
    };
};

export const useListCustomTags = () => {
    const [customTags, setCustomTags] = useState([]);

    const [getCustomTags, { loading, error }] = useLazyQuery(GET_CUSTOM_TAGS, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setCustomTags(data.designTags.results.map((tag) => ({ key: tag.name, value: tag.tag, text: tag.name })));
        },
    });

    return {
        getCustomTags,
        loading,
        error,
        customTags,
    };
};

export const useAddTickerTape = (props) => {
    const [executeMutation] = useMutation(ADD_TICKER_TAPE, {
        onCompleted({ createTickerTape }) {
            props.onCompleted({ id: createTickerTape?.id });
        },
    });

    return {
        addTickerTape: executeMutation,
    };
};

export const useDeleteTickerTapes = (props) => {
    const [mutation] = useMutation(DELETE_TICKER_TAPES, {
        onCompleted: () => {
            props.onCompleted();
        },
        onError: () => {
            props.onError();
        },
    });

    return {
        mutation,
    };
};

export const useUpdateTickerTapes = (props) => {
    const [executeMutation, { loading }] = useMutation(UPDATE_TICKER_TAPE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        update: executeMutation,
        loading,
    };
};

export const useSetAvailabilityTickerTape = (props) => {
    const [setAsUnavailable] = useMutation(SET_TICKER_TAPE_AS_UNAVAILABLE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    const [setAsAvailable] = useMutation(SET_TICKER_TAPE_AS_AVAILABLE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        setAsAvailable,
        setAsUnavailable,
    };
};

export const useDuplicateTickerTape = (props) => {
    const [executeMutation, { loading }] = useMutation(DUPLICATE_TICKER_TAPE, {
        onCompleted() {
            props.onCompleted();
        },
    });
    return {
        duplicate: executeMutation,
        loading,
    };
};

export const useUpdateScheduleTickerTape = (props) => {
    const [executeMutation, { loading }] = useMutation(UPDATE_SCHEDULE_TICKER_TAPE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        updateScheduleTickerTape: executeMutation,
        loadingScheduleTickerTape: loading,
    };
};

export const useUpdateDisplayTickerTape = (props) => {
    const [executeMutation, { loading }] = useMutation(UPDATE_DISPLAY_TICKER_TAPE, {
        onCompleted() {
            if (props?.onCompleted) {
                props.onCompleted();
            }
        },
    });

    return {
        updateDisplayTickerTape: executeMutation,
        loadingScheduleTickerTape: loading,
    };
};
