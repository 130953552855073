import React, { useRef, useCallback, useLayoutEffect, useState } from "react";
import SelectorImageIcon, { ORIENTATION, TYPE_IMAGES } from "components/SelectorImageIcon";
import { useMap } from "../../MapContext";
import { GET_LIBRARY_ASSET_NAME } from "components/Section/Design/Advertising/modals/modalDestination/Destination/Hooks/useDestinationGraphCalls";
import { useLazyQuery } from "react-apollo";
import { useAuth } from "hooks/Session/auth";
import { useSelectLibraryContent } from "components/Section/Design/Advertising/modals/signages/selectLibraryContent";
import { useUpdateMap } from "../../../graphql/useMaps";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Select from "components/Select";
import { useTranslation } from "react-i18next";

const SideBarSettingsImage = () => {
    const selectorRef = useRef(null);
    const { dataMap, setSectionLoading, refetch } = useMap();
    const { image } = dataMap || {};
    const [imageAdjust, setImageAdjust] = useState("cover");
    const { defaultLanguage } = useAuth();
    const { id } = useParams();
    const { t } = useTranslation();

    const { update } = useUpdateMap({
        onCompleted: () => {
            toast.success(t("operation-successful"));
            setSectionLoading(false);
            refetch();
        },
        onError: (error) => {
            toast.error(error.message || "unknown-error");
            setSectionLoading(false);
            refetch();
        },
    });

    const [getLibraryAssetName, { data: libraryAssetData }] = useLazyQuery(GET_LIBRARY_ASSET_NAME, {
        fetchPolicy: "network-only",
    });

    const updateSelectorImageData = useCallback(
        (data, validation = true) => {
            if (selectorRef.current) {
                selectorRef.current.updateImageData(data, defaultLanguage, getLibraryAssetName, validation);
            }
        },
        [defaultLanguage, getLibraryAssetName]
    );

    const updateMapImage = useCallback(
        (imageData) => {
            update({
                variables: {
                    image: imageData,
                    id,
                },
            });
            setSectionLoading(true);
        },
        [update, id, setSectionLoading]
    );

    const openSelectLibrary = useSelectLibraryContent({
        afterSaveAction: (selectedRef) => {
            updateSelectorImageData({ type: TYPE_IMAGES.LIBRARY, value: selectedRef });
            updateMapImage({
                libraryRef: selectedRef,
                externalUrl: "",
                adjust: imageAdjust || "",
            });
        },
    });

    useLayoutEffect(() => {
        if (!image || !selectorRef.current) return;

        if (!image.libraryRef && !image.externalUrl) {
            selectorRef.current.resetData();
        }

        if (image.adjust) {
            setImageAdjust(image.adjust);
        }

        if (image.libraryRef) {
            updateSelectorImageData({ type: TYPE_IMAGES.LIBRARY, value: "" }, true);
            updateSelectorImageData({ type: TYPE_IMAGES.LIBRARY, value: image.libraryRef });
        }
        if (image.externalUrl) {
            updateSelectorImageData({ type: TYPE_IMAGES.LINK, value: image.externalUrl });
        }
    }, [image, updateSelectorImageData]);

    return (
        <div className="w-full h-auto mt-1 px-6 pb-6">
            <SelectorImageIcon
                allowedImageTypes={[TYPE_IMAGES.LIBRARY, TYPE_IMAGES.LINK]}
                orientation={ORIENTATION.VERTICAL}
                ref={selectorRef}
                onDelete={() => {
                    updateMapImage({
                        libraryRef: "",
                        externalUrl: "",
                        adjust: imageAdjust || "",
                    });
                    setSectionLoading(true);
                }}
                id="image-settings-image-selector"
                imageContainerHeightWidth="h-36 w-full"
                buttonChooseImageDesign="blue-outline"
                onImageSelect={openSelectLibrary}
                nameAsset={libraryAssetData?.libraryAsset?.response?.name}
                onLinkSelect={(value, valid) => {
                    if (valid) {
                        updateMapImage({
                            libraryRef: "",
                            externalUrl: value,
                            adjust: imageAdjust || "",
                        });
                    } else {
                        updateSelectorImageData({ type: TYPE_IMAGES.LINK, value: "" }, false);
                    }
                }}
            />
            <div className=" mb-4 font-bold">{t("image-options")}</div>
            <Select
                id="image-settings-adjust"
                value={imageAdjust || "cover"}
                allowUnselect={false}
                optionsContainerClass="w-full"
                options={[
                    {
                        value: "cover",
                        label: t("fill container"),
                    },
                    {
                        value: "cover-adjust",
                        label: t("stretch image"),
                    },
                    {
                        value: "contain",
                        label: t("fit into container"),
                    },
                ]}
                onChange={(value) => {
                    updateMapImage({
                        libraryRef: image?.libraryRef || "",
                        externalUrl: image?.externalUrl || "",
                        adjust: value,
                    });
                    setImageAdjust(value);
                    setSectionLoading(true);
                }}
            />
        </div>
    );
};

export default SideBarSettingsImage;
