import { gql } from "apollo-boost";

import useGQLQuery from "hooks/useQuery";

export const useSupportLink = ({ onSuccess, onError } = {}) => {
    const query = gql`
        query loginTicketing($lang: String) {
            loginTicketing(lang: $lang)
        }
    `;
    const q = useGQLQuery(query, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response?.loginTicketing);
            }
        },
        onError,
    });
    return {
        ...q,
        data: q.data?.loginTicketing,
    };
};

export default useSupportLink;
