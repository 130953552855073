import React from "react";
import classNames from "classnames";
import SideBar from "./SideBar/SideBar";
import Toolbar from "./Toolbar";
import MapEditor from "./MapEditor";

const MapEditorContainer = () => {
    const editorContainer = classNames(" w-full mt-12 px-28 flex  ");
    const sideBarContainer = classNames("w-96 h-full flex flex-col items-end gap-3 ");
    return (
        <div style={{ height: "87%" }} className={editorContainer}>
            <div className={sideBarContainer}>
                <Toolbar />
                <SideBar />
            </div>
            <div className=" w-full">
                <MapEditor />
            </div>
        </div>
    );
};

export default MapEditorContainer;
