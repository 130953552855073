import { newRoute, newRedirect } from "routes/utils";

import Analytics from "components/Section/Statistics/Analytics";
import StatsSidebar from "components/Sidebar/StatsSidebar";

const sectionID = "stats";

const route = newRoute(sectionID);
const redirect = newRedirect(sectionID);

const checkStats = ({ permissions }) => permissions?.stats;
const checkStatsSales = ({ permissions }) => permissions?.stats?.sales;
const checkStatsCast = ({ permissions }) => permissions?.stats?.cast;
const checkStatsWifi = ({ permissions }) => permissions?.stats?.wifi;
const checkStatsMobile = ({ permissions }) => permissions?.stats?.mobile;

const routes = [
    route("/", { initial: true, redirect: redirect("/cast"), check: checkStats, sidebar: StatsSidebar }),
    route("/:type/:id", { section: Analytics, sidebar: StatsSidebar, check: checkStats }),
    route("/:type/:id/:sub", { section: Analytics, sidebar: StatsSidebar, check: checkStats }),

    // Cast statistics
    route("/cast", { section: Analytics, sidebar: StatsSidebar, check: checkStatsCast }),

    // WiFi statistics
    route("/wifi", { section: Analytics, sidebar: StatsSidebar, check: checkStatsWifi }),

    // Mobile statistics
    route("/mobile", { section: Analytics, sidebar: StatsSidebar, check: checkStatsMobile }),

    // Sales statistics
    route("/sales", { section: Analytics, sidebar: StatsSidebar, check: checkStatsSales }),
];

export default routes;
