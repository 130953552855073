import { useContext } from "react";
import { useMutation } from "react-apollo";
import {
    CREATE_ACCESS_TYPE,
    DELETE_ACCESS_PROFILE,
    EDIT_ACCESS_TYPE,
    UPDATE_ACCESS_PROFILES,
} from "../utils/accessProfileQueries";
import { AccessTypesContext } from "contexts/AccessTypes";

export const useCreateAccessType = () => {
    const { setLoadingRefresh } = useContext(AccessTypesContext);

    const [createAccessType] = useMutation(CREATE_ACCESS_TYPE);

    const createAccessTypeMutation = (name, downloadBWMin, uploadBWMax, uploadBWMin, downloadBWMax) => {
        createAccessType({
            variables: {
                name,
                downloadBWMin,
                uploadBWMax,
                uploadBWMin,
                downloadBWMax,
            },
        }).then((response) => {
            if (response.data.createWifiAccessProfile.ok) {
                setLoadingRefresh(true);
            }
            return response;
        });
    };

    return { createAccessTypeMutation };
};

export const useEditAccessType = () => {
    const { setLoadingRefresh } = useContext(AccessTypesContext);

    const [editAccessType] = useMutation(EDIT_ACCESS_TYPE);

    const editAccessTypeMutation = (id, name, downloadBWMin, uploadBWMax, uploadBWMin, downloadBWMax) => {
        editAccessType({
            variables: {
                id,
                name,
                downloadBWMin,
                uploadBWMax,
                uploadBWMin,
                downloadBWMax,
            },
        }).then((response) => {
            if (response.data.updateWifiAccessProfile.ok) {
                setLoadingRefresh(true);
            }
            return response;
        });
    };

    return { editAccessTypeMutation };
};

export const useUpdateAccessProfiles = () => {
    const { setLoadingRefresh } = useContext(AccessTypesContext);
    const [updateWifiAccessProfile] = useMutation(UPDATE_ACCESS_PROFILES);

    const updateWifiAccess = (id, oldIDs, newIDs) => {
        updateWifiAccessProfile({
            variables: {
                id,
                oldIDs,
                newIDs,
            },
        }).then((response) => {
            if (response.data.updateWifiAccessProfile.ok) {
                setLoadingRefresh(true);
            }
        });
    };

    return { updateWifiAccess };
};

export const useDeleteAccessProfile = () => {
    const { setLoadingRefresh } = useContext(AccessTypesContext);

    const [deleteAccessProfileMutation] = useMutation(DELETE_ACCESS_PROFILE);

    const deleteAccessProfile = (id) => {
        deleteAccessProfileMutation({
            variables: {
                id,
            },
        }).then((response) => {
            if (response.data.deleteWifiAccessProfile.ok) {
                setLoadingRefresh(true);
            }
            return response;
        });
    };

    return { deleteAccessProfile };
};
