import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent, setExecuteQuery } from "../../../actions/sectionActions";
import { gql } from "apollo-boost";
import { escapeSpecialChars, dateString } from "../../Utils/Utils";
import { showGlobalLoading } from "../../../actions/uiActions";
import { useMutation } from "@apollo/react-hooks";
import { toast } from "react-toastify";
import { SENT_MSG, BIN_MSG } from "../../Utils/Customers/MessagesUtils";
import { useAuth } from "hooks/Session/auth";

const AutoSaveMessage = (initialAutoSaveState = true) => {
    const dispatch = useDispatch();
    const { languages: projectLangs } = useAuth();
    const { autoSaveDraftMessage } = useSelector((state) => state.sectionContent);
    const messageData = useSelector((state) => state.sectionContent.data);
    const { values } = useSelector((state) => state.action);
    const [autoSave, setAutoSave] = useState(initialAutoSaveState);

    const [mutation, setMutation] = useState("mutation{checkIn}");
    const MUTATION_OBJECT = gql`
        ${mutation}
    `;
    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            //this control is in this onError, because errorPolicy =all is not working like in queryes
            //Custom errors
            // let validations = {};
            let errorMsg = err.message;

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });

    useEffect(() => {
        dispatch(
            setSectionContent({
                autoSaveDraftMessage: {
                    ...autoSaveDraftMessage,
                    ...values,
                },
            })
        );
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (messageData && messageData.message && messageData.message.idMessage) {
            dispatch(
                setSectionContent({
                    autoSaveDraftMessage: {
                        ...autoSaveDraftMessage,
                        values,
                        idMessage: messageData.message.idMessage,
                    },
                })
            );
        }
        if (
            messageData &&
            messageData?.message?.idMessage &&
            (messageData.message.state === SENT_MSG.value || messageData.message.state === BIN_MSG.value)
        ) {
            setAutoSave(false);
        }
        // eslint-disable-next-line
    }, [messageData]);

    useEffect(() => {
        if (autoSave && autoSaveDraftMessage && notEqualMessageValues(values, autoSaveDraftMessage.values)) {
            dispatch(
                setSectionContent({
                    autoSaveDraftMessage: {
                        ...autoSaveDraftMessage,
                        ...values,
                        idMessage: autoSaveDraftMessage.idMessage
                            ? autoSaveDraftMessage.idMessage
                            : messageData?.message?.idMessage
                            ? messageData.message.idMessage
                            : null,
                    },
                })
            );

            setMutation(
                createDinamycMutationChain(
                    {
                        ...values,
                        idMessage: autoSaveDraftMessage.idMessage
                            ? autoSaveDraftMessage.idMessage
                            : messageData?.message?.idMessage
                            ? messageData.message.idMessage
                            : null,
                    },
                    projectLangs
                )
            );

            if (!autoSaveDraftMessage?.idMessage) {
                setAutoSave(false);
            }
            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        if (initialAutoSaveState && autoSaveDraftMessage?.idMessage) {
            setAutoSave(true);
        }
    }, [autoSaveDraftMessage]);

    useEffect(() => {
        if (data && data.createMessage && data.createMessage && data.createMessage.ok && data.createMessage.id) {
            dispatch(
                setSectionContent({
                    autoSaveDraftMessage: { ...autoSaveDraftMessage, autoSaveTime: new window.ZDate() },
                    data: {
                        message: { idMessage: data.createMessage.id },
                    },
                })
            );
        } else if (data && data.updateMessage && data.updateMessage.ok) {
            dispatch(
                setSectionContent({
                    autoSaveDraftMessage: { ...autoSaveDraftMessage, autoSaveTime: new window.ZDate() },
                })
            );
        }

        // eslint-disable-next-line
    }, [data]);
};

const createDinamycMutationChain = (dataValues, projectLangs) => {
    let titles = [];
    let contents = [];
    // eslint-disable-next-line
    projectLangs.map((lang) => {
        if (dataValues) {
            titles.push(
                `{lang:"${lang.languageRef}" text:${
                    dataValues[`subject-${lang.languageRef}`]
                        ? `"""${escapeSpecialChars(dataValues[`subject-${lang.languageRef}`])}"""`
                        : `""`
                }}`
            );
        }

        if (dataValues) {
            contents.push(
                `{lang:"${lang.languageRef}" text:${
                    dataValues[`content-${lang.languageRef}`]
                        ? `"""${escapeSpecialChars(dataValues[`content-${lang.languageRef}`])}"""`
                        : `""`
                }}`
            );
        }
    });

    const MESSAGE_AUTO_CREATE = `
        mutation{
            createMessage(
                title: [${titles.join(",")}]
                body: [${contents.join(",")}]

                ${dataValues["type"] ? `priority: "${dataValues["type"][0].toUpperCase()}"` : ``}
                ${
                    dataValues["expiration-date"] && dataValues["expiration-hour"]
                        ? `expirationTime: "${dateString(
                              `${dataValues["expiration-date"]} ${
                                  dataValues["expiration-hour"] ? dataValues["expiration-hour"] : "00:00:00"
                              }`
                          )}"`
                        : ""
                }
            ){ error id ok }
        }
    `;
    const MESSAGE_AUTO_UPDATE = `
        mutation{
            updateMessage(
                id: ${dataValues.idMessage}
                title: [${titles.join(",")}]
                body: [${contents.join(",")}]
                
                ${dataValues["type"] ? `priority: "${dataValues["type"][0].toUpperCase()}"` : ``}
                ${
                    dataValues["expiration-date"]
                        ? `expirationTime: "${dateString(
                              `${dataValues["expiration-date"]} ${
                                  dataValues["expiration-hour"] ? dataValues["expiration-hour"] : "00:00:00"
                              }`
                          )}"`
                        : `expirationTime:"<null>"`
                }
            ){ error id ok }
        }
    `;
    return dataValues.idMessage ? MESSAGE_AUTO_UPDATE : MESSAGE_AUTO_CREATE;
};
const notEqualMessageValues = (newData, storeData) => {
    // return true => when difference in message values
    if (
        (newData?.type && storeData?.type && newData?.type[0] !== storeData?.type[0]) ||
        (newData?.type && !storeData?.type)
    ) {
        return true;
    }
    if (notEqualMessagesTexts(newData, storeData, "content-")) {
        return true;
    }
    if (notEqualMessagesTexts(newData, storeData, "subject-")) {
        return true;
    }
    if (notEqualMessagesTexts(newData, storeData, "filter_0")) {
        return true;
    }
    if (notEqualMessagesTexts(newData, storeData, "expiration-hour")) {
        return true;
    }
    return false;
};

const notEqualMessagesTexts = (newData, storeData, field) => {
    let notEqual = false;
    const newDataKeys = Object.keys(newData);
    newDataKeys.forEach((key) => {
        if (
            (key.includes(field) && newData?.[key] && storeData?.[key] && newData?.[key] !== storeData?.[key]) ||
            (key.includes(field) &&
                newData?.[key] &&
                !storeData?.[key] &&
                (newData?.[key] !== null || newData?.[key] !== undefined || newData?.[key].length > 0)) ||
            (key.includes(field) && storeData?.[key] && (!newData?.[key] || newData?.[key] === ""))
        ) {
            notEqual = true;
        }
    });
    return notEqual;
};

export default AutoSaveMessage;
