import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "react-apollo";
import { TYPE_IMAGES } from "components/SelectorImageIcon";
import SelectorImageIcon from "components/SelectorImageIcon";
import { GET_LIBRARY_ASSET_NAME } from "../../Advertising/modals/modalDestination/Destination/Hooks/useDestinationGraphCalls";
import useModalSelectLibraryContent from "../../Advertising/modals/signages/useModalSelectLibraryContent";
import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";

export const PinCard = ({ data, update }) => {
    const { t } = useTranslation();
    const selectorImageIconRef = useRef(null);
    const { defaultLanguage } = useAuth();
    const { pointinterestid, id } = useParams();
    const { open: openLibrary, close: closeLibrary } = useModalSelectLibraryContent();
    const [getLibraryAssetName, { data: dataLibraryAssetName }] = useLazyQuery(GET_LIBRARY_ASSET_NAME, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (data?.pinIcon) {
            if (data?.pinIcon?.libraryRef) {
                if (
                    selectorImageIconRef?.current?.getActiveType() != TYPE_IMAGES.LIBRARY ||
                    (selectorImageIconRef?.current?.getActiveType() == TYPE_IMAGES.LIBRARY &&
                        selectorImageIconRef?.current?.getActiveSelectionValue()?.value != data?.pinIcon?.libraryRef)
                ) {
                    handleUpdateImageData(
                        { type: TYPE_IMAGES.LIBRARY, value: data?.pinIcon?.libraryRef },
                        selectorImageIconRef,
                        defaultLanguage
                    );
                }
            } else if (data?.pinIcon?.icon) {
                if (
                    selectorImageIconRef?.current?.getActiveType() != TYPE_IMAGES.ICON ||
                    (selectorImageIconRef?.current?.getActiveType() == TYPE_IMAGES.ICON &&
                        selectorImageIconRef?.current?.getActiveSelectionValue()?.value?.icon != data?.pinIcon?.icon)
                ) {
                    handleUpdateImageData(
                        { type: TYPE_IMAGES.ICON, value: { icon: data?.pinIcon?.icon, lib: data?.pinIcon?.lib } },
                        selectorImageIconRef,
                        defaultLanguage
                    );
                }
            }
        }
    }, [data]);

    const handleUpdateImageData = (dataImage, ref, lang) => {
        if (!dataImage?.type || !dataImage?.value || !ref?.current) return;
        const { type, value } = dataImage;
        const updateImageData = (src) => {
            ref.current.setActiveType(type);
            ref.current.updateData({ type, value, validation: true, src });
        };

        switch (type) {
            case TYPE_IMAGES.LIBRARY:
                updateImageData(Session.getDasUrl(`${value}?lang=${lang}`));
                getLibraryAssetName({ variables: { ref: value } });
                break;
            case TYPE_IMAGES.ICON:
                updateImageData(value);
                break;
            default:
                console.warn(`Type image not supported: ${type}`);
        }
    };

    return (
        <div className="bg-white rounded-md p-4">
            <p className="font-bold text-2xl">{t("pin-icon")}*</p>
            <p className=" mt-2 mb-4">{t("choose-the-pin-icon-to-display-on-the-map")}</p>
            <SelectorImageIcon
                ref={selectorImageIconRef}
                allowedImageTypes={[TYPE_IMAGES.LIBRARY, TYPE_IMAGES.ICON]}
                id="edit-map-selector-image-icon"
                nameAsset={dataLibraryAssetName?.libraryAsset?.response?.name}
                onTypeSelect={() => {
                    const type = selectorImageIconRef?.current?.getActiveType();
                    const selected = selectorImageIconRef?.current?.getData()?.[type];
                    if (
                        ((type == TYPE_IMAGES.ICON && selected?.value?.icon != data?.pinIcon?.icon) ||
                            (type == TYPE_IMAGES.LIBRARY &&
                                selected?.value?.libraryRef != data?.pinIcon?.librariRef)) &&
                        selected?.value &&
                        selected?.validation
                    ) {
                        update({
                            variables: {
                                pinIcon: {
                                    libraryRef: type == TYPE_IMAGES.LIBRARY ? selected?.value : "",
                                    icon: type == TYPE_IMAGES.ICON ? selected?.value : "",
                                },
                                mapId: id,
                                id: pointinterestid,
                            },
                        });
                    }
                }}
                onImageSelect={() => {
                    openLibrary({
                        title: t("choose-an-image"),
                        subtitle: t("select-an-image-of-the-media-library"),
                        isMultiple: false,
                        params: {
                            filters: "asset",
                            hideFilter: true,
                            isMultiple: false,
                            onSaveAction: (selectedRefs) => {
                                if (!selectedRefs?.[0]?.ref) return;
                                handleUpdateImageData(
                                    { type: TYPE_IMAGES.LIBRARY, value: selectedRefs[0].ref },
                                    selectorImageIconRef,
                                    defaultLanguage
                                );
                                update({
                                    variables: {
                                        pinIcon: {
                                            libraryRef: selectedRefs[0].ref,
                                            icon: "",
                                        },
                                        mapId: id,
                                        id: pointinterestid,
                                    },
                                });
                                closeLibrary();
                            },
                        },
                    });
                }}
                onIconSelect={(value) => {
                    handleUpdateImageData({ type: TYPE_IMAGES.ICON, value }, selectorImageIconRef, defaultLanguage);
                    update({
                        variables: {
                            pinIcon: {
                                ...value,
                                libraryRef: "",
                            },
                            mapId: id,
                            id: pointinterestid,
                        },
                    });
                }}
            />
        </div>
    );
};
