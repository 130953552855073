import React, { useState, useEffect } from "react";
import { Radiobutton } from "./Inputs/Radiobuttons";
import { useTranslation } from "react-i18next";

/**
 * ScrollSpeedSelector Component
 *
 * Allows selecting the speed at which the text scrolls (SLOW, MEDIUM, FAST).
 *
 * @param {Object} props - Component properties
 * @param {string} [props.value="SLOW"] - Initial value (can be "SLOW", "MEDIUM", or "FAST").
 * @param {function} [props.onChange] - Callback triggered when the speed changes.
 * @param {Object} [props.duration] - Object with custom duration values for each speed.
 * @param {string} [props.duration.slow="10s"] - Duration for slow speed.
 * @param {string} [props.duration.medium="5s"] - Duration for medium speed.
 * @param {string} [props.duration.fast="2s"] - Duration for fast speed.
 */
const ScrollSpeedSelector = ({ value = "SLOW", onChange, duration = {} }) => {
    const { t } = useTranslation();

    const speeds = {
        SLOW: { label: "slow", duration: duration.slow || "10s" },
        MEDIUM: { label: "medium", duration: duration.medium || "5s" },
        FAST: { label: "fast", duration: duration.fast || "2s" },
    };

    const [speedSelected, setSpeedSelected] = useState(value);
    const [key, setKey] = useState(0);

    useEffect(() => {
        setSpeedSelected(value);
    }, [value]);

    const handleSpeedChange = (newValue) => {
        setSpeedSelected(newValue);
        setKey((prevKey) => prevKey + 1);

        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <div className="w-full flex flex-col">
            <div className="w-full bg-gray-200 overflow-hidden whitespace-nowrap">
                <div
                    key={key}
                    className="animate-scroll-speed-selector w-full"
                    style={{
                        animationDuration: speeds[speedSelected]?.duration,
                        animationDelay: "0s",
                    }}
                >
                    Lorem ipsum dolor sit amet consectetur. Habitant sit id risus eget.
                </div>
            </div>
            <div className="flex flex-col items-start mt-3 space-y-2">
                <Radiobutton
                    id="scroll-speed-slow"
                    label={t(speeds.SLOW.label)}
                    value="SLOW"
                    checked={speedSelected === "SLOW"}
                    onChange={({ checked, value }) => {
                        if (checked) handleSpeedChange(value);
                    }}
                />

                <Radiobutton
                    id="scroll-speed-medium"
                    label={t(speeds.MEDIUM.label)}
                    value="MEDIUM"
                    checked={speedSelected === "MEDIUM"}
                    onChange={({ checked, value }) => {
                        if (checked) handleSpeedChange(value);
                    }}
                />

                <Radiobutton
                    id="scroll-speed-fast"
                    label={t(speeds.FAST.label)}
                    value="FAST"
                    checked={speedSelected === "FAST"}
                    onChange={({ checked, value }) => {
                        if (checked) handleSpeedChange(value);
                    }}
                />
            </div>
        </div>
    );
};

export default ScrollSpeedSelector;
