import React, { useContext, useEffect, useState } from "react";
import Header from "../../useSectionHeader";
import { PropertySettingsCard } from "./Settings/PropertySettingsCard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation } from "react-apollo";
import useGQLQuery from "hooks/useQuery";
import Loading from "components/Loading";
import {
    GENERATE_CODE,
    GET_TV_SETTINGS,
    SET_TV_PERMANENT,
    SET_TV_STATUS,
} from "hooks/GraphqlCalls/Hotel/utils/tvSettingsQueries";
import { GlobalContext } from "contexts/Global";

export const TVSettings = () => {
    const [status, setStatus] = useState(false);
    const [permanent, setPermanent] = useState(false);
    const [installationCode, setInstallationCode] = useState({});
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);

    const tvSettings = useGQLQuery(GET_TV_SETTINGS, {
        onSuccess: (response) => {
            setPermanent(response?.tvSettings?.permanentSocket);
            setStatus(response?.tvSettings?.statusReport);
            setInstallationCode(response?.getInstallationCodeExpiration);
        },
    });

    const [generateCode] = useMutation(GENERATE_CODE);
    const [executeMutationStatus] = useMutation(SET_TV_STATUS);
    const [executeMutationPermanent] = useMutation(SET_TV_PERMANENT);

    const executeMutation = (mutation, variables) =>
        mutation({ variables })
            .then((res) => {
                if (res?.data?.setTVSettings?.ok) {
                    toast.success(t("operation-successful"));
                }
            })
            .catch((err) => {
                toast.error(err);
            });

    useEffect(() => tvSettings?.load(), []);

    const { formattedDate, formattedTime } = formatDateAndTime(installationCode?.expiration, lang);

    const loading = !tvSettings?.ready;

    if (loading) {
        return <Loading adjust={"section-loading"} />;
    }

    return (
        <>
            <Header title="TV" />
            <div className="grid grid-cols-2 gap-4">
                <PropertySettingsCard
                    id="installation-code"
                    title={t("installation-code")}
                    icon="tv-settings"
                    description={t("installation-code-text")}
                    button={installationCode?.code ? t("regenerate-code") : t("generate-code")}
                    onClick={() => generateCode().then(() => tvSettings?.refetch())}
                >
                    {installationCode?.code && (
                        <>
                            <p id="installationCode_code" className="font-black text-gray-800 text-5xl mt-4">
                                {installationCode?.code}
                            </p>
                            <p className="font-normal leading-6 text-orange-100 text-base mt-3">
                                {t("code-expires", { date: formattedDate, hour: formattedTime })}
                            </p>
                            <p className="font-normal leading-6 text-gray-600 text-base mt-2">
                                {t("code-expires-extended")}
                            </p>
                        </>
                    )}
                </PropertySettingsCard>

                <PropertySettingsCard
                    id="tv-report-status"
                    title={t("tv-report-status")}
                    icon="messaging-widget"
                    checkbox={{
                        label: t("send-report-tv"),
                        checked: status,
                        onchange: () => setStatus(!status),
                    }}
                    description={t("tv-report-status-description")}
                    button={t("save")}
                    onClick={() => executeMutation(executeMutationStatus, { statusReportVal: !status })}
                />

                <PropertySettingsCard
                    id="permanent-socket"
                    title={t("permanent-socket")}
                    icon="tv"
                    checkbox={{
                        label: t("permanent-socket-tooltip"),
                        checked: permanent,
                    }}
                    button={t("save")}
                    onClick={() => executeMutation(executeMutationPermanent, { permanentSocketVal: !permanent })}
                />

                <PropertySettingsCard
                    id="tv-input-groups"
                    title={t("tv-input-groups")}
                    icon="login"
                    description={t("manage-tv-inputs")}
                    button={t("manage-inputs-groups")}
                    to={"/hotel/property-settings/tv/inputs"}
                />
            </div>
        </>
    );
};

const formatDateAndTime = (dateTime, lang) => {
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString(lang === "es" ? "es-ES" : "en-Gb", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
    });
    const formattedTime = date.toLocaleTimeString(lang === "es" ? "es-ES" : "en-Gb", {
        hour: "2-digit",
        minute: "2-digit",
    });
    return { formattedDate, formattedTime };
};
