import { gql } from "apollo-boost";

export const UPDATE_MAP = gql`
    mutation updateMap($id: Int64!, $name: [TranslateString], $image: JSON) {
        updateMap(id: $id, nameInput: $name, image: $image) {
            error
            id
            ok
        }
    }
`;

export const CREATE_MAP = gql`
    mutation createMap($name: [TranslateString]!, $image: JSON!) {
        createMap(nameInput: $name, image: $image) {
            error
            id
            ok
        }
    }
`;

export const DELETE_MAP = gql`
    mutation deleteMap($id: Int64!) {
        deleteMap(id: $id) {
            error
            ok
        }
    }
`;

export const DUPLICATE_MAP = gql`
    mutation duplicateMap($id: Int64!) {
        duplicateMap(id: $id) {
            error
            ok
        }
    }
`;

export const CREATE_MAP_INTEREST_POINT = gql`
    mutation CreateMapInterestPoint($mapID: Int64!, $nameInput: [TranslateString]!, $pinIcon: JSON!, $pins: [JSON]) {
        createMapInterestPoint(mapID: $mapID, nameInput: $nameInput, pinIcon: $pinIcon, pins: $pins) {
            error
            id
            ok
        }
    }
`;

export const SET_INTEREST_POINT_ORDER_IN_MAP = gql`
    mutation SetInterestPointOrderInMap($mapID: Int64!, $id: Int64!, $pos: Int!) {
        setInterestPointOrderInMap(mapID: $mapID, id: $id, pos: $pos) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_PINS_INTEREST_POINT = gql`
    mutation UpdateMapInterestPoint($pins: [JSON], $mapID: Int64!, $id: Int64!) {
        updateMapInterestPoint(pins: $pins, mapID: $mapID, id: $id) {
            error
            id
            ok
        }
    }
`;

export const DELETE_MAP_INTEREST_POINT = gql`
    mutation deleteMapInterestPoint($id: Int64!, $mapID: Int64!) {
        deleteMapInterestPoint(id: $id, mapID: $mapID) {
            ok
            error
        }
    }
`;
