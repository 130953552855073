import React from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";

export const AreYouSure = ({ updateNetwork }) => {
    const { close } = useModal();
    const { t } = useTranslation();

    const handleClick = () => {
        updateNetwork();
        close();
    };

    return (
        <Modal
            id="validate-network-modal"
            title={t("update")}
            className="w-1/3"
            footer={
                <>
                    <Button id="cancel-validate-network-button" className="font-bold" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="update-validate-network-button"
                        className="btn-blue p-4 rounded first-capital"
                        onClick={handleClick}
                    >
                        {t("update")}
                    </Button>
                </>
            }
        >
            <p className="mt-3">{t("update-cast-and-user-data")}</p>
            <span>{t("to-validate-networks")}</span>
            <p className="text-gray-800 mt-12 mb-8">*{t("process-will-take-few-minutes")}</p>
        </Modal>
    );
};
