import { gql } from "apollo-boost";

export const UPDATE_POINT_OF_INTEREST = gql`
    mutation updatePointOfInterest(
        $mapId: Int64!
        $id: Int64!
        $image: JSON
        $pinIcon: JSON
        $pins: [JSON]
        $name: [TranslateString]
        $description: [TranslateString]
        $navigation: JSON
    ) {
        updateMapInterestPoint(
            id: $id
            mapID: $mapId
            image: $image
            pinIcon: $pinIcon
            pins: $pins
            nameInput: $name
            descriptionInput: $description
            navigationAction: $navigation
        ) {
            error
            id
            ok
        }
    }
`;
