import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import RGL, { WidthProvider } from "react-grid-layout-juanedc";
import MapPoint from "./MapPoint";
import { useMap } from "./MapContext";
import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";

const ReactGridLayout = WidthProvider(RGL);
const GridLayout = forwardRef(
    (
        {
            items,
            showingHighlitedPoints,
            cols,
            rows,
            rowHeight,
            margin,
            handleRemoveItem,
            gap,
            scrollable,
            onLayoutChange,
            style,
            selectedItemId,
            setSelectedItemId,
        },
        ref
    ) => {
        const { defaultLanguage } = useAuth();

        const [key, setKey] = useState(`layout-${Date.now()}`);
        const layoutRef = useRef(null);
        const [dragging, setDragging] = useState(null);
        const [imageSrc, setImageSrc] = useState(null);

        const { dataMap } = useMap();
        const { image } = dataMap || {};

        const layout = items.map((item) => ({
            i: String(item.id),
            w: item.w || 1,
            h: item.h || 1,
            x: item.x || 0,
            y: item.y || 0,
            isDraggable: true,
            isResizable: false,
            name: item.name || "",
            originalId: item.originalId,
            isHighlighted: item.isHighlighted,
            index: item.index,
            icon: item.icon,
            image: item.image,
            totalPinsInInterestPoint: item.totalPinsInInterestPoint,
        }));

        const handleLayoutChange = (newLayout) => {
            const updatedLayout = newLayout.map((newItem) => {
                const originalItem = items.find((item) => String(item.id) === newItem.i);
                return {
                    ...originalItem,
                    ...newItem,
                };
            });

            if (onLayoutChange) onLayoutChange(updatedLayout);
        };

        const getBackgroundStyles = (imageSrc, image) => {
            const sizeMapping = {
                cover: "cover",
                "cover-adjust": "100% 100%",
                contain: "contain",
            };

            let backgroundSize = "auto";
            if (image?.adjust && sizeMapping[image.adjust]) {
                backgroundSize = sizeMapping[image.adjust];
            }

            let backgroundImage = "none";
            if (imageSrc) {
                backgroundImage = `url(${imageSrc})`;
            }

            return {
                backgroundImage,
                backgroundSize,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundColor: "white",
            };
        };

        const config = {
            style: { ...style },
            containerPadding: [margin?.x || 0, margin?.y || 0],
            margin: [gap?.x || 0, gap?.y || 0],
            cols,
            rows,
            rowHeight,
            isBounded: false,
            compactType: null,
            useCSSTransforms: true,
            transformScale: 1,
            preventCollision: true,
            isResizable: false,
            isDraggable: true,
            allowOverlap: false,
            onLayoutChange: handleLayoutChange,
            draggableHandle: ".drag-handle",
            maxRows: rows,
            onDrag: (layout, oldItem, newItem, placeholder, e, element) => {
                const grid = document.querySelector(".react-grid-layout");
                const maxTranslateX = grid.offsetWidth - element.offsetWidth;
                const maxTranslateY = grid.offsetHeight - element.offsetHeight;
                const clamp = (value, min, max) => Math.max(min, Math.min(value, max));

                const style = window.getComputedStyle(element);
                const transform = style.transform;
                let translateX = 0,
                    translateY = 0;

                if (transform && transform !== "none") {
                    const values = transform.match(/matrix.*\((.+)\)/)[1].split(", ");
                    translateX = parseFloat(values[4]);
                    translateY = parseFloat(values[5]);
                }

                translateX = clamp(translateX, 0, maxTranslateX);
                translateY = clamp(translateY, 0, maxTranslateY);

                element.style.transform = `translate(${translateX}px, ${translateY}px)`;
            },

            onDragStart: (layout, oldItem, newItem, placeholder, e, element) => {
                setDragging({ ...newItem });
                if (e?.dataTransfer) {
                    e.dataTransfer.setData("text/plain", "");
                }
            },
            onDragStop: (layout, oldItem, newItem, placeholder, event, element) => {
                setDragging(null);
            },
        };

        useImperativeHandle(ref, () => ({
            getElement: () => layoutRef?.current?.elementRef?.current,
        }));

        useEffect(() => {
            if (!image) {
                setImageSrc(null);
                return;
            }

            if (image.externalUrl) {
                setImageSrc(image.externalUrl);
                return;
            }

            if (image.libraryRef) {
                setImageSrc(Session.getDasUrl(`${image.libraryRef}?lang=${defaultLanguage}`));
                return;
            }

            setImageSrc(null);
        }, [image, defaultLanguage]);

        return (
            <div
                style={{
                    ...getBackgroundStyles(imageSrc, image),
                }}
                onClick={(e) => {
                    setSelectedItemId(null);
                }}
            >
                <div
                    style={{
                        backgroundColor: showingHighlitedPoints
                            ? "rgb(0,0,0,0.5)"
                            : selectedItemId
                            ? "rgb(255,255,255,0.4)"
                            : "transparent",
                    }}
                >
                    <ReactGridLayout ref={layoutRef} key={key} {...config}>
                        {layout?.map((item) => {
                            const isItemSelected = selectedItemId === item.i;

                            return (
                                <div
                                    key={item.i}
                                    data-grid={item}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedItemId(item.i);
                                    }}
                                    className={`relative ${isItemSelected ? "z-90" : ""}`}
                                >
                                    <MapPoint
                                        showingHighlitedPoints={showingHighlitedPoints}
                                        isSelected={isItemSelected}
                                        onDelete={handleRemoveItem}
                                        item={item}
                                        isHighlighted={item.isHighlighted}
                                    />
                                </div>
                            );
                        })}
                    </ReactGridLayout>
                </div>
            </div>
        );
    }
);

export default GridLayout;
