import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseDashboardCards from "../../DasboardCards/useDashboardCards";
import UseTable from "../../../Table";
//Utils
import RoomDetailDevices from "../../../../hooks/GraphqlCalls/Hotel/Rooms/RoomDetailDevices";
import DetailRoomInfo from "../../../../hooks/GraphqlCalls/Hotel/Rooms/RoomDetailRoomInfo";
import UpdateListDevices from "../../../../hooks/GraphqlCalls/Hotel/UpdateListDevices";

//Actions
import { cleanSectionContent, setRefreshContentData } from "../../../../actions/sectionActions";
import { cleanTableStore } from "../../../../actions/tableActions";
import { cleanDashboardCards } from "../../../../actions/uiActions";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import Loading from "components/Loading";
import Table, { stringValue } from "components/ZafiroTable";
import { useListDeviceCZ } from "hooks/GraphqlCalls/Hotel/Monitoring/useListDeviceCZ";

function CommonZoneDetail() {
    const dispatch = useDispatch();
    //parameters
    const { id } = useParams();
    //states
    const { t } = useTranslation();
    const [roomName, setRoomName] = useState("");
    const [roomNumber, setRoomNumber] = useState(null);
    const { room } = useSelector((state) => state.sectionContent);
    const { permissions } = useSelector((state) => state.ui);
    const guestAccess = permissions?.product?.guest;
    const sectionName = `common-zones`;

    useEffect(() => {
        if (room) {
            setRoomName(room.name);
            setRoomNumber(room.number);
        } else setRoomName("");
    }, [room]);

    //hook calls
    DetailRoomInfo({
        roomId: id,
        guestAccess: guestAccess,
        roomNumber: roomNumber,
        sectionName: sectionName,
        roomType: "COMMONZONE",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(cleanTableStore());
        return () => {
            dispatch(cleanSectionContent());
            dispatch(cleanTableStore());
            dispatch(cleanDashboardCards());
        };
        // eslint-disable-next-line
    }, []);

    const { devices } = useListDeviceCZ(id);

    return (
        <>
            {/*<ListDevices roomId={id} />*/}

            <UseSectionHeader
                subTitle={roomName ? `${capitalizeFirst(t("common-zones"))} ${roomNumber}` : ""}
                noParenthesisSubtitle={true}
                title={[roomName]}
                navToPreviousPage={true}
                breadcrumbsLimit={2}
                adjustSubtitle=" text-base font-normal"
            />
            <div className="inline-grid grid-cols-4 gap-6 w-full">
                <div className={`${guestAccess ? "col-span-4" : "col-span-12"}`}>
                    {/* CARDS */}
                    <UseDashboardCards parentSectionName={sectionName} zoneId={id} roomName={roomName} />

                    {/* DEVICES TABLE */}
                    {
                        <>
                            <div className="main-container">
                                {devices ? (
                                    <Table
                                        id="monitor-devices-table"
                                        cols={["name", "function", "status", "lastActivityDate", "preview"]}
                                        header={{
                                            name: { title: t("name"), width: "25%" },
                                            function: {
                                                title: t("device-function"),
                                                width: "15%",
                                            },
                                            status: { title: t("status"), width: "15%" },
                                            lastActivityDate: { title: t("last-display"), width: "25%" },
                                        }}
                                        rows={devices}
                                        rowTitle={(location) => location?.roomTVName}
                                        //paginate={true}
                                        showCount={true}
                                        customPaginated={true}
                                        //perPage={5}
                                    />
                                ) : (
                                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                                )}
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default CommonZoneDetail;
