import { gql } from "apollo-boost";

export const GET_MAPS = gql`
    query listMaps($id: Int64, $search: String, $orderBy: OrderBy) {
        maps(filter: { id: $id, search: $search }, orderBy: $orderBy, lang: "all") {
            results {
                id
                name
                linkedMapsInfo {
                    id
                    name
                }
                updatedAt
                createdAt
                image
                linkedMapsNum
                createdByInfo {
                    name
                    ref
                }
                updatedByInfo {
                    name
                    ref
                }
            }
        }
    }
`;

export const GET_MAPS_AND_INTEREST_POINTS = gql`
    query GetMapsAndInterestPoints(
        $mapId: Int64!
        $search: String
        $orderBy: OrderBy
        $filter: MapInterestPointsFilter
        $page: Int
        $size: Int
        $lang: LanguageCode
    ) {
        maps(filter: { id: $mapId, search: $search }, orderBy: $orderBy, lang: "all") {
            results {
                id
                image
                name
            }
        }
        mapInterestPoints(filter: $filter, page: $page, size: $size, orderBy: $orderBy, lang: $lang, mapID: $mapId) {
            info {
                count
                page
                size
            }
            results {
                description
                descriptionTID
                id
                image
                mapID
                name
                nameTID
                navigationAction
                pinIcon
                pins
                pos
            }
        }
    }
`;
