import React from "react";
import { useTranslation } from "react-i18next";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";

//Utils
import ListTaxZones from "../../../hooks/GraphqlCalls/Settings/ListTaxZones";
import UpdateTaxZones from "../../../hooks/GraphqlCalls/Settings/UpdateTaxZones";

import { useAuth } from "hooks/Session/auth";

import { WarningReadOnly } from "components/Warning";

const TaxZones = () => {
    const { hasChainModule, isCorporate, isMonoProject } = useAuth();

    ListTaxZones();

    return (
        <>
            <UseSectionHeader title={["tax-zones"]} />
            <UpdateTaxZones />
            <div>
                {!hasChainModule && !isCorporate && !isMonoProject ? (
                    <WarningReadOnly id="info-tax-zones" icon="info" />
                ) : null}
                <UseTable />
            </div>
        </>
    );
};
export default TaxZones;
