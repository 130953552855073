import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import UseSearch from "../../../../useSearch";
import { normalizeToSearch, getFromDAS } from "../../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";
import Parser from "hooks/Utils/Parser";

const SelectPresetChannels = ({ onSelect, idParentContainer, fixedOptions = false, validation, channelsInfo, id }) => {
    const GET_PRESET_CHANNELS = gql`
        query {
            predefinedChannels(filter: { onlyUnusedChannels: true }) {
                results {
                    countryRef
                    id
                    isRadio
                    languageRef
                    logoRef
                    name
                }
            }
        }
    `;

    const { t } = useTranslation();

    const [executeQuery, { data }] = useLazyQuery(GET_PRESET_CHANNELS, {
        fetchPolicy: "network-only",
    });

    const [showOptions, setShowOptions] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [optionSelected, setOptionSelected] = useState(null);
    const [positionFixedToButton, setPositionFixedToButton] = useState({ top: 0, left: 0, width: 0 });
    const [imageError, setImageError] = useState({});

    const RESULTS_INCREMENT = 20;
    const [resultsSize, setResultsSize] = useState(20);
    const scrollableRef = useRef(null);

    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data?.predefinedChannels?.results) {
            const predefinedChannelsInfo = data?.predefinedChannels?.results;
            const excludedCurrentChannelsSelected = predefinedChannelsInfo.filter(
                (c) => !channelsInfo.find((ci) => Number(ci.id) === Number(c.idChannel))
            );
            const updatedChannels = excludedCurrentChannelsSelected.map((c) => {
                const channelCountryRef = c?.countryRef?.toLowerCase()
                    ? t(`country:${c?.countryRef?.toLowerCase()}`)
                    : null;

                return {
                    ...c,
                    name: `<p>${c.name} ${
                        channelCountryRef ? `<span class="text-gray-700" >(${channelCountryRef})</span>` : ""
                    }</p>`,
                };
            });
            setOptions(updatedChannels);
        }
        // eslint-disable-next-line
    }, [data, channelsInfo]);

    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setShowOptions(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        if (idParentContainer && fixedOptions) {
            const containerElement = document.getElementById(idParentContainer);
            const hideOptionsOnScroll = () => {
                setShowOptions(false);
                if (buttonRef.current) {
                    calculatePositionOptions();
                }
            };
            containerElement.addEventListener("scroll", hideOptionsOnScroll);
            return () => {
                containerElement.removeEventListener("scroll", hideOptionsOnScroll);
            };
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (buttonRef.current) {
            calculatePositionOptions();
        }
        // eslint-disable-next-line
    }, [buttonRef.current]);

    useLayoutEffect(() => {
        if (showOptions && fixedOptions) {
            calculatePositionOptions();
        }
        // eslint-disable-next-line
    }, [showOptions]);

    const scroll = () => {
        if (scrollableRef.current) {
            const element = scrollableRef.current;
            const scrollTop = element.scrollTop;
            const scrollHeight = element.scrollHeight;
            const clientHeight = element.clientHeight;

            // Verificar si el scroll está al final
            if (scrollTop + clientHeight >= scrollHeight) {
                const increment = resultsSize + RESULTS_INCREMENT;
                setResultsSize(increment < options.length ? increment : options.length);
            }
        }
    };

    const calculatePositionOptions = () => {
        const rect = buttonRef.current.getBoundingClientRect();
        setPositionFixedToButton({ top: rect.bottom, left: rect.left, width: rect.width });
    };

    return (
        <div className=" relative" ref={dropdownRef}>
            <button
                ref={buttonRef}
                onClick={() => setShowOptions((val) => !val)}
                id={`preset-channels-select-${id}`}
                className={` border ${
                    !validation ? " border-red-100" : " border-transparent"
                } px-3 py-1 bg-gray-200 ${getTextColorSelect(
                    optionSelected,
                    showOptions
                )}  w-full flex justify-between items-center rounded focus:outline-none`}
            >
                {optionSelected ? Parser(optionSelected.name) : t("select-channel")}
                <i className={`icon icon-chevron${showOptions ? "-up" : ""} text-gray-900 text-sm`}></i>
            </button>
            {showOptions ? (
                <div
                    id={`select-preset-channel-options${id ? `-${id}` : ""}`}
                    className={` ${
                        fixedOptions ? "fixed" : "absolute"
                    } flex t-options-container  flex-col left-0 w-full bg-white`}
                    style={{
                        top: fixedOptions ? `${positionFixedToButton.top}px` : null,
                        left: fixedOptions ? `${positionFixedToButton.left}px` : null,
                        width: fixedOptions ? `${positionFixedToButton.width}px` : null,
                        minHeight: "auto",
                        maxHeight: "20rem",
                        overflowY: "scroll",
                    }}
                    ref={scrollableRef}
                    onScroll={() => scroll()}
                >
                    <div className=" py-2 px-4 ">
                        <UseSearch
                            id={`preset-channels-search${id ? `-${id}` : ""}`}
                            onChange={(val) => {
                                if (val?.length > 2) {
                                    setSearchTerm(val);
                                } else {
                                    setSearchTerm("");
                                }
                            }}
                        />
                    </div>
                    {options
                        .filter((option) =>
                            normalizeToSearch(option.name.toLowerCase()).includes(searchTerm.toLowerCase())
                        )
                        .map((opt, _index) => {
                            const key = uuid();
                            if (channelsInfo?.find((ch) => ch.idChannel === opt.id)) {
                                return;
                            }
                            if (_index < resultsSize || searchTerm) {
                                return (
                                    <div
                                        key={key}
                                        onClick={() => {
                                            onSelect(opt);
                                            setOptionSelected(opt);
                                            setShowOptions(false);
                                        }}
                                        id={`preset-channels-opt-${_index}${id ? `-${id}` : ""}`}
                                        className=" w-full cursor-pointer mb-2 flex items-center hover:bg-gray-200 py-2 px-4 "
                                    >
                                        <div
                                            id={`preset-channels-opt-image-container-${_index}${id ? `-${id}` : ""}`}
                                            className=" bg-gray-200 flex items-center justify-center border rounded border-gray-300"
                                            style={{ width: "3.2rem", height: "2.2rem" }}
                                        >
                                            {!opt.logoRef || imageError[opt.id] ? (
                                                <i
                                                    id={`preset-channels-opt-not-image-${_index}${id ? `-${id}` : ""}`}
                                                    className=" icon icon-channel text-gray-400"
                                                ></i>
                                            ) : (
                                                <img
                                                    alt="something"
                                                    id={`preset-channels-image-${_index}${id ? `-${id}` : ""}`}
                                                    className=" h-full w-full object-scale-down"
                                                    onError={() => {
                                                        setImageError((prev) => ({
                                                            ...prev,
                                                            [opt.id]: true,
                                                        }));
                                                    }}
                                                    src={getFromDAS(opt.logoRef)}
                                                />
                                            )}
                                        </div>
                                        <span
                                            id={`preset-channels-opt-name-${_index}${id ? `-${id}` : ""}`}
                                            className=" block w-full ml-4 truncate"
                                        >
                                            {Parser(opt.name)}
                                        </span>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}
                </div>
            ) : null}
        </div>
    );
};

export default SelectPresetChannels;

const getTextColorSelect = (optionSelected, showOptions) => {
    if (optionSelected) {
        return "text-gray-900";
    } else if (showOptions) {
        return "text-zafiro-600 font-bold";
    } else {
        return "text-gray-600";
    }
};
