import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation } from "react-apollo";
import { useModal } from "components/Modal";
import { toast } from "react-toastify";
import { getHotelMap, getRoomGroups, arrangeData, arrangeFilterData } from "../utils/roomListUtils";
import {
    addRoomsToRoomGroups,
    GET_FILTERS_DATA,
    GET_HOTEL_ROOMS_STATIC,
    updateRoomGroupsForRoom,
} from "../utils/roomsListQueries";

export const useListRooms = () => {
    const { open } = useModal();
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState([]);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState("");
    const [group, setGroup] = useState();
    const [sort, setSort] = useState({ field: "room", criteria: "asc" });
    const [search, setSearch] = useState("");
    const [deviceLocation, setDeviceLocation] = useState(null);

    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);
    const guestAccess = permissions?.product?.guest;

    const [
        executeRoomStaticQuery,
        {
            data: hotelRoomStaticData,
            error: hotelRoomStaticError,
            loading: hotelRoomStaticLoading,
            networkStatus: hotelRoomStaticNetworkStatus,
            refetch: refetchStatic,
        },
    ] = useMSQuery(GET_HOTEL_ROOMS_STATIC, { fetchPolicy: "network-only" });

    const [
        executeFiltersQuery,
        { data: filtersData, loading: filterLoading, error: filterError, networkStatus: filterNetworkStatus },
    ] = useLazyQuery(GET_FILTERS_DATA, { fetchPolicy: "network-only" });

    useEffect(() => {
        executeFiltersQuery();
    }, []);

    useEffect(() => {
        if (
            !hotelRoomStaticError &&
            !hotelRoomStaticLoading &&
            hotelRoomStaticNetworkStatus === 7 &&
            hotelRoomStaticData?.hotelRooms?.results &&
            !filterError &&
            !filterLoading &&
            filterNetworkStatus === 7
        ) {
            const hotelMap = getHotelMap(filtersData?.hotelMap?.results);
            const hotelRoomGroups = getRoomGroups(filtersData?.roomGroups?.results);
            const floorFilter = arrangeFilterData(hotelMap, hotelRoomGroups);
            setFilters(floorFilter);
            const roomsData = arrangeData(hotelRoomStaticData, guestAccess, t, open, floorFilter[1], refetchStatic);
            setData(roomsData);
        }
        // eslint-disable-next-line
    }, [hotelRoomStaticData, filtersData]);

    const variables = {
        page,
        status,
        roomGroupID: group,
        orderBy: sort,
        search,
        floorID: deviceLocation,
        size: 6,
        roomType: "GUESTROOM",
    };

    return {
        data,
        loading: hotelRoomStaticLoading,
        filters,
        refetch: refetchStatic,
        page,
        setPage,
        sort,
        setSort,
        search,
        setSearch,
        status,
        setStatus,
        group,
        setGroup,
        deviceLocation,
        setDeviceLocation,
        count: hotelRoomStaticData?.hotelRooms?.info?.count,
        getRooms: () => executeRoomStaticQuery({ variables }),
    };
};

export const useUpdateToGroup = ({ roomsGroupsId, roomsId, refetch }) => {
    const { t } = useTranslation();
    const { close } = useModal();

    const [executeMutation] = useMutation(updateRoomGroupsForRoom);

    const updateRoomGroup = () => {
        executeMutation({
            variables: {
                room: roomsId,
                roomGroups: roomsGroupsId,
            },
        }).then((response) => {
            if (response?.data?.updateRoomGroupsForRoom?.ok) {
                if (refetch) {
                    refetch();
                }
                toast.success(t("operation-successful"));
            }
            close();
        });
    };

    return { updateRoomGroup };
};

export const useAddToGroup = ({ roomsGroupsId, roomsId, refetch }) => {
    const { t } = useTranslation();
    const { close } = useModal();

    const [executeMutation] = useMutation(addRoomsToRoomGroups);

    const updateRoomGroup = () => {
        executeMutation({
            variables: {
                rooms: roomsId,
                roomGroups: roomsGroupsId,
            },
        }).then((response) => {
            if (response?.data?.addRoomsToRoomGroups?.ok) {
                refetch();
                toast.success(t("operation-successful"));
            }
            close();
        });
    };

    return { updateRoomGroup };
};
