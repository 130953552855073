import React, { useEffect, useState } from "react";
import UseSectionHeader from "../../../useSectionHeader";
import { useParams } from "react-router-dom";
import Loading from "components/Loading";
import { TVsPerRoom } from "./TopologyComponents/FloorsDetail";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import TextInput from "components/TextInput";
import NumberInput from "components/NumberInput";
import { useEditRoom } from "hooks/GraphqlCalls/Hotel/Settings/useEditRoom";
import { useTopologyValidation } from "hooks/GraphqlCalls/Hotel/useTopologyMap";
import { toast } from "react-toastify";
import { ROOM_TYPE } from "constants/rooms";

export const EditRoom = () => {
    const [loading, setLoading] = useState(true);
    const [tvsPerRoom, setTvsPerRoom] = useState([]);
    const [roomsSettings, setRoomsSettings] = useState({});

    const [nameValidation, setNameValidation] = useState("");
    const [numberValidation, setNumberValidation] = useState("");

    const { t } = useTranslation();
    const { id } = useParams();
    const { editRoom } = useEditRoom(roomsSettings?.roomTVs, roomsSettings?.roomTVsLength, roomsSettings?.newRoomTVs);
    const { validate, tvsPerRoomValidation } = useTopologyValidation();

    const GET_ROOM_INFO = gql`{
        rooms( id: ${id}) {           
            results { floorID floorName id name number pmsNumber roomTVs{ id name cec} wingID wingName}
        },
    }`;

    const [getRoomInfo, { data }] = useLazyQuery(GET_ROOM_INFO, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        getRoomInfo();
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (data) {
            const room = data?.rooms?.results[0];
            setRoomsSettings({
                wingName: room?.wingName,
                floorName: room?.floorName,
                name: room?.name,
                number: room?.number,
                pmsNumber: room?.pmsNumber,
                roomTVs: room?.roomTVs,
                roomTVsLength: room?.roomTVs?.length,
                newRoomTVs: room?.roomTVs,
            });
        }
    }, [data]);

    const changeRoomName = (value) => {
        setRoomsSettings((prev) => {
            return {
                ...prev,
                name: value,
            };
        });
    };

    const changeRoomNumber = (value) => {
        setRoomsSettings((prev) => {
            return {
                ...prev,
                number: value,
            };
        });
    };

    const changePmsNumber = (value) => {
        setRoomsSettings((prev) => {
            return {
                ...prev,
                pmsNumber: value,
            };
        });
    };

    useEffect(() => {
        if (roomsSettings?.roomTVs) {
            setTvsPerRoom(roomsSettings?.newRoomTVs);
        }
    }, [roomsSettings]);

    useEffect(() => {
        setRoomsSettings((prev) => {
            return {
                ...prev,
                newRoomTVs: tvsPerRoom,
            };
        });
    }, [tvsPerRoom]);

    const handleSave = () => {
        if (roomsSettings?.name === "") {
            setNameValidation("border border-red-500");
            toast.error(t("input error"));
        } else if (roomsSettings?.number == 1) {
            setNumberValidation("border border-red-500");
            toast.error(t("input error"));
        } else if (roomsSettings?.number > 99999) {
            setNumberValidation("border border-red-500");
            toast.error(t("The number must be a maximum of 5 digits. Please enter a valid number"));
        } else if (validate(roomsSettings)) {
            editRoom(id, roomsSettings?.name, roomsSettings?.number, roomsSettings?.pmsNumber);
        } else {
            setNameValidation("");
            setNumberValidation("");
        }
    };

    return !loading ? (
        <>
            <UseSectionHeader
                title={`${roomsSettings?.name} ( ${roomsSettings?.wingName} , ${roomsSettings?.floorName} )`}
                navToPreviousPage={true}
            />
            <div className="bg-white p-6">
                <div className="flex gap-3">
                    <div className="flex flex-col w-1/5">
                        <label className="fieldLabel">{t("room-name")}</label>
                        <TextInput
                            value={roomsSettings?.name}
                            onChange={changeRoomName}
                            className={nameValidation}
                            id="edit-room-name"
                        />
                    </div>
                    <div className="flex flex-col w-52">
                        <label className="fieldLabel">{t("room-number")}</label>
                        <NumberInput
                            id="edit-room-number"
                            className={`w-full ${numberValidation}`}
                            value={roomsSettings?.number}
                            min={1}
                            onChange={changeRoomNumber}
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="fieldLabel">{t("pms-number")}</label>
                        <TextInput id="edit-pms-number" value={roomsSettings?.pmsNumber} onChange={changePmsNumber} />
                    </div>
                    <TVsPerRoom
                        type={ROOM_TYPE.GUESTROOM}
                        value={tvsPerRoom}
                        onChange={setTvsPerRoom}
                        disabled={!roomsSettings?.number > 0}
                        validateLocation={(index) =>
                            tvsPerRoomValidation?.[index]?.validation || tvsPerRoomValidation?.[id]?.[index]?.validation
                        }
                    />
                </div>
                <div className="flex gap-3 mt-10 justify-end">
                    <Button id="cancel-edit-room" className="btn btn-white" to={`/hotel/property-settings/topology`}>
                        {t("cancel")}
                    </Button>
                    <Button id="edit-room" className="btn btn-blue" onClick={handleSave}>
                        {t("save")}
                    </Button>
                </div>
            </div>
        </>
    ) : (
        <Loading adjust="section-container" style={{ height: "50vh" }} />
    );
};
