import { UPCOMING_GUESTS_STATUS } from "hooks/Utils/Customers/GuestsUtils";
import i18n from "i18n";
import { satisfactionLevelValues } from "../Satisfaction/utils";

const ParseDate = (date) => {
    return date
        ? `${date.toLocaleDateString([], {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
          })}`
        : `-`;
};

const getGroups = (guestGroups) => {
    return guestGroups?.length > 0 ? guestGroups.map((group) => group.name).join(" - ") : "";
};

const getGuestRooms = (rooms) => {
    return rooms?.length > 0
        ? rooms
              .map((room) => ({
                  name: room.number,
                  link: `/hotel/rooms/room/detail/${room.roomID}`,
              }))
              .sort((a, b) => a.name - b.name)
        : [];
};

const getStayDates = (rooms) => {
    let from = null;
    let to = null;
    if (rooms?.length > 0) {
        rooms.forEach((room) => {
            const stayFrom = new window.ZDate(room.stayFrom);
            const stayTo = new window.ZDate(room.stayTo);
            if (room.stayFrom && (!from || stayFrom < from)) from = stayFrom;
            if (room.stayTo && (!to || stayTo > to)) to = stayTo;
        });
    }
    return { from, to };
};

export const getSatisfactionValues = (level) => {
    const defaultValues = { icon: null, style: null };
    if (level === null) return defaultValues;
    return satisfactionLevelValues[level + 1] || defaultValues;
};

const GetInfo = ({ user, folderName, groups, guestrooms, from, to, hasGuestSatisfaction }) => {
    const {
        id,
        name,
        surname,
        loyaltyRef,
        email,
        stayHolderEmail,
        stayHolderName,
        stayHolderSurname,
        numberOfRooms,
        numberOfGuests,
        arrivalDate,
        departureDate,
        state,
        pendingChangesSend,
        code,
        lastSatisfactionLevel,
    } = user;

    const baseInfo = [
        {
            name: "name",
            cellContent: "link",
            value: `${name} ${surname}`,
            icon: loyaltyRef ? "loyal-customers" : null,
            iconAdjust: "text-lg",
            iconTooltip: "loyal-guest",
            link: `/customers/guests/guest-list/guest/${id}`,
            specialWidth: "w-3/12",
        },
        {
            name: "email",
            cellContent: "text",
            value: email,
            specialWidth: "w-3/12",
            adjust: "truncate",
        },
        {
            name: "groups",
            cellContent: "value",
            value: groups,
            specialWidth: "w-2/12 max-h-full overflow-hidden",
        },
        {
            name: "rooms",
            cellContent: "link",
            multipleLink: guestrooms,
            specialWidth: hasGuestSatisfaction ? "w-1/12" : "w-1.5/12",
            permissionToLink: { section: "hotel", attribute: "roomList" },
        },
        {
            name: "signupDate",
            cellContent: "value",
            value: `${
                from
                    ? `${from.toLocaleDateString([], {
                          year: "2-digit",
                          month: "2-digit",
                          day: "2-digit",
                      })} ${from.toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}`
                    : `-`
            }\n${
                to
                    ? `${to.toLocaleDateString([], {
                          year: "2-digit",
                          month: "2-digit",
                          day: "2-digit",
                      })} ${to.toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}`
                    : `-`
            }`,
            specialWidth: "w-1.5/12 whitespace-prewrap",
        },
    ];

    if (hasGuestSatisfaction) {
        const satisfactionValues = getSatisfactionValues(lastSatisfactionLevel);

        baseInfo.push({
            name: "signupDate",
            cellContent: "icon",
            icon: {
                name: satisfactionValues.icon,
                adjust: "w-full flex justify-center",
                style: satisfactionValues.style,
            },
            specialWidth: "w-2/12",
        });
    } else {
        baseInfo.push({
            name: "booking-management",
            specialWidth: "w-1/12",
        });
    }

    const upcomingInfo = [
        {
            name: "code",
            cellContent: "link",
            link: `/customers/guests/guest-list/reservation-details/${id}`,
            value: code,
            specialWidth: "w-2/12 truncate mr-4",
        },
        {
            name: "email",
            cellContent: "text",
            value: stayHolderEmail ?? "-",
            specialWidth: "w-3/12",
            adjust: "truncate",
        },
        {
            name: "name",
            cellContent: "text",
            value: `${stayHolderName && stayHolderSurname ? `${stayHolderName} ${stayHolderSurname}` : "-"}`,
            specialWidth: "w-1.5/12 max-h-full overflow-hidden text-center",
        },
        {
            name: "booking",
            cellContent: "booking",
            value: { room: numberOfRooms, guest: numberOfGuests },
            specialWidth: "w-1.5/12",
        },
        {
            name: "stayDate",
            cellContent: "date",
            value: `${ParseDate(new window.ZDate(arrivalDate))} ${
                departureDate ? ParseDate(new window.ZDate(departureDate)) : "-"
            }`,
            specialWidth: "w-2/12",
        },
        {
            name: "status",
            cellContent: "upcomingCheckInsStatus",
            specialWidth: `w-2/12 ${pendingChangesSend ? "hidden" : "block"}`,
            value: UPCOMING_GUESTS_STATUS[state],
        },
        {
            name: "status",
            cellContent: "text",
            specialWidth: `w-2/12 ${pendingChangesSend ? "block" : "hidden"}`,
            value: pendingChangesSend ? i18n.t("data-in-modification") : "",
        },
        {
            name: "all-information",
            value: user,
        },
    ];

    return folderName === "currents" ? baseInfo : upcomingInfo;
};

const Guest = (user, folderName, hasGuestSatisfaction, openModalFeedbackHistory) => {
    const dataType = `guest-${folderName}`;

    const groups = getGroups(user.guestGroups);
    const guestrooms = getGuestRooms(user.rooms);
    const { from, to } = getStayDates(user.rooms);

    const info = GetInfo({ user, folderName, groups, guestrooms, from, to, hasGuestSatisfaction });

    return {
        dataType: dataType,
        id: user.id,
        adjust: "h-auto",
        actions: [{ type: dataType, value: user.id }],
        rooms: user.rooms,
        hasGuestSatisfaction,
        lastSatisfactionLevel: user.lastSatisfactionLevel,
        guestID: user.id,
        info: info,
        openModalFeedbackHistory,
        executeSectionQuery: !(folderName === "currents"),
    };
};

export default Guest;
