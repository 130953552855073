import { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { useMSQuery } from "../useMSQuery";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent, setRefreshContentData } from "../../../actions/sectionActions";
import { CORPORATE_APPEARANCE_TYPE, PROPERTY_APPEARANCE_TYPE, CORPORATE_REF } from "../../Utils/AppearancesUtils";

import { useAuth } from "hooks/Session/auth";

const ListAppearances = ({ themeType, setLoadingListAppearances }) => {
    const dispatch = useDispatch();
    const { project, isCorporate, hasChainModule } = useAuth();

    const showCorporateAppearances = isCorporate || hasChainModule;

    const projectRef = project?.ref;

    const { refreshData, appearanceSelected, appearanceId, searchAppearance } = useSelector(
        (state) => state.sectionContent
    );
    const [keepSelected, setkeepSelected] = useState(appearanceId ? true : false);

    //API Call
    const GET_NAME_THEMES = gql`
        {
            themes: themes(filter: { themeType: ${themeType}, search: "${searchAppearance ? searchAppearance : ""}" }) {
                info {
                    count
                }
                results {
                    id
                    name
                    type
                    projectId
                    hasDesigns
                    hasMobileApps
                    isCopyableToProperty
                }
            }
            properties: properties{
                results{
                  id
                  name
                  hasChainModule
                }
              }
        }
    `;

    const [executeNameThemesQuery, { loading, error, data, refetch }] = useMSQuery(GET_NAME_THEMES, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (typeof setLoadingListAppearances === "function") {
            setLoadingListAppearances(loading);
        }
    }, [loading]);

    useEffect(() => {
        executeNameThemesQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (appearanceId) {
            setkeepSelected(true);
        }
        // eslint-disable-next-line
    }, [appearanceId]);

    useEffect(() => {
        if (refreshData) {
            dispatch(setRefreshContentData(false));
            refetch();
            setkeepSelected(appearanceId ? true : false);
        }
        // eslint-disable-next-line
    }, [refreshData]);

    const sortThemes = (_propertyThemes, projects) => {
        // eslint-disable-next-line
        _propertyThemes.map((theme) => {
            let pFind = projects.find((prj) => prj.id === theme.projectId);
            theme.projectName = pFind ? pFind.name : null;
        });

        _propertyThemes.sort(function (a, b) {
            return a.projectId - b.projectId;
        });
        return _propertyThemes;
    };

    useEffect(() => {
        if (!loading && !error && data) {
            let _corporateThemes = [];
            let _propertyThemes = [];
            let _keepName = appearanceSelected ? appearanceSelected.name : null;

            if (data?.themes?.results?.length) {
                data.themes.results.forEach((theme) => {
                    if (theme.type === CORPORATE_APPEARANCE_TYPE) {
                        _corporateThemes.push(theme);
                    } else {
                        _propertyThemes.push(theme);
                    }
                    if (keepSelected && theme.id === appearanceSelected.id) {
                        _keepName = theme.name;
                    }
                });
            }

            let _arrayMenuItems = [];
            //show order appearances
            if (projectRef === CORPORATE_REF) {
                if (showCorporateAppearances) {
                    _arrayMenuItems.push({
                        name: CORPORATE_APPEARANCE_TYPE,
                        editionMode: "EDITION",
                        appearances: _corporateThemes,
                    });
                }
                //ORDER propertyThemes by chains
                _arrayMenuItems.push({
                    name: PROPERTY_APPEARANCE_TYPE,
                    editionMode: "VIEW",
                    appearances: sortThemes(_propertyThemes, data.properties.results),
                });
            } else {
                _arrayMenuItems.push({
                    name: PROPERTY_APPEARANCE_TYPE,
                    editionMode: "EDITION",
                    appearances: _propertyThemes,
                });
                if (showCorporateAppearances) {
                    _arrayMenuItems.push({
                        name: CORPORATE_APPEARANCE_TYPE,
                        editionMode: "VIEW",
                        appearances: _corporateThemes,
                    });
                }
            }

            const _firstId = _arrayMenuItems?.filter((i) => i.appearances.length > 0)[0]?.appearances[0]?.id;
            const _firstName = _arrayMenuItems?.filter((i) => i.appearances.length > 0)[0]?.appearances[0]?.name;
            const _firstType = _arrayMenuItems?.filter((i) => i.appearances.length > 0)[0]?.name;

            dispatch(
                setSectionContent({
                    listAppearances: _arrayMenuItems,
                    appearanceSelected:
                        keepSelected && appearanceSelected
                            ? { ...appearanceSelected, name: _keepName }
                            : {
                                  id: _firstId,
                                  name: _firstName,
                                  type: _firstType,
                                  locks: { LOGOS_TOOLBAR: false, TYPOGRAPHY_TOOLBAR: false, COLOUR_TOOLBAR: false },
                              },
                    appearanceId: keepSelected && appearanceSelected ? appearanceSelected.id : _firstId,
                })
            );
        }
        // eslint-disable-next-line
    }, [data]);
};

export default ListAppearances;
