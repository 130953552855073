import React, { useEffect } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { POLLINTERVAL_15S } from "../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import {
    setLoading,
    setItemsPerpage,
    setHeaders,
    setHeaderButtons,
    setCustomError,
    setSearchable,
    setCheckable,
    setActions,
    setTableResults,
    setBatchActive,
    setRefreshData,
    cleanCheckedItems,
    setError,
    setCount,
} from "../../../actions/tableActions";
import GetTableHeaders from "../../Utils/Table/Headers/GetServicesTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { useTranslation } from "react-i18next";
import Grid from "./models/Grid";
import ApplyMakeDefaultGrid from "./modals/ApplyMakeDefaultGrid";
import { openModal, setModalContent } from "../../../actions/uiActions";

const ListGrids = () => {
    const dispatch = useDispatch();
    const SECTION_NAME = "list-grids-channels";
    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);
    const { refreshData, currentPage, perPage, sortCriteria, filterQuery } = useSelector((state) => state.table);
    const multipleGrids = permissions?.services?.multipleGrids ? true : false;

    const GET_DATA = gql(`
    query{
        tuneGridsSummary(page:${currentPage} , size:${perPage}
            ${sortCriteria ? sortCriteria : ""}
            ${filterQuery ? filterQuery : ""}){
          info{
            count
            page
            size
          }
          results{
            filtersNum
            id
            isDefault
            name
            destination {
                tvLocation: roomTvsTpGuestRoom {
                  id
                }
                countries {
                  ref
                }
                floors {
                  id
                }
                languages {
                  ref
                }
                roomGroups {
                  id
                }
                rooms {
                  id
                }
                stayGroups {
                  id
                }
                stayGuests {
                  id
                }
                wings {
                  id
                }
                tvLocationZone: roomTvsTpCommonZone {
                  id
                }
                commonZone: roomsTpCommonZone {
                  id
                }
                commonZoneGroup: roomGroupsTpCommonZone {
                  id
                }
                
              }
            tuneGroupsNum
          }
        }
      }      
      `);

    const [executeQuery, { data, refetch, loading, error }] = useLazyQuery(GET_DATA, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
        dispatch(setItemsPerpage(9));
        dispatch(setLoading(true));
        dispatch(setHeaders(GetTableHeaders(SECTION_NAME, { multipleGrids })));
        dispatch(setCustomError(t("no-grids-yet")));
        dispatch(setSearchable(true));
        dispatch(setBatchActive(BatchActions()));
        dispatch(setActions(true));
        dispatch(setCheckable(true));
    }, []);

    useEffect(() => {
        dispatch(setHeaderButtons(GetTableHeaderButton(SECTION_NAME, { multipleGrids }, null, t)));
    }, [permissions]);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
            dispatch(setRefreshData(false));
            dispatch(cleanCheckedItems());
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (data?.tuneGridsSummary?.results) {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 2000);
        }
        //eslint-disable-next-line
    }, [loading, sortCriteria]);

    useEffect(() => {
        if (data && data.tuneGridsSummary.results) {
            const arrangedData = arrangeData(data.tuneGridsSummary.results, multipleGrids);
            dispatch(setCount(data.tuneGridsSummary.info.count));
            dispatch(setTableResults(arrangedData));
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
    }, [error]);

    const arrangeData = (data, multipleGrids) => {
        return data.map(({ tuneGroupsNum, filtersNum, isDefault, ...rest }) => {
            return Grid(
                {
                    ...rest,
                    numberGroups: tuneGroupsNum,
                    numberFilters: filtersNum,
                    isDefault: isDefault,
                    multipleGrids: multipleGrids,
                    actionMakeGridEnabled: (data) => {
                        const content = ApplyMakeDefaultGrid(t, data);
                        dispatch(setModalContent(content));
                        dispatch(openModal());
                    },
                },
                t
            );
        });
    };

    return null;
};

export default ListGrids;

const BatchActions = () => {
    const actions = [
        {
            name: "delete",
            action: "delete-grids-channels",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "delete-grids-confirm",
                },
                {
                    type: "deleteBatchGridDetail",
                    style: "",
                    elementsType: "grid",
                    columnPos: 0,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "delete", style: "red", action: "delete-grids-channels" },
            ],
        },
    ];
    return actions;
};
