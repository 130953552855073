import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useTranslation } from "react-i18next";
import ZafiroTable from "components/ZafiroTable";
import Data from "components/Data";
import Button from "components/Button";
import { useAuth } from "hooks/Session/auth";
import { useModal } from "components/Modal";
import { TagApp } from "sections/customers/TagApp";
import { getOrigin } from "hooks/Utils/Customers/Customers";

export const useLogoutDevices = ({ onSuccess, onError }) => {
    const query = gql`
        mutation LogOutGuestDevices($refs: [String!]) {
            logOutGuestDevices(refs: $refs) {
                error
                ok
            }
        }
    `;
    const [load, { data, loading, error: mutationError, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = mutationError || data?.logOutGuestDevices?.error;
    const success = data?.logOutGuestDevices?.ok || false;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(data?.logOutGuestDevices);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        logout: ({ refs }) => {
            if (load) {
                load({ variables: { refs } });
            }
        },
        loading,
        error: error,
        ready: called && !loading && !mutationError,
        success: success,
    };
};

export const useUpdateMarketing = ({ onSuccess, onError }) => {
    const query = gql`
        mutation updateCustomerTerms($id: [Int64], $marketingAccepted: Boolean) {
            updateCustomerTerms(id: $id, marketingAccepted: $marketingAccepted) {
                error
                ok
            }
        }
    `;
    const [load, { data, loading, error: mutationError, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = mutationError || data?.updateCustomerTerms?.error;
    const success = data?.updateCustomerTerms?.ok || false;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(data?.updateCustomerTerms);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        update: ({ id, value }) => {
            if (load) {
                load({ variables: { id, marketingAccepted: value } });
            }
        },
        loading,
        error: error,
        ready: called && !loading && !mutationError,
        success: success,
    };
};

export const useUpdateUnregisteredMarketing = ({ onSuccess, onError }) => {
    const query = gql`
        mutation updateUnregisteredCustomer($email: [Email!], $marketingAccepted: Boolean) {
            updateUnregisteredCustomer(email: $email, marketingAccepted: $marketingAccepted) {
                error
                ok
            }
        }
    `;
    const [load, { data, loading, error: mutationError, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = mutationError || data?.updateUnregisteredCustomer?.error;
    const success = data?.updateUnregisteredCustomer?.ok || false;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(data?.updateUnregisteredCustomer);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        update: ({ email, value }) => {
            if (load) {
                load({ variables: { email, marketingAccepted: value } });
            }
        },
        loading,
        error: error,
        ready: called && !loading && !mutationError,
        success: success,
    };
};

export const useAccessListModal = ({ customersData, mobileApps, projects }) => {
    const { t } = useTranslation();
    const { isCorporate } = useAuth();
    const { open: openModal, close: closeModal } = useModal();
    const [page, setPage] = useState(1);

    return {
        open: ({ id, email, logins, properties }) => {
            const customer = customersData.find((c) => c.id === id);
            const signupOrigin = getOrigin(
                customer.project,
                customer.signupApp,
                customer.signupWifi,
                t,
                mobileApps,
                projects
            );
            const checkinUnregistered = properties?.map((p) => {
                return {
                    properties: (
                        <div
                            key={p.ref}
                            className="bg-teal-500 rounded px-1 py-2 text-white"
                            data-tip={p.name}
                            data-for="default-tooltip"
                        >
                            {p.name}
                        </div>
                    ),
                    signin: <TagApp type="check-in" name={t("check-in")} />,
                };
            });
            const signup = logins
                ? logins
                      .map((login) => ({
                          ...login,
                          origin: getOrigin(null, login.app, login.wifi, t, mobileApps, projects),
                      }))
                      .filter((login) => signupOrigin?.name === login.origin?.name)
                      .sort((a, b) => new Date(a.date) - new Date(b.date))[0]
                : null;

            openModal({
                id: "access-list-modal",
                title: `${email} - ${t("customer-activity")}`,
                fullHeight: true,
                children: (
                    <ZafiroTable
                        id="access-list"
                        search={true}
                        perPage={logins?.length || properties?.length}
                        maxHeight="37rem"
                        onPageChange={setPage}
                        paginate={"lazy"}
                        cols={["signin", isCorporate && "properties", "date", "signup"]}
                        header={{
                            signin: { title: t("accesses-text") },
                            date: { title: t("date-and-time") },
                            signup: { title: t("") },
                            properties: isCorporate ? { title: t("properties") } : undefined,
                        }}
                        rows={
                            logins?.length
                                ? logins.map((login, index) => {
                                      const propertiesRow = properties?.length ? (
                                          properties?.length > 1 ? (
                                              <div
                                                  className="bg-teal-500 rounded px-1 py-2 text-white"
                                                  data-tip={properties.map((p) => p.name).join("\n")}
                                                  data-for="default-tooltip"
                                              >
                                                  {t("x properties", { count: properties?.length })}
                                              </div>
                                          ) : (
                                              <div className="bg-teal-500 rounded px-1 py-2 text-white">
                                                  {properties?.[0]?.name}
                                              </div>
                                          )
                                      ) : null;
                                      const origin = getOrigin(null, login.app, login.wifi, t, mobileApps, projects);

                                      const lastAccess = login.date;
                                      const lastDate = <Data>{lastAccess ? new Date(lastAccess) : "-"}</Data>;
                                      const signUp =
                                          signup?.date === lastAccess ? (
                                              <span className="text-gray-500">{t("signUp")}</span>
                                          ) : null;
                                      const date = (
                                          <div className="flex flex-col">
                                              <Data key={lastAccess}>{lastAccess ? new Date(lastAccess) : "-"}</Data>
                                          </div>
                                      );
                                      return {
                                          signin: (
                                              <TagApp
                                                  key={`tag-${origin?.name}`}
                                                  type={origin?.type}
                                                  name={origin?.name}
                                                  warning={origin?.warning}
                                                  alt={`${origin?.type} ${origin.host} ${origin?.name}`}
                                              >
                                                  {origin?.name}
                                              </TagApp>
                                          ),
                                          properties: propertiesRow,
                                          date: date ?? lastDate,
                                          signup: signUp,
                                      };
                                  })
                                : checkinUnregistered
                        }
                    />
                ),
                footer: (
                    <Button design="blue" id="modal-button-close" onClick={closeModal}>
                        {t("close")}
                    </Button>
                ),
            });
        },
        close: closeModal,
    };
};

export const GET_CUSTOMERS_DATA = gql`
    query Customers($page: Int, $orderBy: OrderBy, $filter: CZCatFilter) {
        customers(page: $page, orderBy: $orderBy, filter: $filter) {
            info {
                count
            }
            results {
                customerID: id
                ref
                name
                surname
                email
                signupApp
                signupWifi
                gender
                birthDate
                phone
                country
                language
                project
                lastAccess
                termsAccepted
                marketingAccepted
                emailVerified
                logins {
                    app
                    date
                    wifi
                }
            }
        }
        mobileApps {
            results {
                name
                shortName
                url
                projects {
                    ref
                    name
                }
            }
        }
        properties {
            results {
                ref
                id
                name
            }
        }
    }
`;
export const GET_COUNTRIES = gql`
    query Countries {
        countries(page: 1, size: 1000, byCustomer: true) {
            results {
                ref
            }
        }
    }
`;
