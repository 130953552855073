import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHotelMap } from "hooks/GraphqlCalls/Hotel/useTopologyMap";
import Button from "components/Button";
import Icon from "components/Icon";
import NumberInput from "components/NumberInput";
import Select from "components/Select";
import Switch from "components/Switch";
import TextInput from "components/TextInput";
import { ROOM_TYPE } from "constants/rooms";

export const FloorDetails = ({
    id,
    checked,
    setFloorSettings,
    installationMap,
    floorNameValidation,
    tvsPerRoomValidation,
    starterRoomValidation,
    floorLength,
    pos,
    addingRoom,
    lastRoom,
    isRoom,
}) => {
    const [floorOrder, setFloorOrder] = useState(id + 1);
    const [floorName, setFloorName] = useState(``);
    const [numbersOfRooms, setNumbersOfRooms] = useState(1);
    const [starterRoom, setStarterRoom] = useState(1);
    const [tvsPerRoom, setTvsPerRoom] = useState([{}]);
    const { t } = useTranslation();

    const handleFloorNameChange = useCallback(
        (value) => {
            setFloorName(value);
        },
        [id]
    );

    useEffect(() => {
        if (installationMap) {
            setStarterRoom(
                Number(
                    floorLength
                        ? `${pos}${floorLength + id + 1}01`
                        : lastRoom
                        ? lastRoom + 1
                        : `${installationMap.length + 1}${id + 1}01`
                )
            );
            setFloorName(`Floor ${(floorLength ? floorLength + id : id) + 1}`);
        }
        if (floorLength) {
            setFloorOrder(floorLength + 1);
        }
    }, [installationMap, id, floorLength]);

    useEffect(() => {
        if (setFloorSettings) {
            setFloorSettings((prevFloorSettings) => {
                const newFloorSettings = [...prevFloorSettings];
                newFloorSettings[id] = {
                    floorOrder,
                    floorName,
                    numbersOfRooms,
                    starterRoom,
                    tvsPerRoom,
                };
                return newFloorSettings;
            });
        }
    }, [floorOrder, floorName, numbersOfRooms, starterRoom, tvsPerRoom, id, setFloorSettings]);

    const handleStarterRoomChange = useCallback(
        (value) => {
            const newStarterRoom = Number(value);
            setStarterRoom(newStarterRoom);
        },
        [id]
    );

    return (
        <div className={`flex gap-3 w-full ${!addingRoom && "border rounded border-gray-300 p-8"}  mt-4`}>
            {checked && !addingRoom && (
                <>
                    <div className="flex flex-col w-1/5">
                        <label className="fieldLabel">{t("floor-name")}</label>
                        <TextInput
                            id={`floor-name-${id}`}
                            value={floorName}
                            onChange={handleFloorNameChange}
                            className={floorNameValidation?.[id]}
                        />
                    </div>
                </>
            )}
            <div className="flex flex-col w-52">
                <label className="fieldLabel">{t("number-of-rooms")}</label>
                <NumberInput
                    id={!addingRoom ? `number-of-rooms-${id}` : `number-of-rooms-0`}
                    className="w-full"
                    value={numbersOfRooms}
                    min={addingRoom ? 1 : 0}
                    onChange={(value) => setNumbersOfRooms(Number(value))}
                />
            </div>
            {checked && (
                <div className="flex flex-col w-40">
                    <label className="fieldLabel">{t("starter-room")}</label>
                    <NumberInput
                        id={!addingRoom ? `starter-rooms-${id}` : `starter-rooms-0`}
                        value={starterRoom}
                        onChange={handleStarterRoomChange}
                        disabled={numbersOfRooms === 0}
                        className={starterRoomValidation?.[id] || starterRoomValidation?.adRoomValidation}
                    />
                </div>
            )}
            <TVsPerRoom
                id={!addingRoom ? id : 0}
                type={isRoom ? ROOM_TYPE.GUESTROOM : ROOM_TYPE.COMMONZONE}
                value={tvsPerRoom}
                onChange={setTvsPerRoom}
                disabled={!numbersOfRooms > 0}
                validateLocation={(index) =>
                    tvsPerRoomValidation?.[index]?.validation ||
                    tvsPerRoomValidation?.[isRoom ? 0 : id]?.[index]?.validation
                }
            />
        </div>
    );
};

export const TVsPerRoom = ({ id, type, value, onChange, disabled, validateLocation }) => {
    const { t } = useTranslation();

    const [data, setData] = useState(value || [{ name: "", CEC: true }]);
    const dataHash = JSON.stringify(data);

    const hasManyLocations = data?.length >= 2;

    const { locations } = useHotelMap();

    const label = type === ROOM_TYPE.COMMONZONE ? t("tvs-per-common-zone") : t("tvs-per-room");

    const forceCEC = type === ROOM_TYPE.COMMONZONE;

    const locationsOptions = locations?.length
        ? locations
              ?.filter((l) => !type || l?.type === type)
              ?.map((l, index) => ({
                  id: index,
                  value: l?.name,
                  label: l?.name,
              }))
        : [];

    const tvsPerRoomOptions = useCallback(
        () => Array.from({ length: 10 }, (_, i) => i + 1).map((i) => ({ value: i, label: i })),
        []
    );
    const options = tvsPerRoomOptions();

    useEffect(() => {
        if (onChange) {
            onChange(data);
        }
    }, [dataHash]);

    return (
        <div className="flex flex-col">
            {label ? <label className="fieldLabel">{label}</label> : null}
            <Select
                id={`add-tvs-per-room${id !== undefined ? `-${id}` : ""}`}
                width="40%"
                options={options}
                search={false}
                allowUnselect={false}
                disabled={disabled}
                onChange={(amount) => {
                    if (data?.length < amount) {
                        const toAdd = amount - data.length;
                        const newValues = Array.from({ length: toAdd }, (_, i) => ({
                            id: new Date().getTime() + i,
                            name: "",
                            CEC: true,
                        }));
                        setData([...(data || []), ...newValues]);
                    } else if (data?.length > amount) {
                        setData(data.slice(0, amount));
                    }
                }}
                value={data?.length}
            />
            <label className="font-bold mt-3">{t("tv-locations")}</label>
            <div className="flex items-center gap-2 mt-3">
                <p className="text-sm text-gray-800">{t("select-tv-locations-and-cec")}</p>
                <Icon type="info" size="xl" tooltip={t("cec-enabled-description")} />
            </div>
            {data?.length
                ? data?.map((tv, index) => {
                      const locationName = tv?.name;
                      const error = validateLocation ? validateLocation(index) : null;

                      const onChangeLocation = (value) => {
                          setData((current) => {
                              return current.map((v) => {
                                  if (v.id === tv.id) {
                                      v.name = value;
                                  }
                                  return v;
                              });
                          });
                      };
                      return (
                          <div key={tv?.id} className="flex items-center gap-3 mt-3">
                              <Select
                                  id={`alt-tv-location-${id ?? ""}${index}`}
                                  width="35%"
                                  allowCustomOptions={true}
                                  allowUnselect={false}
                                  disabled={disabled}
                                  placeholder={t("select-locations")}
                                  value={locationName}
                                  options={locationsOptions}
                                  onChange={onChangeLocation}
                                  error={error}
                              />
                              {!forceCEC ? (
                                  <>
                                      <p className="text-sm text-gray-800">{t("send-instructions-chromecast")}</p>
                                      <Switch
                                          id={`cec-${id ?? ""}${index}`}
                                          checked={tv.CEC || tv?.cec}
                                          disabled={disabled}
                                          onChange={({ checked }) => {
                                              setData((current) =>
                                                  current.map((v) => (v.id === tv.id ? { ...v, CEC: checked } : v))
                                              );
                                          }}
                                      />
                                  </>
                              ) : null}
                              <Button
                                  id={`delete-tv-location-${id ?? ""}${index}`}
                                  disabled={!hasManyLocations}
                                  onClick={() => {
                                      setData(data.filter((v) => v.id !== tv.id));
                                  }}
                              >
                                  <Icon
                                      type="delete"
                                      size="xl"
                                      className={!hasManyLocations ? "disabled" : ""}
                                      tooltip={!hasManyLocations ? t("required-tv-location") : ""}
                                  />
                              </Button>
                          </div>
                      );
                  })
                : null}
        </div>
    );
};
