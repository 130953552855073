import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TextInput from "components/TextInput";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import Button from "components/Button";
import { useVendureAPI, executeVendureQuery } from "hooks/Utils/Integrations/useVendure";
import { toast } from "react-toastify";
import { setRefreshContentData } from "actions/sectionActions";
import { setRefreshData } from "actions/tableActions";
import Loading from "components/Loading";
import ReactTooltip from "components/ReactTooltip";
import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";

const MUTATION_CREATE_EXTRA = `mutation CreateExtra($extra: CreateExtraInput!) {
    createExtra(extra: $extra) {
        id
    }
}`;

const useMutation = ({ closeModal, refetch }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { adminApi } = useVendureAPI();
    const [loading, setLoading] = useState(false);
    const tokenShop = Session.getSessionProp("tokenShop");
    const mutate = async (variables) => {
        setLoading(true);
        try {
            const response = await executeVendureQuery(
                adminApi,
                {
                    queryBody: MUTATION_CREATE_EXTRA,
                    variables,
                },
                { "vendure-token": tokenShop }
            );

            if (response?.errors && response.errors.length > 0) {
                toast.error(t("mutation-error"));
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
                closeModal();
                refetch();
            } else if (response?.data) {
                toast.success(t("operation-successful"));
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
                closeModal();
                refetch();
            } else {
                toast.error(t("mutation-error"));
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
                closeModal();
                refetch();
            }
        } catch (error) {
            toast.error(`${error.message}`);
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
            closeModal();
            refetch();
        } finally {
            setLoading(false);
        }
    };

    return { mutate, loading };
};

export const ModalContent = (props) => {
    const { t } = useTranslation();
    const [requiredFields, setRequiredFields] = useState({ name: false });
    const { mutate, loading } = useMutation({ closeModal: props?.close, refetch: props?.refetch });
    const { appliesTo, taxCategory, order } = props;

    const SINGLE_TYPE = "single";
    const GROUP_TYPE = "group";
    const SINGLE_TYPE_SELECTION_SEVERAL = "single_several";
    const SINGLE_TYPE_SELECTION_SINGLE = "single_single";
    const GROUP_TYPE_SELECTION_SEVERAL = "group_several";
    const GROUP_TYPE_SELECTION_SINGLE = "group_single";

    const [dataExtra, setDataExtra] = useState({
        name: "",
        type: SINGLE_TYPE,
        singleTypeSelection: SINGLE_TYPE_SELECTION_SEVERAL,
        groupTypeSelection: GROUP_TYPE_SELECTION_SEVERAL,
    });

    const { languages: projectLangs } = useAuth();
    const defaultLang = projectLangs ? projectLangs.find((lang) => lang.isDefault === true)?.languageRef : null;

    const getExtraType = (dataExtra) => {
        if (dataExtra.type === SINGLE_TYPE && dataExtra.singleTypeSelection === SINGLE_TYPE_SELECTION_SEVERAL) {
            return "numeric";
        }
        if (dataExtra.type === SINGLE_TYPE && dataExtra.singleTypeSelection === SINGLE_TYPE_SELECTION_SINGLE) {
            return "normal";
        }
        if (dataExtra.type === GROUP_TYPE && dataExtra.groupTypeSelection === GROUP_TYPE_SELECTION_SEVERAL) {
            return "multicombobox";
        }
        if (dataExtra.type === GROUP_TYPE && dataExtra.groupTypeSelection === GROUP_TYPE_SELECTION_SINGLE) {
            return "combobox";
        }

        return "";
    };

    const renderTypeOfSelectionToolTipHtml = (type) => {
        let tooltipHtml = '<div class="flex items-center w-full  justify-between">';

        if (type === SINGLE_TYPE_SELECTION_SINGLE || type === GROUP_TYPE_SELECTION_SINGLE) {
            tooltipHtml += '<span class="h-6 w-6 border rounded border-gray-600 mr-2"></span>';
        }

        tooltipHtml += `<span class="mr-4 max-w-xs truncate">${
            requiredFields.name && requiredFields.name !== "" ? requiredFields.name : t("extra-name")
        }</span>`;

        if (type === SINGLE_TYPE_SELECTION_SEVERAL || type === GROUP_TYPE_SELECTION_SEVERAL) {
            tooltipHtml += `
                <div class="flex items-center">
                    <span class="text-3xl">-</span>
                    <div class="h-8 w-8 flex items-center justify-center mx-1 border rounded-md border-gray-600">
                        <span class="text-base text-gray-500">01</span>
                    </div>
                    <span class="text-3xl">+</span>
                </div>
            `;
        }

        tooltipHtml += "</div>";

        return tooltipHtml;
    };

    const handleUpdate = () => {
        if (dataExtra?.name?.trim() !== "") {
            mutate({
                extra: {
                    available: false,
                    appliesTo: appliesTo,
                    taxCategory: taxCategory,
                    order: order,
                    type: getExtraType(dataExtra),
                    translations: [
                        {
                            languageCode: defaultLang || "en",
                            name: dataExtra?.name,
                        },
                    ],
                    options: {
                        min: 0,
                        max: 0,
                        priceWithoutTax: 0,
                    },
                },
            });
        } else {
            setRequiredFields({ ...requiredFields, name: true });
            toast.error(t("input error"));
        }
    };

    return (
        <Modal
            id="add-extra-modal"
            title={t("new-extra")}
            minWidth={"30vw"}
            footer={
                !loading ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={props?.close}>
                            {t("cancel")}
                        </Button>
                        <Button
                            design="blue"
                            id="modal-button-save"
                            onClick={() => {
                                handleUpdate();
                            }}
                        >
                            {t("save")}
                        </Button>
                    </>
                ) : null
            }
        >
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className=" font-bold">{`${t("name")}*`}</div>
                    <div className=" flex items-center space-x-2 mb-6">
                        <div className=" w-auto whitespace-no-wrap">
                            {t(`language:${defaultLang ? defaultLang : "en"}`) + ` (${t("default-language")})`}
                        </div>
                        <div className=" flex w-full  ">
                            <TextInput
                                placeholder={t("extra-name")}
                                id={"add-shop-name"}
                                value={dataExtra.name}
                                onChange={(val) => setDataExtra({ ...dataExtra, name: val })}
                                className={`flex-grow w-full ${
                                    requiredFields.name ? " border border-red-100" : "border border-transparent"
                                } `}
                            />
                        </div>
                    </div>
                    <div className=" font-bold">{`${t("type-of-extra")}*`}</div>
                    <div className=" mt-2">{`${t("select-type-of-extra")}`}:</div>
                    <div className=" mt-2">
                        <Radiobutton
                            id="single-item"
                            label={t("single-item")}
                            checked={dataExtra.type === SINGLE_TYPE}
                            onChange={() => {
                                setDataExtra({ ...dataExtra, type: SINGLE_TYPE });
                            }}
                        />
                        {dataExtra.type === SINGLE_TYPE ? (
                            <div className=" ml-10">
                                <div className=" mt-2 font-bold">{t("type-of-selection")}</div>
                                <div
                                    data-for="add-extra-modal-tooltip"
                                    data-tip={renderTypeOfSelectionToolTipHtml(SINGLE_TYPE_SELECTION_SEVERAL)}
                                >
                                    <Radiobutton
                                        id={`several-units-${dataExtra.type}`}
                                        className=" mt-2"
                                        label={t("several-units")}
                                        checked={dataExtra.singleTypeSelection === SINGLE_TYPE_SELECTION_SEVERAL}
                                        onChange={() => {
                                            setDataExtra({
                                                ...dataExtra,
                                                singleTypeSelection: SINGLE_TYPE_SELECTION_SEVERAL,
                                            });
                                        }}
                                    />
                                </div>
                                <div
                                    data-for="add-extra-modal-tooltip"
                                    data-tip={renderTypeOfSelectionToolTipHtml(SINGLE_TYPE_SELECTION_SINGLE)}
                                >
                                    <Radiobutton
                                        id={`single-unit-${dataExtra.type}`}
                                        className=" mt-2"
                                        label={t("single-unit")}
                                        checked={dataExtra.singleTypeSelection === SINGLE_TYPE_SELECTION_SINGLE}
                                        onChange={() => {
                                            setDataExtra({
                                                ...dataExtra,
                                                singleTypeSelection: SINGLE_TYPE_SELECTION_SINGLE,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        ) : null}
                        <Radiobutton
                            id="group-items"
                            label={t("group-items")}
                            checked={dataExtra.type === GROUP_TYPE}
                            className=" mt-2"
                            onChange={() => {
                                setDataExtra({ ...dataExtra, type: GROUP_TYPE });
                            }}
                        />
                        {dataExtra.type === GROUP_TYPE ? (
                            <div className=" ml-10">
                                <div className=" mt-2 font-bold">{t("type-of-selection")}</div>
                                <div
                                    data-tip={renderTypeOfSelectionToolTipHtml(GROUP_TYPE_SELECTION_SEVERAL)}
                                    data-for="add-extra-modal-tooltip"
                                >
                                    <Radiobutton
                                        id={`several-units-${dataExtra.type}`}
                                        className=" mt-2"
                                        label={t("several-units")}
                                        checked={dataExtra.groupTypeSelection === GROUP_TYPE_SELECTION_SEVERAL}
                                        onChange={() => {
                                            setDataExtra({
                                                ...dataExtra,
                                                groupTypeSelection: GROUP_TYPE_SELECTION_SEVERAL,
                                            });
                                        }}
                                    />
                                </div>
                                <div
                                    data-for="add-extra-modal-tooltip"
                                    data-tip={renderTypeOfSelectionToolTipHtml(GROUP_TYPE_SELECTION_SINGLE)}
                                >
                                    <Radiobutton
                                        id={`single-unit-${dataExtra.type}`}
                                        className=" mt-2"
                                        label={t("single-unit")}
                                        checked={dataExtra.groupTypeSelection === GROUP_TYPE_SELECTION_SINGLE}
                                        onChange={() => {
                                            setDataExtra({
                                                ...dataExtra,
                                                groupTypeSelection: GROUP_TYPE_SELECTION_SINGLE,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <ReactTooltip
                        id="add-extra-modal-tooltip"
                        dangerousHtml
                        place="bottom"
                        type="light"
                        offset={{ top: -8, left: -8 }}
                        border={true}
                        borderColor="#D3DAE1"
                    />
                </>
            )}
        </Modal>
    );
};

export const useAddExtraModal = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};
