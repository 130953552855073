import React from "react";
import { useTranslation } from "react-i18next";
import { TagApp } from "./TagApp";
import { getOrigin } from "hooks/Utils/Customers/Customers";

export const CustomerDetails = ({ customer, mobileApps, projects }) => {
    const { t } = useTranslation();
    const signupOrigin = getOrigin(customer.project, customer.signupApp, customer.signupWifi, t, mobileApps, projects);

    const birthDate = customer.birthDate ? new Date(customer.birthDate).toLocaleDateString() : null;
    const countryName = customer.country ? t(`country:${customer.country.toLowerCase()}`) : null;
    const phone = customer.phone;
    const gender = customer.gender;
    const isRegistered = customer.registered;

    const getData = () => {
        return [
            [
                {
                    title: t("personal-details"),
                    data: (
                        <p>
                            {t("title-treatment")}: {gender ? t(gender) : "-"} <br />
                            {t("birthdate")}: {birthDate || "-"} <br />
                            {t("mobile-phone")}: {phone || "-"} <br />
                            {t("country")}: {countryName || "-"}
                        </p>
                    ),
                },
            ],
            isRegistered && [
                {
                    title: t("signUp"),
                    data: (
                        <div className="whitespace-nowrap">
                            {signupOrigin ? (
                                <TagApp
                                    key={`tag-${signupOrigin?.name}`}
                                    type={signupOrigin?.type}
                                    name={signupOrigin?.name}
                                    icon={signupOrigin?.icon?.signup}
                                    tooltip={signupOrigin?.tooltip?.signup}
                                />
                            ) : null}
                        </div>
                    ),
                },
            ],
        ];
    };

    const data = getData();

    return (
        <div
            className={`text-sm text-gray-800 leading-6 grid gap-4 grid-cols-${data?.length}`}
            style={{ marginLeft: "5.5rem" }}
        >
            {data.map((row, index) =>
                row ? (
                    <div key={`customer-detail-row-${index}`} className="grid grid-flow-row gap-2">
                        {row.map((col, index) => (
                            <div key={`customer-detail-col-${index}`}>
                                {col?.title ? (
                                    <p>
                                        <strong>{col.title}</strong>
                                    </p>
                                ) : null}
                                {col.data}
                            </div>
                        ))}
                    </div>
                ) : null
            )}
        </div>
    );
};
