import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import ProductSettingsExtrasCardItem from "./Extras/ProductSettingsExtrasCardItem";
import { SalesContextDeprecated } from "contexts/Sales";

function ProductSettingsExtrasCard({ data, disabled }) {
    const { id: shopId, productId } = useParams();
    const { t } = useTranslation();

    const { data: dataContext } = useContext(SalesContextDeprecated);

    const currencyShop = dataContext?.channel?.currencyCode ? dataContext.channel.currencyCode : "";

    return (
        <div className="w-full my-2">
            <div className="flex items-center w-full justify-between mb-5">
                <span className="font-bold text-gray-900 text-1xl">{t("product-extras")}</span>
                {disabled ? null : (
                    <Link id="edit-product-extras" to={`/services/sales/shop/${shopId}/product/${productId}/extras`}>
                        <span className={`icon icon-edit text-1xl`}></span>
                    </Link>
                )}
            </div>
            {disabled ? null : (
                <div className="w-full text-left mb-4">
                    <span className="">{t("edit-extra-options")}</span>
                </div>
            )}
            <div>
                {dataContext?.extras?.map((item, index) => {
                    return (
                        <ProductSettingsExtrasCardItem
                            itemIndex={index}
                            itemData={item}
                            key={item.id}
                            currencyCode={currencyShop}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default ProductSettingsExtrasCard;
