import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import { useAuth } from "hooks/Session/auth";

export const useDesignLinkedContentAlerts = ({ contents = [], projectsInUse = [], mode = "delete" }) => {
    const { t } = useTranslation();
    const { permissions } = useAuth();

    const hasPermissionSignage = permissions?.product?.signage;
    const hasPermissionMaps = permissions?.design?.maps;

    const hasSignageLinked = contents.some((item) => item.linkedSignages.length > 0);
    const hasLinkedBanners = contents.some((item) => item.linkedBanners.length > 0);
    const hasLinkedMaps = contents.some((item) => item.linkedMaps?.length > 0);

    const alertCount = (hasSignageLinked ? 1 : 0) + (projectsInUse.length ? 1 : 0) + (hasLinkedBanners ? 1 : 0);

    const loseNavigationAlert = (
        <>
            {(hasSignageLinked || hasLinkedBanners) && !hasPermissionMaps && hasPermissionSignage && (
                <p className="mt-4">
                    {t("check-promo-images-and-banners-as-they-may-lose-the-navigation-link-to-my-screens")}
                </p>
            )}

            {(hasSignageLinked || hasLinkedBanners || hasLinkedMaps) && hasPermissionMaps && hasPermissionSignage && (
                <p className="mt-4">
                    {t("check-promo-images-and-banners-and-maps-as-they-may-lose-the-navigation-link-to-my-screens")}
                </p>
            )}

            {hasSignageLinked && mode === "apply" && (
                <div className="mt-4 flex gap-2">
                    <Icon type="warning" size="xl" />
                    <p>{t("please-note-that-some-screens-are-linked-to-a-signage-if-the-design-is-apply")}</p>
                </div>
            )}

            {hasSignageLinked && mode === "unapply" && (
                <div className="mt-4 flex gap-2">
                    <Icon type="warning" size="xl" />
                    <p>{t("please-note-that-some-screens-are-linked-to-a-signage-if-the-design-is-unapply")}</p>
                </div>
            )}
        </>
    );

    const alertCountWarning =
        alertCount > 1 ? (
            <p className="mt-4">
                <Icon
                    type="warning"
                    size="lg"
                    text={
                        <div>
                            <p>{t("please-note-that")}:</p>
                            <ul className="ml-2 list-disc">
                                {projectsInUse.length > 0 && (
                                    <li>
                                        {t(
                                            "Please note that this design is applied on some properties. If you delete it, the default design will be applied instead"
                                        )}
                                    </li>
                                )}
                                {hasSignageLinked && (
                                    <li>
                                        {t(
                                            "please-note-that-some-screens-are-linked-to-a-signage-if-the-design-is-deleted"
                                        )}
                                    </li>
                                )}
                            </ul>
                        </div>
                    }
                />
            </p>
        ) : null;

    const singleWarnings = (
        <>
            {projectsInUse.length > 0 && alertCount < 1 ? (
                <p className="mt-4">
                    <Icon
                        type="warning"
                        size="lg"
                        text={t(
                            "Please note that this design is applied on some properties. If you delete it, the default design will be applied instead"
                        )}
                    />
                </p>
            ) : null}
            {hasSignageLinked && alertCount < 1 ? (
                <p className="mt-4">
                    <Icon
                        type="warning"
                        size="lg"
                        text={t("please-note-that-some-screens-are-linked-to-a-signage-if-the-design-is-deleted")}
                    />
                </p>
            ) : null}
        </>
    );

    return {
        loseNavigationAlert,
        alertCountWarning,
        singleWarnings,
    };
};
