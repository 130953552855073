import Button from "components/Button";
import Icon from "components/Icon";
import Select from "components/Select";
import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/Session/auth";
import { sortableContainer, sortableElement, arrayMove } from "react-sortable-hoc";
import DragAndDropMediaCard from "components/DragAndDropMediaCard";
import CustomTimePicker from "components/CustomTimePicker";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { formatSecondsToHMS } from "./utils";
import {
    UseGetDataContents,
    useUpdateSignage,
    useUpdateSignageContentDuration,
    useUpdateSignageContentPosition,
} from "./graphql/useSignages";
import { Session } from "hooks/Utils/Session";
import { useDeleteSignageContent } from "./modals/signages/useDeleteSignageContent";
import { useLoading } from "components/Loading";
import { toast } from "react-toastify";
import Dropdown from "components/Dropdown";
import Search from "components/TextInput/Search";
import { normalizeToSearch } from "hooks/Utils/Utils";
import usePreviewModal from "components/Section/MediaLibrary/modals/usePreviewModal";
import useAddContentReplaceSignage from "./modals/signages/useAddContentReplaceSignage";
import { UseModalContentDetail } from "./modals/useModalContentDetail";
import Loading from "components/Loading";

const SignageContent = ({ data, getData }) => {
    const { t } = useTranslation();
    const { languages: projectLangs } = useAuth();
    const { open: openAddContentReplaceSignage } = useAddContentReplaceSignage();
    const { open: openDeleteContent } = useDeleteSignageContent();
    const { open: openModalPreview } = usePreviewModal();
    const { open: openModalContentDetail } = UseModalContentDetail();
    const [loading, setLoading] = useState(false);

    const [defaultLanguage, setDefaultLanguage] = useState(null);
    const [totalDuration, setTotalDuration] = useState(null);
    const { id } = useParams();
    const defaultDuration = data?.[0]?.contentDefaultFilesDuration;
    const contents = data?.[0]?.contents || null;
    const contentLanguageRef = data?.[0]?.contentLanguageRef || null;
    const name = data?.[0]?.name || null;
    const dessingIdAssigned = data?.[0]?.designIdAssigned || null;

    const {
        getLibraryContentInfo,
        contentsInfo,
        totalDurationContents,
        loading: loadingDataContents,
    } = UseGetDataContents(contents || [], dessingIdAssigned, defaultLanguage);

    const handleCompleted = useCallback(() => {
        getData();
        setLoading(false);
        toast.success(t("operation-successful"));
    }, [getData, setLoading, t]);

    const handleError = useCallback(
        (error) => {
            setLoading(false);
            toast.error(t(error));
        },
        [setLoading, t]
    );

    const { updatePosition } = useUpdateSignageContentPosition({
        onCompleted: handleCompleted,
        onError: handleError,
    });

    const { updateDuration } = useUpdateSignageContentDuration({
        onCompleted: handleCompleted,
        onError: handleError,
    });

    const { update } = useUpdateSignage({
        onCompleted: handleCompleted,
        onError: handleError,
    });

    const [itemsContent, setItemsContent] = useState([]);

    useEffect(() => {
        if (contentLanguageRef) {
            setDefaultLanguage(contentLanguageRef);
        }
    }, [contentLanguageRef]);

    useEffect(() => {
        if (contents?.length > 0) {
            getLibraryContentInfo();
        }
    }, [contents, getLibraryContentInfo]);

    useEffect(() => {
        if (!loadingDataContents) {
            setItemsContent(contentsInfo);
            setTotalDuration(totalDurationContents);
        }
    }, [contentsInfo, defaultLanguage]);

    useEffect(() => {
        if (loadingDataContents) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [loadingDataContents]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const movedItem = itemsContent[oldIndex];

            setLoading(true);

            updatePosition({
                variables: {
                    pos: newIndex + 1,
                    id: movedItem.id,
                    signageID: id,
                },
            });

            const newItemsContent = arrayMove(itemsContent, oldIndex, newIndex);
            setItemsContent(newItemsContent);
        }
    };
    const onChangeDuration = useCallback(
        (newDuration, itemId) => {
            const currentItem = itemsContent.find((item) => item.id === itemId);
            if (currentItem && currentItem.duration !== newDuration) {
                setLoading(true);
                updateDuration({
                    variables: {
                        duration: newDuration,
                        id: itemId,
                        signageID: id,
                    },
                });
            }
        },
        [id, setLoading, updateDuration, itemsContent]
    );

    return (
        <div className="w-full bg-white rounded p-6 mt-8">
            <div className="flex items-center">
                <p className="font-bold text-xl mr-4">{t("playlist-content")}</p>
                <Button
                    onClick={() =>
                        openModalPreview({
                            data: {
                                contents: arrangeItemsContentToPreview(itemsContent),
                                name: name,
                            },
                        })
                    }
                    disabled={contents?.length === 0 || !contents}
                    className={classNames("font-bold", {
                        "text-gray-700": contents?.length === 0 || !contents,
                        " text-zafiro-600": contents?.length > 0,
                    })}
                    id="playlist-content-preview-button"
                >
                    {t("preview")}
                </Button>
            </div>
            <div className="flex justify-between items-center">
                <p className="mr-4">{t("add-and-order-content-for-interface")}</p>
                <Button
                    onClick={() =>
                        openAddContentReplaceSignage({
                            params: {
                                id,
                                defaultDuration: formatSecondsToHMS(defaultDuration),
                                defaultLanguage,
                            },
                            refetch: getData,
                        })
                    }
                    design="blue"
                    id="playlist-content-add-button"
                >
                    {t("add-content")}
                </Button>
            </div>
            {itemsContent.length > 0 ? (
                <div className="w-full flex justify-between items-center pt-4 mt-4 border-t border-gray-400">
                    <p>{`${t("total-duration-of-the-sequence")}: ${totalDuration || ""}`}</p>
                    <div className="w-2/5 flex items-center justify-end space-x-3">
                        <div className=" whitespace-no-wrap">{t("select-a-language")}</div>
                        <DropdownDefaultLanguage
                            value={defaultLanguage}
                            onChange={(ref) => {
                                if (ref !== defaultLanguage) {
                                    setLoading(true);

                                    update({
                                        variables: {
                                            id: id,
                                            contentLanguageRef: ref,
                                        },
                                    });
                                }
                            }}
                            projectLangs={projectLangs}
                        />
                        <Icon tooltip={t("select-the-language-for-content-display")} size={1.5} type="info" />
                    </div>
                </div>
            ) : null}

            <div className=" mt-6">
                {itemsContent?.length > 0 ? (
                    <SortableContainer onSortEnd={onSortEnd} distance={40} helperClass="dragging" axis="xy">
                        {itemsContent.map((item, index) => {
                            return (
                                <SortableItem
                                    key={`item-${item.id}`}
                                    index={index}
                                    item={item}
                                    actions={getItemContentActions({
                                        t,
                                        item,
                                        signageId: id,
                                        refetch: getData,
                                        defaultLanguage,
                                        defaultDuration,
                                        modals: {
                                            openModalDelete: openDeleteContent,
                                            openAddContentReplaceSignage: openAddContentReplaceSignage,
                                            openModalShowDetails: openModalContentDetail,
                                        },
                                        dessingIdAssigned,
                                    })}
                                    indexItem={index}
                                    onChangeDuration={onChangeDuration}
                                />
                            );
                        })}
                    </SortableContainer>
                ) : (
                    <p className="text-gray-700 text-center ">{t("no-content-yet")}</p>
                )}
                {loading && <Loading adjust="absolute" />}
            </div>
        </div>
    );
};

const DropdownDefaultLanguage = ({ projectLangs, value, onChange }) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [defaultLanguage, setIsDefaultLanguage] = useState(null);
    const dropdownRef = useRef();

    const parseProjectLangsToOptions = (projectLangs) => {
        return projectLangs?.map((l) => {
            const isDefaultLanguage = l.isDefault;

            if (isDefaultLanguage) {
                setIsDefaultLanguage(l.languageRef);
            }

            return {
                label: (
                    <div className="flex w-full mb-3">
                        <p className="text-gray-900">{t("language:" + l.languageRef)}</p>
                        {isDefaultLanguage && <div className="text-gray-600 ml-2">({t("default-language")})</div>}
                    </div>
                ),
                searchLabel: normalizeToSearch(t("language:" + l.languageRef)),
                value: l.languageRef,
            };
        });
    };

    useEffect(() => {
        const options = parseProjectLangsToOptions(projectLangs);
        const normalizedSearchValue = normalizeToSearch(searchValue);
        const filtered = options?.filter((option) => option.searchLabel.includes(normalizedSearchValue));

        setFilteredOptions(filtered);
    }, [projectLangs, searchValue]);

    return (
        <Dropdown
            ref={dropdownRef}
            id={`dropdown-default-language`}
            showArrow={true}
            handler={
                <div className="w-full h-8 bg-gray-200">
                    {value
                        ? defaultLanguage === value
                            ? `${t("language:" + value)} (${t("default-language")})`
                            : t("language:" + value)
                        : "Select Language"}
                </div>
            }
            designClass={{
                dropdown: `bg-gray-200 px-3 py-1 flex items-center rounded`,
                handler: `flex items-center`,
            }}
        >
            <div
                style={{ maxHeight: "20rem", overflowY: "scroll" }}
                className="absolute z-10 w-full mt-2 p-4 bg-white border border-gray-300 rounded-md shadow-lg flex flex-col"
            >
                <Search
                    className="mb-2"
                    id={`dropdown-default-language-search`}
                    value={searchValue}
                    onChange={(value) => setSearchValue(value)}
                />
                {filteredOptions?.map((option, index) => (
                    <Button
                        key={index}
                        onClick={() => {
                            onChange(option.value);
                            dropdownRef.current.close();
                        }}
                        id={`option-default-language-${index}`}
                    >
                        {option.label}
                    </Button>
                ))}
            </div>
        </Dropdown>
    );
};

const SortableContainer = sortableContainer(({ children }) => {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(auto-fill, minmax(clamp(10rem, 100%, 10rem), 1fr))`,
                gridTemplateRows: `fit-content(40%)`,
                gridGap: "2.3rem",
                alignItems: "center",
                overflowX: "hidden",
                padding: "0.5rem",
                minHeight: "40rem",
                maxHeight: "40rem",
                overflowY: "scroll",
            }}
        >
            {children}
        </div>
    );
});

const SortableItem = sortableElement((props) => {
    const { item, onChangeDuration } = props;
    const { duration } = item;

    return (
        <div className=" flex flex-col h-full w-full">
            <DragAndDropMediaCard {...props} />
            <div className=" mt-2">
                <CustomTimePicker
                    key={duration}
                    readOnly={item?.type === "VIDEO"}
                    id={`signage-time-duration-${props?.indexItem}`}
                    units={["hours", "minutes", "seconds"]}
                    value={duration}
                    onBlur={(newTime) => {
                        if (newTime) {
                            onChangeDuration(newTime, item.id);
                        } else {
                            onChangeDuration("00:00:00", item.id);
                        }
                    }}
                />
            </div>
        </div>
    );
});

const getItemContentActions = ({ t, item, signageId, refetch, defaultLanguage, modals, dessingIdAssigned }) => {
    const { id: idItem, name, originalDuration, type, ref, isUnlinked } = item;
    const { openModalDelete, openAddContentReplaceSignage, openModalShowDetails } = modals;

    const actions = [
        {
            id: "item-content-replace",
            label: t("replace"),
            onClick: () =>
                openAddContentReplaceSignage({
                    params: {
                        id: signageId,
                        idItem,
                        defaultDuration: originalDuration,
                        defaultLanguage,
                        isReplace: true,
                        name,
                    },
                    refetch,
                }),
            disabled: false,
        },
        {
            id: "item-content-delete",
            label: t("delete"),
            onClick: () => openModalDelete({ name, signageId, id: idItem, refetch }),
            disabled: false,
        },
    ];

    if (!isUnlinked) {
        actions.splice(1, 0, {
            id: "item-content-show-details",
            label: t("show-details"),
            onClick: () => {
                openModalShowDetails({
                    contentRef: ref,
                    item,
                    type,
                    name,
                    contentDefaultLanguage: defaultLanguage,
                    designID: dessingIdAssigned,
                });
            },
            disabled: false,
        });
    }

    return actions;
};

const arrangeItemsContentToPreview = (items) => {
    return items
        .filter((item) => !item.isUnlinked)
        .map((item) => ({
            src: item.type !== "VIDEO" ? item.imgSrc : item.videoSrc,
            type: item.type || null,
            duration: item.type !== "VIDEO" ? item.originalDuration : null,
            name: item.name,
            thumbnail: item.thumbnail,
        }));
};

export default SignageContent;
