import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UseSearch from "../useSearch";
import { useMSQuery } from "../../hooks/GraphqlCalls/useMSQuery";
import { gql } from "apollo-boost";
import { setLoadingModalContent } from "../../actions/uiActions";
import { capitalizeFirst } from "../../hooks/Utils/Utils";

const GET_ASSIGNED_TVS = gql`
    query Rooms($id: Int64!, $isDefault: Boolean!, $type: RoomType!) {
        assignedRooms: rooms(filter: { tvinput: { id: $id, isDefault: $isDefault }, type: $type }) {
            results {
                id
                name
                number
                roomTVs {
                    id
                    tvInputID
                    name
                }
            }
        }
        allRooms: rooms {
            results {
                id
                name
                number
                roomTVs {
                    id
                    tvInputID
                    name
                }
            }
        }
    }
`;

export const UseModalAssignedTvs = ({ index }) => {
    const dispatch = useDispatch();
    const { isDefault, tvsAssigned } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const inputTvId = useSelector((state) => state.ui.modalContent.id);
    const { t } = useTranslation();
    const [tvsFiltered, setTvsFiltered] = useState([]);
    const [allTvs, setAllTvs] = useState([]);
    const [search, setSearch] = useState(null);

    const [getGuestroomTvsQuery, { data: guestroomData, networkStatus: guestroomStatus }] = useMSQuery(
        GET_ASSIGNED_TVS,
        { fetchPolicy: "network-only" }
    );

    // Consulta para GUESTROOM
    useEffect(() => {
        dispatch(setLoadingModalContent(true));
        getGuestroomTvsQuery({
            variables: { id: String(inputTvId), isDefault, type: "GUESTROOM" },
        });
    }, [inputTvId, isDefault, dispatch, getGuestroomTvsQuery]);

    // Actualizar resultados cuando la query tenga datos
    useEffect(() => {
        if (
            guestroomData &&
            guestroomData.assignedRooms &&
            guestroomData.assignedRooms.results &&
            guestroomStatus === 7
        ) {
            const rooms = guestroomData.assignedRooms.results;
            setAllTvs(rooms);
            setTvsFiltered(rooms);
            dispatch(setLoadingModalContent(false));
        }
    }, [guestroomData, guestroomStatus, dispatch]);

    useEffect(() => {
        if (search) {
            setTvsFiltered(
                allTvs.filter(
                    (tv) => tv.name.toLowerCase().includes(search.toLowerCase()) || String(tv.number).includes(search)
                )
            );
        } else {
            setTvsFiltered(allTvs);
        }
    }, [search, allTvs]);

    const inputSearchChange = (change) => {
        if (change.target.value) {
            setSearch(change.target.value);
        } else {
            setSearch(null);
        }
    };

    const printRoomTv = (room, index) => {
        return room.roomTVs.map((roomTV) => {
            if (
                (isDefault && (roomTV.tvInputID === inputTvId || roomTV.tvInputID === 0)) ||
                (!isDefault && roomTV.tvInputID === inputTvId)
            ) {
                return (
                    <div
                        key={index}
                        className="mx-2 inline-flex border-b border-gray-200 py-2"
                        id={`recipient_${index}`}
                    >
                        <div className="w-3/6 pr-2 my-auto">
                            <div className="py-1 inline-block cursor-default" style={{ maxWidth: "100%" }}>
                                {room.name}
                            </div>
                            <div className="text-gray-300">{room.number}</div>
                        </div>
                        <div className="w-3/6 pr-2 my-auto">
                            <div
                                className="rounded py-1 px-4 m-1 inline-block text-white bg-blue-300 truncate cursor-default"
                                style={{ maxWidth: "100%" }}
                            >
                                {roomTV.name}
                            </div>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            {tvsFiltered && tvsFiltered.length > 0 ? (
                <>
                    <div className="flex">
                        <div className="w-3/6">
                            <UseSearch
                                handleChangeSearch={inputSearchChange}
                                placeholder={capitalizeFirst(t("search"))}
                            />
                        </div>
                        <div className="w-3/6 pt-2 text-right">
                            {tvsAssigned || 0} {t("assigned-tvs")}
                        </div>
                    </div>
                    <div
                        id="tvs_show-header"
                        className="grid grid-cols-2 grid-flow-row overflow-y-auto mt-4 rounded border-b border-gray-200 font-bold text-lg"
                    >
                        <div className="pl-2">{capitalizeFirst(t("room"))}</div>
                        <div className="pl-2">{capitalizeFirst(t("locations"))}</div>
                    </div>
                    <div
                        id="tvs_show"
                        className="grid grid-cols-1 grid-flow-row overflow-y-auto rounded"
                        style={{ height: "50vh", gridAutoRows: "min-content" }}
                    >
                        {tvsFiltered.map((tv, index) => printRoomTv(tv, index))}
                    </div>
                </>
            ) : null}
        </>
    );
};
