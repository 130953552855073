import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Header } from "sections/playground";

import Button from "components/Button";

const Toast = () => {
    const { t } = useTranslation();

    const success = () => toast.success(t("operation-successful"));
    const error = () => toast.error(t("mutation-error"));
    const warning = () => toast.warning("Warning message example");
    const info = () =>
        toast.info(
            <>
                <div>
                    <strong>Informational notification</strong>
                </div>
                <p className="mt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eleifend eros non dolor.
                </p>
            </>,
            { autoClose: 10000, className: "custom" }
        );

    return (
        <>
            <Header title="Toasts">
                The Toasts will be used to provide feedback to the user about the status of an action.
            </Header>
            <div className="p-10 grid grid-cols-2 gap-5 items-start">
                <div className="bg-yellow-200 p-5 rounded-lg shadow text-gray-900">
                    <h2 className="font-bold text-xl mb-5">Normas de uso</h2>
                    <ul className="flex flex-col items-start gap-3 list-outside list-disc ml-5">
                        <li>
                            Mostrar toast solo cuando existe un botón de confirmación o aquellas acciones directas
                            asociadas a un elemento.
                            <br />
                            No mostrarlo cuando se realiza una actualización automática en back tras cambiar un valor de
                            un input (excepto en caso de error).
                        </li>
                        <li>
                            Los mensajes predeterminados son:
                            <br />
                            <dl className="grid grid-cols-2 gap-2 p-2" style={{ gridTemplateColumns: "auto 1fr" }}>
                                <dt className="font-bold text-sm leading-7 text-right">Success</dt>
                                <dd className="italic">{t("operation-successful")}</dd>
                                <dt className="font-bold text-sm leading-7 text-right">Error</dt>
                                <dd className="italic">{t("mutation-error")}</dd>
                            </dl>
                        </li>
                        <li>
                            Ejemplo de mensajes personalizados:
                            <br />
                            <dl className="grid grid-cols-2 gap-2 p-2" style={{ gridTemplateColumns: "auto 1fr" }}>
                                <dt className="font-bold text-sm leading-7 text-right">Required</dt>
                                <dd className="italic">{t("errors-in-red")}</dd>
                                <dt className="font-bold text-sm leading-7 text-right">Login failed</dt>
                                <dd className="italic">{t("invalid email or password")}</dd>
                            </dl>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col gap-3">
                    <Button id="toast-success" onClick={success} design="success">
                        <div className="p-5">Success notification</div>
                    </Button>
                    <Button id="toast-error" onClick={error} design="error">
                        <div className="p-5">Error notification</div>
                    </Button>
                    <Button id="toast-info" onClick={info} design="info">
                        <div className="p-5">Informational notification</div>
                    </Button>
                    <Button id="toast-warning" onClick={warning} design="warning">
                        <div className="p-5">Warning notification</div>
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Toast;
