export const getFirstAvailablePosition = ({ existingItems, newItemWidth, newItemHeight, totalCols, totalRows }) => {
    const candidatePositions = [];
    for (let row = 0; row <= totalRows - newItemHeight; row++) {
        for (let col = 0; col <= totalCols - newItemWidth; col++) {
            candidatePositions.push({ x: col, y: row });
        }
    }

    const centerX = totalCols / 2;
    const centerY = totalRows / 2;

    candidatePositions.sort((a, b) => {
        const distA = Math.hypot(a.x + newItemWidth / 2 - centerX, a.y + newItemHeight / 2 - centerY);
        const distB = Math.hypot(b.x + newItemWidth / 2 - centerX, b.y + newItemHeight / 2 - centerY);
        return distA - distB;
    });

    for (const candidate of candidatePositions) {
        const isOccupied = existingItems.some((item) =>
            isOverlapping(
                { x1: candidate.x, y1: candidate.y, width1: newItemWidth, height1: newItemHeight },
                { x2: item.x, y2: item.y, width2: item.w, height2: item.h }
            )
        );
        if (!isOccupied) {
            return candidate;
        }
    }
    return null;
};

export const isOverlapping = ({ x1, y1, width1, height1 }, { x2, y2, width2, height2 }) => {
    return x1 < x2 + width2 && x1 + width1 > x2 && y1 < y2 + height2 && y1 + height1 > y2;
};
