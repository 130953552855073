import React, { useEffect } from "react";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { gql } from "apollo-boost";
import { Session } from "hooks/Utils/Session";
import { cancelEdit, UPDATE_SETTINGS } from "../utils";
import { useLoading } from "components/Loading";
import { useTranslation } from "react-i18next";

const UpdateSettings = ({ EXECUTE, SET_EXECUTE, setCardInEdition, refetch }) => {
    const { t } = useTranslation();

    const { setLoading } = useLoading();

    const projectID = Session.getProject()?.id || null;

    const RESET_EXECUTE = () => {
        SET_EXECUTE({
            ACTION: "",
            PARAMS: {},
        });
        setLoading(false);
        cancelEdit(setCardInEdition);
        refetch();
    };

    const [executeMutation, { error }] = useMutation(gql`
        mutation UpdateFeedbackSetting(
            $projectID: Int64!
            $sendEmail: Boolean
            $satisfactionQuestion: [TranslateString]
            $minLevelSendEmail: Int
            $feedbackEmails: [String]
            $userIds: [Int64]
        ) {
            updateFeedbackSetting(
                projectID: $projectID
                sendEmail: $sendEmail
                satisfactionQuestion: $satisfactionQuestion
                minLevelSendEmail: $minLevelSendEmail
                feedbackEmails: $feedbackEmails
                userIDs: $userIds
            ) {
                error
                ok
            }
        }
    `);

    const performMutation = (variables) => {
        executeMutation({ variables })
            .then((response) => {
                if (response.data.updateFeedbackSetting.ok) {
                    toast.success(t("operation-successful"));
                    RESET_EXECUTE();
                } else {
                    toast.error(response.data.updateFeedbackSetting.error);
                    RESET_EXECUTE();
                }
            })
            .catch((err) => {
                toast.error(err.message);
                RESET_EXECUTE();
            });
    };

    useEffect(() => {
        if (!EXECUTE || !projectID) return;

        const actionToVariables = {
            [UPDATE_SETTINGS.EMAIL]: { sendEmail: EXECUTE.PARAMS.EMAIL },
            [UPDATE_SETTINGS.SATISFACTION_QUESTION]: { satisfactionQuestion: EXECUTE.PARAMS.TRANSLATIONS },
            [UPDATE_SETTINGS.SATISFACTION_LEVEL]: { minLevelSendEmail: EXECUTE.PARAMS.SATISFACTION_LEVEL_SELECTED },
        };

        const variables = actionToVariables[EXECUTE.ACTION];
        if (variables) {
            setLoading(true);
            performMutation({ projectID, ...variables });
        }
    }, [EXECUTE, projectID]);

    return null;
};

export default UpdateSettings;
