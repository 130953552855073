import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Table from "components/ZafiroTable";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";

const ModalLinkedMaps = ({ name, close, linkedMaps }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const footer = (
        <>
            <Button design="blue" id="modal-button-close" onClick={close} disabled={loading}>
                {t("close")}
            </Button>
        </>
    );

    return (
        <Modal title={`${name} - ${t("linked-maps")}`} footer={footer} className="w-5/12 p-10">
            {loading ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-2">
                    <p>{t(`the-map-has-the-following-maps-linked`, { name })}:</p>
                    <div className=" flex justify-between">
                        <p className=" font-bold">{t("maps")}</p>
                        <p>{t("mapcount", { count: linkedMaps.length })}</p>
                    </div>
                    <Table
                        id={`linked-maps-table`}
                        cols={["name"]}
                        className="border border-gray-100 p-2"
                        maxHeight="max-h-30"
                        header={{ name: { title: "" } }}
                        rows={linkedMaps.map((map) => ({ name: map.name.replace(/^"(.*)"$/, "$1") }))}
                    />
                </div>
            )}
        </Modal>
    );
};

export const useModalLinkedMaps = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            open(<ModalLinkedMaps {...props} close={close} />);
        },
        close,
    };
};
