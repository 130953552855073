import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setAvailableFilters,
    setCount,
    setCountPosition,
    setError,
    setExtendedInfo,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setRefreshData,
    setActions,
    setBatchActive,
    setCustomConfig,
    setCheckable,
    setFolder,
    setHeaderButtons,
    setTableType,
    cleanSortCriteria,
    setInformativeMessage,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCustomersTableHeaders";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
//Model
import Message from "./models/Message";
import { useTranslation } from "react-i18next";
import { useMSQuery } from "../useMSQuery";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { showGlobalLoading } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";
import { SENT_MSG, DRAFTS_MSG, TEMPLATES_MSG, BIN_MSG, SCHEDULED_MSG } from "../../Utils/Customers/MessagesUtils";
import { destinationData, POLLINTERVAL_15S } from "../../Utils/Utils";
import { UseModalDeleteScheduleMessages } from "components/Section/Customers/Message/modals/useModalDeleteScheduleMessage";
import { UseModalSendNowScheduleMessages } from "components/Section/Customers/Message/modals/useModalSendNow";

const QUERY_BODY = {
    sent: `${destinationData("messages")} priority sentTime expirationTime information{ orderID }`,
    drafts: `${destinationData("messages")} priority`,
    scheduled: `${destinationData(
        "messages"
    )} priority scheduleType scheduleDate scheduleDaysOfWeek scheduleTime expirationTime`,
    templates: "body",
    bin: `${destinationData("messages")} deletedTime priority`,
};

const ListMessages = () => {
    const { t } = useTranslation();
    //Store data
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData, folder, searchString } = useSelector(
        (state) => state.table
    );
    const { permissions } = useSelector((state) => state.ui);

    //Data
    const sectionName = `list-messages-${folder ? folder.name : ""}`;
    let folders = permissions?.guests?.messageSendLater
        ? [SENT_MSG, SCHEDULED_MSG, DRAFTS_MSG, TEMPLATES_MSG, BIN_MSG]
        : [SENT_MSG, DRAFTS_MSG, TEMPLATES_MSG, BIN_MSG];
    //States
    const [sorting] = useState(`,orderBy:{field: "id", criteria: "desc"}`);
    const [queryBody, setQueryBody] = useState(QUERY_BODY.sent);
    const defaultFilter = ",filter:{state : SENT}";
    //Actions
    const dispatch = useDispatch();
    const { open: openModalDeleteSchedule } = UseModalDeleteScheduleMessages();
    const { open: openModalSendNow } = UseModalSendNowScheduleMessages();

    //API
    const MESSAGES_QUERY = ` {
        messages(page: ${currentPage}, size: ${perPage}${sortCriteria ? sortCriteria : sorting}${
        folder && folder.key !== SENT_MSG.key ? defaultFilter : filterQuery
    }){
         info { count }
         results { id title state ${queryBody} recipients{
                    isRead
                    reason{all roomIDs roomGroupIDs wingIDs floorIDs countryRefs languageRefs stayGuestIDs stayGroupIDs}
                    checkedout
                    roomId
                    roomName
                    stayGuestName
                    stayGuestSurname
                }
            }
        }
        occupiedRoomsInfo {
            name
            id
        }
        guests(filter: {checkedOut: true}, orderBy: {field: "id", criteria: "ASC"} includeCheckoutRooms:true) {
            results {
                id
                rooms{
                    name
                }
            }
        }
        drafts: messages (filter:{state:DRAFT}){
            info { count}
        }
        scheduled: messages(filter:{state:SCHEDULED}){
            info { count}
        }
     }`;
    const TEMPLATES_QUERY = ` {
        messages: templateMessages(page: ${currentPage}, size: ${perPage}${sortCriteria ? sortCriteria : sorting}
            ${searchString ? `filter:{search:"${searchString}"}` : ""}
            ){
            info { count }
            results { id title body createdTime }
        }

        drafts: messages (filter:{state:DRAFT}){
            info { count}
        }
        scheduled: messages(filter:{state:SCHEDULED}){
            info { count}
        }
     }`;

    const GET_MESSAGES = gql`
        ${folder && folder.name === TEMPLATES_MSG.name ? TEMPLATES_QUERY : MESSAGES_QUERY}
    `;

    const thereAreNoMessages = t("No messages available");

    const thereAreNoTemplates = t("No templates available");

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_MESSAGES, {
        // fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        if (!folder || folder.key !== SENT_MSG.key) {
            dispatch(setFolder(folders[0]));
        }
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
            dispatch(cleanActiveFilters());
            dispatch(cleanAction());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if ((refreshData || folder) && refetch) {
            dispatch(setLoading(true));
            dispatch(cleanSortCriteria());
            setQueryBody(QUERY_BODY[folder.name]);
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData, folder]);

    useEffect(() => {
        //SET DEFAULT MESSAGES FOLDER IF NO FOLDER OR FOLDER NOT FROM MESSAGES
        if (!folder || folder.key !== SENT_MSG.key) {
            dispatch(setFolder(folders[0]));
        }

        if (folder?.name == SENT_MSG.name) {
            dispatch(setInformativeMessage("after-7-days-expired-messages-wiil-be-moved-to-the-bin"));
        } else {
            dispatch(setInformativeMessage(""));
        }
        // eslint-disable-next-line
    }, [folder]);

    useEffect(() => {
        // remove loading when query is ready
        if (!loading && !error && data && networkStatus === 7) {
            dispatch(setRefreshData(false));
        }
        // compared table-results and query-results to only refresh with new data
        if (!loading && !error && data && data.messages && networkStatus === 7) {
            //Get data
            const dataItems = arrangeData(data, folder);
            const tableHeaders = GetTableHeaders(sectionName);
            const availableFilters = GetAvailableFilters(sectionName);
            //Add to store
            dispatch(setLoading(true));
            dispatch(setSearchable(true));
            dispatch(setTableType("list-messages"));
            dispatch(setHeaders(tableHeaders));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.messages.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setCheckable(folder && folder.name !== BIN_MSG.name ? true : false));
            dispatch(
                setBatchActive(
                    folder.name === SCHEDULED_MSG.name
                        ? BatchActionsSchedule({ openModalDeleteSchedule, openModalSendNow })
                        : folder.name !== BIN_MSG.name
                        ? BatchActions(folder.name === TEMPLATES_MSG.name ? true : false)
                        : false
                )
            );
            dispatch(setExtendedInfo(false));
            dispatch(showRowForm(false));
            dispatch(setActions());
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }

            //update drafs number
            folders.forEach((folder) =>
                folder.name === DRAFTS_MSG.name
                    ? (folder.total = data.drafts.info.count)
                    : folder.name === SCHEDULED_MSG.name
                    ? (folder.total = data.scheduled.info.count)
                    : null
            );
            dispatch(
                setCustomConfig({
                    searchStyle: "col-span-3",
                    filtersStyle: "col-span-9",
                    batchStyle: "w-3/12",
                    folders: folders,
                })
            );
            dispatch(
                setCustomError(
                    folder && folder.name && folder.name === "templates" ? thereAreNoTemplates : thereAreNoMessages
                )
            );
            dispatch(setLoading(false));
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);

    const arrangeData = (data, folder) => {
        const messagesData = [];
        // eslint-disable-next-line
        data.messages.results.map((message) => {
            messagesData.push(Message(message, t, folder, data?.occupiedRoomsInfo, data?.guests?.results));
        });
        return messagesData;
    };
};

export default ListMessages;

const BatchActions = (inTemplates) => {
    const actions = [
        {
            name: "delete",
            action: inTemplates ? "delete-template" : "delete-message",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: inTemplates ? "delete-templates-confirm" : "delete-msgs-confirm",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "delete", style: "red", action: inTemplates ? "delete-template" : "delete-message" },
            ],
        },
    ];
    return actions;
};

const BatchActionsSchedule = ({ openModalDeleteSchedule, openModalSendNow }) => {
    return [
        {
            name: "send-now-schedule-message",
            customModal: ({ checkedItems }) => {
                openModalSendNow({ ids: checkedItems, batch: true });
            },
        },
        {
            name: "delete-schedule",
            customModal: ({ checkedItems }) => {
                openModalDeleteSchedule({ ids: checkedItems, batch: true });
            },
        },
    ];
};
