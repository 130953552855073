import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { toast } from "react-toastify";
import UseSectionHeader from "components/useSectionHeader";
import SettingsCard from "components/SettingsCard";
import { useDesign, useUpdateDesign } from "hooks/Data/useDesigns";
import { useAuth } from "hooks/Session/auth";

import TextInput from "components/TextInput";
import Button from "components/Button";
import Icon from "components/Icon";
import Select from "components/Select";
import Table from "components/ZafiroTable";
import { useModal } from "components/Modal";
import useThemes from "hooks/Data/useThemes";
import Loading from "components/Loading";
import { THEME_TYPE } from "constants/design";
import ProjectName from "components/ProjectName";
import MakeDefault from "sections/design/modals/makeDefault";
import ManageProjects from "sections/design/modals/manageProjects";
import { useFlattenProjectsDestinations } from "hooks/Utils/DesignUtils";

const EditDesign = ({ id }) => {
    const { t } = useTranslation();

    const design = useDesign(id, {
        onError: () => toast.error(t("mutation-error")),
    });

    useEffect(() => {
        if (id) {
            design.load();
        }
    }, [id]);

    return (
        <>
            <UseSectionHeader title={design?.data?.name} navToPreviousPage={true} />
            {!design?.ready ? (
                <Loading />
            ) : (
                <div className="grid grid-cols-2 gap-8 mb-5">
                    <NameCard design={design?.data} refetch={design?.refetch} />
                    <ThemeCard design={design?.data} />
                    <DesignProjects design={design?.data} refetch={design?.refetch} />
                </div>
            )}
        </>
    );
};

const NameCard = ({ design, refetch }) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);

    const [editing, setEditing] = useState(false);

    const update = useUpdateDesign(design?.id, {
        onSuccess: () => {
            toast.success(t("operation-successful"));
            design.name = inputRef.current.getValue();
            setEditing(false);
            refetch();
        },
        onError: () => toast.error(t("mutation-error")),
    });

    const toggleEditing = () => setEditing(!editing);

    const save = () => {
        if (!inputRef.current) {
            return;
        }
        update.post({
            name: inputRef.current.getValue(),
            themeID: design.themeID, // because is required
            id: design.id,
        });
    };

    return (
        <SettingsCard
            title={t("name")}
            description={
                editing ? (
                    t("Write the name of the design")
                ) : (
                    <div
                        className={classNames({
                            "py-3": true,
                            "font-bold": !!design?.name,
                        })}
                    >
                        {design?.name || <Icon type="warning" text={t("unknown")} />}
                    </div>
                )
            }
            edit={!editing ? { onClick: toggleEditing } : null}
        >
            {editing ? (
                <>
                    <TextInput ref={inputRef} value={design?.name} />
                    <div className="flex justify-end gap-4 mt-6">
                        <Button id="cancel" className="font-bold" design="white" onClick={toggleEditing}>
                            {t("cancel")}
                        </Button>
                        <Button id="save" design="blue" onClick={save}>
                            {t("save")}
                        </Button>
                    </div>
                </>
            ) : null}
        </SettingsCard>
    );
};

const ThemeCard = ({ design }) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);

    const themes = useThemes();

    const [editing, setEditing] = useState(false);

    const update = useUpdateDesign(design?.id, {
        onSuccess: () => {
            toast.success(t("operation-successful"));
            design.themeID = inputRef.current.getValue();
            setEditing(false);
        },
        onError: () => toast.error(t("mutation-error")),
    });

    const themeName = themes?.data?.length ? themes?.data.find((t) => t?.id === design?.themeID)?.name : null;

    const toggleEditing = () => setEditing(!editing);

    const save = () => {
        if (!inputRef.current) {
            return;
        }
        update.post({
            themeID: inputRef.current.getValue(),
            name: design.name, // because is required
            id: design.id,
        });
    };

    useEffect(() => {
        themes.load({ filter: { themeType: THEME_TYPE.DESIGN } });
    }, []);

    return (
        <SettingsCard
            title={t("default appearance")}
            loading={!themes?.ready && !themes?.error}
            error={themes?.error}
            description={
                editing ? (
                    t("default appearance instructions")
                ) : (
                    <div
                        className={classNames({
                            "py-3": true,
                            "font-bold": !!themeName,
                        })}
                    >
                        {themeName || (
                            <Icon type="warning" text={design?.themeID ? `ID ${design.themeID}` : t("unknown")} />
                        )}
                    </div>
                )
            }
            edit={!editing ? { onClick: toggleEditing } : null}
        >
            {editing ? (
                <>
                    <Select
                        ref={inputRef}
                        id="defaultAppearance"
                        options={themes?.data?.length ? themes?.data.map((t) => ({ label: t.name, value: t.id })) : []}
                        allowUnselect={false}
                        value={design?.themeID}
                    />
                    <div className="flex justify-end gap-4 mt-6">
                        <Button id="cancel" className="font-bold" design="white" onClick={toggleEditing}>
                            {t("cancel")}
                        </Button>
                        <Button id="save" design="blue" onClick={save}>
                            {t("save")}
                        </Button>
                    </div>
                </>
            ) : null}
        </SettingsCard>
    );
};

const DesignProjects = ({ design, refetch }) => {
    const { t } = useTranslation();
    const flattenDestinations = useFlattenProjectsDestinations();
    const { open } = useModal();
    const { allProjects } = useAuth();

    // This condition is to check if current user has access to all projects
    const manageAll = !allProjects?.some((p) => p.disabled);
    const batchActions = [
        {
            id: "make-default",
            label: t("make-default"),
            value: "make-default",
        },
    ];
    const projects = flattenDestinations(design?.visibilityScope);
    const projectsFromVisibilityScope = projects
        ?.map((p) => {
            let projects;
            const ref = p?.ref;
            if (ref?.includes("brand-")) {
                projects = p?.projects;
            }
            if (ref?.includes("project-")) {
                projects = p;
            }
            return projects;
        })
        .flat();

    const projectsToList = projectsFromVisibilityScope?.reduce((acc, p) => {
        if (acc.some((project) => project?.ref === p?.ref)) {
            return acc;
        }
        const projectInUse = design?.projectsInUse?.find((project) => project?.projectRef === p?.ref);
        if (projectInUse) {
            acc.push(projectInUse);
        } else {
            acc.push(p);
        }
        return acc;
    }, []);

    const getProjectActions = (project) => [
        {
            id: "make-default",
            label: t("make-default"),
            disabled: !project?.hasChainModule,
            onClick: () => open(<MakeDefault design={design} project={project} refetch={refetch} />),
        },
    ];

    const cols = ["properties", "status", "default"];

    const rows = projectsToList?.length
        ? projectsToList.map((project) => {
              const hasChainModule = project?.hasChainModule;
              const isDefaultDesign = project?.defaultDesignID === design?.id;
              const isAssigned = project?.designID === design?.id;

              const name = project?.projectName || project?.name;
              const id = project?.projectID || project?.id;
              const ref = project?.projectRef || project?.ref;
              const assignText =
                  isAssigned === undefined ? t("not-applied") : isAssigned ? t("applied") : t("not-applied");
              return {
                  ...project,
                  isAssigned,
                  properties: (
                      <ProjectName
                          name={name || <Icon type="warning" text={t("unknown")} />}
                          hasChainModule={hasChainModule}
                      />
                  ),
                  status: (
                      <span className={isAssigned === undefined ? null : isAssigned && "text-green-100"}>
                          {assignText}
                      </span>
                  ),
                  default: isDefaultDesign ? <div className="text-gray-600">{t("default-design")}</div> : null,
                  rowConfig: {
                      actions: getProjectActions({ id, ref, name, isAssigned, hasChainModule }),
                      batchActions: hasChainModule,
                  },
              };
          })
        : [];

    return (
        <SettingsCard className="bg-white col-span-2 ">
            <div className="pt-5">
                <Table
                    id="manage-properties"
                    title={t("properties")}
                    description={t("Manage the properties that will have access to the design in order to apply it")}
                    paginate={"lazy"}
                    cols={cols}
                    topRightCorner={
                        <div className="flex gap-4 items-end h-full">
                            <Button
                                id="make-all-default"
                                design="white"
                                disabled={!manageAll || !rows?.length}
                                className="font-semibold text-gray-700"
                                onClick={() => {
                                    open(
                                        <MakeDefault
                                            design={design}
                                            applyAll={true}
                                            refetch={refetch}
                                            projects={projectsToList}
                                        />
                                    );
                                }}
                            >
                                {t("make-all-default")}
                            </Button>
                            <Button
                                id="manage-properties"
                                design="blue"
                                onClick={() => open(<ManageProjects design={design} refetch={refetch} />)}
                            >
                                {t("Manage properties")}
                            </Button>
                        </div>
                    }
                    search={true}
                    showCount={true}
                    header={{
                        properties: { title: t("properties"), sortable: true, width: "50%" },
                        status: { title: t("status"), sortable: true, width: "25%" },
                        actions: { title: "" },
                    }}
                    rows={rows}
                    filters={[
                        {
                            id: "status",
                            title: t("status"),
                            allowUnselect: false,
                            options: [
                                { id: "all", label: `-- ${t("all")} --`, value: null },
                                { id: "not-applied", label: t("not-applied"), value: "not-applied" },
                                { id: "applied", label: t("applied"), value: "applied" },
                            ],
                            onFilter: (value, row) => {
                                if (value === "not-applied") {
                                    return !row?.isAssigned ? row : null;
                                } else if (value === "applied") {
                                    return row?.isAssigned ? row : null;
                                }
                                return row;
                            },
                        },
                    ]}
                    batchActions={{
                        options: batchActions,
                        onChange: (action, rows) => {
                            console.log(rows);
                            if (action === "make-default") {
                                open(
                                    <MakeDefault
                                        design={design}
                                        projectRefs={rows?.map((r) => r?.projectRef)}
                                        refetch={refetch}
                                    />
                                );
                            }
                        },
                    }}
                />
            </div>
        </SettingsCard>
    );
};

export default EditDesign;
