import { gql } from "apollo-boost";

export const ADD_BANNER = gql`
    mutation createBanner($name: String!) {
        createBanner(name: $name) {
            error
            id
            ok
        }
    }
`;

export const DELETE_BANNERS = gql`
    mutation deleteBanners($ids: [Int64]!) {
        deleteBanners(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_CONTENT_BANNER = gql`
    mutation updateBanner($id: Int64!, $contentImage: JSON) {
        updateBanner(id: $id, contentImage: $contentImage) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_BANNER = gql`
    mutation updateBanner($id: Int64!, $name: String, $destination: destinationSET) {
        updateBanner(id: $id, name: $name, destination: $destination) {
            error
            id
            ok
        }
    }
`;

export const SET_BANNER_AS_AVAILABLE = gql`
    mutation setBannersAsAvailable($ids: [Int64]!) {
        setBannersAsAvailable(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const SET_BANNER_AS_UNAVAILABLE = gql`
    mutation setBannersAsUnavailable($ids: [Int64]!) {
        setBannersAsUnavailable(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const DUPLICATE_BANNER = gql`
    mutation duplicateBanners($ids: [Int64]!) {
        duplicateBanners(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_SCHEDULE_BANNER = gql`
    mutation updateBanner(
        $id: Int64!
        $scheduleStart: Date
        $scheduleEnd: NullableDate
        $timeRanges: [BannerTimeRangeSET]
        $scheduleObtrusiveMode: Boolean
        $scheduleFrequencyLimited: Boolean
        $scheduleDaysOfWeek: [Int]
        $scheduleFrequencyDisplay: String
        $scheduleDaysType: BannerScheduleDaysType
        $scheduleDuration: String
    ) {
        updateBanner(
            id: $id
            scheduleStart: $scheduleStart
            scheduleEnd: $scheduleEnd
            timeRanges: $timeRanges
            scheduleObtrusiveMode: $scheduleObtrusiveMode
            scheduleFrequencyLimited: $scheduleFrequencyLimited
            scheduleDaysOfWeek: $scheduleDaysOfWeek
            scheduleFrequencyDisplay: $scheduleFrequencyDisplay
            scheduleDaysType: $scheduleDaysType
            scheduleDuration: $scheduleDuration
        ) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_POSITION_SIZE_BANNER = gql`
    mutation updateBanner($id: Int64!, $displayPosition: BannerDisplayPosition, $displaySize: BannerDisplaySize) {
        updateBanner(id: $id, displayPosition: $displayPosition, displaySize: $displaySize) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_DESTINATION_BANNER = gql`
    mutation updateBanner($id: Int64!, $contentAction: JSON) {
        updateBanner(id: $id, contentAction: $contentAction) {
            error
            id
            ok
        }
    }
`;
