import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Loading from "components/Loading";
import Select from "components/Select";
import { useUpdateCategories } from "../../graphql/useCategories";

const ModalEditCategory = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [pointsOfInterest, setPointsOfInterest] = useState([]);
    const [selected, setSelected] = useState([]);

    const { update: updateCategory } = useUpdateCategories({
        onCompleted: () => {
            setLoading(false);
            props.fetch();
            props.close();
        },
    });

    const handleSaveClick = () => {
        setLoading(true);
        updateCategory({ variables: { id: props.id, mapId: props.mapId, interestPointIDs: selected } });
    };

    const handleCloseClick = () => {
        if (typeof props.close === "function") {
            props.close();
        }
    };

    useEffect(() => {
        setPointsOfInterest(
            props.interestPoints.map((val) => ({ value: val.id, label: val.name.replace(/^"(.*)"$/, "$1") }))
        );
        setSelected(props.selected.map((val) => val.mapInterestPointID));
    }, []);

    return (
        <Modal
            title={`${props.name} - ${t("edit-category")}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-save" disabled={loading} onClick={handleSaveClick}>
                        {t("save")}
                    </Button>
                </>
            }
            className="w-5/12 p-6"
        >
            {loading ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-2">
                    <p>{t("add-the-points-of-interest")}</p>
                    <p className=" text-sm font-bold text-gray-800">{t("points-of-interest")}</p>
                    <Select
                        id={"select-points-of-interest"}
                        search={true}
                        options={pointsOfInterest}
                        value={selected}
                        multiple={true}
                        onChange={(ids) => {
                            setSelected(ids || []);
                        }}
                    ></Select>
                </div>
            )}
        </Modal>
    );
};

export const useModalEditCategory = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalEditCategory {...newProps} />);
        },
    };
};
