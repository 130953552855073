import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Icon from "components/Icon";
import Button from "components/Button";
import moment from "moment";
import { getDaysNamesByValues, getTimesRangesFormatted, isSchedulling } from "./utils";
import classNames from "classnames";
import { toast } from "react-toastify";
import { BANNER_TYPE, SIGNAGE_TYPE, TICKER_TAPE_TYPE } from "./constants";

const ScheduleCard = ({ data, loading, getData, title, subtitle, tooltip, display, type, openModal }) => {
    const { t } = useTranslation();
    const { id } = useParams();

    const {
        scheduleDaysOfWeek = [],
        scheduleDailyType,
        scheduleDaysType,
        scheduleEnd,
        scheduleStart,
        timeRanges,
        isDefault,
        scheduleFrequencyDisplay,
        scheduleFrequencyLimited,
        scheduleObtrusiveMode,
        scheduleFrequencyRepetitions,
        scheduleDuration,
    } = data?.[0] || {};

    const formatScheduleDuration = (duration) => {
        if (duration === null || typeof duration !== "string") {
            return null;
        }

        if (duration.length >= 5) {
            return duration.substr(0, 5);
        }

        return duration;
    };

    return (
        <div className=" w-full bg-white  p-4 rounded">
            <div className=" flex justify-between items-center mb-5">
                <div className=" font-bold text-2xl">{t(title)}</div>
                <div className=" ">
                    <Button
                        disabled={loading || isDefault}
                        id={`${type}-details-open-modal-schedule`}
                        onClick={() => {
                            openModal({
                                data: {
                                    scheduleDaysOfWeek,
                                    scheduleDailyType,
                                    scheduleDaysType,
                                    scheduleEnd,
                                    scheduleStart,
                                    id,
                                    timeRanges,
                                },
                                onCompleted: () => {
                                    getData();
                                    toast.success(t("operation-successful"));
                                },
                            });
                        }}
                    >
                        <Icon
                            tooltip={isDefault ? t(tooltip) : null}
                            className={classNames({ "text-gray-700": isDefault, "text-gray-800": !isDefault })}
                            size={1.2}
                            type="edit"
                        />
                    </Button>
                </div>
            </div>
            <div>
                <div className={classNames({ "text-gray-700": isDefault })}>{t(subtitle)}</div>
                {isSchedulling(data?.[0]) ? (
                    <>
                        <div className=" inline-flex mb-5 mt-5">
                            {scheduleStart ? (
                                <div>
                                    <strong className=" mr-1">{t("start-date")}:</strong>
                                    {moment(scheduleStart).format("MM/DD/YY")}
                                </div>
                            ) : null}
                            {scheduleEnd ? (
                                <div className=" ml-8">
                                    <strong className=" mr-1">{t("end-date")}:</strong>
                                    {moment(scheduleEnd).format("MM/DD/YY")}
                                </div>
                            ) : null}
                        </div>
                        {scheduleDaysType ? (
                            <div className=" mb-5">
                                <strong className=" mr-1">{t(display)}:</strong>
                                {scheduleDaysType === "ALL_DAYS"
                                    ? t("all-days-of-the-week")
                                    : getDaysNamesByValues(scheduleDaysOfWeek)}
                            </div>
                        ) : null}
                        {scheduleDailyType && SIGNAGE_TYPE ? (
                            <div>
                                <strong className=" mr-1">{t("time-range")}:</strong>
                                {scheduleDailyType === "TIME_RANGE"
                                    ? getTimesRangesFormatted(
                                          timeRanges.sort(function (a, b) {
                                              return a?.startTime?.localeCompare(b.startTime);
                                          }) || []
                                      )
                                    : t("all-day")}
                            </div>
                        ) : null}
                        {type === TICKER_TAPE_TYPE || type === BANNER_TYPE ? (
                            <div>
                                <strong className=" mr-1">{t("time-range")}:</strong>
                                {getTimesRangesFormatted(
                                    timeRanges.sort(function (a, b) {
                                        return a?.startTime?.localeCompare(b.startTime);
                                    }) || []
                                )}
                            </div>
                        ) : null}
                        {type === TICKER_TAPE_TYPE || type === BANNER_TYPE ? (
                            <div className=" flex gap-8 mt-5">
                                {scheduleFrequencyLimited && (
                                    <div className=" flex">
                                        <strong className=" mr-1">{t("frecuency")}:</strong>
                                        <div>{`${t("every")} ${scheduleFrequencyDisplay}`}</div>
                                    </div>
                                )}
                                {scheduleFrequencyLimited && type === TICKER_TAPE_TYPE && (
                                    <div className=" flex">
                                        <strong className=" mr-1">{t("repetition")}: </strong>
                                        <div>{scheduleFrequencyRepetitions}</div>
                                        <div className=" ml-1">{t("times")}</div>
                                    </div>
                                )}
                                {scheduleDuration && type === BANNER_TYPE && (
                                    <div className=" flex">
                                        <strong className=" mr-1">{t("duration")}:</strong>
                                        <div>{formatScheduleDuration(scheduleDuration)}</div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div className=" text-gray-700 pb-10 mt-4">{t("no-scheduling-yet")}</div>
                )}
            </div>
        </div>
    );
};

export default ScheduleCard;
