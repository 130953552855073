import React from "react";
import Icon from "components/Icon";
import Button from "components/Button";
import Checkbox from "components/Inputs/Checkbox";

export const PropertySettingsCard = ({ id, title, icon, description, checkbox, button, onClick, to, children }) => (
    <div className="min-h-40 bg-white rounded shadow flex flex-col items-center text-center py-11">
        <div className="flex justify-center items-center">
            <div className="bg-zafiro-200 rounded-full w-16 h-16 flex justify-center items-center">
                <Icon type={icon} className="text-white" size="4xl" />
            </div>
        </div>
        <div className="mt-4">
            <h3 className="text-2xl font-bold text-gray-900">{title}</h3>
            <p className="text-gray-800 px-20 mt-2">{description}</p>
        </div>
        {checkbox && (
            <div className="mt-8">
                <Checkbox id={id} label={checkbox?.label} checked={checkbox?.checked} onChange={checkbox?.onChange} />
            </div>
        )}
        {children ? children : null}
        <div className="mt-10">
            <Button id={id} className="px-10" design="blue" onClick={onClick} to={to}>
                {button}
            </Button>
        </div>
    </div>
);
