import React, { useState, useRef, useEffect } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Button from "components/Button";
import TextInput from "components/TextInput";
import Loading from "components/Loading";
import { useAddMap } from "../graphql/useMaps";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/Session/auth";
import SelectorImageIcon from "components/SelectorImageIcon";
import { TYPE_IMAGES } from "components/SelectorImageIcon";
import { GET_LIBRARY_ASSET_NAME } from "../../Advertising/modals/modalDestination/Destination/Hooks/useDestinationGraphCalls";
import useModalSelectLibraryContent from "../../Advertising/modals/signages/useModalSelectLibraryContent";
import { useLazyQuery } from "react-apollo";
import { Session } from "hooks/Utils/Session";
import Icon from "components/Icon";

const ModalAddMap = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const selectorImageIconRef = useRef(null);
    const { defaultLanguage } = useAuth();
    const { data } = props;

    const [name, setName] = useState(props.name);
    const [loading, setLoading] = useState(false);

    const [getLibraryAssetName, { data: dataLibraryAssetName }] = useLazyQuery(GET_LIBRARY_ASSET_NAME, {
        fetchPolicy: "network-only",
    });
    const { add: addMap } = useAddMap({
        onCompleted: ({ id }) => {
            setLoading(false);
            navigate(`/design/maps/${id}`);
            props.close();
        },
    });
    const { open: openLibrary, close: closeLibrary } = useModalSelectLibraryContent();
    const [validations, setValidations] = useState({ name: true, image: true });

    function validateName(inputName) {
        const isValid = !!inputName;
        setValidations((val) => ({ ...val, name: isValid }));
        return !isValid;
    }

    const handleSaveClick = () => {
        const hasError = validateName(name);
        const { library, link } = selectorImageIconRef?.current?.getData() || {};
        const type = selectorImageIconRef?.current?.getActiveType();

        if (link?.value) {
            setValidations((val) => ({ ...val, image: link?.validation }));
        }
        if (!hasError) {
            setLoading(true);
            addMap({
                variables: {
                    name: [{ lang: defaultLanguage, text: name }],
                    image: {
                        libraryRef:
                            library?.validation && library?.value && type == TYPE_IMAGES.LIBRARY ? library?.value : "",
                        externalUrl: link?.validation && link?.value && type == TYPE_IMAGES.LINK ? link.value : "",
                        adjust: "cover",
                    },
                },
            });
        } else {
            toast.error(t("errors-in-red"));
        }
    };

    const handleCloseClick = () => {
        if (typeof props.close === "function") {
            props.close();
        }
    };

    const handleUpdateImageData = (data, ref, lang) => {
        if (!data?.type || !data?.value || !ref?.current) return;
        const { type, value } = data;
        const updateImageData = (src) => {
            ref.current.setActiveType(type);
            ref.current.updateData({ type, value, validation: true, src });
        };

        switch (type) {
            case TYPE_IMAGES.LIBRARY:
                updateImageData(Session.getDasUrl(`${value}?lang=${lang}`));
                getLibraryAssetName({ variables: { ref: value } });
                break;
            case TYPE_IMAGES.LINK:
                updateImageData(value);
                break;
            default:
                console.warn(`Type image not supported: ${type}`);
        }
    };

    useEffect(() => {
        handleUpdateImageData(data, selectorImageIconRef, defaultLanguage);
    }, [data]);

    return (
        <Modal
            title={t("add-map")}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-add" disabled={loading} onClick={handleSaveClick}>
                        {t("add")}
                    </Button>
                </>
            }
            className="w-5/12 p-10"
        >
            {loading || !defaultLanguage ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-2">
                    <p className="font-bold">{t("name")}*</p>
                    <div className="flex gap-2 justify-items-center items-center">
                        <p>
                            {t(`language:${defaultLanguage}`)}
                            {" ("}
                            <span>{t("default-language")}</span>
                            {")"}
                        </p>
                        <div className="flex-1">
                            <TextInput
                                id="map-input-name"
                                error={!validations.name}
                                value={name}
                                onChange={(val) => {
                                    setName(val);
                                    validateName(val);
                                }}
                            />
                        </div>
                    </div>
                    <div className=" flex gap-2 items-center">
                        <p className="font-bold">{t("image")}</p>
                        <Icon type={"info"} tooltip={t("image-recommended-resolution")}></Icon>
                    </div>

                    <p>{t("add-map-image")}</p>
                    <SelectorImageIcon
                        ref={selectorImageIconRef}
                        allowedImageTypes={[TYPE_IMAGES.LIBRARY, TYPE_IMAGES.LINK]}
                        id="add-map-selector-image-icon"
                        nameAsset={dataLibraryAssetName?.libraryAsset?.response?.name}
                        onError={!validations?.image}
                        onImageSelect={() => {
                            props.close();
                            openLibrary({
                                title: t("choose-an-image"),
                                subtitle: t("select-an-image-of-the-media-library"),
                                isMultiple: false,
                                params: {
                                    filters: "asset",
                                    hideFilter: true,
                                    isMultiple: false,
                                    onSaveAction: (selectedRefs) => {
                                        if (!selectedRefs?.[0]?.ref) return;
                                        closeLibrary();
                                        props.open({
                                            open: props.open,
                                            data: { type: TYPE_IMAGES.LIBRARY, value: selectedRefs[0].ref },
                                            name,
                                        });
                                    },
                                    onCancelAction: () => {
                                        props.open({
                                            open: props.open,
                                            data: {
                                                type: TYPE_IMAGES.LIBRARY,
                                                value: null,
                                            },
                                            name,
                                        });
                                    },
                                },
                            });
                        }}
                    />
                </div>
            )}
        </Modal>
    );
};

export const useModalAddMap = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalAddMap {...newProps} />);
        },
    };
};
