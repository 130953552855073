import NotFound from "components/Section/NotFound";
import { useAuth } from "hooks/Session/auth";
import React from "react";
import { useParams } from "react-router-dom";

import Designs from "sections/design/designs";
import EditDesign from "sections/design/edit";

const List = () => {
    const { id } = useParams();
    const { isCorporate } = useAuth();

    if (!id || id === "list") {
        return <Designs />;
    }

    return isCorporate ? (
        <EditDesign id={id} />
    ) : (
        <div className="overflow-auto" style={{ height: "100vh" }}>
            <NotFound />
        </div>
    );
};

export default List;
