import React from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Modal, { ModalSize, useModal } from "components/Modal";
import { useLogoutDevices, useUpdateMarketing, useUpdateUnregisteredMarketing } from "hooks/Data/useCustomer";
import { toast } from "react-toastify";

export const LogOutDevices = ({ rows, refetch }) => {
    const { t } = useTranslation();
    const { close } = useModal();

    const { logout: logoutDevices } = useLogoutDevices({
        onError: (error) => {
            console.error("Error logging out devices", error);
            toast.error(t("mutation-error"));
        },
        onSuccess: () => toast.success(t("operation-successful")),
    });

    const logout = () => {
        const refs = rows.map((row) => row.ref);
        logoutDevices({ refs });
        refetch();
        close();
    };

    return (
        <Modal
            id="activate-commercial-communications"
            size={ModalSize.SMALL}
            title={t("activate-commercial-communications")}
            onKeyPress={{ Enter: close }}
            footer={
                <>
                    <Button id="cancel" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button id="logout-devices" design="red" onClick={logout}>
                        {t("logout-devices")}
                    </Button>
                </>
            }
        >
            <p>{t("are-you-sure-log-out-devices")}</p>
            <div className="border border-gray-200 rounded py-2 px-4 mt-3 max-h-30 overflow-y-auto">
                <ul>
                    {rows.map((row, index) => (
                        <li
                            key={row.id}
                            className={`py-4 px-2 ${index !== rows.length - 1 ? "border-b border-gray-200" : ""}`}
                        >
                            {row.email}
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    );
};

export const ActivateCommercialCommunications = ({ rows, refetch }) => {
    const { t } = useTranslation();
    const { close } = useModal();

    const { update: updateUnregisteredMarketing } = useUpdateUnregisteredMarketing({
        onError: (error) => {
            console.error("Error updating marketing", error);
            toast.error(t("mutation-error"));
        },
        onSuccess: () => toast.success(t("operation-successful")),
    });

    const { update: updateMarketing } = useUpdateMarketing({
        onError: (error) => {
            console.error("Error updating marketing", error);
            toast.error(t("mutation-error"));
        },
        onSuccess: () => toast.success(t("operation-successful")),
    });

    const updateCustomerMarketing = (id, value) => {
        const numericIDs = id.filter((i) => !isNaN(parseFloat(i)));
        const stringIDs = id.filter((i) => isNaN(parseFloat(i)));

        if (numericIDs) {
            updateMarketing({ id: numericIDs, value });
            refetch();
        }
        if (stringIDs) {
            updateUnregisteredMarketing({ email: stringIDs, value });
            refetch();
        }
    };

    return (
        <Modal
            id="activate-commercial-communications"
            title={t("activate-commercial-communications")}
            size={ModalSize.SMALL}
            onKeyPress={{ Enter: close }}
            footer={
                <div className="flex justify-end space-x-4">
                    <Button id="cancel" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="activate"
                        design="blue"
                        onClick={() => {
                            const ids = rows.map((row) => row.id);
                            updateCustomerMarketing(ids, true);
                            close();
                        }}
                    >
                        {t("activate")}
                    </Button>
                </div>
            }
        >
            <p>{t("are-you-sure-activate-commercial-communications")}</p>
            <div className="border border-gray-200 rounded py-2 px-4 mt-3 max-h-30 overflow-y-auto">
                <ul>
                    {rows.map((row, index) => (
                        <li
                            key={row.id}
                            className={`py-4 px-2 ${index !== rows.length - 1 ? "border-b border-gray-200" : ""}`}
                        >
                            {row.email}
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    );
};
