import { gql } from "apollo-boost";

export const SET_TV_STATUS = gql`
    mutation SetTVSetting($statusReportVal: Boolean) {
        setTVSettings(statusReport: $statusReportVal) {
            error
            ok
        }
    }
`;

export const SET_TV_PERMANENT = gql`
    mutation SetTVSetting($permanentSocketVal: Boolean) {
        setTVSettings(permanentSocket: $permanentSocketVal) {
            error
            ok
        }
    }
`;

export const GET_TV_SETTINGS = gql`
    {
        getInstallationCodeExpiration {
            code
            expiration
            error
        }
        tvSettings {
            permanentSocket
            statusReport
        }
    }
`;

export const GENERATE_CODE = gql`
    mutation {
        generateInstallationCode {
            code
        }
    }
`;
