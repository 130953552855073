import React, { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { GlobalContext } from "contexts/Global";

import Icon from "components/Icon";

const ProjectName = ({ name, hasChainModule, size }) => {
    const { t } = useTranslation();

    const { highlightComponent } = useContext(GlobalContext);

    const icon = hasChainModule ? (
        <Icon type="chain" tooltip={t("This property has access to Global Management content")} size={size} />
    ) : null;

    return name ? (
        <div
            className={classNames({
                "inline-flex items-center space-x-2": true,
                "highlight-component": highlightComponent,
            })}
        >
            {icon}
            <div className="break-normal">{name}</div>
        </div>
    ) : (
        icon
    );
};
ProjectName.displayName = "ProjectName";

export default ProjectName;
