import React, { useEffect } from "react";
import UseIcon from "../../useIcon";
import ReactTooltip from "components/ReactTooltip";
import sanitizeHtml from "sanitize-html";
import EditRoomGroupModal from "../../../hooks/GraphqlCalls/Hotel/Rooms/modals/EditRoomGroupModal";
import { useTranslation } from "react-i18next";
import { useModal } from "components/Modal";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";

const GET_GROUPS = gql`
    query GetRoomGroups($type: RoomType!) {
        roomGroups(filter: { type: $type }) {
            results {
                id
                name
                rooms {
                    id
                    name
                }
            }
        }
    }
`;

function UseRoomGroupCard({ data, parentSectionName }) {
    const { t } = useTranslation();
    const { open } = useModal();

    const roomType = parentSectionName.toLowerCase() === "common-zones" ? "COMMONZONE" : "GUESTROOM";

    const [executeGroupListQuery, { data: groups, error: errorZones, loading: loadingGroups, refetch }] = useLazyQuery(
        GET_GROUPS,
        {
            fetchPolicy: "network-only",
        }
    );

    useEffect(() => {
        executeGroupListQuery({ variables: { type: roomType } });
    }, [data]);

    const { headerText, roomName, roomId } = data;
    const sectionName = `${parentSectionName ? parentSectionName + "-" : ""}room-group`;

    const updatedRoomGroup =
        groups?.roomGroups?.results
            ?.filter((group) => group.rooms.some((room) => room.id === roomId))
            .map((group) => group.name) || [];

    const getToolTip = () => {
        let tooltipText = "";
        updatedRoomGroup.map((group) => {
            tooltipText += `${group}<br>`;
            return null;
        });
        return sanitizeHtml(tooltipText);
    };

    const editGroup = () => {
        open(
            <EditRoomGroupModal
                roomsId={roomId}
                refetch={refetch}
                groups={groups?.roomGroups?.results || []}
                currentGroups={updatedRoomGroup}
                roomName={roomName}
            />
        );
    };

    return (
        <div className={`dc-card-container bg-white text-blue-400  grid grid-cols-3`}>
            <div className="col-span-2 first-capital">{t(headerText)}</div>
            <div className=" text-right">
                {parentSectionName === "common-zones" ? (
                    <UseIcon icon={{ name: "Commonzones_large" }} adjust="text-4xl"></UseIcon>
                ) : (
                    <UseIcon icon={{ name: "room" }} adjust="text-4xl"></UseIcon>
                )}
            </div>
            <div className="col-span-3 first-capital text-lg font-bold mt-5 flex">
                {updatedRoomGroup.length > 0 ? (
                    <>
                        <div
                            className=" inline overflow-ellipsis whitespace-nowrap"
                            style={{ maxWidth: "75%", overflow: "hidden" }}
                        >
                            {`${updatedRoomGroup[0]}`}
                        </div>
                        {updatedRoomGroup.length > 1 ? (
                            <>
                                <span
                                    className="inline-block font-normal text-base cursor-default ml-2 mt-0.5"
                                    data-tip={getToolTip()}
                                >{` +${updatedRoomGroup.length - 1}`}</span>
                                <ReactTooltip
                                    place="bottom"
                                    type="light"
                                    offset={{ top: -8, left: -8 }}
                                    html={true}
                                    border={true}
                                    borderColor="#D3DAE1"
                                />
                            </>
                        ) : null}
                    </>
                ) : (
                    <span onClick={editGroup} className="cursor-pointer">
                        {t("assign")}
                    </span>
                )}
                <UseIcon
                    id={`${sectionName}-edit`}
                    icon={{ name: "edit" }}
                    adjust="inline-block ml-4 text-1xl font-normal"
                    onclickCallback={editGroup}
                ></UseIcon>
            </div>
        </div>
    );
}

export default UseRoomGroupCard;
