import { useContext, useState } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";

import useGQLQuery from "hooks/useQuery";

import { GlobalContext } from "contexts/Global";

const useDesigns = ({ onSuccess, onError } = {}) => {
    const { projects } = useContext(GlobalContext);

    const query = gql`
        query designs($page: Int, $size: Int, $orderBy: OrderBy, $filter: designFilter) {
            designs(page: $page, size: $size, orderBy: $orderBy, filter: $filter) {
                info {
                    count
                }
                projectAssignment {
                    defaultDesignID
                    designID
                    projectID
                    projectRef
                    projectName
                }
                results {
                    author
                    creatorProject {
                        name
                        ref
                        type
                    }
                    id
                    isAssigned
                    isAssignedFromCorporate: corporateDefault
                    isEnabled
                    lastUpdatedTime
                    lastUpdatedUserInfo {
                        name
                        ref
                    }
                    name
                    parentDesignID
                    visibilityScope {
                        name
                        ref
                        type
                    }
                    projectsInUse {
                        assignedDate
                        designID
                        defaultDesignID
                        projectID
                        projectName
                        projectRef
                    }
                    status
                    themeID
                    type
                }
            }
        }
    `;

    const q = useGQLQuery(query, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response?.designs);
            }
        },
        onError,
    });

    // Fill hasChainModule in visibilityScope / projectsInUse from projects from context
    const data =
        q.data?.designs?.results?.length >= 0
            ? q.data.designs.results.map((design) => {
                  if (design?.projectsInUse?.length) {
                      design.projectsInUse = design.projectsInUse.map((project) => {
                          const ctxProject = projects?.length
                              ? projects.find((p) => p.ref === project.projectRef)
                              : null;
                          if (ctxProject) {
                              project.hasChainModule = ctxProject?.hasChainModule;
                          } else {
                              console.warn(`Project ${project.projectRef} not found in context`);
                          }
                          return project;
                      });
                  }
                  if (design?.visibilityScope?.length) {
                      design.visibilityScope = design.visibilityScope.map((destination) => {
                          if (destination?.type === "PROJECT") {
                              const ctxProject = projects?.length
                                  ? projects.find((p) => p.ref === destination.ref)
                                  : null;
                              if (ctxProject) {
                                  destination.hasChainModule = ctxProject?.hasChainModule;
                              } else {
                                  console.warn(`Project ${destination.ref} not found in context`);
                              }
                          }
                          return destination;
                      });
                  }
                  return design;
              })
            : null;

    return {
        ...q,
        data,
        projectAssigment: q.data?.designs?.projectAssignment,
        count: q.data?.designs?.info?.count,
        load: ({
            page,
            size,
            orderBy,
            filter = {
                id: undefined, // int
                type: undefined, // CORPORATE |  LOCAL
                projectsInUse: undefined, // [String]
                lastUpdatedUser: undefined, // [String]
                isAssigned: undefined, // boolean
                isCorporate: undefined, // boolean
                search: undefined, // string
            },
        } = {}) => q.load({ page, size, orderBy, filter }),
    };
};

export const useProjectsWithDesigns = ({ onSuccess, onError } = {}) => {
    const query = gql`
        {
            propertiesWithDesigns {
                assignedDate
                designID
                projectID
                projectName
                projectRef
            }
        }
    `;
    const q = useGQLQuery(query, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response?.propertiesWithDesigns);
            }
        },
        onError,
    });
    return {
        ...q,
        data: q.data?.propertiesWithDesigns,
    };
};

export const useDesign = (id, { onSuccess, onError } = {}) => {
    const q = useDesigns({
        onSuccess: (results) => {
            if (onSuccess) {
                onSuccess(results?.[0]);
            }
        },
        onError,
    });
    return {
        ...q,
        load: (params) => (id ? q.load({ filter: { ...params?.filter, id } }) : null),
        data: q.data?.[0],
        count: q.data?.[0] ? 1 : 0,
    };
};

export const useCreateDesign = ({ onSuccess, onError } = {}) => {
    // Preguntar porque el cambio de respuesta de la mutacion
    const mutation = gql`
        mutation CreateDesign($name: String!, $type: String, $themeID: Int64!) {
            createDesign(name: $name, type: $type, themeID: $themeID) {
                assignedDate
                author
                creatorProject {
                    name
                    ref
                    type
                }
                id
                isAssigned
                isAssignedFromCorporate: corporateDefault
                isEnabled
                lastUpdatedTime
                lastUpdatedUserInfo {
                    name
                    ref
                }
                name
                parentDesignID
                visibilityScope {
                    name
                    ref
                    type
                }
                projectsInUse {
                    assignedDate
                    designID
                    defaultDesignID
                    projectID
                    projectName
                    projectRef
                }
                status
                themeID
                type
            }
        }
    `;
    const q = useGQLQuery(mutation, {
        onSuccess: (results) => {
            if (onSuccess) {
                onSuccess(results?.createDesign);
            }
        },
        onError,
    });
    return {
        ...q,
        data: q.data?.createDesign,
    };
};

export const useAssignToProject = (id, { onSuccess, onError } = {}) => {
    const mutation = gql`
        mutation LinkDesignToProjects($designID: Int64, $projectRefs: [String]!, $action: LinkDesignType!) {
            linkDesignToProjects(designID: $designID, projectRefs: $projectRefs, action: $action) {
                ok
                error
            }
        }
    `;
    const q = useGQLQuery(mutation, {
        onSuccess: (results) => {
            if (onSuccess) {
                onSuccess(results?.assignDesignToProject); // revisar esta respuesta cuando funcione
            }
        },
        onError,
    });
    return {
        ...q,
        post: ({ projectRefs, action }) =>
            q.post({
                designID: id, // int64
                projectRefs, // [string]
                action, // LinkDesignType
            }),
        data: q.data?.assignDesignToProject,
    };
};

export const useUpdateDesign = (id, { onSuccess, onError } = {}) => {
    const mutation = gql`
        mutation UpdateDesign($ID: Int64!, $name: String, $themeID: Int64) {
            updateDesign(ID: $ID, name: $name, themeID: $themeID) {
                assignedDate
                author
                creatorProject {
                    name
                    ref
                    type
                }
                id
                isAssigned
                corporateDefault
                isEnabled
                lastUpdatedTime
                lastUpdatedUserInfo {
                    name
                    ref
                }
                name
                parentDesignID
                visibilityScope {
                    name
                    ref
                    type
                }
                projectsInUse {
                    assignedDate
                    designID
                    defaultDesignID
                    projectID
                    projectName
                    projectRef
                }
                status
                themeID
                type
            }
        }
    `;

    const q = useGQLQuery(mutation, {
        onSuccess: (results) => {
            if (onSuccess) {
                onSuccess(results?.updateDesign);
            }
        },
        onError,
    });

    return {
        ...q,
        post: ({ name, themeID }) =>
            q.post({
                ID: id, // int64
                name, // string
                themeID, // int64
            }),
        data: q.data?.updateDesign,
    };
};

export const useMakeDefaultProjects = ({ onSuccess, onError } = {}) => {
    const mutation = gql`
        mutation LinkDesignToProjects($designID: Int64, $projectRefs: [String]!, $action: LinkDesignType!) {
            linkDesignToProjects(designID: $designID, projectRefs: $projectRefs, action: $action) {
                ok
                error
            }
        }
    `;

    const q = useGQLQuery(mutation, {
        onSuccess: (results) => {
            if (onSuccess) {
                onSuccess(results?.assignDesignToProject);
            }
        },
        onError,
    });

    return {
        ...q,
        post: ({ designID, projectRefs, action }) =>
            q.post({
                designID, // int
                projectRefs, // [string]
                action, // LinkDesignType
            }),
        data: q.data?.assignDesignToProject,
    };
};

export const useManageProperties = ({ onSuccess, onError } = {}) => {
    const mutation = gql`
        mutation UpdateDesignVisibility($designID: Int64, $refs: [String]) {
            updateDesignVisibility(designID: $designID, refs: $refs) {
                error
                ok
            }
        }
    `;

    const q = useGQLQuery(mutation, {
        onSuccess: (results) => {
            if (onSuccess) {
                onSuccess(results?.assignDesignToProject);
            }
        },
        onError,
    });

    return {
        ...q,
        post: ({ designID, refs }) =>
            q.post({
                designID, // int
                refs, // [string]
            }),
        data: q.data?.assignDesignToProject,
    };
};

export const useGetDesignLinkedSignage = (props) => {
    const [signages, setSignages] = useState([]);
    const query = gql`
        query getLinkedSignages($id: Int64!) {
            contentTree(designID: $id) {
                screens {
                    dir {
                        contents {
                            id
                            linkedSignages {
                                id
                                chainRef
                                projectRef
                            }
                        }
                        subDirs {
                            contents {
                                id
                                linkedSignages {
                                    id
                                    chainRef
                                    projectRef
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
    const [getLinkedSignages, { loading, error }] = useLazyQuery(query, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            const signagesInContents = data.contentTree.screens.dir.contents.reduce(
                (acc, { linkedSignages }) => acc.concat(linkedSignages),
                []
            );
            const signagesInSubDir = data.contentTree.screens.dir.subDirs.reduce((acc, { contents }) => {
                acc.concat(contents.reduce((acc, { linkedSignages }) => acc.concat(linkedSignages), []));
                return acc;
            }, []);
            setSignages([...signagesInContents, ...signagesInSubDir]);
            if (props.onCompleted && typeof props.onCompleted == "function") {
                props.onCompleted();
            }
        },
        onError: () => {
            if (props.onError && typeof props.onError == "function") {
                props.onError();
            }
        },
    });
    return {
        getLinkedSignages,
        signages,
        loading,
    };
};

export default useDesigns;
