import React, { useEffect, useState } from "react";
import classNames from "classnames";
import ReactDOMServer from "react-dom/server";
import { Editor } from "react-draft-wysiwyg";

import Button from "components/Button";

import { EditorState, Modifier } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";

const Textarea = ({
    id,
    children,
    value,
    html,
    toolbar,
    disabled,
    readOnly,
    placeholder,
    className,
    style,
    onChange,
    onBlur,
    editor = false,
    resize = true,
}) => {
    const htmlContent = html ?? ReactDOMServer.renderToStaticMarkup(children);
    const [plainContent, setPlainContent] = useState(value);
    const [codeMode, setCodeMode] = useState(!editor);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(stateFromHTML(htmlContent)));
    const getHTMLFromEditorState = () => {
        const contentState = editorState.getCurrentContent();
        return stateToHTML(contentState);
    };

    const appendHTML = (html) => {
        const contentState = stateFromHTML(html);
        const newContentState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            contentState.getBlockMap()
        );
        setEditorState(EditorState.push(editorState, newContentState, "insert-characters"));
    };

    useEffect(() => {
        setPlainContent(value);
    }, [value]);

    return (
        <div
            id={id}
            className={classNames({
                "p-2 bg-white w-full": editor,
                [className]: className,
            })}
            style={style}
        >
            {editor ? (
                <>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        toolbarCustomButtons={[
                            <div className="rdw-inline-wrapper" key="hello-button">
                                <Button
                                    id="editor-hello-button"
                                    className="rdw-option-wrapper icon icon-image text-3xl text-gray-800"
                                    onClick={() => appendHTML("<big><strong><i>HOLA!!</i></strong></big>")}
                                />
                            </div>,
                            <Button
                                id="editor-html-button"
                                key="html-button"
                                className={classNames({
                                    "p-2 ml-auto": true,
                                    "text-black": codeMode,
                                    "text-gray-800": !codeMode,
                                })}
                                narrow={true}
                                onClick={() => setCodeMode((prev) => !prev)}
                            >
                                HTML
                            </Button>,
                        ]}
                        wrapperClassName="rdw-editor-wrapper bg-white text-black"
                        editorClassName={codeMode ? "hidden" : "p-2"}
                        toolbarClassName="py-2 border-b border-gray-400 flex items-center justify-start"
                        toolbarStyle={{ borderWidth: 0, borderBottomWidth: "1px" }}
                        toolbar={toolbar ?? defaultToolbar}
                    />
                    {codeMode ? (
                        <textarea
                            className="p-5 text-base bg-white text-gray-800 w-full"
                            onChange={(event) => {
                                setEditorState(EditorState.createWithContent(stateFromHTML(event.target.value)));
                            }}
                        >
                            {getHTMLFromEditorState()}
                        </textarea>
                    ) : null}
                </>
            ) : (
                <textarea
                    className={classNames({
                        "p-5 text-base rounded w-full h-full": true,
                        "bg-gray-300 text-gray-700": disabled,
                        "text-black": !disabled,
                        "bg-gray-200": !disabled && !readOnly,
                        "bg-transparent border": readOnly,
                        "resize-none": !resize,
                    })}
                    disabled={disabled}
                    readOnly={readOnly}
                    style={{ minHeight: "9em" }}
                    placeholder={placeholder}
                    value={plainContent}
                    onChange={(event) => {
                        if (onChange) {
                            onChange(event.target.value);
                        }
                    }}
                    onBlur={(event) => {
                        if (onBlur) {
                            onBlur(event.target.value);
                        }
                    }}
                >
                    {plainContent}
                </textarea>
            )}
        </div>
    );
};

const defaultToolbar = {
    options: ["inline", "textAlign", "list"],
    inline: {
        options: ["bold", "italic", "underline"],
        bold: { icon: "", className: "icon icon-bold text-3xl text-gray-800 rdw-bold-button " },
        italic: { icon: "", className: "icon icon-italic text-3xl text-gray-800 rdw-italic-button" },
        underline: { icon: "", className: "icon-underline text-3xl text-gray-800 rdw-underline-button" },
    },
    textAlign: {
        options: ["justify", "left", "right"],
        justify: {
            icon: "",
            className: "icon icon-alignJustify text-3xl text-gray-800 rdw-justify-button",
        },
        left: { icon: "", className: "icon icon-alignLeft text-3xl text-gray-800 rdw-left-button" },
        right: { icon: "", className: "icon-alignRight text-3xl text-gray-800 rdw-right-button" },
    },
    list: {
        options: ["ordered", "unordered"],
        unordered: {
            icon: "",
            className: "icon icon-unordered-list text-3xl rdw-unordered-button text-gray-800",
        },
        ordered: {
            icon: "",
            className: "icon icon-ordered-list text-3xl rdw-ordered-button text-gray-800",
        },
    },
};

export default Textarea;
